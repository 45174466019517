import { action } from 'mobx'
import { isIntegerString } from '../../general/helpers/isIntegerString'
import { isUuid } from '../../general/helpers/isUuid'
import { isYmdDate } from '../../general/helpers/isYmdDate'
import { IInputBlockField } from '../interfaces/IInputBlock'

export const validateAndUpdateFields = action(
  (fields: IInputBlockField[], modelValues: any) => {
    // check if field is new or update field type
    for (const field of fields) {
      // update field type if needed
      switch (field.type) {
        case 'text':
          if (typeof modelValues[field.key] !== 'string') {
            modelValues[field.key] = ''
          }
          break
        case 'date':
          if (!isYmdDate(modelValues[field.key])) {
            modelValues[field.key] = null
          }
          break
        case 'select':
          if (!field.options.find((o: string) => o === modelValues[field.key])) {
            modelValues[field.key] = ''
          }
          break
        case 'file':
          if (!isUuid(modelValues[field.key])) {
            modelValues[field.key] = ''
          }
          break
        case 'companySelect':
          if (!isIntegerString(modelValues[field.key])) {
            modelValues[field.key] = ''
          }
          break
        case 'dropdownEntry':
          if (typeof modelValues[field.key] !== 'string') {
            modelValues[field.key] = ''
          }
      }
    }

    // check if model has fields that are not in section
    for (const key in modelValues) {
      if (key === 'id') {
        continue
      }
      if (!fields.find((f) => f.key === key)) {
        delete modelValues[key]
      }
    }

    return modelValues
  },
)
