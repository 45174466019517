import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { Callout } from 'components/Callout'
import { Spinner } from 'components/Spinner'
import { action, makeObservable, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { Collection } from '@byll/hermes'
import * as React from 'react'
import { FixedSizeList as List } from 'react-window'
import debounce from 'lodash/debounce'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { IShiftFilter } from 'contracts/workplan/interfaces/IShiftFilter'
import { ShiftListRow } from './components/ShiftListRow'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { IShiftSearchResultMetadata } from 'contracts/workplan/interfaces/IShiftSearchResultMetadata'
import { Model } from 'components/Form/Model'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'

interface Props {
  shiftId: string | null
  filterModel: Model<IShiftFilter>
}

@observer
export class ShiftList extends React.Component<Props, {}> {
  static contextType = AppContext
  private parentRef: HTMLDivElement | null = null
  private readonly shifts: Collection<
    IShiftSearchResult,
    IShiftSearchResultMetadata,
    IShiftFilter
  >
  private readonly disposers: Disposer[] = []
  @observable private readonly size = { width: 0, height: 0, small: false }

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.shifts = new Collection(
      `/api/${context.instance.id}/workload/shifts/${this.props.filterModel.values.month}/shiftSearchResults`,
      { buildingId: this.props.filterModel.values.buildingId },
    )
  }

  componentDidMount() {
    this.onResize()
    window.addEventListener('resize', this.debouncedResize)
    this.disposers.push(this.shifts.init({ observeQuery: true }))
    this.disposers.push(() => window.removeEventListener('resize', this.debouncedResize))
    this.disposers.push(
      reaction(
        () => this.props.shiftId,
        () => this.debouncedResize(),
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private onResize = action(() => {
    if (!this.parentRef) {
      return
    }
    const size = this.parentRef.getBoundingClientRect()
    this.size.width = size.width
    this.size.height = size.height
    this.size.small = size.width < 800
  })

  private debouncedResize: any = debounce(this.onResize, 200)

  private setParentRef = (ref: HTMLDivElement) => {
    if (!ref) {
      this.parentRef = null
    } else {
      this.parentRef = ref
      this.onResize()
    }
  }

  private renderRow = ({ index, style }) => {
    const shift = this.shifts.resources?.[index]
    if (!shift || !shift.data) {
      return <div style={style} />
    }

    return <ShiftListRow style={style} shift={shift.data} />
  }

  render() {
    if (this.shifts.error && this.shifts.error.id !== RequestPendingError.id) {
      return (
        <div className='absolute top-0 left-0 right-0 bottom-0 flex' key={1}>
          <Callout
            className='my-auto'
            icon='fas fa-exclamation-triangle'
            iconColor='#f56565'
            title='Fehler beim Laden der Schichten'
          />
        </div>
      )
    }
    if (!this.shifts.resources) {
      return <Spinner />
    }
    if (this.shifts.resources.length === 0) {
      return (
        <div className='absolute top-0 left-0 right-0 bottom-0 flex' key={2}>
          <Callout
            className='my-auto'
            icon='fas fa-filter'
            iconColor='#22c55e'
            title={'Keine Schichten mit den aktuellen\nFilterkriterien gefunden'}
          />
        </div>
      )
    }
    return (
      <div
        ref={this.setParentRef}
        className='absolute top-0 left-0 right-0 bottom-0 overflow-hidden'
      >
        {this.size.height > 0 && (
          <List
            key={this.shifts.metadata?.key}
            height={this.size.height}
            itemCount={this.shifts.resources.length}
            itemSize={66}
            width={''}
          >
            {this.renderRow}
          </List>
        )}
      </div>
    )
  }
}
