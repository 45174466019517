import * as React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable } from 'mobx'
import { AppContext } from 'services/connection/models/AppContext'
import { getCellWindowStatus } from './helpers/getCellWindowStatus'
import { Model } from 'components/Form/Model'
import { storage } from 'services/storage'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import styles from './styles.module.scss'

interface Props {
  dragBorder: number
  windowWidth: number
  windowHeight: number
  menuBarHeight: number
  model: Model<any>
  name: string
  parentId: string
}

@observer
export class Movable extends React.Component<Props, {}> {
  static contextType = AppContext
  elem: HTMLDivElement | null = null
  offset: { x: number; y: number } | null = null
  window: { x: number; y: number } = { x: 0, y: 0 }
  private disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  setRef = (ref) => (this.elem = ref)

  componentDidMount(): void {
    window.addEventListener('resize', this.onResize)
    window.addEventListener('mousemove', this.onMouseMove)
    window.addEventListener('mouseup', this.onMouseUp)

    const initial = getCellWindowStatus(this.props.parentId, this.context.user.id)
    this.window.x = initial.x
    this.window.y = initial.y
    setTimeout(this.onResize)
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)
    dispose(this.disposers)
  }

  onMouseDown = (e) => {
    if (!this.elem) {
      return
    }
    const elemRect = this.elem.getBoundingClientRect()
    this.offset = {
      x: e.nativeEvent.pageX - elemRect.left,
      y: e.nativeEvent.pageY - elemRect.top,
    }
    e.preventDefault()
    // remove focus from active element (which is prevented due to preventDefault. Yet preventDefault is required,
    // so that the user doesn't start selecting the entire page while dragging the element around.)
    if (
      document.activeElement !== document.body &&
      (document.activeElement as HTMLElement).blur
    ) {
      ;(document.activeElement as HTMLElement).blur()
    }
  }

  @action onMouseUp = (e) => {
    if (!this.offset) {
      return
    }
    const { left, top } = this.position(e)

    this.window.x = left
    this.window.y = top
    storage.set('cell.detail.window.' + this.context.user.id, { x: left, y: top })

    this.offset = null
  }

  onMouseMove = (e) => {
    if (!this.offset || !this.elem) {
      return
    }
    const { left, top } = this.position(e)

    this.elem.style.left = `${left}px`
    this.elem.style.top = `${top}px`
  }

  onResize = () => {
    if (!this.props.model.values[this.props.name]) {
      return
    }
    // const parent = document.getElementById(WORKPLAN_PARENT_ID)
    if (!this.elem) {
      return
    }
    //const parentRect = parent.getBoundingClientRect()

    let left = this.window.x
    let top = this.window.y

    // fix position to ensure that image is at least partially visible
    if (left < this.props.dragBorder) {
      left = this.props.dragBorder
    }
    if (top < this.props.dragBorder + this.props.menuBarHeight) {
      top = this.props.dragBorder + this.props.menuBarHeight
    }
    if (left > window.innerWidth - this.props.windowWidth - this.props.dragBorder) {
      left = window.innerWidth - this.props.windowWidth - this.props.dragBorder
    }
    if (top > window.innerHeight - this.props.windowHeight - this.props.dragBorder) {
      top = window.innerHeight - this.props.windowHeight - this.props.dragBorder
    }

    this.elem.style.left = `${left}px`
    this.elem.style.top = `${top}px`
    this.window.x = left
    this.window.y = top

    storage.set(`movable.${this.props.name}.window.${this.context.user.id}`, {
      ...this.window,
    })
  }

  position = (e): { left: number; top: number } => {
    if (!this.elem) {
      return { left: 0, top: 0 }
    }
    if (!this.offset) {
      return { left: 0, top: 0 }
    }
    // const parentRect = document.getElementById(WORKPLAN_PARENT_ID)!.getBoundingClientRect()
    const elemRect = this.elem.getBoundingClientRect()

    let left = e.pageX - this.offset.x
    let top = e.pageY - this.offset.y

    // fix position to ensure that image is at least partially visible
    if (left < this.props.dragBorder) {
      left = this.props.dragBorder
    }
    if (top < this.props.dragBorder + this.props.menuBarHeight) {
      top = this.props.dragBorder + this.props.menuBarHeight
    }
    if (left > window.innerWidth - elemRect.width - this.props.dragBorder) {
      left = window.innerWidth - elemRect.width - this.props.dragBorder
    }
    if (top > window.innerHeight - this.props.windowHeight - this.props.dragBorder) {
      top = window.innerHeight - this.props.windowHeight - this.props.dragBorder
    }
    return { left, top }
  }

  render() {
    const { windowWidth, windowHeight } = this.props
    if (!this.props.model.values[this.props.name]) {
      return null
    }

    return (
      <div
        className={styles.window}
        ref={this.setRef}
        style={{ width: windowWidth, height: windowHeight }}
      >
        <div className='h-full' onMouseDown={this.onMouseDown}>
          {this.props.children}
        </div>
      </div>
    )
  }
}
