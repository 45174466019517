import { Model } from 'components/Form/Model'
import { Tooltip } from 'components/Tooltip'
import { IMonthlyEmployeeWorkScheduleFilter } from 'contracts/workplan/interfaces/IMonthlyEmployeeWorkScheduleFilter'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface IListEntry {
  label: string
  shortcut: string
  color: string
  background: string
}

interface Props {
  specialShifts: IShift[]
  model: Model<IMonthlyEmployeeWorkScheduleFilter>
  employeeDetailsModel: Model<{ showEmployeeDetailsBox: boolean }>
  activeBuildingShifts: IShift[] | null
}

@observer
export class Legend extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private list: IListEntry[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    for (const shift of props.specialShifts) {
      const keys = shift.comment.split(',')
      if (keys[0].includes('*')) {
        continue
      }
      this.list.push({
        label: shift.label,
        shortcut: keys[0],
        background: keys[1],
        color: keys[2],
      })
    }
  }

  @action
  toggleEmployeeDetails = () => {
    this.props.employeeDetailsModel.values.showEmployeeDetailsBox =
      !this.props.employeeDetailsModel.values.showEmployeeDetailsBox
  }

  render() {
    return (
      <div className='flex absolute bottom-0 left-0 right-0 h-10 leading-[40px] z-10 bg-gray-800 text-white pl-4'>
        <div className='w-1/2 flex justify-between border-r border-gray-600 pr-2 mr-4'>
          <span
            className='cursor-pointer has-tooltip'
            onClick={this.toggleEmployeeDetails}
          >
            <Tooltip position='right'>Info-Fenster ein-/ausblenden</Tooltip>
            <i className='far fa-window-restore' />
          </span>
          <div className='flex'>
            {this.props.model.values.activeBuilding &&
              this.props.activeBuildingShifts && (
                <ShiftsLegend
                  key={this.props.model.values.activeBuilding}
                  model={this.props.model}
                  activeBuildingShifts={this.props.activeBuildingShifts}
                />
              )}
          </div>
        </div>
        <div className='w-1/2 flex justify-end'>
          <div className='flex items-center justify-center text-center my-2 has-tooltip'>
            <Tooltip>Eingeteilt</Tooltip>
            <span className='mr-2 rounded-md px-1.5 py-0.5 text-white bg-gray-600 text-xs min-h-[20px] min-w-[20px] flex items-center justify-center'>
              x
            </span>
          </div>
          {this.list.map((e, i) => (
            <div
              key={e.shortcut}
              className='flex items-center justify-center text-center my-2 has-tooltip'
            >
              <span
                className='mr-2 rounded-md px-1.5 py-0.5 text-white text-xs min-h-[20px] min-w-[20px] flex items-center justify-center'
                style={{ backgroundColor: e.background, color: e.color }}
              >
                {e.shortcut}
              </span>
              <Tooltip position={i === this.list.length ? 'left' : 'top'}>
                {e.label}
              </Tooltip>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

@observer
class ShiftsLegend extends React.Component<
  { model: Model<IMonthlyEmployeeWorkScheduleFilter>; activeBuildingShifts: IShift[] },
  {}
> {
  render() {
    return (
      <>
        {this.props.activeBuildingShifts.map((shift) => {
          if (!shift) {
            return null
          }
          if (!shift.abbreviation) {
            return null
          }
          return (
            <div
              key={shift.id}
              className='flex items-center text-center my-2 has-tooltip'
            >
              <span
                className='mr-2 rounded-md px-1.5 py-0.5 text-white text-xs min-h-[20px] min-w-[20px] flex items-center justify-center'
                style={{ backgroundColor: shift.color || '#6366F1' }}
              >
                {shift.abbreviation}
              </span>
              <Tooltip>
                <span>
                  {shift.label}
                  <br />
                  {Object.keys(shift.shortcuts).map((key) => {
                    return (
                      <div key={key}>
                        {key}: {shift.shortcuts[key].timeInterval}
                      </div>
                    )
                  })}
                </span>
              </Tooltip>
            </div>
          )
        })}
      </>
    )
  }
}
