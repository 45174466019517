import { IMonthlyEmployeeWorkSchedule } from 'contracts/workplan/interfaces/IMonthlyEmployeeWorkSchedule'

/**
 * @param month
 * @param user
 * @returns values of a IMonthlyEmployeeWorkSchedule Resource as handsontable changes
 */
export function getChangesRow(
  data: IMonthlyEmployeeWorkSchedule,
  index: number,
): Array<[number, string | number, any]> {
  const changes: Array<[number, string | number, any]> = []
  changes.push([index, 'user', data.user])
  changes.push([index, 'day1', data.day1])
  changes.push([index, 'day2', data.day2])
  changes.push([index, 'day3', data.day3])
  changes.push([index, 'day4', data.day4])
  changes.push([index, 'day5', data.day5])
  changes.push([index, 'day6', data.day6])
  changes.push([index, 'day7', data.day7])
  changes.push([index, 'day8', data.day8])
  changes.push([index, 'day9', data.day9])
  changes.push([index, 'day10', data.day10])
  changes.push([index, 'day11', data.day11])
  changes.push([index, 'day12', data.day12])
  changes.push([index, 'day13', data.day13])
  changes.push([index, 'day14', data.day14])
  changes.push([index, 'day15', data.day15])
  changes.push([index, 'day16', data.day16])
  changes.push([index, 'day17', data.day17])
  changes.push([index, 'day18', data.day18])
  changes.push([index, 'day19', data.day19])
  changes.push([index, 'day20', data.day20])
  changes.push([index, 'day21', data.day21])
  changes.push([index, 'day22', data.day22])
  changes.push([index, 'day23', data.day23])
  changes.push([index, 'day24', data.day24])
  changes.push([index, 'day25', data.day25])
  changes.push([index, 'day26', data.day26])
  changes.push([index, 'day27', data.day27])
  changes.push([index, 'day28', data.day28])
  changes.push([index, 'day29', data.day29])
  changes.push([index, 'day30', data.day30])
  changes.push([index, 'day31', data.day31])
  return changes
}
