import { Model } from 'components/Form/Model'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Resource, hermes } from '@byll/hermes'
import {
  IInputBlock,
  IInputBlockField,
} from 'contracts/inputBlock/interfaces/IInputBlock'
import { Message } from 'components/Message'
import { toast } from 'react-toastify'
import { SortableInputGrid } from './components/SortableInputGrid'
import { InputFields } from './components/InputFields'
import { RoundIcon } from 'components/RoundIcon'
import { ColumnSelect } from './components/ColumnSelect'
import * as uuid from 'uuid'
import styles from './styles.module.scss'
import { mergeFieldsAndMandatoryFields } from 'contracts/inputBlock/helpers/mergeFieldsAndMandatoryFields'

interface Props {
  model: Model<any>
  name: string
  inputBlock: Resource<IInputBlock>
  label: string
  multiple?: boolean
  disabled?: boolean
  readonly?: boolean
  editable?: boolean | 'inEditingMode'
  emptyText?: string
  mandatoryFields?: IInputBlockField[]
  toggleEdit?: () => void
  onAdd?: () => void
  onEdit?: (id?: string) => void
  onDelete?: (data: any) => void
}

export const selectOptions = [
  { value: 'text', label: 'Text' },
  { value: 'date', label: 'Datum' },
  { value: 'select', label: 'Auswahl' },
  { value: 'dropdownEntry', label: 'Eigene Auswahl' },
  { value: 'file', label: 'Dokument' },
]

export const colClasses = [
  '',
  'grid-cols-1',
  'grid-cols-2',
  'grid-cols-3',
  'grid-cols-4',
  'grid-cols-5',
  'grid-cols-6',
]

export const colSpanClasses = [
  '',
  'col-span-1',
  'col-span-2',
  'col-span-3',
  'col-span-4',
  'col-span-5',
  'col-span-6',
]

@observer
export class InputSection extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private edit: boolean =
    this.props.editable === 'inEditingMode' ? true : false
  @observable private error: string | null = null
  private sectionModel: Model<any>

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.mergeMandatoryFields()
    this.sectionModel = new Model({ ...props.inputBlock.data?.config })
    if (typeof this.sectionModel.values.fields === 'string') {
      this.sectionModel.values.fields = JSON.parse(this.sectionModel.values.fields)
    }
  }

  private mergeMandatoryFields = () => {
    if (!this.props.mandatoryFields || !this.props.inputBlock.data) {
      return
    }
    this.props.inputBlock.data.config.fields = mergeFieldsAndMandatoryFields(
      this.props.inputBlock.data.config.fields,
      this.props.mandatoryFields,
    )
  }

  @action
  private toggleEdit = async () => {
    if (this.edit) {
      if (!this.sectionModel.isValid()) {
        this.sectionModel.setFocusToLeftTopmostInvalidField()
        return
      }

      this.setKeysForNewFields()
      const error = this.checkFieldsForErrors()
      this.removeDuplicatedOptions()

      if (error) {
        toast.error(error)
        return
      }

      await hermes.patch(
        `/api/${this.context.instance.id}/inputBlocks/${this.props.inputBlock.data?.id}`,
        { config: this.sectionModel.values },
      )

      if (this.props.toggleEdit) {
        this.props.toggleEdit()
      }
    }
    runInAction(() => (this.edit = !this.edit))
  }

  private setKeysForNewFields = () => {
    const newKeys = this.sectionModel.values.fields.filter((f) =>
      f.key.includes('unique_'),
    )
    for (const key of newKeys) {
      let newKey = key.label.toLowerCase().replace(/ /g, '-')
      const existingKeys = this.sectionModel.values.fields.filter((f) =>
        f.key.includes(newKey),
      )
      if (existingKeys.length > 0 || newKey === 'id') {
        newKey = `${newKey}-${existingKeys.length}`
      }
      key.key = newKey

      // set automatic dropdownEntryType for new fields
      if (key.type === 'dropdownEntry' && !key.dropdownEntryType) {
        key.dropdownEntryType = uuid.v4()
      }
    }
  }

  private checkFieldsForErrors = () => {
    let error: string = ''
    for (const field of this.sectionModel.values.fields) {
      if (field.label === '') {
        error = 'Bitte geben Sie eine Bezeichnung für jedes Feld ein.'
      }
    }
    return error
  }

  private removeDuplicatedOptions = () => {
    for (const field of this.sectionModel.values.fields) {
      if (field.type === 'select') {
        const options = field.options.filter(
          (o, i, arr) => arr.findIndex((t) => t === o) === i,
        )
        field.options = options
      }
    }
  }

  @action private cancelEdit = () => {
    this.mergeMandatoryFields()
    this.sectionModel = new Model(this.props.inputBlock.data?.config)
    if (this.props.toggleEdit) {
      this.props.toggleEdit()
    }
    this.edit = false
  }

  render() {
    const cols = this.sectionModel.values.cols || 4
    if (!this.props.inputBlock.data) {
      return null
    }
    if (this.edit) {
      return (
        <div
          key={`${this.edit}${this.props.inputBlock.data?.version}`}
          className='bg-white shadow-md rounded-md p-4'
        >
          <div className='flex'>
            <div className='flex-auto leading-[30px] text-base text-gray-600 truncate'>
              {this.props.label}
            </div>
            <div className='flex-content flex gap-2'>
              <ColumnSelect
                model={this.sectionModel}
                name='cols'
                className='w-[68px]'
                label='Spalten'
                options={[
                  { value: 1, label: '1' },
                  { value: 2, label: '2' },
                  { value: 3, label: '3' },
                  { value: 4, label: '4' },
                  { value: 5, label: '5' },
                  { value: 6, label: '6' },
                ]}
              />
              <RoundIcon
                style={{ borderRadius: '9999px 0 0 9999px', marginRight: -8 }}
                icon='fa fa-times'
                color='danger'
                onClick={this.cancelEdit}
              />
              <RoundIcon
                classNameContainer='text-white bg-green-500'
                style={{ borderRadius: '0 9999px 9999px 0' }}
                icon='fa fa-check'
                color='success'
                onClick={this.toggleEdit}
              />
            </div>
          </div>
          {this.error && (
            <Message className='mb-2' color='danger'>
              {this.error}
            </Message>
          )}
          <SortableInputGrid
            key={cols}
            sectionModel={this.sectionModel}
            mandatoryFields={this.props.mandatoryFields}
          />
        </div>
      )
    }
    return (
      <div
        key={`${this.edit}${this.props.inputBlock.data?.version}`}
        className='bg-white shadow-md rounded-md p-4'
      >
        <div className='flex'>
          <div className='flex-auto leading-[30px] text-base text-gray-600 truncate'>
            {this.props.label}
          </div>
          <div className='flex-content'>
            {this.props.onEdit && !this.props.multiple && (
              <RoundIcon icon='fa fa-edit' onClick={() => this.props.onEdit!()} />
            )}
            {this.props.multiple && this.props.onAdd && (
              <RoundIcon icon='fa fa-plus' onClick={this.props.onAdd} />
            )}
            {this.context.permissions.admin_manageCustomFields &&
              !this.edit &&
              this.props.editable && (
                <RoundIcon
                  classNameContainer='ml-2'
                  icon='fa fa-cog'
                  onClick={this.toggleEdit}
                />
              )}
          </div>
        </div>
        <div className='mt-2'>
          {this.props.multiple &&
            !Array.isArray(this.props.model.values[this.props.name]) && (
              <div className='col-span-full text-red-600 mx-auto my-4'>
                Die Komponente ist multiple, doch die Werte passen nicht.
              </div>
            )}
          {this.sectionModel.values.fields.length === 0 && (
            <div className='col-span-full text-gray-500 mx-auto my-4'>
              Für diesen Abschnitt wurden noch keine Felder definiert.
            </div>
          )}
          {this.sectionModel.values.fields.length > 0 &&
            this.props.multiple &&
            this.props.model.values[this.props.name].length === 0 && (
              <div className='col-span-full text-gray-500 mx-auto my-4'>
                {this.props.emptyText ||
                  'Es befinden sich noch keine Einträge in diesem Abschnitt.'}
              </div>
            )}
          {this.sectionModel.values.fields.length > 0 &&
            (this.props.multiple ? (
              <div>
                {this.props.model.values[this.props.name].map(
                  (data: any, index: number) => (
                    <div
                      key={index}
                      className={index % 2 === 1 ? 'bg-gray-200 -mx-4' : 'undefined'}
                    >
                      {index !== 0 && index % 2 === 1 && (
                        <div className={`${styles.white} h-[20px] mt-2`} />
                      )}
                      <div
                        className={`grid ${colClasses[cols]} ${
                          index % 2 === 1 ? 'px-4' : ''
                        } gap-4 py-4 relative group/row`}
                      >
                        {this.sectionModel.values.fields.map((field: any) => (
                          <InputFields
                            key={field.key}
                            cols={cols}
                            field={field}
                            configFields={this.props.inputBlock.data?.config.fields}
                            model={data}
                            readonly={this.props.readonly}
                            disabled={this.props.disabled}
                          />
                        ))}
                        <div
                          className={`absolute top-1 ${
                            index % 2 === 1 ? 'right-2' : '-right-2'
                          } hidden group-hover/row:block z-1`}
                        >
                          {this.props.onEdit && (
                            <RoundIcon
                              icon='fa fa-edit'
                              onClick={() => this.props.onEdit!(data.id)}
                            />
                          )}
                          {this.props.onDelete && (
                            <RoundIcon
                              classNameContainer='ml-2'
                              color='danger'
                              icon='fa fa-trash'
                              onClick={() => this.props.onDelete!(data.id)}
                            />
                          )}
                        </div>
                      </div>
                      {index % 2 === 1 && (
                        <div className={`${styles.gray} h-[20px] mb-4`} />
                      )}
                    </div>
                  ),
                )}
              </div>
            ) : (
              <div className={`grid ${colClasses[cols]} gap-4 my-4`}>
                {this.sectionModel.values.fields.map((field: any) => (
                  <InputFields
                    key={field.key}
                    cols={cols}
                    field={field}
                    configFields={this.sectionModel.values.fields}
                    model={this.props.model.values[this.props.name]}
                    readonly={this.props.readonly}
                    disabled={this.props.disabled}
                  />
                ))}
              </div>
            ))}
        </div>
      </div>
    )
  }
}
