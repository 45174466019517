import { hermes, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Message } from 'components/Message'
import { RoundIcon } from 'components/RoundIcon'
import { Spinner } from 'components/Spinner'
import { IResident } from 'contracts/residents/interfaces/IResident'
import dayjs from 'dayjs'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { ContractDialog } from './ContractDialog'
import { IWorkplanResident } from 'contracts/residents/interfaces/IWorkplanResident'

interface Props {
  resident: IResident
}

@observer
export class WorkplanEmployee extends React.Component<Props, {}> {
  static contextType = AppContext
  private workplanEmployee: Resource<IWorkplanResident>
  private disposers: Disposer[] = []
  @observable private error: string | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.workplanEmployee = new Resource(
      `/api/${context.instance.id}/workplan/workplanResidents/${props.resident.employeeId}`,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.workplanEmployee.init())
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private deleteEmployee = async () => {
    runInAction(() => (this.error = null))
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/workplan/workplanResidents/${this.props.resident.employeeId}`,
      )
    } catch (_e) {
      runInAction(
        () =>
          (this.error =
            'Beim Entfernen aus der Arbeitsplanung ist ein Fehler aufgetreten.'),
      )
    }
  }

  private restoreEmployee = async () => {
    runInAction(() => (this.error = null))
    try {
      await hermes.create(`/api/${this.context.instance.id}/workplan/workplanResidents`, {
        residentId: this.props.resident.id,
      })
    } catch (_e) {
      runInAction(
        () =>
          (this.error =
            'Beim Wiederherstellen des Mitarbeiters ist ein Fehler aufgetreten.'),
      )
    }
  }

  private openContractDialog = () => {
    const promise = box.custom(
      <ContractDialog
        workplanEmployee={this.workplanEmployee}
        onClose={() => promise.close()}
      />,
      {
        closable: true,
        size: 'md',
      },
    )
  }

  render() {
    if (!this.workplanEmployee.data) {
      return <Spinner />
    }
    return (
      <>
        {this.error && <Message color='danger'>{this.error}</Message>}
        <div
          // onClick={this.openContractDialog}
          className='group rounded-md p-3 border-2 relative bg-gray-100 hover:bg-gray-50 border-blue-500'
        >
          <div className='relative pb-2'>
            <div className='relative flex space-x-3'>
              <div>
                <span className='h-10 w-10 rounded-full flex items-center justify-center ring-8 ring-white bg-indigo-500'>
                  <span className='text-white text-lg text-center' aria-hidden='true'>
                    <i className='fas fa-user' />
                  </span>
                </span>
              </div>
              <div className='min-w-0 flex-1 pt-1.5 justify-between'>
                <div className='text-md text-gray-900 truncate'>
                  {this.workplanEmployee.data.employee.lastName},{' '}
                  {this.workplanEmployee.data.employee.firstName}
                  <span className='text-gray-400'>
                    &nbsp;&nbsp;·&nbsp;&nbsp;erstellt am{' '}
                    {dayjs(this.workplanEmployee.data.employee.createdAt).format(
                      'DD.MM.YYYY',
                    )}
                  </span>
                  {this.workplanEmployee.data.employee.deletedAt && (
                    <span className='text-gray-400'>
                      &nbsp;&nbsp;·&nbsp;&nbsp;gelöscht am{' '}
                      {dayjs(this.workplanEmployee.data.employee.deletedAt).format(
                        'DD.MM.YYYY',
                      )}
                    </span>
                  )}
                </div>
                <div className='mt-2 text-gray-400'>
                  {this.workplanEmployee.data.contract.end
                    ? `Vertrag von ${dayjs(
                        this.workplanEmployee.data.contract.begin,
                      ).format('DD.MM.YYYY')} bis ${
                        this.workplanEmployee.data.contract.end
                      }`
                    : `Vertrag ab ${dayjs(
                        this.workplanEmployee.data.contract.begin,
                      ).format('DD.MM.YYYY')}`}
                </div>
              </div>
              {!this.workplanEmployee.data.employee.deletedAt && (
                <RoundIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    this.deleteEmployee()
                  }}
                  color='danger'
                  icon='fas fa-trash'
                  tooltip='Aus Arbeitsplanung entfernen'
                />
              )}
              {this.workplanEmployee.data.employee.deletedAt && (
                <RoundIcon
                  key={this.workplanEmployee.data.employee.deletedAt}
                  onClick={(e) => {
                    e.stopPropagation()
                    this.restoreEmployee()
                  }}
                  color='primary'
                  icon='fas fa-redo'
                  tooltip='Mitarbeiter wiederherstellen'
                />
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}
