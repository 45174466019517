import { z } from 'zod'
import { isIntegerString } from '../../general/helpers/isIntegerString'
import { isYmdDate } from '../../general/helpers/isYmdDate'

export const ShiftParticipantOverrideValidator = z.object({
  id: z.string().refine(isIntegerString),
  shiftId: z.string().refine(isIntegerString),
  date: z.string().refine(isYmdDate),
  participants: z.number(),
})
