import * as React from 'react'
import { SalaryGroup } from '..'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { hermes } from '@byll/hermes'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { AppContext } from 'services/connection/models/AppContext'
import { box } from 'services/box'
import { Spinner } from 'components/Spinner'
import { observer } from 'mobx-react'
import { SalaryListEntry } from './SalaryListEntry'
import { ISalary } from 'contracts/companies/interfaces/ISalary'
import { ISalaryIncrease } from 'contracts/companies/interfaces/ISalaryIncrease'
import { Message } from 'components/Message'

interface Props {
  salaryGroup: SalaryGroup
  companyId: string
  model: Model<{ validFrom: string; salaryGroupId: string }>
  navigate: (path) => void
}

@observer
export class SalaryGroupItem extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private loading: boolean = false
  @observable private success: string | null = null
  @observable private salaries: ISalaryIncrease[] | null = null
  @observable private failedEmployees: { name: string; reason?: string }[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    void this.loadSalaries()
  }

  private loadSalaries = async () => {
    try {
      const salaries = await hermes.indexOnceNew<ISalary>(
        `/api/${this.context.instance.id}/companies/${this.props.companyId}/salaryIncreases?salaryGroupId=${this.props.salaryGroup.id}&validFrom=${this.props.model.values.validFrom}`,
      )
      runInAction(
        () =>
          (this.salaries = salaries.map((s) => {
            const wage =
              s.id.split('#').length > 1 ? s.id.split('#')[2] : s.id.split('#')[0]
            return {
              id: s.id,
              employees: s.employees,
              wage,
            }
          })),
      )
    } catch (_e) {
      box.alert(
        'Fehler',
        'Beim Laden der Löhne ist ein Fehler aufgetreten. Bitte versuche Sie es später erneut oder kontaktieren Sie einen Systemadministrator.',
      )
    }
  }

  private onSubmit = async () => {
    if (!this.salaries || this.salaries.length === 0) {
      return
    }
    runInAction(() => (this.loading = true))
    try {
      const { failedEmployees } = await hermes.create(
        `/api/${this.context.instance.id}/companies/${this.props.companyId}/salaryIncreases`,
        {
          salaries: this.salaries,
          validFrom: this.props.model.values.validFrom,
        },
      )
      runInAction(() => {
        this.failedEmployees = failedEmployees
        if (failedEmployees.length === 0) {
          this.success = 'Alle Löhne wurden erfolgreich erhöht.'
        }
      })
    } catch (_e) {
      box.alert(
        'Fehler',
        'Beim Speichern der Löhne ist ein Fehler aufgetreten. Bitte versuche Sie es später erneut oder kontaktieren Sie einen Systemadministrator.',
      )
    }
    runInAction(() => (this.loading = false))
  }

  @action
  private resetFailedEmployees = () => {
    this.failedEmployees = []
  }

  render() {
    if (!this.salaries) {
      return <Spinner />
    }
    if (this.failedEmployees.length > 0) {
      const failedEmployees = this.failedEmployees.slice(0, 30)
      return (
        <div className='px-4'>
          <div className='text-gray-600'>
            Bei folgenden Mitarbeitern konnte das Gehalt nicht erhöht werden:
          </div>
          <div className='my-4'>
            {failedEmployees.map((e, i) => (
              <div key={i} className='text-gray-600'>
                {e.name}
                <div className='text-sm text-red-400'>{e.reason}</div>
              </div>
            ))}
            {this.failedEmployees.length > 30 && (
              <div className='truncate text-gray-600'>
                ... und {this.failedEmployees.length - 30} weitere
              </div>
            )}
          </div>
          <Button onClick={this.resetFailedEmployees} className='float-left' outline>
            Zurück
          </Button>
        </div>
      )
    } else {
      return (
        <div className='h-full'>
          <div className='text-lg text-gray-900 -mt-2'>
            {this.props.salaryGroup.label}
          </div>
          {this.salaries.filter((s) => s.id.split('#').length > 1).length > 0 && (
            <table className='min-w-full divide-y divide-gray-300 mt-4 border border-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                  >
                    Arbeitszeit [Std.]
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Pro Woche / Monat
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Bisheriger Lohn
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Neuer Lohn
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {this.salaries
                  .filter((s) => s.id.split('#').length > 1)
                  .map((salary, i) => (
                    <SalaryListEntry key={i} salary={salary} />
                  ))}
              </tbody>
            </table>
          )}
          {this.salaries.filter((s) => s.id.split('#').length === 1).length > 0 && (
            <table className='min-w-full divide-y divide-gray-300 mt-4 border border-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                  >
                    Bisheriger Stundenlohn
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Neuer Stundenlohn
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {this.salaries
                  .filter((s) => s.id.split('#').length === 1)
                  .map((salary, i) => (
                    <SalaryListEntry key={i} salary={salary} />
                  ))}
              </tbody>
            </table>
          )}
          {this.salaries.length === 0 && (
            <div className='text-gray-600 my-6'>
              Es befinden sich keine Mitarbeiter in dieser Gehaltsgruppe.
            </div>
          )}
          {this.success && (
            <Message color='success' className='mt-4'>
              {this.success}
            </Message>
          )}
          <div className='mt-4'>
            <Button
              onClick={() => this.props.navigate(-1)}
              className='float-left'
              outline
            >
              Zurück
            </Button>
            {this.salaries.length > 0 && (
              <Button
                onClick={this.onSubmit}
                className='float-right'
                loading={this.loading}
              >
                Bestätigen
              </Button>
            )}
          </div>
        </div>
      )
    }
  }
}
