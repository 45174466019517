import * as React from 'react'
import { SalaryGroup } from '..'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { InputDate } from 'components/Form/components/InputDate'

interface Props {
  salaryGroups: SalaryGroup[]
  model: Model<{ validFrom: string }>
  navigate: (path) => void
}

@observer
export class SalaryGroupsOverview extends React.Component<Props, {}> {
  private onClick = (groupId: string) => {
    if (!this.props.model.isValid()) {
      this.props.model.setFocusToLeftTopmostInvalidField()
      return
    }
    this.props.navigate(groupId)
  }
  render() {
    return (
      <div
        className='max-h-full overflow-y-auto overflow-x-visible'
        id={this.props.model.id}
      >
        <div className='text font-medium text-gray-900'>
          Wählen Sie ein Datum für die Lohnerhöhung aus:
          <InputDate
            className='my-2'
            model={this.props.model}
            name='validFrom'
            label='Gültig ab'
          />
        </div>
        <div className='mb-4 bg-gray-100 rounded-md p-4 flex flex-col gap-1 max-h-full'>
          {this.props.salaryGroups.map((s, i) => (
            <div
              key={i}
              onClick={() => this.onClick(s.id)}
              className='block rounded-md bg-slate-200 border hover:bg-slate-300 border-indigo-200 p-2 cursor-pointer'
            >
              {s.label}
            </div>
          ))}
        </div>
      </div>
    )
  }
}
