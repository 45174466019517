import * as React from 'react'
import { colSpanClasses } from '..'
import { InputText } from '../../InputText'
import { InputSelect } from '../../InputSelect'
import { InputDate } from '../../InputDate'
import { Model } from 'components/Form/Model'
import { makeObservable, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { InputDocumentMultiple } from '../../InputDocumentMultiple'
import { InputDropdownEntry } from '../../InputDropdownEntry'
import { IInputBlockField } from 'contracts/inputBlock/interfaces/IInputBlock'
import { InputCompany } from '../../InputCompany'
import { validateAndUpdateFields } from 'contracts/inputBlock/helpers/validateAndUpdateFields'

interface Props {
  field: IInputBlockField
  cols: number
  configFields: any
  model: any
  readonly?: boolean
  disabled?: boolean
}

@observer
export class InputFields extends React.Component<Props, {}> {
  @observable.ref private customFieldModel: Model<any>
  @observable error: boolean = false
  private disposer: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.customFieldModel = new Model(
      validateAndUpdateFields(this.props.configFields, props.model),
    )
  }

  componentDidMount(): void {
    this.disposer.push(
      reaction(
        () => this.props.model,
        (model) => {
          this.customFieldModel = new Model(
            validateAndUpdateFields(this.props.configFields, model),
          )
        },
      ),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposer)
  }

  render() {
    const colSpan =
      this.props.field.colspan > this.props.cols
        ? this.props.cols
        : this.props.field.colspan
    if (this.props.field.type === 'text') {
      return (
        <InputText
          key={`${this.props.field.key}-${this.error}`}
          className={colSpanClasses[colSpan]}
          hasError={
            this.props.field.allowEmpty === false &&
            !this.props.readonly &&
            !this.customFieldModel.values[this.props.field.key]
          }
          model={this.customFieldModel}
          name={this.props.field.key}
          label={this.props.field.label}
          readOnly={this.props.readonly}
          disabled={this.props.disabled}
        />
      )
    } else if (this.props.field.type === 'date') {
      return (
        <InputDate
          key={this.props.field.key}
          className={colSpanClasses[colSpan]}
          hasError={
            this.props.field.allowEmpty === false &&
            !this.props.readonly &&
            !this.customFieldModel.values[this.props.field.key]
          }
          model={this.customFieldModel}
          name={this.props.field.key}
          label={this.props.field.label}
          disabled={this.props.disabled}
        />
      )
    } else if (this.props.field.type === 'select') {
      return (
        <InputSelect
          key={this.props.field.key}
          className={colSpanClasses[colSpan]}
          hasError={
            this.props.field.allowEmpty === false &&
            !this.props.readonly &&
            !this.customFieldModel.values[this.props.field.key]
          }
          model={this.customFieldModel}
          name={this.props.field.key}
          label={this.props.field.label}
          options={this.props.field.options.map((o) => ({ value: o, label: o }))}
          readOnly={this.props.readonly}
          disabled={this.props.disabled}
        />
      )
    } else if (this.props.field.type === 'file') {
      return (
        <InputDocumentMultiple
          key={this.props.field.key}
          scope='section'
          className={colSpanClasses[colSpan]}
          hasError={
            this.props.field.allowEmpty === false &&
            !this.props.readonly &&
            !this.customFieldModel.values[this.props.field.key]
          }
          model={this.customFieldModel}
          name={this.props.field.key}
          label={this.props.field.label}
          disabled={this.props.disabled}
          preview
        />
      )
    } else if (this.props.field.type === 'dropdownEntry') {
      return (
        <InputDropdownEntry
          key={this.props.field.key}
          className={colSpanClasses[colSpan]}
          hasError={
            this.props.field.allowEmpty === false &&
            !this.props.readonly &&
            !this.customFieldModel.values[this.props.field.key]
          }
          model={this.customFieldModel}
          name={this.props.field.key}
          label={this.props.field.label}
          disabled={this.props.disabled}
          readOnly={this.props.readonly}
          type={this.props.field.dropdownEntryType!}
          allowCreate
        />
      )
    } else if (this.props.field.type === 'companySelect') {
      return (
        <InputCompany
          key={this.props.field.key}
          className={colSpanClasses[colSpan]}
          hasError={
            this.props.field.allowEmpty === false &&
            !this.props.readonly &&
            !this.customFieldModel.values[this.props.field.key]
          }
          model={this.customFieldModel}
          name={this.props.field.key}
          label={this.props.field.label}
          disabled={this.props.disabled}
        />
      )
    } else {
      return null
    }
  }
}
