import { hermes } from '@byll/hermes'
import { Model } from 'components/Form/Model'
import { InputMonth } from 'components/Form/components/InputMonth'
import { Message } from 'components/Message'
import { RoundIcon } from 'components/RoundIcon'
import { IShiftParticipantOverride } from 'contracts/workplan/interfaces/IShiftParticipantOverride'
import { makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import { DateOverridesEditDialog } from './components/DateOverridesEditDialog'
import { OverrideList } from './components/OverrideList'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { Spinner } from 'components/Spinner'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'

interface Props {
  shift: IShiftSearchResult
  month: string
}

@observer
export class DateOverrides extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private monthModel: Model<{ month: string }>
  @observable private error: string | null = null
  @observable private dateOverrides: IShiftParticipantOverride[] | null = null
  private disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.monthModel = new Model({ month: props.month })
  }

  componentDidMount(): void {
    this.loadOverrides()
    this.disposers.push(
      reaction(
        () => this.monthModel.values.month,
        () => this.loadOverrides(),
      ),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private loadOverrides = async () => {
    try {
      const res = await hermes.indexOnceNew<IShiftParticipantOverride>(
        `/api/${this.context.instance.id}/workplan/shiftParticipantOverrides?shiftId=${this.props.shift.id}&month=${this.monthModel.values.month}`,
      )
      runInAction(() => (this.dateOverrides = res))
    } catch (e: any) {
      runInAction(
        () =>
          (this.error =
            'Beim Laden der Abweichungen ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.'),
      )
    }
  }

  private editDateOverrides = async () => {
    const promise = box.custom(
      <DateOverridesEditDialog
        overrides={this.dateOverrides!}
        shift={this.props.shift}
        month={this.monthModel.values.month}
        onClose={(result?: boolean) => promise.close(result)}
      />,
      { closable: true, context: this.context, size: 'md' },
    )
    const result = await promise
    if (result) {
      this.loadOverrides()
    }
  }

  render() {
    if (!this.dateOverrides) {
      return <Spinner />
    }
    return (
      <>
        {this.context.permissions.workplan_shifts > 1 && (
          <div className='absolute top-4 right-4'>
            <RoundIcon icon='fa fa-edit' onClick={this.editDateOverrides} />
          </div>
        )}
        <div className='text-lg text-gray-600'>Abweichung - Datum</div>
        {this.error && (
          <Message className='mb-6' color='danger'>
            {this.error}
          </Message>
        )}
        <Message color='primary' className='mt-4 mb-6'>
          Legen Sie hier die Abweichungen für ein spezifisches Datum des ausgewählten
          Monats fest.
        </Message>
        <div className='my-4'>
          <InputMonth model={this.monthModel} name='month' />
          {this.dateOverrides.length === 0 && (
            <div className='text-gray-700 mt-4'>
              Für diesen Monat sind keine Abweichungen vorhanden.
            </div>
          )}
          {this.dateOverrides.length > 0 && (
            <OverrideList
              overrides={this.dateOverrides}
              shift={this.props.shift}
              readOnly
            />
          )}
        </div>
      </>
    )
  }
}
