import { Model } from 'components/Form/Model'
import { InputBuilding } from 'components/Form/components/InputBuilding'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputDate } from 'components/Form/components/InputDate'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { InputText } from 'components/Form/components/InputText'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import * as React from 'react'
import styles from './styles.module.scss'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { ShiftRepeatDays } from './ShiftRepeatDays'
import { IShiftFilter } from 'contracts/workplan/interfaces/IShiftFilter'
import { IShortcut, ShortcutRow } from './ShortcutRow'
import { observer } from 'mobx-react'
import { InputColor } from 'components/Form/components/InputColor'

const typeOptions: InputSelectOption[] = [
  { value: 'shift', label: 'Schicht' },
  { value: 'training', label: 'Schulung' },
  { value: 'on call', label: 'Bereitschaft' },
]

const participantValidationOptions: InputSelectOption[] = [
  { value: 'exact', label: 'Genau' },
  { value: 'max', label: 'Max' },
]

interface Props {
  model: Model<IShiftSearchResult>
  filterModel: Model<IShiftFilter>
  shortcuts: IShortcut[]
  readOnly?: boolean
  onAdd?: () => void
  onDelete?: (shortcut: IShortcut) => void
}

@observer
export class ShiftOverviewForm extends React.Component<Props, {}> {
  render() {
    const isNew = !this.props.model.values.id
    return (
      <div className='grid grid-cols-4 gap-4'>
        <InputText
          className='col-span-2'
          name='label'
          model={this.props.model}
          label='Bezeichnung'
          readOnly={this.props.readOnly}
          disabled={this.props.readOnly}
        />
        <div className='col-span-1 flex gap-2 items-center'>
          <InputText
            name='abbreviation'
            model={this.props.model}
            label='Schichtkürzel'
            readOnly={this.props.readOnly}
            disabled={this.props.readOnly}
          />
          <InputColor
            name='color'
            position='bottom'
            model={this.props.model}
            readOnly={this.props.readOnly}
          />
        </div>
        <InputText
          className='col-span-1'
          name='recovery'
          model={this.props.model}
          label='Ruhezeit'
          readOnly={this.props.readOnly}
          disabled={this.props.readOnly}
        />
        <InputDate
          className='col-span-1'
          name='repeatStartDate'
          model={this.props.model}
          label='Planung ab'
          readOnly={this.props.readOnly}
          disabled={this.props.readOnly}
        />
        <InputDate
          className='col-span-1'
          name='repeatStopDate'
          model={this.props.model}
          label='Bis'
          placeholder='Unbegrenzt'
          readOnly={this.props.readOnly}
          disabled={this.props.readOnly}
        />
        <div className='flex-auto relative col-span-1'>
          <InputDecimal
            model={this.props.model}
            style={{ borderRadius: '0 0.375rem 0.375rem 0' }}
            name='maxNumParticipants'
            label='Teilnehmerzahl'
            className={styles.participantPaddingExact}
            scale={0}
            precision={10}
            readOnly={this.props.readOnly}
            disabled={this.props.readOnly}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              overflow: 'hidden',
              width: 80,
            }}
          >
            <InputSelect
              model={this.props.model}
              style={{ borderRadius: '0.375rem 0 0 0.375rem' }}
              name='participantValidation'
              options={participantValidationOptions}
              tabIndex={-1}
              readOnly={this.props.readOnly}
              disabled={this.props.readOnly}
            />
          </div>
        </div>
        <InputSelect
          className='col-span-1'
          options={typeOptions}
          name='type'
          model={this.props.model}
          label='Typ'
          readOnly={!isNew || this.props.readOnly}
          disabled={!isNew || this.props.readOnly}
        />
        <InputCompound
          className='col-span-2'
          name='compoundId'
          model={this.props.model}
          label='Gelände'
          readOnly={!isNew}
          disabled={!isNew}
        />
        <InputBuilding
          className='col-span-2'
          name='buildingId'
          model={this.props.model}
          label='Gebäude'
          readOnly={!isNew}
          disabled={!isNew}
          compoundId={this.props.model.values.compoundId}
        />
        <div className='col-span-4 flex flex-col gap-4'>
          {this.props.shortcuts.map((shortcut) => {
            return (
              <ShortcutRow
                key={shortcut.key}
                shortcut={shortcut}
                hasBreak={this.props.shortcuts[0].hasBreak}
                shiftId={this.props.model.values.id}
                readOnly={this.props.readOnly}
                onAdd={
                  shortcut.label === 'x' && this.props.shortcuts.length <= 20
                    ? this.props.onAdd
                    : undefined
                }
                onDelete={shortcut.label !== 'x' ? this.props.onDelete : undefined}
              />
            )
          })}
        </div>
        <InputTextarea
          className='col-span-4'
          name='comment'
          model={this.props.model}
          label='Notiz'
          rows={2}
          readOnly={this.props.readOnly}
          disabled={this.props.readOnly}
        />
        <ShiftRepeatDays model={this.props.model} readOnly={this.props.readOnly} />
      </div>
    )
  }
}
