import { hermes } from '@byll/hermes'
import { RoundIcon } from 'components/RoundIcon'
import { IShiftCompany } from 'contracts/workplan/interfaces/IShiftCompany'
import { IShiftQualification } from 'contracts/workplan/interfaces/IShiftQualification'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { ShiftQualificationsForm } from './components/ShiftQualificationsForm'
import { box } from 'services/box'
import { ShiftQualificationsEditDialog } from './components/ShiftQualificationsEditDialog'
import { Spinner } from 'components/Spinner'
import { cloneDeep } from 'lodash'

interface Props {
  shift: IShiftSearchResult
}

@observer
export class ShiftDialogQualificationsTab extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private shiftCompanies: IShiftCompany[] | null = null
  @observable private shiftQualifications: IShiftQualification[] | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    void this.loadShiftCompanies()
    void this.loadShiftQualifications()
  }

  private loadShiftCompanies = async () => {
    const res = await hermes.indexOnceNew<IShiftCompany>(
      `/api/${this.context.instance.id}/workplan/shiftCompanies?shiftId=${this.props.shift.id}`,
    )
    runInAction(() => (this.shiftCompanies = res))
  }

  private loadShiftQualifications = async () => {
    const res = await hermes.indexOnceNew<IShiftQualification>(
      `/api/${this.context.instance.id}/workplan/shiftQualifications?shiftId=${this.props.shift.id}`,
    )
    runInAction(() => (this.shiftQualifications = res))
  }

  private editQualifications = async () => {
    if (!this.shiftCompanies || !this.shiftQualifications) {
      return
    }
    const companiesCopy = cloneDeep(this.shiftCompanies)
    const qualificationsCopy = cloneDeep(this.shiftQualifications)
    const promise = box.custom(
      <ShiftQualificationsEditDialog
        shift={this.props.shift}
        shiftCompanies={companiesCopy}
        shiftQualifications={qualificationsCopy}
        onClose={(result?: boolean) => promise.close(result)}
      />,
      { closable: true, context: this.context, size: 'md' },
    )
    const result = await promise
    if (result) {
      await this.loadShiftCompanies()
      await this.loadShiftQualifications()
    }
  }

  render() {
    if (!this.shiftCompanies || !this.shiftQualifications) {
      return (
        <div className='p-6'>
          <div className='bg-white rounded-md shadow-md p-4 relative min-h-20'>
            <Spinner />
          </div>
        </div>
      )
    }
    return (
      <div className='p-6'>
        <div className='bg-white rounded-md shadow-md p-4 relative'>
          {this.context.permissions.workplan_shifts > 1 && (
            <div className='absolute top-4 right-4'>
              <RoundIcon icon='fa fa-edit' onClick={this.editQualifications} />
            </div>
          )}
          <ShiftQualificationsForm
            shift={this.props.shift}
            shiftCompanies={this.shiftCompanies}
            shiftQualifications={this.shiftQualifications}
            readOnly
          />
        </div>
      </div>
    )
  }
}
