import * as React from 'react'
import { NavLink, Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { AppContext } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { hermes } from '@byll/hermes'
import { Spinner } from 'components/Spinner'
import { UnsyncedCollection } from 'modules/Residents/modules/FindRecord/models/UnsyncedCollection'
import { EmployeeDialogOverviewTab } from './components/EmployeeDialogOverviewTab'
import { EmployeeDialogHoursTab } from './components/EmployeeDialogHoursTab'
import { EmployeeDialogRecordTab } from './components/EmployeeDialogRecordTab'
import { EmployeeDialogCardTab } from './components/EmployeeDialogCardTab'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { NotFoundError } from 'contracts/errors/HermesErrors'
import { Model } from 'components/Form/Model'
import { IEmployeeSearchResultsFilter } from 'contracts/employees/interfaces/IEmployeeSearchResultsFilter'
import { checkPermissionForEmployeeFile } from 'contracts/employees/helpers/checkPermissionForEmployeeFile'

const tabs: Tab[] = [
  { label: 'Übersicht', to: `overview` },
  { label: 'Stundenkonto', to: `hours` },
  { label: 'Akte', to: `record`, permission: 'user_mitarbeiterakte' },
  { label: 'Ausweis', to: `card` },
]

interface Props {
  employees: UnsyncedCollection<IEmployeeSearchResult>
  employeeId: string | null
  filterModel: Model<IEmployeeSearchResultsFilter>
}

interface Tab {
  label: string
  to: string
  permission?: string
}

export const EmployeeDialog: React.FC<Props> = ({
  employees,
  employeeId,
  filterModel,
}) => {
  const context = React.useContext(AppContext)

  return (
    <div className='min-w-[700px]'>
      <div className='h-[66px] pt-0.5 border-b border-gray-300 px-6 sticky z-2 top-0 bg-gray-100'>
        <nav className='-mb-px flex space-x-8 flex-wrap' aria-label='Tabs'>
          {tabs.map((tab: Tab, i) => {
            if (
              tab.to === 'record' &&
              !checkPermissionForEmployeeFile(
                context.permissions.user_mitarbeiterakte,
                employees.resources?.find((e) => e.id === employeeId)?.tagIds || [],
              )
            ) {
              return null
            } else if (tab.permission && !context.permissions[tab.permission]) {
              return null
            }
            return (
              <NavLink
                key={i}
                to={`${employeeId}/${tab.to}`}
                className={({ isActive }) =>
                  isActive
                    ? 'border-indigo-500 text-indigo-600 whitespace-nowrap pb-[21px] pt-[21px] px-1 border-b-2 font-medium text-sm'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-[21px] pt-[21px] px-1 border-b-2 font-medium text-sm'
                }
                aria-current='page'
              >
                {tab.label}
              </NavLink>
            )
          })}
        </nav>
      </div>

      {employeeId && (
        <EmployeeDialogTabs
          key={employeeId}
          employeeId={employeeId}
          employees={employees}
          filterModel={filterModel}
        />
      )}
    </div>
  )
}

export const EmployeeDialogTabs: React.FC<Props> = observer((props) => {
  const navigate = useNavigate()
  const context = React.useContext(AppContext)
  const [error, setError] = React.useState<string | null>(null)
  const [employee, setEmployee] = React.useState<IEmployeeSearchResult | null>(() => {
    return (
      props.employees.resources?.find((employee) => employee.id === props.employeeId) ||
      null
    )
  })

  React.useEffect(() => {
    if (employee || !props.employeeId) {
      return
    }
    hermes
      .getOnceNew<IEmployeeSearchResult>(
        `/api/${context.instance.id}/workload/employeeSearchResults/${props.employeeId}`,
      )
      .then((data) => setEmployee(data))
      .catch((error: any) =>
        setError(
          error?.id === NotFoundError.id
            ? 'Dieser Mitarbeiter ist nicht in Inuv angelegt.'
            : 'Beim Laden ist ein Fehler aufgetreten.',
        ),
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onClose() {
    navigate(`/employees`)
  }

  if (error) {
    return (
      <div className='absolute top-16 bottom-0 left-0 right-0 flex flex-col'>
        <LoadingError title={error} />
      </div>
    )
  }

  if (!employee) {
    return <Spinner delay={400} />
  }

  return (
    <div>
      {/* Tab routes */}
      <Routes>
        <Route
          path={`${employee.id}/overview`}
          element={
            <EmployeeDialogOverviewTab
              filterModel={props.filterModel}
              employee={employee}
            />
          }
        />
        <Route
          path={`${employee.id}/hours`}
          element={<EmployeeDialogHoursTab employee={employee} />}
        />
        <Route
          path={`${employee.id}/record`}
          element={<EmployeeDialogRecordTab employee={employee} />}
        />
        <Route
          path={`${employee.id}/card`}
          element={<EmployeeDialogCardTab employee={employee} onClose={onClose} />}
        />
        <Route path='*' element={<Navigate to={`${employee.id}/overview`} replace />} />
      </Routes>
    </div>
  )
})
