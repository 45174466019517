import { XIcon } from '@heroicons/react/outline'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { InputText } from 'components/Form/components/InputText'
import { IEmployeeReportDefinition } from 'contracts/workload/interfaces/IEmployeeReportDefinition'
import * as React from 'react'
import { Dialog as UIDialog } from '@headlessui/react'
import { z } from 'zod'
import { Spinner } from 'components/Spinner'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { ReportFields } from './ReportFields'
import { EmployeeReportDefinitionColumn } from 'contracts/report/helpers/EmployeeReportDefinition'
import * as uuid from 'uuid'
import { observer } from 'mobx-react'
import { hermes } from '@byll/hermes'
import { Message } from 'components/Message'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  definition: IEmployeeReportDefinition
  onClose: () => void
  loadDefinitionOptions: () => void
}

function preventSelection(_, event) {
  // Safari/IE select text on invoice position reorder (click/drag leads to select).
  // Preventing default action fixes this problem.
  event.preventDefault()
}

@observer
export class ReportForm extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IEmployeeReportDefinition>
  @observable private saving = false
  @observable private message: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(
      { ...props.definition },
      z.object({ label: z.string().min(1) }),
    )
  }

  @action private addRow = () => {
    this.model.values.columns.push({
      id: uuid.v4(),
      section: '',
      column: '',
      aggregate: null,
      aggregateValue: '',
      selected: null,
    })
  }

  @action private onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return
    }
    const current = this.model.values.columns[oldIndex]
    this.model.values.columns.splice(oldIndex, 1)
    this.model.values.columns.splice(newIndex, 0, current)
  }

  @action private deleteRow = (column: EmployeeReportDefinitionColumn) => {
    for (let i = 0; i < this.model.values.columns.length; i++) {
      if (this.model.values.columns[i].id === column.id) {
        this.model.values.columns.splice(i, 1)
        return
      }
    }
  }

  private onSave = async () => {
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    if (this.model.values.columns.length === 0) {
      runInAction(() => (this.message = 'Bitte fügen Sie mindestens eine Spalte hinzu.'))
      return
    }
    for (const column of this.model.values.columns) {
      if (!column.section || !column.column) {
        runInAction(() => (this.message = 'Bitte füllen Sie alle Felder aus.'))
        return
      }
      if (
        column.section &&
        column.column &&
        column.aggregate === 'columns' &&
        !column.aggregateValue
      ) {
        runInAction(() => (this.message = 'Bitte füllen Sie alle Felder aus.'))
        return
      }
    }
    runInAction(() => {
      this.message = null
      this.saving = true
    })
    try {
      if (!this.model.values.id) {
        await hermes.create(
          `/api/${this.context.instance.id}/workload/reportDefinitions`,
          { ...this.model.values, id: undefined },
        )
      } else {
        await hermes.patch(
          `/api/${this.context.instance.id}/workload/reportDefinitions/${this.model.values.id}`,
          { ...this.model.values, id: undefined },
        )
      }
      this.props.onClose()
    } catch (_e) {
      runInAction(() => {
        this.message = 'Beim Speichern ist ein Fehler aufgetreten.'
        this.saving = false
      })
    }
  }

  private onDelete = async () => {
    try {
      if (
        !window.confirm(
          'Sind Sie sicher, dass Sie diesen Report unwiderruflich löschen möchten?',
        )
      ) {
        return
      }
      await hermes.delete(
        `/api/${this.context.instance.id}/workload/reportDefinitions/${this.model.values.id}`,
      )
      this.props.loadDefinitionOptions()
      this.props.onClose()
    } catch (_e) {
      runInAction(() => {
        this.message = 'Beim Löschen ist ein Fehler aufgetreten.'
      })
    }
  }

  render() {
    return (
      <div>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6 pb-4 border-b border-gray-200'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <UIDialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                {this.model.values.id
                  ? `${this.model.values.label} Report bearbeiten`
                  : 'Report erstellen'}
              </UIDialog.Title>
            </div>
          </div>
        </div>

        <div id={this.model.id} className='p-6 bg-gray-100'>
          <div className='bg-white shadow-md rounded-md p-4'>
            {this.message && (
              <Message className='mb-4' color='danger'>
                {this.message}
              </Message>
            )}
            <InputText label='Name' model={this.model} name='label' />
            <div className='my-4'>
              {!this.saving && (
                <ReportFields
                  columns={this.props.definition.columns}
                  onSortEnd={this.onSortEnd}
                  onSortStart={preventSelection}
                  onDelete={this.deleteRow}
                />
              )}
            </div>
            {!this.saving && (
              <div style={{ paddingLeft: 27 }}>
                <Button color='secondary' outline block onClick={this.addRow}>
                  Spalte hinzufügen
                </Button>
              </div>
            )}
            {this.saving && <Spinner />}
          </div>
        </div>

        <div
          className='py-4 px-6 sticky z-1 text-right bottom-0 bg-white border-t border-gray-200'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          {this.props.definition.id && (
            <Button color='danger' onClick={this.onDelete}>
              Löschen
            </Button>
          )}
          <Button color='secondary' className='ml-2' outline onClick={this.props.onClose}>
            Schließen
          </Button>
          <Button color='primary' className='ml-2' onClick={this.onSave}>
            Speichern
          </Button>
        </div>
      </div>
    )
  }
}
