import { Model } from 'components/Form/Model'
import * as React from 'react'
import { Route, Routes } from 'react-router'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import Handsontable from 'handsontable'
import { toast } from 'react-toastify'
import { Collection, Resource, hermes } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { observer } from 'mobx-react'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { IMonthlyEmployeeWorkSchedule } from 'contracts/workplan/interfaces/IMonthlyEmployeeWorkSchedule'
import { ResourceChange } from '@byll/hermes/lib/interfaces/ResourceChange'
import { getChangesRow } from '../../helpers/getChangesRow'
import dayjs from 'dayjs'
import { makeObservable, observable, runInAction } from 'mobx'
import { cloneDeep } from 'lodash'
import { IMonthlyEmployeeWorkScheduleFilter } from 'contracts/workplan/interfaces/IMonthlyEmployeeWorkScheduleFilter'
import { PlanGrid } from './components/PlanGrid'
import { RealGrid } from './components/RealGrid'
import { IShiftParticipantOverride } from 'contracts/workplan/interfaces/IShiftParticipantOverride'
import { Spinner } from 'components/Spinner'
import { IEmployeeDayScheduleCell } from 'contracts/workplan/interfaces/IEmployeeDayScheduleCell'

interface Props {
  isShiftView: boolean
  model: Model<IMonthlyEmployeeWorkScheduleFilter>
  specialShifts: IShift[]
  shift: IShift | null
  plannableShiftsMap: Map<string, IShift>
  setSelectedDateAndUser: (userId: string | null, column: number) => void
}

@observer
export class WorkplanGrids extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly plannings: Collection<IMonthlyEmployeeWorkSchedule>
  private disposers: Disposer[] = []
  private hot: Handsontable | null = null
  @observable shifts: IShift[] | null = null
  @observable participantsOverrides: Map<string, number> | null = null
  private updateCaptions: (() => void) | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.plannings = new Collection<IMonthlyEmployeeWorkSchedule>(
      `/api/${context.instance.id}/workplan/views/${props.model.values.employeePoolId}-${props.model.values.month}-${props.model.values.type}/schedules`,
      { mountedAt: dayjs().toISOString() },
      {
        onAdd: this.onAdd,
        onChange: this.onServerSideChange,
        onRemove: this.onRemove,
      },
    )
  }

  componentDidMount() {
    if (this.props.isShiftView) {
      this.loadParticipantsOverrides()
    }
    this.disposers.push(this.plannings.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  getRef = (hot: Handsontable | null, updateCaptions: () => void) => {
    this.hot = hot
    this.updateCaptions = updateCaptions
  }

  private loadParticipantsOverrides = async () => {
    try {
      const res = await hermes.indexOnceNew<IShiftParticipantOverride>(
        `/api/${this.context.instance.id}/workplan/shiftParticipantOverrides?shiftId=${this.props.model.values.shiftId}&month=${this.props.model.values.month}`,
      )
      const overrides = new Map<string, number>()
      for (const o of res) {
        overrides.set(o.date, o.participants)
      }
      runInAction(() => (this.participantsOverrides = overrides))
    } catch (_e) {
      runInAction(() => (this.participantsOverrides = null))
    }
  }

  private onClientSideChange = async (changes: Map<string, any[]>) => {
    if (
      (!this.props.isShiftView && !this.props.model.values.activeBuilding) ||
      (this.props.isShiftView && !this.props.model.values.shiftId)
    ) {
      toast.error(
        `Bitte wählen Sie ${
          this.props.isShiftView ? 'eine Schicht' : 'ein Gebäude'
        } aus.`,
      )
      return
    }
    const promises: any[] = []
    for (let [userId, cellChanges] of changes) {
      promises.push(
        hermes.patch(
          `/api/${this.context.instance.id}/workplan/views/${this.props.model.values.employeePoolId}-${this.props.model.values.month}-${this.props.model.values.type}/schedules/${userId}`,
          {
            changes: cellChanges,
            buildingId: this.props.isShiftView
              ? undefined
              : this.props.model.values.activeBuilding,
            shiftId: this.props.isShiftView ? this.props.model.values.shiftId : undefined,
          },
        ),
      )
    }
    try {
      await Promise.all(promises)
    } catch (e: any) {
      if (e.id && e.id === ConflictError.id) {
        for (const error of e.details) {
          toast.error(error)
        }
      } else {
        toast.error(
          'Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.',
        )
      }
    }
  }

  onAdd = async (resources: Resource<IMonthlyEmployeeWorkSchedule>[]) => {
    if (!this.plannings.resources) {
      return
    }
    if (!this.hot) {
      return
    }
    const changes: any[] = []
    let index = 0
    for (const resource of resources) {
      const resourceData: IMonthlyEmployeeWorkSchedule = cloneDeep(resource.data)
      if (!resourceData) {
        continue
      }
      for (const key of Object.keys(resourceData)) {
        if (!key.includes('day')) {
          continue
        }
        if (resourceData[key] === null) {
          continue
        }
        resourceData[key].cells = resourceData[key].cells.filter(
          (cell: IEmployeeDayScheduleCell) =>
            cell.shiftId !== '-22' && this.props.plannableShiftsMap.has(cell.shiftId),
        )
      }
      changes.push(...getChangesRow(resourceData, index))
      index++
    }
    this.hot.alter('insert_row', 0, resources.length)
    this.hot.setDataAtRowProp(changes, 'custom')
    this.updateCaptions?.()
  }

  private onServerSideChange = (
    changes: ResourceChange<IMonthlyEmployeeWorkSchedule>[],
  ) => {
    if (!this.hot) {
      console.error('onServerSideChange: Hot instance not defined')
      return
    }
    if (!this.plannings.resources) {
      return
    }
    const incomingChanges: any[] = []
    for (const change of changes) {
      if (change.error) {
        toast.error(change.error.message)
        continue
      }
      if (!change.data?.user) {
        continue
      }
      let tableRow: number | undefined
      for (let i = 0; i < this.plannings.resources.length; i++) {
        if (!this.plannings.resources[i].data) {
          continue
        }
        if (change.data.user.id !== this.plannings.resources[i].data!.id) {
          continue
        }
        tableRow = i
        break
      }
      if (tableRow === undefined) {
        return
      }
      for (const key of Object.keys(change.data)) {
        if (!key.includes('day')) {
          continue
        }
        if (change.data[key] === null || change.data[key].cells.length === 0) {
          continue
        }
        change.data[key].cells = change.data[key].cells.filter(
          (cell) =>
            cell.shiftId !== '-22' && this.props.plannableShiftsMap.has(cell.shiftId),
        )
        incomingChanges.push([tableRow, key, change.data[key]])
      }
      incomingChanges.push([tableRow, 'user', change.data.user])
      // Push new user info (updated working hours, etc.) to changes
    }
    this.hot.setDataAtRowProp(incomingChanges, 'custom')
    this.updateCaptions?.()
  }

  private onRemove = (resources: Resource<IMonthlyEmployeeWorkSchedule>[]) => {
    if (!this.hot) {
      console.error('onRemove: Hot instance not defined')
      return
    }
    const tableRowLength = this.hot?.countRows()
    const userColumn = this.hot?.getDataAtProp('user')
    let tableRow: number | undefined
    for (const resource of resources) {
      if (!resource.data) {
        continue
      }
      for (let i = 0; i < tableRowLength; i++) {
        if (userColumn[i].id !== resource.data.user!.id) {
          continue
        }
        tableRow = i
        break
      }
    }
    if (tableRow === undefined) {
      return
    }
    this.hot?.alter('remove_row', tableRow, 1, 'custom')
    this.updateCaptions?.()
  }

  render() {
    if (!this.plannings) {
      return <Spinner />
    }
    return (
      <div className='absolute bottom-[40px] left-0 right-0 top-[126px] flex flex-column overflow-x-hidden overflow-y-auto'>
        <Routes>
          <Route
            path='view'
            element={
              <PlanGrid
                key={this.props.model.values.month}
                getRef={this.getRef}
                month={this.props.model.values.month}
                viewId={this.props.model.values.viewId}
                employeePoolId={this.props.model.values.employeePoolId}
                specialShifts={this.props.specialShifts}
                plannings={this.plannings}
                onChange={this.onClientSideChange}
                setSelectedDateAndUser={this.props.setSelectedDateAndUser}
                participantsOverrides={this.participantsOverrides}
                shift={this.props.shift}
                isShiftView={this.props.isShiftView}
              />
            }
          />
          <Route
            path='shift/plan'
            element={
              <PlanGrid
                key={this.props.model.values.month}
                getRef={this.getRef}
                month={this.props.model.values.month}
                viewId={this.props.model.values.viewId}
                employeePoolId={this.props.model.values.employeePoolId}
                specialShifts={this.props.specialShifts}
                plannings={this.plannings}
                onChange={this.onClientSideChange}
                setSelectedDateAndUser={this.props.setSelectedDateAndUser}
                participantsOverrides={this.participantsOverrides}
                shift={this.props.shift}
                isShiftView={this.props.isShiftView}
              />
            }
          />
          <Route
            path='shift/real'
            element={
              <RealGrid
                key={this.props.model.values.month}
                getRef={this.getRef}
                month={this.props.model.values.month}
                viewId={this.props.model.values.viewId}
                employeePoolId={this.props.model.values.employeePoolId}
                specialShifts={this.props.specialShifts}
                plannings={this.plannings}
                onChange={this.onClientSideChange}
                setSelectedDateAndUser={this.props.setSelectedDateAndUser}
                participantsOverrides={this.participantsOverrides}
                shift={this.props.shift}
              />
            }
          />
        </Routes>
      </div>
    )
  }
}
