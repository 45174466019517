import * as React from 'react'
import { action, makeObservable, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { getShiftBeginTime } from 'contracts/workplan/helpers/getShiftBeginTime'
import { getShiftEndTime } from 'contracts/workplan/helpers/getShiftEndTime'
import { AppContext } from 'services/connection/models/AppContext'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { Model } from 'components/Form/Model'
import { InputText } from 'components/Form/components/InputText'
import { getBreakIntervals } from 'contracts/workplan/helpers/getBreakIntervals'
import { dayjs } from 'helpers/dayjs'
import { Tooltip } from 'components/Tooltip'

export const shortcutLabels = [
  'xb',
  'xc',
  'xd',
  'xe',
  'xf',
  'xg',
  'xh',
  'xi',
  'xj',
  'xk',
  'xl',
  'xm',
  'xn',
  'xo',
  'xp',
  'xq',
  'xr',
  'xs',
  'xt',
  'xu',
  'xv',
  'xw',
  'xx',
  'xy',
  'xz',
  'x1',
  'x2',
  'x3',
  'x4',
  'x5',
  'x6',
  'x7',
  'x8',
  'x9',
]

const labelOptions: InputSelectOption[] = shortcutLabels.map((l) => ({
  value: l,
  label: l,
}))

export interface IShortcut {
  key: string
  label: string
  begin: string
  end: string
  hasBreak: boolean
  breakIntervals: string
}

interface Props {
  shortcut: IShortcut
  readOnly?: boolean
  onAdd?: () => void
  onDelete?: (shortcut: IShortcut) => void
  hasBreak: boolean
  shiftId: string
}

@observer
export class ShortcutRow extends React.Component<Props, {}> {
  static contextType = AppContext
  private model: Model<IShortcut>
  private disposers: Disposer[] = []
  @observable warning: boolean = false
  @observable placeholder: string = ''

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(props.shortcut)
  }

  @action toggleBreak = () => {
    if (this.model.values.hasBreak) {
      this.model.values.breakIntervals = ''
    }
    this.model.values.hasBreak = !this.model.values.hasBreak
  }

  componentDidMount(): void {
    this.disposers.push(
      reaction(() => this.model.values.breakIntervals, this.checkBreakIntervals, {
        fireImmediately: true,
      }),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  onDelete = () => {
    if (this.props.onDelete) {
      this.props.onDelete(this.props.shortcut)
    }
  }

  checkBreakIntervals = () => {
    if (!this.model.values.hasBreak) {
      return false
    }
    const shiftDuration = dayjs(
      getShiftEndTime('2024-01-01', this.model.values.begin, this.model.values.end),
    ).diff(getShiftBeginTime('2024-01-01', this.model.values.begin), 'minute')
    const intervals = this.model.values.breakIntervals.split(',')
    if (intervals.length === 1 && intervals[0] === '') {
      return false
    }
    let sum = 0
    for (const interval of intervals) {
      const begin = getShiftBeginTime('2024-01-01', interval.split('-')[0])
      const end = getShiftEndTime(
        '2024-01-01',
        interval.split('-')[0],
        interval.split('-')[1],
      )
      sum += dayjs(end).diff(begin, 'minute')
    }
    if (
      (shiftDuration / 60 > 6 && shiftDuration / 60 < 9.5 && sum !== 30) ||
      (shiftDuration / 60 > 9.5 && sum !== 45)
    ) {
      return true
    }
    return false
  }

  render() {
    const isX = this.props.shortcut.label === 'x'
    const placeholder = this.props.shortcut.hasBreak
      ? getBreakIntervals(
          getShiftBeginTime('2019-01-01', this.model.values.begin),
          getShiftEndTime('2019-01-01', this.model.values.begin, this.model.values.end),
          this.props.shortcut.hasBreak,
        )
      : 'HH:mm-HH:mm'
    return (
      <div className='grid grid-cols-8 gap-4'>
        <div className='col-span-2'>
          {isX && (
            <div className='relative'>
              <input
                type='text'
                className='block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 bg-gray-100 rounded-md'
                readOnly
                disabled
                value='x'
              />
              <label
                className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
                style={{ left: 9, top: -7, zIndex: 1 }}
              >
                Kürzel
              </label>
              {this.props.onAdd && !this.props.readOnly && (
                <span
                  onClick={this.props.onAdd}
                  className='absolute cursor-pointer z-1'
                  style={{ right: 11, top: 8, color: '#1c9ad6' }}
                >
                  <i className='fa fa-plus' />
                </span>
              )}
            </div>
          )}
          {!isX && (
            <div className='relative'>
              <InputSelect
                model={this.model}
                name='label'
                label='Kürzel'
                options={labelOptions}
                readOnly={this.props.readOnly}
                disabled={this.props.readOnly}
              />
              {this.props.onDelete && !this.props.readOnly && (
                <span
                  className='absolute cursor-pointer'
                  onClick={this.onDelete}
                  style={{ right: 11, top: 8, color: '#d6554d' }}
                >
                  <i className='fa fa-trash' />
                </span>
              )}
            </div>
          )}
        </div>
        <div>
          <InputText
            model={this.model}
            name='begin'
            label='Start'
            placeholder='z. B. 09:00'
            readOnly={this.props.readOnly}
            disabled={this.props.readOnly}
          />
        </div>
        <div>
          <InputText
            model={this.model}
            name='end'
            label='Ende'
            placeholder='z. B. 17:00'
            readOnly={this.props.readOnly}
            disabled={this.props.readOnly}
          />
        </div>
        <div className='relative flex items-center col-span-4'>
          <InputText
            className='w-full'
            model={this.model}
            name='breakIntervals'
            label='Pause'
            readOnly={this.props.readOnly}
            disabled={this.props.readOnly}
            style={{ paddingLeft: 30, paddingRight: 30 }}
            placeholder={placeholder}
          />
          <input
            type='checkbox'
            className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
            checked={this.model.values.hasBreak}
            onChange={this.toggleBreak}
            style={{ position: 'absolute', left: 10, top: 11 }}
            disabled={this.props.readOnly}
          />
          {(this.model.values.breakIntervals || placeholder) &&
            this.checkBreakIntervals() && (
              <span
                className='ml-2 text-yellow-500 has-tooltip'
                style={{ position: 'absolute', right: 10, top: 8 }}
              >
                <i className='fa fa-exclamation-circle' />
                <Tooltip position={this.props.readOnly ? 'left' : 'top'}>
                  Pause entspricht nicht der gesetzlichen Pause.
                </Tooltip>
              </span>
            )}
          {(this.model.values.breakIntervals || placeholder) &&
            !this.checkBreakIntervals() && (
              <span
                className='ml-2 text-green-500 has-tooltip'
                style={{ position: 'absolute', right: 10, top: 8 }}
              >
                <i className='fa fa-check-circle' />
                <Tooltip position={this.props.readOnly ? 'left' : 'top'}>
                  Pause entspricht der gesetzlichen Pause.
                </Tooltip>
              </span>
            )}
        </div>
      </div>
    )
  }
}
