import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { IRoomLock } from 'contracts/accommodations/interfaces/IRoomLock'
import { Dayjs } from 'dayjs'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { RoomTileLockDialog } from './RoomTileLockDialog'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  room: IRoom
  lock: IRoomLock | null
  at?: Dayjs // Timestamp when lock should be evaluated, it not set, current time is used and lock is editable
  askNotes?: boolean
  onChange?: (lock: string | null) => void
}

export const RoomTileLockDialogToggle: React.FC<Props> = observer(
  ({ lock, at, room }) => {
    const context = React.useContext(AppContext)

    function openDialog() {
      if (at) {
        alert(
          'Diese Ansicht zeigt einen Zeitpunkt in der Zukunft oder Vergangenheit. Die Zimmersperre kann nur in einer Ansicht geändert werden, die den gegenwärtigen Zustand der Zimmersperren widerspiegelt.',
        )
        return
      }
      const promise = box.custom(
        <RoomTileLockDialog room={room} lock={lock} onClose={() => promise.close()} />,
        { context },
      )
    }

    return (
      <div>
        {lock ? (
          <span key='lock' className='text-red-500 cursor-pointer' onClick={openDialog}>
            <i className='fas fa-lock' />
          </span>
        ) : (
          <span key='unlock' className='cursor-pointer' onClick={openDialog}>
            <i className='fas fa-unlock' />
          </span>
        )}
      </div>
    )
  },
)
