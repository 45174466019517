import { Tooltip } from 'components/Tooltip'
import { IEmployeeDayScheduleCell } from 'contracts/workplan/interfaces/IEmployeeDayScheduleCell'
import { dayjs } from 'helpers/dayjs'
import * as React from 'react'

interface Props {
  scheduleDay?: IEmployeeDayScheduleCell
  leftPercent: number
  widthPercent: number
  recoveryPercent?: number
  recoveryMinutes?: number
  color: string
  tooltipPosition?: 'left' | 'right'
  onClick?: () => void
}

export class DayScheduleCell extends React.Component<Props, {}> {
  render() {
    const scheduleDay = this.props.scheduleDay
    if (!scheduleDay) {
      const recoveryMinutes = (this.props.recoveryMinutes ?? 0) % 60
      return (
        <div
          className='absolute top-0 h-[22px] px-1.5 leading-[22px] z-10'
          style={{
            left: `${this.props.leftPercent}%`,
            width: `${this.props.widthPercent}%`,
            backgroundColor: `${this.props.color}60`,
          }}
        >
          <div className='has-tooltip'>
            <div className='text-white truncate'>Ruhezeit</div>
            {!!this.props.recoveryPercent && !!this.props.recoveryMinutes && (
              <Tooltip>
                {`Ruhezeit: ${Math.floor(this.props.recoveryMinutes / 60)} Stunden${
                  recoveryMinutes ? `, ${recoveryMinutes} Minuten` : ''
                }`}
              </Tooltip>
            )}
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={`absolute top-0 h-[22px] px-1.5 leading-[22px] z-10 ${
            +scheduleDay.shiftId < 0 ? 'cursor-auto' : 'cursor-pointer'
          }`}
          style={{
            left: `${this.props.leftPercent}%`,
            width: `${this.props.widthPercent}%`,
            backgroundColor: this.props.color,
          }}
          onClick={this.props.onClick}
        >
          <div className='has-tooltip'>
            <div className='text-white truncate'>{scheduleDay.label}</div>
            <Tooltip position={this.props.tooltipPosition || 'right'}>
              {scheduleDay.label}
              {+scheduleDay.shiftId > 0 && (
                <>
                  <br />
                  {dayjs(scheduleDay.begin).format('DD.MM.YYYY HH:mm')} -<br />
                  {dayjs(scheduleDay.end).format('DD.MM.YYYY HH:mm')}
                </>
              )}
            </Tooltip>
          </div>
        </div>
      )
    }
  }
}
