import { Resource } from '@byll/hermes'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Button } from 'components/Form/components/Button'
import { IWorkplanResident } from 'contracts/residents/interfaces/IWorkplanResident'
import * as React from 'react'

interface Props {
  workplanEmployee: Resource<IWorkplanResident>
  onClose: () => void
}

export class ContractDialog extends React.Component<Props, {}> {
  render() {
    return (
      <div>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='border-b border-gray-200 px-6 -mx-6 pb-4'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                Vertrag
              </Dialog.Title>
            </div>
          </div>
        </div>

        <div className='bg-gray-100 -mx-6 p-6'>
          <div className='bg-white rounded-md shadow-md p-6'>Content</div>
        </div>

        <div
          className='sticky z-1 text-right bottom-0 bg-white border-t border-gray-200 -mx-6 pt-4 px-6'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          <Button color='secondary' outline onClick={this.props.onClose}>
            Schließen
          </Button>
        </div>
      </div>
    )
  }
}
