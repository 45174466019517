import { hermes } from '@byll/hermes'
import { RoundIcon } from 'components/RoundIcon'
import { IShiftBonusType } from 'contracts/workplan/interfaces/IShiftBonusType'
import { IShiftBuildingBonusType } from 'contracts/workplan/interfaces/IShiftBuildingBonusType'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { cloneDeep } from 'lodash'
import { box } from 'services/box'
import { Spinner } from 'components/Spinner'
import { ShiftBonusTypesForm } from './components/ShiftBonusTypesForm'
import { ShiftBonusTypesEditDialog } from './components/ShiftBonusTypesEditDialog'

interface Props {
  shift: IShiftSearchResult
}

@observer
export class ShiftDialogBonusesTab extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private shiftBonusTypes: IShiftBonusType[] | null = null
  @observable private shiftBuildingBonusTypes: IShiftBuildingBonusType[] | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    void this.loadShiftBonusTypes()
    void this.loadShiftBuildingBonusTypes()
  }

  private loadShiftBonusTypes = async () => {
    const res = await hermes.indexOnceNew<IShiftBonusType>(
      `/api/${this.context.instance.id}/workplan/shiftBonusTypes?shiftId=${this.props.shift.id}`,
    )
    runInAction(() => (this.shiftBonusTypes = res))
  }

  private loadShiftBuildingBonusTypes = async () => {
    const res = await hermes.indexOnceNew<IShiftBuildingBonusType>(
      `/api/${this.context.instance.id}/workplan/shiftBuildingBonusTypes?shiftId=${this.props.shift.id}`,
    )
    runInAction(() => (this.shiftBuildingBonusTypes = res))
  }

  private editBonusTypes = async () => {
    if (!this.shiftBonusTypes || !this.shiftBuildingBonusTypes) {
      return
    }
    const bonusTypesCopy = cloneDeep(this.shiftBonusTypes)
    const buildingBonusTypesCopy = cloneDeep(this.shiftBuildingBonusTypes)
    const promise = box.custom(
      <ShiftBonusTypesEditDialog
        shift={this.props.shift}
        shiftBonusTypes={bonusTypesCopy}
        shiftBuildingBonusTypes={buildingBonusTypesCopy}
        onClose={(result?: boolean) => promise.close(result)}
      />,
      { closable: true, context: this.context, size: 'md' },
    )
    const result = await promise
    if (result) {
      await this.loadShiftBonusTypes()
      await this.loadShiftBuildingBonusTypes()
    }
  }

  render() {
    if (!this.shiftBonusTypes || !this.shiftBuildingBonusTypes) {
      return (
        <div className='p-6'>
          <div className='bg-white rounded-md shadow-md p-4 relative min-h-20'>
            <Spinner />
          </div>
        </div>
      )
    }
    return (
      <div className='p-6'>
        <div className='bg-white rounded-md shadow-md p-4 relative'>
          {this.context.permissions.workplan_shifts > 1 && (
            <div className='absolute top-4 right-4'>
              <RoundIcon icon='fa fa-edit' onClick={this.editBonusTypes} />
            </div>
          )}
          <ShiftBonusTypesForm
            shift={this.props.shift}
            shiftBonusTypes={this.shiftBonusTypes}
            shiftBuildingBonusTypes={this.shiftBuildingBonusTypes}
            readOnly
          />
        </div>
      </div>
    )
  }
}
