import { Decimal } from 'decimal.js-light'

export function appendDecimalPlaces(
  value: string | null,
  scale: number,
  doNotFillDecimalPlaces: boolean = false,
): string | null {
  if (value === null || value === '') {
    return null
  }

  let decimal = new Decimal(value)

  // add decimal places
  if (doNotFillDecimalPlaces) {
    return decimal.toString()
  }
  return decimal.toFixed(scale)
}
