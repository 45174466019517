import { isIntegerString } from 'contracts/general/helpers/isIntegerString'
import { isMonth } from 'contracts/general/helpers/isMonth'
import { IUser } from 'contracts/users/interfaces/IUser'
import { IShiftFilter } from 'contracts/workplan/interfaces/IShiftFilter'
import { dayjs } from 'helpers/dayjs'
import { isPlainObject } from 'helpers/isPlainObject'
import { storage } from 'services/storage'

export function getSavedShiftSearchResultsFilter(user: IUser): IShiftFilter {
  let settings: any = storage.get(`shiftlist.${user.id}`)
  if (!isPlainObject(settings)) {
    settings = {}
  }
  const filter: IShiftFilter = {
    month: dayjs().format('YYYY-MM'),
    buildingId: '',
  }
  if (isMonth(settings.month)) {
    filter.month = settings.month
  }
  if (isIntegerString(settings.buildingId)) {
    filter.buildingId = settings.buildingId
  }
  return filter
}
