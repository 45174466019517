import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Button } from 'components/Form/components/Button'
import { InputForm } from 'components/Form/components/InputForm'
import { Message } from 'components/Message'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { action, makeObservable, observable, runInAction } from 'mobx'
import * as React from 'react'
import { ShiftOverviewForm } from './ShiftOverviewForm'
import { IShiftFilter } from 'contracts/workplan/interfaces/IShiftFilter'
import { Model } from 'components/Form/Model'
import { IShortcut, shortcutLabels } from './ShortcutRow'
import { getShortcutsStructure } from '../helpers/getShortcutsStructure'
import { hoursMinutesToMinutes } from '../helpers/hoursMinutesToMinutes'
import { uniqueId } from 'helpers/uniqueId'
import { observer } from 'mobx-react'
import { getShiftShortcuts } from '../helpers/getShiftShortcuts'
import { calculateRecovery } from '../helpers/calculateRecovery'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { ShiftValidator } from 'contracts/workplan/validators/ShiftValidator'
import { z } from 'zod'
import { IShift } from 'contracts/workplan/interfaces/IShift'

interface Props {
  shift: IShiftSearchResult
  filterModel: Model<IShiftFilter>
  navigate: (path: string) => void
  onClose: () => void
}

@observer
export class ShiftEditDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private model: Model<IShiftSearchResult>
  @observable private error: string | null = null
  @observable private shortcuts: IShortcut[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(
      {
        ...props.shift,
        recovery: calculateRecovery(props.shift.recovery),
        maxNumParticipants: String(props.shift.maxNumParticipants),
      },
      ShiftValidator.omit({ id: true, begin: true, end: true }).extend({
        recovery: z.string(),
        maxNumParticipants: z.string(),
        buildingId: z.string().min(1),
      }),
    )
    this.shortcuts = getShortcutsStructure(props.shift)
  }

  private saveShift = async () => {
    try {
      if (!this.model.isValid()) {
        this.model.setFocusToLeftTopmostInvalidField()
        return
      }
      const recovery = hoursMinutesToMinutes(this.model.values.recovery as any)
      if (!recovery) {
        runInAction(
          () =>
            (this.error =
              'Bitte geben Sie eine gültige Ruhezeit zwischen 0 und 24 Stunden ein.'),
        )
        return
      }
      runInAction(() => {
        this.error = null
      })
      if (!this.shortcuts[0].begin || !this.shortcuts[0].end) {
        runInAction(
          () =>
            (this.error = 'Bitte geben Sie die Anfangs- und Endzeiten der Schicht an.'),
        )
        return
      }
      const shortcuts = getShiftShortcuts(this.shortcuts)
      // Save the shift
      const shift: Omit<IShift, 'holidayRegion'> = {
        id: this.model.values.id,
        type: this.model.values.type,
        label: this.model.values.label,
        abbreviation: this.model.values.abbreviation,
        color: this.model.values.color,
        comment: this.model.values.comment,
        maxNumParticipants: Number(this.model.values.maxNumParticipants),
        minNumFemaleParticipants: this.model.values.minNumFemaleParticipants,
        minNumMaleParticipants: this.model.values.minNumMaleParticipants,
        participantValidation: this.model.values.participantValidation,
        hasBreak: this.shortcuts[0].hasBreak,
        begin: this.shortcuts[0].begin,
        end: this.shortcuts[0].end,
        shortcuts,
        recovery,
        buildingId: this.model.values.buildingId,
        repeatMon: this.model.values.repeatMon,
        repeatTue: this.model.values.repeatTue,
        repeatWed: this.model.values.repeatWed,
        repeatThu: this.model.values.repeatThu,
        repeatFri: this.model.values.repeatFri,
        repeatSat: this.model.values.repeatSat,
        repeatSun: this.model.values.repeatSun,
        repeatHoliday: this.model.values.repeatHoliday,
        repeatStartDate: this.model.values.repeatStartDate,
        repeatStopDate: this.model.values.repeatStopDate,
        participantsOverrideDays: this.model.values.participantsOverrideDays,
        shiftCreatedByUserId: this.model.values.shiftCreatedByUserId,
        shiftEditedByUserId: this.model.values.shiftEditedByUserId,
        shiftLastEditTimestamp: this.model.values.shiftLastEditTimestamp,
      }
      let result: { id: string; month: string; buildingId: string } | null = null
      if (!this.model.values.id) {
        result = await hermes.create(`/api/${this.context.instance.id}/workplan/shifts`, {
          shift,
          month: this.props.filterModel.values.month,
        })
      } else {
        await hermes.patch(
          `/api/${this.context.instance.id}/workplan/shifts/${this.model.values.id}`,
          { shift, month: this.props.filterModel.values.month },
        )
      }
      if (result) {
        runInAction(() => {
          this.props.filterModel.values.month = result!.month
          this.props.filterModel.values.buildingId = result!.buildingId
        })
        this.props.navigate(`/shifts/${result.id}/overview`)
      }
      this.props.onClose()
    } catch (e: any) {
      if (e.id && e.id === ConflictError.id) {
        runInAction(() => (this.error = e.message))
      } else {
        runInAction(
          () =>
            (this.error =
              'Beim Speichern der Schicht ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.'),
        )
      }
    }
  }

  private deleteShift = async () => {
    if (
      !window.confirm(
        'Möchten Sie diese Schicht und alle dazugehörigen Schichtpläne wirklich unwiderruflich löschen?',
      )
    ) {
      return
    }
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/workplan/shifts/${this.model.values.id}-${this.props.filterModel.values.month}`,
      )
      this.props.onClose()
      this.props.navigate('/shifts')
    } catch (e: any) {
      runInAction(
        () =>
          (this.error =
            'Beim Löschen der Schicht ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.'),
      )
    }
  }

  @action private addShortcut = () => {
    const letters = new Set<string>()
    for (const shortcut of this.shortcuts) {
      letters.add(shortcut.label)
    }
    for (const label of shortcutLabels) {
      if (letters.has(label)) {
        continue
      }
      this.shortcuts.push({
        key: uniqueId('s'),
        label,
        begin: '',
        end: '',
        hasBreak: true,
        breakIntervals: '',
      })
      return
    }
  }

  @action private deleteShortcut = (shortcut: IShortcut) => {
    for (let i = 0; i < this.shortcuts.length; i++) {
      if (this.shortcuts[i].key !== shortcut.key) {
        continue
      }
      this.shortcuts.splice(i, 1)
      return
    }
  }

  render() {
    const { shift, filterModel } = this.props
    return (
      <div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4 z-20'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div
          className={`sticky ${
            this.error ? '-top-7' : '-top-11'
          } bg-white -mx-6 px-6 border-b border-gray-300 z-10`}
        >
          <div className='pb-4 pt-1 -mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {shift.id ? shift.label : 'Neue Schicht'}
            </Dialog.Title>
          </div>
          {this.error && (
            <Message className='mb-6' color='danger'>
              {this.error}
            </Message>
          )}
        </div>
        <InputForm onSubmit={this.saveShift}>
          <div className='sm:-mx-6 -mx-4 bg-gray-100 py-6'>
            <div id={this.model.id} className='mx-6 bg-white rounded-md shadow-md p-4'>
              <ShiftOverviewForm
                model={this.model}
                filterModel={filterModel}
                shortcuts={this.shortcuts}
                onAdd={this.addShortcut}
                onDelete={this.deleteShortcut}
              />
            </div>
          </div>
        </InputForm>
        <div className='flex gap-2 justify-end sticky z-1 text-right bottom-0 bg-white -mx-6 border-t border-gray-300 py-4 px-6 -mb-4'>
          {this.model.values.id && this.context.permissions.workplan_shifts === 3 && (
            <Button color='danger' className='mr-auto' onClick={this.deleteShift}>
              Löschen
            </Button>
          )}
          <Button color='secondary' outline onClick={this.props.onClose}>
            Abbrechen
          </Button>
          <Button onClick={this.saveShift}>Speichern</Button>
        </div>
      </div>
    )
  }
}
