import * as React from 'react'
import { NavLink, Navigate, Route, Routes } from 'react-router-dom'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { Collection } from '@byll/hermes'
import { Spinner } from 'components/Spinner'
import { Model } from 'components/Form/Model'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { IShiftFilter } from 'contracts/workplan/interfaces/IShiftFilter'
import { ShiftDialogOverviewTab } from './components/ShiftDialogOverviewTab'
import { ShiftDialogQualificationsTab } from './components/ShiftDialogQualificationsTab'
import { ShiftDialogBonusesTab } from './components/ShiftDialogBonusesTab'
import { ShiftDialogOverridesTab } from './components/ShiftDialogOverridesTab'
import { IShiftSearchResultMetadata } from 'contracts/workplan/interfaces/IShiftSearchResultMetadata'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'

const tabs: Tab[] = [
  { label: 'Übersicht', to: 'overview' },
  { label: 'Teilnehmerabweichungen', to: 'overrides' },
  { label: 'Anforderungsprofil', to: 'qualifications' },
  { label: 'Zulagen', to: 'bonuses' },
]

interface Props {
  shiftId: string | null
  filterModel: Model<IShiftFilter>
}

interface TabProps {
  shiftId: string | null
  shifts: Collection<IShiftSearchResult, IShiftSearchResultMetadata, IShiftFilter>
  filterModel: Model<IShiftFilter>
}

interface Tab {
  label: string
  to: string
  permission?: string
}

@observer
export class ShiftDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly shifts: Collection<
    IShiftSearchResult,
    IShiftSearchResultMetadata,
    IShiftFilter
  >
  private disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.shifts = new Collection(
      `/api/${context.instance.id}/workload/shifts/${this.props.filterModel.values.month}/shiftSearchResults`,
      { buildingId: this.props.filterModel.values.buildingId },
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.shifts.init({ observeQuery: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  render() {
    return (
      <div className='min-w-[700px]'>
        <div className='h-[66px] pt-0.5 border-b border-gray-300 px-6 sticky z-2 top-0 bg-gray-100'>
          <nav className='-mb-px flex space-x-8 flex-wrap' aria-label='Tabs'>
            {tabs.map((tab: Tab, i) => {
              if (tab.permission && !this.context.permissions[tab.permission]) {
                return null
              }
              return (
                <NavLink
                  key={i}
                  to={`${this.props.shiftId}/${tab.to}`}
                  className={({ isActive }) =>
                    isActive
                      ? 'border-indigo-500 text-indigo-600 whitespace-nowrap pb-[21px] pt-[21px] px-1 border-b-2 font-medium text-sm'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-[21px] pt-[21px] px-1 border-b-2 font-medium text-sm'
                  }
                  aria-current='page'
                >
                  {tab.label}
                </NavLink>
              )
            })}
          </nav>
        </div>

        {this.props.shiftId && (
          <ShiftDialogTabs
            key={this.props.shiftId}
            shiftId={this.props.shiftId}
            shifts={this.shifts}
            filterModel={this.props.filterModel}
          />
        )}
      </div>
    )
  }
}

@observer
export class ShiftDialogTabs extends React.Component<TabProps, {}> {
  render() {
    const shift = this.props.shifts.resources?.find(
      (shift) => shift.data?.id === this.props.shiftId,
    )
    if (!shift) {
      return <Spinner delay={400} />
    }
    if (!shift.data) {
      return null
    }
    return (
      <div>
        {/* Tab routes */}
        <Routes>
          <Route
            path={`${shift.id}/overview`}
            element={
              <ShiftDialogOverviewTab
                filterModel={this.props.filterModel}
                shift={shift.data}
              />
            }
          />
          <Route
            path={`${shift.id}/overrides`}
            element={
              <ShiftDialogOverridesTab
                shift={shift.data}
                filterModel={this.props.filterModel}
              />
            }
          />
          <Route
            path={`${shift.id}/qualifications`}
            element={<ShiftDialogQualificationsTab shift={shift.data} />}
          />
          <Route
            path={`${shift.id}/bonuses`}
            element={<ShiftDialogBonusesTab shift={shift.data} />}
          />
          <Route path='*' element={<Navigate to={`${shift.id}/overview`} replace />} />
        </Routes>
      </div>
    )
  }
}
