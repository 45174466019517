import { makeObservable, observable, runInAction } from 'mobx'
import * as React from 'react'
import { observer } from 'mobx-react'
import { dayjs } from 'helpers/dayjs'
import { MONTHS } from 'contracts/general/helpers/months'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { AppContext } from 'services/connection/models/AppContext'
import { Model } from 'components/Form/Model'
import { Dialog } from 'components/Dialog'
import { XIcon } from '@heroicons/react/outline'
import { Dialog as UIDialog } from '@headlessui/react'
import { Button } from 'components/Form/components/Button'
import { hermes } from '@byll/hermes'
import { ICompany } from 'contracts/companies/interfaces/ICompany'
import { Festschreibungsliste } from './components/Festschreibungsliste'

interface Props {
  month: string
  companyId: string | null
  onClose: () => void
}

@observer
export class KanzleiDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<{ companyId: string }>
  @observable.ref private companies: InputSelectOption[] = [
    { value: null, label: 'Firma wählen...' },
  ]

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model({ companyId: props.companyId || '' })
  }

  componentDidMount(): void {
    void this.load()
  }

  private load = async () => {
    const companies = await hermes.indexOnceNew<ICompany>(
      `/api/${this.context.instance.id}/companies`,
    )
    runInAction(() => {
      this.companies = [
        { value: null, label: 'Kein Vertrag' },
        ...companies.map(
          (company: ICompany): InputSelectOption => ({
            value: company.id,
            label: company.name,
          }),
        ),
      ]
    })
  }

  render() {
    const month = dayjs(this.props.month, 'YYYY-MM')
    return (
      <Dialog size='lg' open setOpen={this.props.onClose}>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={this.props.onClose}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6 pb-4 border-b border-gray-200'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <UIDialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                {`Festschreibung für ${MONTHS[month.month()]} ${month.year()}`}
              </UIDialog.Title>
            </div>
          </div>
        </div>

        <div id={this.model.id} className='p-6 bg-gray-100'>
          <div className='bg-white shadow-md rounded-md p-4 overflow-hidden'>
            <InputSelect model={this.model} name='companyId' options={this.companies} />
            {this.model.values.companyId && (
              <Festschreibungsliste
                key={`${this.model.values.companyId}.${this.props.month}`}
                companyId={this.model.values.companyId}
                month={this.props.month}
              />
            )}
          </div>
        </div>

        <div
          className='py-4 px-6 sticky z-1 text-right bottom-0 bg-white border-t border-gray-200'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          <Button color='secondary' outline onClick={this.props.onClose}>
            Schließen
          </Button>
        </div>
      </Dialog>
    )
  }
}
