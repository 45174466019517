import { action, makeObservable, observable, runInAction } from 'mobx'
import * as React from 'react'
import { observer } from 'mobx-react'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { AppContext } from 'services/connection/models/AppContext'
import { hermes } from '@byll/hermes'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { Model } from 'components/Form/Model'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { Message } from 'components/Message'
import { InputMonth } from 'components/Form/components/InputMonth'
import { Button } from 'components/Form/components/Button'

interface Props {
  buildingId: string | null
  month: string
  onClose: () => void
}

@observer
export class RechnungsanhangAwlDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private model: Model<{ shiftIds: Set<string>; month: string }>
  @observable shiftOptions: InputSelectOption[] = []
  @observable private downloading = false
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model({ shiftIds: new Set<string>(), month: props.month })
  }

  componentDidMount(): void {
    void this.loadShifts()
  }

  private loadShifts = async () => {
    const response = await hermes.indexOnceNew<IShift>(
      `/api/${this.context.instance.id}/workplan/shifts?month=${
        this.props.month
      }&includeBuildingPathInLabels=yes${
        this.props.buildingId ? `&buildingId=${this.props.buildingId}` : ''
      }`,
    )
    runInAction(() => {
      this.shiftOptions = response.map((shift) => ({
        value: shift.id,
        label: shift.label,
      }))
      if (response.length === 1) {
        this.model.values.shiftIds.add(response[0].id)
      }
    })
  }

  private onSubmit = async () => {
    if (this.downloading) {
      return
    }
    if (this.model.values.shiftIds.size === 0) {
      runInAction(() => (this.error = 'Bitte wählen Sie mindestens eine Schicht aus.'))
      return
    }
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport('stundennachweise', this.context.instance.id, {
      shiftIds: Array.from(this.model.values.shiftIds.values()),
      month: this.model.values.month,
    })
    this.props.onClose()
  }

  @action private toggleShift = (shiftId: string) => {
    if (this.model.values.shiftIds.has(shiftId)) {
      this.model.values.shiftIds.delete(shiftId)
    } else {
      this.model.values.shiftIds.add(shiftId)
    }
  }
  @action private toggleAll = () => {
    if (this.model.values.shiftIds.size === this.shiftOptions.length) {
      this.model.values.shiftIds.clear()
    } else {
      this.model.values.shiftIds = new Set(
        this.shiftOptions.map((option) => option.value),
      )
    }
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4 z-20'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div
          className={`sticky ${
            this.error ? '-top-7' : '-top-11'
          } bg-white -mx-6 px-6 border-b border-gray-300 z-10`}
        >
          <div className='pb-4 pt-1 -mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Rechnungsanhang AWL
            </Dialog.Title>
          </div>
          {this.error && (
            <Message className='mb-6' color='danger'>
              {this.error}
            </Message>
          )}
        </div>

        <div className='sm:-mx-6 -mx-4 bg-gray-100 py-6'>
          <div className='mx-6 bg-white rounded-md shadow-md p-4'>
            <InputMonth model={this.model} name='month' />

            <div className='text-lg my-2 flex items-center gap-2'>
              Schichten auswählen
              <Button small onClick={this.toggleAll} className='ml-1'>
                Alle
              </Button>
            </div>
            {this.shiftOptions.map((o) => (
              <div className='relative flex items-center' key={o.value}>
                <input
                  className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                  type='checkbox'
                  checked={this.model.values.shiftIds.has(o.value)}
                  onChange={() => this.toggleShift(o.value)}
                />
                <span className='ml-2 block text-sm text-gray-900'>{o.label}</span>
              </div>
            ))}
          </div>
        </div>

        <div className='flex gap-2 justify-end sticky z-1 text-right bottom-0 bg-white -mx-6 border-t border-gray-300 p-4 -mb-4'>
          <Button color='secondary' className='ml-2' onClick={this.props.onClose}>
            Abbrechen
          </Button>
          <Button
            color='primary'
            className='ml-2'
            onClick={this.onSubmit}
            disabled={this.downloading}
          >
            Herunterladen
          </Button>
        </div>
      </>
    )
  }
}
