import { Collection, hermes } from '@byll/hermes'
import { InputUser } from 'components/Form/components/InputUser'
import { IMonthlyEmployeeWorkSchedule } from 'contracts/workplan/interfaces/IMonthlyEmployeeWorkSchedule'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import Handsontable from 'handsontable'
import * as React from 'react'
import { HotTable } from '@handsontable/react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { getHoliday } from 'contracts/workplan/helpers/getHolidays'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { toast } from 'react-toastify'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { Model } from 'components/Form/Model'
import { box } from 'services/box'
import { IEmployeeDayScheduleCell } from 'contracts/workplan/interfaces/IEmployeeDayScheduleCell'
import { IEmployeeDaySchedule } from 'contracts/workplan/interfaces/IEmployeeDaySchedule'

const COLORS = {
  feiertag: '#e2e6e9', // '#ed840e',
  wochenende: '#e2e6e9',
  andereSchicht: '#6f42c1',
}
const dayDict = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
const hourFormatRegEx =
  /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]-([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

interface Props {
  month: string
  viewId: string
  employeePoolId: string
  specialShifts: IShift[]
  plannings: Collection<IMonthlyEmployeeWorkSchedule>
  onChange: (changes: Map<string, any[]>) => void
  getRef: (hot: Handsontable | null, updateCaptions: () => void) => void
  setSelectedDateAndUser: (userId: string | null, column: number) => void
  participantsOverrides: Map<string, number> | null
  shift: IShift | null
}

export class RealGrid extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private repeat: number = 0
  private headers: string[] = ['Mitarbeiter']
  private columns: any[] = []
  private colWidths: number[] = [288]
  private settings: any = { fixedColumnsLeft: 1 }
  private colors: string[] = []
  private hotRef = React.createRef<HotTable>()
  private model: Model<{ userId: string }> = new Model({ userId: '' })
  private specialShiftsShortcuts: Map<string, IShift> = new Map()
  private badgeClasses =
    'block whitespace-nowrap rounded-[0.27rem] px-[0.65em] pt-[0.35em] pb-[0.25em] text-center text-[0.75em] font-bold leading-none'
  private disposers: Disposer[] = []

  get hot(): Handsontable | null {
    return this.hotRef.current ? this.hotRef.current.hotInstance : null
  }

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.columns.push({ readOnly: true, data: 'user', renderer: this.nameRenderer })
    for (const shift of props.specialShifts) {
      this.specialShiftsShortcuts.set(shift.comment.split(',')[0], shift)
    }
    this.calculateRepeatBitMask(props, context)
    const current = new Date(`${props.month}-01`)
    const month = current.getMonth()
    while (current.getMonth() === month) {
      const dayOfMonth = current.getDate()
      const dayOfWeek = current.getDay()
      const ymd = `${this.props.month}-${dayOfMonth < 10 ? '0' + dayOfMonth : dayOfMonth}`
      this.headers.push(this.getHeaderHtml(dayOfWeek, dayOfMonth) as string)
      this.columns.push({
        data: function (row) {
          if (!row[`day${dayOfMonth}`]?.cells) {
            return null
          }
          return row[`day${dayOfMonth}`].cells.map((cell) => cell.label).join(',')
        },
        type: 'text',
        renderer: this.dayRenderer,
      })
      this.colWidths.push(105)
      if (getHoliday(dayjs(ymd))) {
        this.colors.push(COLORS.feiertag)
      } else if (dayOfWeek === 0 || dayOfWeek === 6) {
        this.colors.push(COLORS.wochenende)
      } else {
        this.colors.push('white')
      }
      current.setDate(dayOfMonth + 1)
    }
    this.headers.push('')
    this.columns.push({ readOnly: true, data: 'total' })
  }

  componentDidMount(): void {
    if (this.hotRef.current) {
      this.props.getRef(this.hotRef.current.hotInstance, this.updateCaptions)
    }
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  @action
  private calculateRepeatBitMask = async (props: Props, context: AppContextProps) => {
    const repeatDays = [
      'repeatSun',
      'repeatMon',
      'repeatTue',
      'repeatWed',
      'repeatThu',
      'repeatFri',
      'repeatSat',
    ]
    if (props.shift) {
      for (let i = 0; i < repeatDays.length; i++) {
        if (props.shift[repeatDays[i]]) {
          runInAction(() => (this.repeat = this.repeat | (1 << i)))
        }
      }
    } else {
      runInAction(() => (this.repeat = 127)) // All day bits set
    }
  }

  dayRenderer = (_instance, td, row, col, _prop, value) => {
    if (
      col === 0 ||
      this.colors.length < col ||
      !this.props.plannings.resources ||
      row > this.props.plannings.resources.length - 1
    ) {
      return
    }

    td.style.background = this.colors[col - 1]
    td.style.fontSize = '14px'
    td.innerText = value

    const user = this.hot?.getDataAtRowProp(row, 'user')
    if (!user) {
      return
    }
    // Use gray color if no changes in comparison to plan
    const planningRow = hermes.getFromStore(
      `/api/${this.context.instance.id}/workplan/views/${this.props.employeePoolId}-${this.props.month}-real/schedules/${user.id}`,
    )
    if (!planningRow || !planningRow) {
      return
    }
    const dayValue: IEmployeeDaySchedule = planningRow[`day${col}`]
    if (!dayValue) {
      return
    }

    if (dayValue.color) {
      td.style.background = dayValue.color
    } else {
      td.style.background = this.colors[col - 1]
    }

    td.style.position = 'relative'

    const coloredText = dayValue.cells
      .map((item) => {
        if (!item.label) {
          return ''
        }
        const specialShift = this.props.specialShifts.find(
          (s) => s.comment.split(',')[0] === item.label,
        )
        const shiftShortcut = item.label.split('.')[0]
        const label = item.label.split('.')[1]
        let color: string = '#5d5aed'
        if (this.props.shift && item.shiftId !== this.props.shift.id) {
          color = '#aaaaaa'
        } else {
          color = item.color || '#5d5aed'
        }
        if (specialShift && item.shiftId === '-22') {
          return ''
        }
        if (specialShift) {
          return `<span style="position: absolute; bottom: 0; left: 0; right: 0; height: 3px; background-color: ${
            specialShift.comment.split(',')[1]
          };"></span>`
        }
        if (label !== 'x' && !hourFormatRegEx.test(label)) {
          return `
              <span style="display: flex; color: white; margin: 2px auto; ${
                item.notes ? 'border: 1px solid black;' : ''
              }">
                <span style="background-color: ${color}; border-radius: 8px 0 0 8px; padding: 0 0.25rem;">${shiftShortcut}</span>
                <span style="background-color: ${color}; opacity: 0.8; border-radius: 0 8px 8px 0; padding: 0 0.25rem;">${label}</span>
              </span>
            `
        } else {
          return `
            <span style="background-color: ${
              label ? color : ''
            }; border-radius: 0.375rem; color: ${
              this.specialShiftsShortcuts.has(item.label) ? '' : 'white'
            }; ${
              item.notes ? 'border: 1px solid black;' : ''
            } padding: 0 0.375rem; display: flex; justify-content: center; align-items: center; margin: 2px auto;">${this.getCellLabel(
              label === 'x' ? shiftShortcut : item.label,
              item,
            )}
            </span>
          `
        }
      })
      .join('')
    td.innerHTML = `<span style="display: flex; gap: 2px;">${coloredText}</span>`
  }

  private getCellLabel = (label: string, item: IEmployeeDayScheduleCell): string => {
    if (hourFormatRegEx.test(label.split('.')[1])) {
      const shortcut = label.split('.')[0]
      const startHour = dayjs(item.begin).format('HH')
      const endHour = dayjs(item.end).format('HH')
      return `
        <span style="display: flex; align-items: center">
          <span>${shortcut}</span>
          <span style="display: flex; flex-direction: column; font-size: 9px; line-height: 9px;">
            <span>${startHour}</span>
            <span>${endHour}</span>
          </span>
        </span>
      `
    } else {
      return `${label}`
    }
  }

  getHeaderHtml = (dayOfWeek: number, dayOfMonth: number) => {
    const badgeClasses =
      'block whitespace-nowrap rounded-[0.27rem] px-[0.65em] pt-[0.35em] pb-[0.25em] text-center text-[0.75em] font-bold leading-none'
    const date = `${this.props.month}-${dayOfMonth < 10 ? '0' + dayOfMonth : dayOfMonth}`
    const shift = this.props.shift
    const isHoliday = !!getHoliday(dayjs(date), shift?.holidayRegion || undefined)
    const total: number | null = shift
      ? this.props.participantsOverrides?.get(date) ??
        +(
          shift.participantsOverrideDays[isHoliday ? 7 : dayOfWeek] ||
          shift.maxNumParticipants
        )
      : null
    let assigned: number = 0
    for (const row of this.props.plannings.resources || []) {
      if (!row.data || !row.data[`day${dayOfMonth}`]) {
        continue
      } // If component is already unmounted and data is not available
      if (
        row.data[`day${dayOfMonth}`].cells.some(
          (c: IEmployeeDayScheduleCell) => c.shiftId === shift?.id,
        )
      ) {
        assigned++
      }
    }

    let includeDay = !!((1 << dayOfWeek) & this.repeat)
    if (isHoliday && shift?.repeatHoliday === 'include') {
      includeDay = true
    }
    if (isHoliday && shift?.repeatHoliday === 'exclude') {
      includeDay = false
    }

    let badgeColor = 'bg-white text-gray-800 px-2 py-1 border border-gray-300'
    if (total !== null && includeDay) {
      // dayRepeat: true
      if (assigned < total && assigned > 0) {
        badgeColor = 'bg-yellow-500 text-white'
      }
      if (assigned === total) {
        badgeColor = 'bg-green-500 text-white'
      }
      if (assigned > total) {
        badgeColor = 'bg-red-500 text-white'
      }
      return `
        <div>
          <div class='text-sm uppercase pb-2 pt-1'>${dayDict[dayOfWeek]}</div><div>${
            isHoliday ? `<span class="${badgeClasses} bg-yellow-500 text-white">` : ''
          }${dayOfMonth}${isHoliday ? '</span>' : ''}</div>
          <div
            class='${badgeClasses} ${badgeColor} my-0.5'
            style='font-weight: 300; min-width: 30px'
          >
            ${assigned} / ${total}
          </div>
        </div>`
    }
    if (!includeDay) {
      badgeColor = 'bg-indigo-500 text-white'
      return `<div>
          <div class='text-sm uppercase pb-2 pt-1'>${dayDict[dayOfWeek]}</div><div>${
            isHoliday ? `<span class="${badgeClasses} bg-yellow-500 text-white">` : ''
          }${dayOfMonth}${isHoliday ? '</span>' : ''}</div>
          <div
            class='${badgeClasses} ${badgeColor} my-0.5'
            style="font-weight: 300; min-width: 30px"
          >
            Sperre
          </div>
        </div>`
    }
    return ''
  }

  updateCaptions = () => {
    const current = new Date(`${this.props.month}-01`)
    const month = current.getMonth()
    while (current.getMonth() === month) {
      const dayOfWeek = current.getDay()
      const dayOfMonth = current.getDate()
      this.headers[dayOfMonth] = this.getHeaderHtml(dayOfWeek, dayOfMonth)
      current.setDate(dayOfMonth + 1)
    }
    this.hot?.updateSettings({ colHeaders: this.headers }, false)
  }

  nameRenderer = (_instance, td, row, _col, _prop, _value) => {
    const data = this.hot?.getDataAtRowProp(row, 'user')
    if (!data || !data.id) {
      return
    }

    td.style.background = 'rgb(246, 247, 248)'
    td.style.display = 'flex'
    td.style.alignItems = 'center'

    const actualHours: number = Math.round(data.realHours) || 0
    const expectedHours: number | null = data.expectedHours
    const percent = expectedHours ? actualHours / expectedHours : 0
    let color = 'bg-yellow-500'
    if (percent >= 0.9) {
      color = 'bg-green-500'
    }
    if (percent > 1.1) {
      color = 'bg-red-500'
    }

    const isDeletedThisMonth =
      data.deletedMonth &&
      dayjs(data.deletedMonth).isSameOrBefore(this.props.month, 'month')
    const nameStyle = isDeletedThisMonth ? 'text-decoration: line-through;' : ''
    const readOnlyStyle = isDeletedThisMonth ? 'pointer-events: none; opacity: 0.5;' : ''

    td.innerHTML = `<div class="mr-1 truncate" style="flex: 0 1 auto; margin-left: 20px; ${nameStyle}">${
      data.lastName + ', ' + data.firstName
    }</div><div class="flex-content" style="margin-left: auto; margin-right: 20px; ${readOnlyStyle}"><span class="${
      this.badgeClasses
    } ${color} text-white">${actualHours} / ${
      !expectedHours ? '?' : Math.round(expectedHours)
    } h</span></div>`
  }

  beforeChange = (changes, source) => {
    if (!this.hot || source === 'custom' || !this.props.plannings.resources) {
      return
    }
    let preventedChangeBecauseOfRepeatSetting = false

    const patches: Map<string, any[]> = new Map()
    for (let i = changes.length - 1; i >= 0; i--) {
      if (
        changes[i][2] === changes[i][3] ||
        changes[i][0] > this.props.plannings.resources.length - 1
      ) {
        continue
      }
      const user = this.hot.getDataAtRowProp(changes[i][0], 'user')
      if (!user || dayjs(user.deletedMonth).isSameOrBefore(this.props.month, 'month')) {
        continue
      }
      // Replace approved special shifts with their unapproved versions in real shift views
      if (this.specialShiftsShortcuts.has(changes[i][3] + '*')) {
        changes[i][3] += '*'
      }

      if (changes[i][3] === 'r') {
        changes[i][3] = ''
      }

      const row = this.hot.getDataAtRow(changes[i][0])
      if (!row) {
        continue
      }
      const prop = changes[i][1]
      const dayOfMonth = this.hot.propToCol(prop)

      const date = `${this.props.month}-${
        dayOfMonth < 10 ? '0' + dayOfMonth : dayOfMonth
      }`

      const p = patches.get(row[0].id) || []
      p.push({
        label: changes[i][3],
        date,
      })
      patches.set(row[0].id, p)
    }

    if (patches.size > 0) {
      this.props.onChange(patches)
    }
    if (preventedChangeBecauseOfRepeatSetting) {
      toast.warning(
        'Eine oder mehrere Änderungen wurden nicht übernommen, da sie an gesperrten Wochentagen vorgenommen wurden.',
      )
    }
  }

  afterDocumentKeyDown = (event) => {
    if (!this.hot) {
      return
    }
    // Delete user (first cell with user info must be selected)
    if (event.keyCode === 46) {
      // del key
      this.removeUser()
    }
  }

  removeUser = async () => {
    if (!this.hot) {
      return
    }
    const selected = this.hot.getSelected()
    if (!selected || selected.length !== 1) {
      return
    }
    const user = this.hot.getDataAtRowProp(selected[0][0], 'user')
    if (
      selected[0][1] !== 0 ||
      selected[0][0] !== selected[0][2] ||
      selected[0][1] !== selected[0][3] ||
      user.id <= 0 ||
      dayjs(user.deletedMonth).isSameOrBefore(this.props.month, 'month')
    ) {
      return
    }
    if (
      !(await box.alert(
        'Mitarbeiter aus Schicht entfernen?',
        'Möchten Sie den Mitarbeiter wirklich entfernen?',
        { confirm: 'Ja', cancel: 'Abbrechen' },
      ))
    ) {
      return
    }
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/workplan/views/${this.props.employeePoolId}-${this.props.month}-real/schedules/${user.id}`,
      )
    } catch (_e) {
      toast.error('Beim Entfernen des Mitarbeiters ist ein Fehler aufgetreten.')
    }
  }

  addUser = async (userId: string | null) => {
    if (!userId || !this.hot) {
      return
    }
    try {
      await hermes.create(
        `/api/${this.context.instance.id}/workplan/views/${this.props.employeePoolId}-${this.props.month}-real/schedules`,
        { userId },
      )
      runInAction(() => (this.model.values.userId = ''))
    } catch (e: any) {
      runInAction(() => (this.model.values.userId = ''))
      if (e.id && e.id === ConflictError.id) {
        toast.error(e.message)
      } else {
        toast.error('Mitarbeiter konnte nicht hinzugefügt werden.')
      }
    }
  }

  private afterSelectionEnd = (
    row: number,
    column: number,
    _row2: number,
    _column2: number,
  ) => {
    const user = this.hot?.getDataAtRowProp(row, 'user')
    if (!this.props.plannings.resources) {
      return
    }
    this.props.setSelectedDateAndUser(user ? user.id : null, column)
  }

  render() {
    return (
      <div className='hot-container relative flex-auto'>
        <HotTable
          style={{ zIndex: 10 }}
          data={Array(40).fill([])}
          afterSelectionEnd={this.afterSelectionEnd}
          afterDocumentKeyDown={this.afterDocumentKeyDown}
          beforeChange={this.beforeChange}
          colHeaders={this.headers}
          colWidths={this.colWidths}
          columnHeaderHeight={80}
          columns={this.columns}
          rowHeights={30}
          stretchH='last'
          settings={this.settings}
          autoRowSize={false}
          autoColumnSize={false}
          licenseKey='non-commercial-and-evaluation'
          readOnly={false}
          undo={false}
          ref={this.hotRef}
        />
        <div className='absolute top-0 left-0 z-10 w-[288px] h-[82px] border border-gray-300 bg-white flex'>
          <InputUser
            className='w-full p-4'
            onChoose={this.addUser}
            name='userId'
            model={this.model}
            placeholder='Mitarbeiter hinzufügen'
          />
        </div>
      </div>
    )
  }
}
