import { ExclamationIcon } from '@heroicons/react/outline'
import * as React from 'react'
import { Dialog } from '@headlessui/react'
import { Message } from 'components/Message'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  details: any
  blockId: string
  employeeId: string
  close: () => void
}

export const DeleteEmployeeFileEntryPrompt: React.FC<Props> = (props) => {
  const context = React.useContext(AppContext)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  const onDelete = async () => {
    try {
      setLoading(true)
      setError(null)
      await hermes.patch(
        `/api/${context.instance.id}/employees/${props.employeeId}/employeeFileBlocks/${props.blockId}`,
        { details: props.details },
      )
      props.close()
    } catch (e: any) {
      setError('Der Eintrag konnte nicht gelöscht werden.')
      setLoading(false)
    }
  }

  return (
    <>
      <div className='sm:flex sm:items-start'>
        <div
          className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-red-100`}
        >
          <ExclamationIcon className='h-6 w-6 text-red-600' aria-hidden='true' />
        </div>
        <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-auto'>
          <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
            Eintrag löschen
          </Dialog.Title>
          <div className='mt-3 text-sm text-gray-500'>
            {error && (
              <Message color='danger' className='mb-4'>
                {error}
              </Message>
            )}
            Möchten Sie diesen Eintrag wirklich unwiderruflich löschen?
          </div>
        </div>
      </div>
      <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
        <button
          type='button'
          className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm bg-red-600 hover:bg-red-700 focus:ring-red-500`}
          onClick={onDelete}
          disabled={loading}
        >
          Löschen
        </button>
        <button
          type='button'
          className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
          onClick={() => props.close()}
          disabled={loading}
        >
          Abbrechen
        </button>
      </div>
      {loading && <DialogOverlaySpinner opaque />}
    </>
  )
}
