import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import * as React from 'react'
import { Model } from 'components/Form/Model'
import { IShiftFilter } from 'contracts/workplan/interfaces/IShiftFilter'
import { observer } from 'mobx-react'
import { DateOverrides } from './components/DateOverrides'
import { DayOverrides } from './components/DayOverrides'

interface Props {
  shift: IShiftSearchResult
  filterModel: Model<IShiftFilter>
}

@observer
export class ShiftDialogOverridesTab extends React.Component<Props, {}> {
  render() {
    return (
      <div className='p-6 flex flex-col gap-6'>
        <div className='bg-white min-h-20 rounded-md shadow-md p-4 relative'>
          <DateOverrides
            shift={this.props.shift}
            month={this.props.filterModel.values.month!}
          />
        </div>
        <div className='bg-white min-h-20 rounded-md shadow-md p-4 relative'>
          <DayOverrides shift={this.props.shift} />
        </div>
      </div>
    )
  }
}
