import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { ITimeAccountRow } from 'contracts/workload/interfaces/ITimeAccountRow'
import dayjs from 'dayjs'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { TimeAccountListRow } from './TimeAccountListRow'

interface Props {
  employeeId: string
  contract: any
}

@observer
export class TimeAccountList extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private tab: 'overtime' | 'vacation' | 'sick' | 'parental leave' =
    'overtime'
  @observable private timeAccountRows: ITimeAccountRow[] | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    this.loadTimeAccountRows()
  }

  loadTimeAccountRows = async () => {
    const timeAccountRows = await hermes.indexOnceNew<ITimeAccountRow>(
      `/api/${this.context.instance.id}/employees/${this.props.employeeId}/timeAccounts?contractId=${this.props.contract.id}`,
    )
    runInAction(() => (this.timeAccountRows = timeAccountRows))
  }

  private mapRow = (row: ITimeAccountRow) => {
    if (this.tab === 'overtime' && row.month > dayjs().format('YYYY-MM-DD')) {
      return null
    }
    return (
      <TimeAccountListRow
        key={`${row.month}-${row.order}`}
        row={row}
        tab={this.tab}
        contract={this.props.contract}
        onReload={this.loadTimeAccountRows}
      />
    )
  }

  render() {
    return (
      <div className='bg-white rounded-md shadow-md p-4 mt-2'>
        <div className='w-full flex'>
          <Button
            className='flex-auto'
            onClick={action(() => (this.tab = 'overtime'))}
            color='secondary'
            outline={this.tab !== 'overtime'}
            style={{
              borderRadius: '8px 0 0 8px',
            }}
          >
            Überstunden
          </Button>
          <Button
            className='flex-auto'
            onClick={action(() => (this.tab = 'vacation'))}
            color='secondary'
            outline={this.tab !== 'vacation'}
            style={{
              borderRadius: '0',
            }}
          >
            Urlaub
          </Button>
          <Button
            className='flex-auto'
            onClick={action(() => (this.tab = 'sick'))}
            color='secondary'
            outline={this.tab !== 'sick'}
            style={{
              borderRadius: '0',
            }}
          >
            Krank
          </Button>
          <Button
            className='flex-auto'
            onClick={action(() => (this.tab = 'parental leave'))}
            color='secondary'
            outline={this.tab !== 'parental leave'}
            style={{
              borderRadius: '0 8px 8px 0',
            }}
          >
            Elternzeit
          </Button>
        </div>
        {this.timeAccountRows && (
          <div className='relative mt-4'>
            <table className='w-full text-left text-gray-500 divide-y-2 divide-gray-100'>
              <thead className='text-gray-600 bg-gray-50'>
                <tr>
                  <th>Monat</th>
                  {this.tab === 'overtime' && <th className='p-2'>Überstunden</th>}
                  {this.tab === 'overtime' && <th className='p-2'>Feiertagsarbeit</th>}
                  {this.tab === 'overtime' && <th className='p-2'>Saldo</th>}
                  {this.tab === 'vacation' && <th className='p-2'>Regulär</th>}
                  {this.tab === 'vacation' && <th className='p-2'>Unbezahlt</th>}
                  {this.tab === 'vacation' && <th className='p-2'>Sonder</th>}
                  {this.tab === 'vacation' && <th className='p-2'>Zusatz</th>}
                  {this.tab === 'vacation' && <th className='p-2'>Rest regulär</th>}
                  {this.tab === 'sick' && <th className='p-2'>Krank</th>}
                  {this.tab === 'sick' && <th className='p-2'>Kind krank</th>}
                  {this.tab === 'sick' && <th className='p-2'>Saldo</th>}
                  {this.tab === 'parental leave' && <th className='p-2'>Elternzeit</th>}
                  {this.tab === 'parental leave' && <th className='p-2'>Saldo</th>}
                </tr>
              </thead>
              <tbody className='divide-y-2 divide-gray-100'>
                {this.timeAccountRows.map(this.mapRow)}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }
}
