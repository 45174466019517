import { IEmployeeDaySchedule } from 'contracts/workplan/interfaces/IEmployeeDaySchedule'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import * as React from 'react'
import { DayScheduleCell } from './DayScheduleCell'
import { IEmployeeDayScheduleCell } from 'contracts/workplan/interfaces/IEmployeeDayScheduleCell'
import { box } from 'services/box'
import { ShiftCellDialog } from '../../ShiftCellDialog'
import { AppContext } from 'services/connection/models/AppContext'
import { dayjs } from 'helpers/dayjs'

interface Props {
  date: string
  schedule: IEmployeeDaySchedule
  shifts: IShift[]
  employeePoolId: string
  viewId: string
  loadSchedule: () => void
}

export class EmployeeDaySchedule extends React.Component<Props, {}> {
  static contextType = AppContext

  private showShiftCellDialog = async (c: IEmployeeDayScheduleCell) => {
    if (+c.shiftId < 0) {
      return
    }
    const promise = box.custom(
      <ShiftCellDialog
        shiftCell={c}
        employeePoolId={this.props.employeePoolId}
        viewId={this.props.viewId}
        onClose={(success?: boolean) => promise.close(success)}
      />,
      { closable: true, context: this.context },
    )
    const result = await promise
    if (result) {
      this.props.loadSchedule()
    }
  }

  render() {
    if (!this.props.schedule) {
      return null
    }
    return (
      <div>
        {this.props.schedule.cells.map((c, i) => {
          const shift = this.props.shifts.find((s) => s.id === c.shiftId)
          if (!shift) {
            return null
          }
          const color = c.color || '#6366F1'
          const begin = dayjs(c.begin)
          const end = dayjs(c.end)
          const windowStart = dayjs(this.props.date).subtract(2, 'days')
          const leftPercent = begin.diff(windowStart, 'days', true) * 20
          const widthPercent = end.diff(begin, 'days', true) * 20
          const recoveryPercent =
            +c.shiftId > 0 && shift.recovery ? (shift.recovery / 1440) * 20 : 0
          return (
            <div key={i} className='w-full relative h-[22px] bg-gray-100 my-2.5'>
              {recoveryPercent > 0 && (
                <DayScheduleCell
                  leftPercent={leftPercent + widthPercent}
                  widthPercent={recoveryPercent}
                  recoveryPercent={recoveryPercent}
                  recoveryMinutes={shift.recovery}
                  color={color}
                />
              )}
              <DayScheduleCell
                tooltipPosition={
                  dayjs(this.props.date).isSameOrAfter(c.date) ? 'right' : 'left'
                }
                scheduleDay={c}
                leftPercent={leftPercent}
                widthPercent={widthPercent}
                recoveryPercent={recoveryPercent}
                color={color}
                onClick={() => this.showShiftCellDialog(c)}
              />
            </div>
          )
        })}
      </div>
    )
  }
}
