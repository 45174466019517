import { Tooltip } from 'components/Tooltip'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { dayjs } from 'helpers/dayjs'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { getUserImageSrc } from 'modules/Users/Administration/helpers/getUserImageSrc'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  size: { small: boolean }
  employee: IEmployeeSearchResult
  rerender: Map<string, number>
  style: any
}

const statements = {
  missing: { label: 'Gehaltszettel fehlt', color: 'bg-red-500' },
  available: { label: 'Gehaltszettel versendet', color: 'bg-indigo-500' },
  received: { label: 'Gehaltszettel empfangen', color: 'bg-green-500' },
}

const onCheck = action(
  (employee: IEmployeeSearchResult, rerender: Map<string, number>) => {
    if ((rerender.get(employee.id) || 0) > 0) {
      rerender.set(employee.id, -1) // uncheck
    } else {
      rerender.set(employee.id, 1) // check
    }
  },
)

export const EmployeeListRow: React.FC<Props> = observer(
  ({ employee, rerender, style, size }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const context = React.useContext(AppContext)
    const statement = statements[employee.utilization.salaryStatement]
    const selected = location.pathname.indexOf(`/${employee.id}/`) !== -1

    function onOpen() {
      if (selected) {
        navigate(`/employees`)
        return
      }

      const path = window.location.pathname.replace(/\/$/, '').toLowerCase()
      if (path.endsWith('/employees')) {
        navigate(`/employees/${employee.id}/overview`)
      } else {
        // Navigate to different user but same open tab as before
        navigate(`/employees/${employee.id}/${path.split('/').pop()}`)
      }
    }

    return (
      <div
        style={style}
        className={`border-b border-gray-200 flex py-2 ${
          selected ? 'bg-indigo-500' : ''
        }`}
      >
        <div className='ml-6 flex-[0_0_30px] pt-[11px]'>
          <input
            className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
            type='checkbox'
            checked={(rerender.get(employee.id) || 0) > 0}
            onChange={() => onCheck(employee, rerender)}
          />
        </div>
        <img
          className='mr-2 h-12 w-12 flex-content rounded-full bg-gray-50'
          src={getUserImageSrc(
            context.instance.id,
            employee.imageId,
            employee.sex,
            'thumbnail',
          )}
          alt={`${employee.lastName}, ${employee.firstName}`}
        />
        <div className='mr-6 min-w-0 flex-auto truncate'>
          <div className='mt-1 flex items-center gap-x-1.5'>
            <div
              onClick={onOpen}
              className={`flex-[0_0_auto] lg:flex-[0_1_auto] cursor-pointer hover:underline truncate ${
                selected ? 'text-white' : 'text-black hover:text-blue-500'
              }`}
            >
              {`${employee.title} ${employee.lastName.toUpperCase()}, ${
                employee.firstName
              }`}
            </div>
            {employee.utilization &&
            typeof employee.utilization.percentage === 'number' ? (
              <div className='flex-auto'>
                <span
                  className={`has-tooltip flex-content cursor-pointer inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium text-white ${
                    employee.utilization.percentage > 90 &&
                    employee.utilization.percentage < 110
                      ? 'bg-green-500'
                      : 'bg-yellow-500'
                  }`}
                >
                  {employee.utilization.percentage > 90 &&
                  employee.utilization.percentage < 110 ? (
                    <span className='mr-1'>
                      <i className='fas fa-check-circle' />
                    </span>
                  ) : employee.utilization.percentage > 110 ? (
                    <span className='mr-1'>
                      <i className='fas fa-arrow-circle-down' />
                    </span>
                  ) : (
                    <span className='mr-1'>
                      <i className='fas fa-arrow-circle-up' />
                    </span>
                  )}
                  {`${employee.utilization.percentage} %`}
                  <Tooltip position='right'>Auslastung</Tooltip>
                </span>
              </div>
            ) : (
              <div className='flex-auto'></div>
            )}

            <div className={size.small ? 'flex-[0_0_auto]' : 'flex-[0_0_20%]'}>
              {employee.utilization && employee.utilization.planSendDate ? (
                <span className='inline-flex items-center rounded-full bg-green-500 px-2 py-0.5 text-xs font-medium text-white'>
                  <span>
                    <i className='fas fa-paper-plane' />
                  </span>
                  <span
                    className={`ml-1 ${size.small ? 'hidden' : 'inline-block'}`}
                  >{`Arbeitsplan versendet ${dayjs(
                    employee.utilization.planSendDate,
                  ).format('DD.MM.')}`}</span>
                </span>
              ) : (
                <span className='inline-flex items-center rounded-full bg-gray-500 px-2 py-0.5 text-xs font-medium text-white'>
                  <span>
                    <i className='fas fa-paper-plane' />
                  </span>
                  <span
                    className={`ml-1 ${size.small ? 'hidden' : 'inline-block'}`}
                  >{`Arbeitsplan nicht versendet`}</span>
                </span>
              )}
            </div>

            <div className={size.small ? 'flex-[0_0_auto]' : 'flex-[0_0_20%]'}>
              <span
                className={`inline-flex items-center rounded-full  px-2 py-0.5 text-xs font-medium text-white ${statement?.color}`}
              >
                <span>
                  <i className='fas fa-euro-sign' />
                </span>
                <span className={`ml-1 ${size.small ? 'hidden' : 'inline-block'}`}>
                  {statement?.label}
                </span>
              </span>
            </div>

            <div
              className={size.small ? 'flex-[0_0_auto]' : 'flex-[0_0_80px] text-right'}
            >
              {employee.plannable && (
                <span className='cursor-pointer inline-flex items-center rounded-full bg-green-500 px-2 py-0.5 text-xs font-medium text-white'>
                  <span>
                    <i className='fas fa-unlock' />
                  </span>
                  <span className={`ml-1 ${size.small ? 'hidden' : 'inline-block'}`}>
                    Planbar
                  </span>
                </span>
              )}
              {!employee.plannable && (
                <span className='cursor-pointer inline-flex items-center rounded-full bg-yellow-500 px-2 py-0.5 text-xs font-medium text-white'>
                  <span>
                    <i className='fas fa-lock' />
                  </span>
                  <span className={`ml-1 ${size.small ? 'hidden' : 'inline-block'}`}>
                    Gesperrt
                  </span>
                </span>
              )}
            </div>
          </div>
          <div
            className={`truncate text-xs leading-5  ${
              selected ? 'text-white' : 'text-gray-500'
            }`}
          >
            {employee.companies.length > 0
              ? employee.companies.join(', ')
              : 'Kein Vertrag'}
            <span className={selected ? undefined : 'text-red-500'}>
              &nbsp;&nbsp;·&nbsp;&nbsp;mit Unterbrechnungen
            </span>
          </div>
        </div>
      </div>
    )
  },
)
