import React from 'react'
import { Link } from 'react-router-dom'
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { reaction } from 'mobx'
import { IPermissions } from 'contracts/users/interfaces/IPermissions'
import { isVisibleMenuItem } from './helpers/isVisibleMenuItem'
import { TodoIndicator } from './components/TodoIndicator'
import { Collection } from '@byll/hermes'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { NavbarUserIcon } from './components/NavbarUserIcon'
import { isStage } from 'helpers/isStage'
import { logout } from 'modules/Login/helpers/logout'

interface MenuItem {
  label: string
  url: string
  variable?: string | ((permissions: IPermissions) => boolean)
}

interface MenuCaption {
  label: string
  menu: MenuItem[]
}

type Item = MenuItem | MenuCaption

const navigation: Item[] = [
  { label: 'Übersicht', url: '/' },
  {
    label: 'Ressourcen',
    menu: [
      // ['label' => 'Gebäude', 'url' => '/app/compounds', 'variable' => 'menu_asset'],
      { label: 'Unterkünfte', url: '/accommodations', variable: 'menu_asset' },
      { label: 'Reporting', url: '/reports', variable: 'menu_reports' },
      {
        label: 'Inventar',
        url: '/inventory/items',
        variable: 'menu_inventoryManagement',
      },
      // ['label' => 'Inventar Mitarbeiter', 'url' => '/employee/inventory', 'variable' => 'menu_employeeInventory'],
      // ['label' => 'Shop', 'url' => '/inventory/orders/new', 'variable' => 'menu_inventoryOrders'],
      // { 'label': 'Taschengeldausgabe', 'url': '/www/inventory/taschengeldausgabe', 'variable': 'report_taschengeldausgabe' },
    ],
  },
  {
    label: 'Bewohner',
    menu: [
      { label: 'Suche', url: '/residents/find', variable: 'menu_resident_search' },
      {
        label: 'Registrieren',
        url: '/residents/registration',
        variable: 'menu_resident_register',
      },
      {
        label: 'Belegung',
        url: '/residents/occupancy',
        variable: 'resident_accommodationBookings',
      },
      {
        label: 'Postliste',
        url: '/residents/letters',
        variable: 'menu_resident_letters',
      },
      {
        label: 'Ein- und Ausgang',
        url: '/visits',
        variable: 'resident_barcodeVisitTrackings',
      },
      { label: 'Essensausgabe', url: '/meals', variable: 'resident_barcodeMealScanning' },
      { label: 'Scanliste', url: '/inactive', variable: 'resident_leaInactive' },
      {
        label: 'RASt Meldungen',
        url: '/residents/rast',
        variable: 'menu_rastNotification',
      },
      {
        label: 'Transferplanung',
        url: '/residents/transfer/planning',
        variable: 'menu_residentTransferPlanningList_show',
      },
      {
        label: 'Bundesweite Verlegung',
        url: '/residents/transfer/federal',
        variable: 'menu_residentFederalTransfers',
      },
      //['label' => 'Wäscheliste', 'url' => '/www/resident/laundry', 'variable' => 'menu_laundry'],
      //['label' => 'Inventarscan', 'url' => '/inventory/scan', 'variable' => 'menu_inventoryQuickScan'],
      { label: 'Abrechnung', url: '/costs', variable: 'menu_resident_abrechnung' },
      {
        label: 'Ausweise',
        url: '/residents/cards',
        variable: (permissions: IPermissions) =>
          permissions.resident_id_cards > 0 && permissions.resident_id_card_modules <= 1,
      },
      {
        label: 'Hygienemittelausgabe',
        url: '/inventory/scan',
        variable: 'menu_inventoryScan',
      },
      { label: 'Bewohnergruppen', url: '/groups', variable: 'menu_groups' },
      {
        label: 'Import',
        url: '/migrations',
        variable: 'menu_migrations',
      },
    ],
  },
  {
    label: 'Mitarbeiter',
    menu: [
      {
        label: 'Kommunikation',
        url: '/employee/communication',
        variable: 'menu_employeeCommunication',
      },
      {
        label: 'Stundenzettel',
        url: '/workplan-signatures',
        variable: 'menu_workplanSignature',
      },
      { label: 'Arbeitsplanung', url: '/workplan/shift/plan', variable: 'menu_workplan' },
      { label: 'Komfortplanung', url: '/workplan/view', variable: 'menu_workplan' },
      { label: 'Gesamtplanung', url: '/employees', variable: 'menu_workload' },
      { label: 'Rechteverwaltung', url: '/administration', variable: 'admin_isAdmin' },
      {
        label: 'Anforderungsprofile',
        url: '/app/qualifications',
        variable: 'menu_qualifications',
      },
      // ['label' => 'Personalwesen', 'url' => '/user/hr', 'variable' => 'menu_userHr'],
      // ['label' => 'Datev Benutzerimport', 'url' => '/app/datev', 'variable' => 'menu_datevUserImport'],
      // { 'label': 'Rechnungsstellung', 'url': '/app/billing/accounts', 'variable': 'menu_userAccountBilling' },
      { label: 'Firmen', url: '/companies', variable: 'menu_companies' },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  onBellClick: () => void
}

@observer
export class Navbar extends React.Component<Props, { menu }> {
  static contextType = AppContext
  private readonly todos: Collection<ITodoSearchResult>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.state = { menu: [] }
    this.todos = new Collection(
      `/api/${context.instance.id}/todoLists/user-${context.user.id}/todos`,
      { page: '0,1', status: 'due', link: 'assignee' },
    )
  }

  componentDidMount() {
    this.disposers.push(this.todos.init({ readOnly: true }))
    this.disposers.push(
      reaction(
        () => this.getMenu(),
        (menu) => this.setState({ menu }),
        { fireImmediately: true },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private getMenu = (): Item[] => {
    const items: Item[] = []
    for (const item of navigation) {
      // First level menu item
      if ((item as MenuItem).url) {
        if (isVisibleMenuItem(this.context.permissions, (item as MenuItem).variable)) {
          items.push(item)
        }
        continue
      }

      // First level menu caption
      const submenu: MenuItem[] = []
      for (const subitem of (item as MenuCaption).menu) {
        if (isVisibleMenuItem(this.context.permissions, subitem.variable)) {
          submenu.push(subitem)
        }
      }
      if (submenu.length === 1) {
        items.push(submenu[0]) // Pull up menu item to caption position if menu has only one item
      } else if (submenu.length > 1) {
        items.push({ ...item, menu: submenu })
      }
    }
    return items
  }

  render() {
    return (
      <Disclosure
        as='nav'
        className={`${
          isStage ? 'bg-indigo-700' : 'bg-gray-800'
        } fixed top-0 w-full max-w-full-hd z-40`}
      >
        {({ open }) => (
          <>
            <div className='pl-2 pr-4 sm:pr-6'>
              <div className='relative flex items-center justify-between h-14'>
                <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                  {/* Mobile menu button*/}
                  <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                    <span className='sr-only'>Hauptmenü öffnen</span>
                    {open ? (
                      <XIcon className='block h-6 w-6' aria-hidden='true' />
                    ) : (
                      <MenuIcon className='block h-6 w-6' aria-hidden='true' />
                    )}
                  </Disclosure.Button>
                </div>
                <div className='flex-1 flex items-center justify-center sm:items-stretch sm:justify-start'>
                  <div className='hidden sm:block'>
                    <div className='flex'>
                      {this.state.menu.map((item) => {
                        if ((item as MenuItem).url) {
                          return (
                            <Link
                              key={item.label}
                              to={(item as MenuItem).url}
                              className='text-gray-300 hover:text-white px-4 py-2 text-md font-medium mr-1'
                              style={{ paddingTop: 9 }}
                            >
                              {item.label}
                            </Link>
                          )
                        } else if ((item as MenuCaption).menu) {
                          return (
                            <Menu as='div' className='mr-1 relative' key={item.label}>
                              <div>
                                <Menu.Button
                                  className='text-gray-300 hover:text-white px-4 py-2 text-md font-medium whitespace-nowrap'
                                  style={{ paddingTop: 9 }}
                                >
                                  {item.label}&nbsp;&nbsp;
                                  <i className='fa fa-caret-down' />
                                </Menu.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                enter='transition ease-out duration-100'
                                enterFrom='transform opacity-0 scale-95'
                                enterTo='transform opacity-100 scale-100'
                                leave='transition ease-in duration-75'
                                leaveFrom='transform opacity-100 scale-100'
                                leaveTo='transform opacity-0 scale-95'
                              >
                                <Menu.Items className='origin-top-left absolute left-0 rounded-md shadow-lg py-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40'>
                                  {(item as MenuCaption).menu.map((subitem) => (
                                    <Menu.Item key={subitem.label}>
                                      {({ active }) => {
                                        // todo: Remove if-block, after old react app is completely ported to cra.
                                        // After it is ported, there is only one SPA and real route changes are not required anymore.
                                        if (subitem.url.startsWith('/app/')) {
                                          return (
                                            <a
                                              href={subitem.url}
                                              className={classNames(
                                                active ? 'bg-gray-100' : '',
                                                'block px-4 py-1 text-md text-gray-700 whitespace-nowrap',
                                              )}
                                            >
                                              {subitem.label}
                                            </a>
                                          )
                                        }
                                        return (
                                          <Link
                                            to={subitem.url}
                                            className={classNames(
                                              active ? 'bg-gray-100' : '',
                                              'block px-4 py-1 text-md text-gray-700 whitespace-nowrap',
                                            )}
                                          >
                                            {subitem.label}
                                          </Link>
                                        )
                                      }}
                                    </Menu.Item>
                                  ))}
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          )
                        } else {
                          return null
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div className='absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                  <TodoIndicator onClick={this.props.onBellClick} todos={this.todos} />

                  {/* Profile dropdown */}
                  <Menu as='div' className='ml-3 relative'>
                    <div>
                      <Menu.Button className='bg-gray-800 flex text-md rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
                        <span className='sr-only'>Benutzermenü öffnen</span>
                        <NavbarUserIcon />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items className='origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg py-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to='/account/profile'
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-1 text-md text-gray-700',
                              )}
                            >
                              Konto
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={() => logout(this.context.instance.id)}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'cursor-pointer block px-4 py-1 text-md text-gray-700',
                              )}
                            >
                              Logout
                            </span>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className='sm:hidden'>
              <div
                className='px-2 pt-2 pb-3 space-y-1 z-40 relative bg-gray-800 overflow-y-auto'
                style={{ maxHeight: 'calc(100vh - 56px)' }}
              >
                {this.state.menu.map((item) => {
                  if ((item as MenuItem).url) {
                    return (
                      <Disclosure.Button
                        key={item.label}
                        as={Link}
                        to={(item as MenuItem).url}
                        className='text-gray-300 hover:text-white block px-3 py-2 text-base font-medium'
                      >
                        {item.label}
                      </Disclosure.Button>
                    )
                  } else if ((item as MenuCaption).menu) {
                    return (item as MenuCaption).menu.map((subitem) => {
                      // todo: Remove if-block, after old react app is completely ported to cra.
                      // After it is ported, there is only one SPA and real route changes are not required anymore.
                      if (subitem.url.startsWith('/app/')) {
                        return (
                          <a
                            key={item.label + '/' + subitem.label}
                            href={subitem.url}
                            className='text-gray-300 hover:text-white block px-3 py-2 text-base font-medium'
                          >
                            {subitem.label}
                          </a>
                        )
                      }
                      return (
                        <Disclosure.Button
                          key={item.label + '/' + subitem.label}
                          as={Link}
                          to={subitem.url}
                          className='text-gray-300 hover:text-white block px-3 py-2 text-base font-medium'
                        >
                          {item.label}&nbsp;&nbsp;
                          <i className='fa fa-caret-right' />
                          &nbsp;&nbsp;{subitem.label}
                        </Disclosure.Button>
                      )
                    })
                  } else {
                    return null
                  }
                })}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    )
  }
}
