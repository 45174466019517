const timeInterval =
  /^ *([0-9]|0[0-9]|1[0-9]|2[0-3])[:.]([0-5][0-9]) *- *([0-9]|0[0-9]|1[0-9]|2[0-3])[:.]([0-5][0-9]) *$/

/**
 * @param input string format "hh:mm - hh:mm"
 */
export function parseTimeInterval(input: string): { begin: string; end: string } | null {
  const match = input.match(timeInterval)
  if (!match) {
    return null
  }
  return {
    begin: `${match[1].length === 1 ? `0${match[1]}` : match[1]}:${match[2]}`,
    end: `${match[3].length === 1 ? `0${match[3]}` : match[3]}:${match[4]}`,
  }
}
