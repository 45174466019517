import { Model } from 'components/Form/Model'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  model: Model<{ [key: number]: string | null }>
  readOnly?: boolean
}

@observer
export class DayOverridesForm extends React.Component<Props, {}> {
  render() {
    return (
      <div className='flex mt-2 gap-3'>
        <span className='mr-1'>
          <InputDecimal
            label='Mo'
            model={this.props.model}
            name='1'
            scale={0}
            precision={3}
            readOnly={this.props.readOnly}
            disabled={this.props.readOnly}
          />
        </span>
        <span className='mr-1'>
          <InputDecimal
            label='Di'
            model={this.props.model}
            name='2'
            scale={0}
            precision={3}
            readOnly={this.props.readOnly}
            disabled={this.props.readOnly}
          />
        </span>
        <span className='mr-1'>
          <InputDecimal
            label='Mi'
            model={this.props.model}
            name='3'
            scale={0}
            precision={3}
            readOnly={this.props.readOnly}
            disabled={this.props.readOnly}
          />
        </span>
        <span className='mr-1'>
          <InputDecimal
            label='Do'
            model={this.props.model}
            name='4'
            scale={0}
            precision={3}
            readOnly={this.props.readOnly}
            disabled={this.props.readOnly}
          />
        </span>
        <span className='mr-1'>
          <InputDecimal
            label='Fr'
            model={this.props.model}
            name='5'
            scale={0}
            precision={3}
            readOnly={this.props.readOnly}
            disabled={this.props.readOnly}
          />
        </span>
        <span className='mr-1'>
          <InputDecimal
            label='Sa'
            model={this.props.model}
            name='6'
            scale={0}
            precision={3}
            readOnly={this.props.readOnly}
            disabled={this.props.readOnly}
          />
        </span>
        <span className='mr-1'>
          <InputDecimal
            label='So'
            model={this.props.model}
            name='0'
            scale={0}
            precision={3}
            readOnly={this.props.readOnly}
            disabled={this.props.readOnly}
          />
        </span>
        <span className='mr-1'>
          <InputDecimal
            label='FT'
            model={this.props.model}
            name='7'
            scale={0}
            precision={3}
            readOnly={this.props.readOnly}
            disabled={this.props.readOnly}
          />
        </span>
      </div>
    )
  }
}
