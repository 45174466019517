import * as React from 'react'
import { CompanyTitleBar } from '../CompanyTitleBar'
import { CardColumnCard } from 'components/SideBarLayout/components/CardColumnCard'
import styles from '../styles.module.scss'
import { AppContext } from 'services/connection/models/AppContext'
import { makeObservable, observable, runInAction } from 'mobx'
import { hermes } from '@byll/hermes'
import { box } from 'services/box'
import { observer } from 'mobx-react'
import { Spinner } from 'components/Spinner'
import { Route, Routes, useNavigate, useParams } from 'react-router'
import { SalaryGroupsOverview } from './components/SalaryGroupsOverview'
import { SalaryGroupItem } from './components/SalaryGroupItem'
import { Model } from 'components/Form/Model'
import dayjs from 'dayjs'
import { z } from 'zod'
import { isYmdDate } from 'contracts/general/helpers/isYmdDate'

export interface SalaryGroup {
  id: string
  instanceId: string
  type: string
  label: string
  abbreviation: string
}

interface Props {
  companyId: string
}

const Validator = z.object({
  validFrom: z.string().refine(isYmdDate),
  salaryGroupId: z.string(),
})

@observer
export class CompanySalaryGroupsTab extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private dropdownEntries: any[] | null = null
  private readonly model: Model<{ validFrom: string; salaryGroupId: string }>

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(
      {
        validFrom: dayjs().format('YYYY-MM-DD'),
        salaryGroupId: '',
      },
      Validator,
    )
  }

  componentDidMount() {
    void this.loadDropdownEntries()
  }

  private async loadDropdownEntries() {
    try {
      const entries = await hermes.indexOnceNew(
        `/api/${this.context.instance.id}/dropdownEntries?type=personnelRecordContracts.Gehaltsgruppe`,
      )
      runInAction(() => (this.dropdownEntries = entries))
    } catch (_e) {
      box.alert('Fehler', 'Die Gehaltsgruppen konnten nicht geladen werden.')
    }
  }

  render() {
    if (!this.dropdownEntries) {
      return (
        <div className={styles.tabs}>
          <CompanyTitleBar companyId={this.props.companyId} />
          <div className='mt-6'>
            <CardColumnCard
              title={'Gehaltsgruppen'}
              subtitle={'Führen Sie Lohnerhöhungen für Ihre Gehaltsgruppen durch.'}
            >
              <Spinner />
            </CardColumnCard>
          </div>
        </div>
      )
    }
    return (
      <div className={styles.tabs}>
        <CompanyTitleBar companyId={this.props.companyId} />
        <div className='m-6 max-h-[85vh]'>
          <CardColumnCard
            className='max-h-full'
            title={'Gehaltsgruppen'}
            subtitle={'Führen Sie Lohnerhöhungen für Ihre Gehaltsgruppen durch.'}
          >
            <Routes>
              <Route
                path='/'
                element={
                  <RenderSalaryGroupsOverview
                    model={this.model}
                    salaryGroups={this.dropdownEntries}
                  />
                }
              />
              <Route
                path='/:id'
                element={
                  <RenderSalaryGroupItem
                    salaryGroups={this.dropdownEntries}
                    model={this.model}
                    companyId={this.props.companyId}
                  />
                }
              />
            </Routes>
          </CardColumnCard>
        </div>
      </div>
    )
  }
}

const RenderSalaryGroupItem: React.FC<{
  salaryGroups: SalaryGroup[]
  companyId: string
  model: Model<{ validFrom: string; salaryGroupId: string }>
}> = (props) => {
  const params = useParams()
  const navigate = useNavigate()
  const salaryGroup = props.salaryGroups.find((s) => s.id === params.id)
  if (!salaryGroup) {
    return null
  }
  return (
    <SalaryGroupItem
      companyId={props.companyId}
      salaryGroup={salaryGroup}
      model={props.model}
      navigate={navigate}
    />
  )
}

const RenderSalaryGroupsOverview: React.FC<{
  salaryGroups: SalaryGroup[]
  model: Model<{ validFrom: string; salaryGroupId: string }>
}> = (props) => {
  const navigate = useNavigate()
  return (
    <SalaryGroupsOverview
      salaryGroups={props.salaryGroups}
      model={props.model}
      navigate={navigate}
    />
  )
}
