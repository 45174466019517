import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { ITimeAccountRow } from 'contracts/workload/interfaces/ITimeAccountRow'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { hermes } from '@byll/hermes'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { InputText } from 'components/Form/components/InputText'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { XIcon } from '@heroicons/react/outline'
import { Message } from 'components/Message'
import { Dialog } from '@headlessui/react'
import { z } from 'zod'
import { isDecimalString } from 'contracts/general/helpers/isDecimalString'
import { AppContext } from 'services/connection/models/AppContext'
import { IEmployeeTimeAccountChangeReason } from 'contracts/workload/interfaces/IEmployeeTimeAccountChangeReason'

const directionOptions: InputSelectOption[] = [
  { value: '+', label: 'hinzufügen' },
  { value: '-', label: 'entfernen' },
]
const reasonOptionsAddOvertime: InputSelectOption[] = [
  { value: '', label: 'Bitte wählen...' },
  { value: 'Geleistete Überstunden', label: 'Geleistete Überstunden' },
  { value: 'Übertrag von anderem Vertrag', label: 'Übertrag von anderem Vertrag' },
  { value: 'Übertrag auf anderen Vertrag', label: 'Übertrag auf anderen Vertrag' },
]
const reasonOptionsRemoveOvertime: InputSelectOption[] = [
  { value: '', label: 'Bitte wählen...' },
  { value: 'Abzug für Unterstunden', label: 'Abzug für Unterstunden' },
  { value: 'Auszahlung der Überstunden', label: 'Auszahlung der Überstunden' },
  { value: 'Verfall der Überstunden', label: 'Verfall der Überstunden' },
  { value: 'Übertrag von anderem Vertrag', label: 'Übertrag von anderem Vertrag' },
  { value: 'Übertrag auf anderen Vertrag', label: 'Übertrag auf anderen Vertrag' },
]
const reasonOptionsAddVacation: InputSelectOption[] = [
  { value: '', label: 'Bitte wählen...' },
  { value: 'Übertrag von anderem Vertrag', label: 'Übertrag von anderem Vertrag' },
  { value: 'Übertrag auf anderen Vertrag', label: 'Übertrag auf anderen Vertrag' },
  { value: 'Bezahlter Zusatzurlaub', label: 'Bezahlter Zusatzurlaub' },
]
const reasonOptionsRemoveVacation: InputSelectOption[] = [
  { value: '', label: 'Bitte wählen...' },
  { value: 'Auszahlung der Urlaubstage', label: 'Auszahlung der Urlaubstage' },
  { value: 'Verfall der Urlaubstage', label: 'Verfall der Urlaubstage' },
  { value: 'Übertrag von anderem Vertrag', label: 'Übertrag von anderem Vertrag' },
  { value: 'Übertrag auf anderen Vertrag', label: 'Übertrag auf anderen Vertrag' },
]

interface Props {
  onReload: () => void
  row: ITimeAccountRow
  contract: any
  tab: 'overtime' | 'vacation' | 'sick' | 'parental leave'
  position: 'before' | 'after'
  onClose: (result?: boolean) => void
  style?
}

interface ITimeAccountChange {
  diff: string | null // daysApprovedVacation or hoursOvertime depending on view (overtime | vacation)
  direction: '+' | '-'
  // Option 'Offset' is not offered for end user. This is only here to mark the beginning of usage in inuv.
  // Offset is added via migration.
  reason: '' | IEmployeeTimeAccountChangeReason | 'Offset'
  notes: string
}

const Validator = z.object({
  diff: z.string().refine((val) => isDecimalString(val, 8, 2, '0.01')),
  direction: z.string().refine((val) => val === '+' || val === '-'),
  reason: z.string().min(1),
})

@observer
export class TimeAccountChangeDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private model: Model<ITimeAccountChange>
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(
      { diff: null, direction: '-', reason: '', notes: '' },
      Validator,
    )
  }

  private checkReason = () => {
    const options: InputSelectOption[] =
      this.props.tab === 'overtime'
        ? this.model.values.direction === '+'
          ? reasonOptionsAddOvertime
          : reasonOptionsRemoveOvertime
        : this.model.values.direction === '+'
        ? reasonOptionsAddVacation
        : reasonOptionsRemoveVacation
    for (const option of options) {
      if (option.value === '') {
        continue
      }
      if (option.value === this.model.values.reason) {
        return true
      }
    }
    return false
  }

  private save = async () => {
    if (!this.model.isValid() || !this.checkReason()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    try {
      const data = {
        contractId: this.props.contract.id,
        month: this.props.row.month,
        view: this.props.tab as any,
        diff:
          this.props.tab === 'overtime'
            ? this.model.values.direction === '-'
              ? `-${this.model.values.diff}`
              : (this.model.values.diff as string)
            : this.model.values.direction === '+'
            ? `-${this.model.values.diff}`
            : (this.model.values.diff as string),
        reason: this.model.values.reason as any,
        notes: this.model.values.notes,
        referenceId: this.props.row.id, // Element where user clicked on "+ Button".
        position: this.props.position, // Insert 'before' or 'after' reference.
      }
      await hermes.create(
        `/api/${this.context.instance.id}/employees/${this.props.contract.userId}/timeAccounts`,
        data,
      )
      this.props.onClose(true)
    } catch (_e) {
      runInAction(() => (this.error = 'Die Änderung konnte leider nicht erfasst werden.'))
    }
  }

  render() {
    return (
      <div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4 z-20'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div
          className={`sticky ${
            this.error ? '-top-7' : '-top-11'
          } bg-white -mx-6 px-6 border-b border-gray-300 z-10`}
        >
          <div className='pb-4 pt-1 -mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Korrektur hinzufügen
            </Dialog.Title>
          </div>
          {this.error && (
            <Message className='mb-6' color='danger'>
              {this.error}
            </Message>
          )}
        </div>
        <div className='sm:-mx-6 -mx-4 bg-gray-100 py-6 flex flex-col gap-6'>
          <div className='mx-6 bg-white rounded-md shadow-md p-4'>
            <div id={this.model.id} className='grid grid-cols-3 gap-4'>
              <InputDecimal
                model={this.model}
                name='diff'
                label={this.props.tab === 'vacation' ? 'Urlaubstage' : 'Überstunden'}
                precision={8}
                scale={2}
              />
              <InputSelect
                className='col-span-2'
                model={this.model}
                name='direction'
                options={directionOptions}
              />
              <InputSelect
                className='col-span-3'
                model={this.model}
                name='reason'
                label='Begründung'
                options={
                  this.props.tab === 'overtime'
                    ? this.model.values.direction === '+'
                      ? reasonOptionsAddOvertime
                      : reasonOptionsRemoveOvertime
                    : this.model.values.direction === '+'
                    ? reasonOptionsAddVacation
                    : reasonOptionsRemoveVacation
                }
              />
              <InputText
                className='col-span-3'
                model={this.model}
                name='notes'
                label='Notizen'
              />
            </div>
          </div>
        </div>
        <div className='flex gap-2 justify-end sticky z-1 text-right bottom-0 bg-white -mx-6 border-t border-gray-300 py-4 px-6 -mb-4'>
          <Button
            color='secondary'
            outline
            className='mr-2'
            onClick={() => this.props.onClose()}
          >
            Abbrechen
          </Button>
          <Button color='primary' onClick={this.save}>
            Speichern
          </Button>
        </div>
      </div>
    )
  }
}
