import * as React from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { Model } from 'components/Form/Model'
import dayjs from 'dayjs'
import { hermes } from '@byll/hermes'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { WorkplanGrids } from './components/WorkplanGrids'
import { toast } from 'react-toastify'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import { InputEmployeePool } from 'components/Form/components/InputEmployeePool'
import { storage } from 'services/storage'
import { IMonthlyEmployeeWorkScheduleFilter } from 'contracts/workplan/interfaces/IMonthlyEmployeeWorkScheduleFilter'
import { getSavedMonthlyEmployeeWorkScheduleFilter } from './helpers/getSavedMonthlyEmployeeWorkScheduleFilter'
import { WorkplanTopBar } from './components/WorkplanTopBar'
import { Legend } from './components/Legend'
import { ViewEmployeeDetails } from './components/ViewEmployeeDetails'
import { Movable } from 'components/Movable'
import { Callout } from 'components/Callout'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
const PARENT_ID = 'workplan'

interface Props {
  navigate: (path: string) => void
  pathname: string
}

@observer
export class Workplan extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private specialShifts: IShift[] | null = null
  @observable private activeBuildingShifts: IShift[] | null = null
  private employeeDetailsModel: Model<{ showEmployeeDetailsBox: boolean }>
  private model: Model<IMonthlyEmployeeWorkScheduleFilter>
  private selectShiftModel: Model<{
    compoundId: string
    selectedBuilding: IBuilding | null
    selectedShift: IShift | null
    view: 'shortlist' | 'select'
  }>
  private disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.model = new Model(
      getSavedMonthlyEmployeeWorkScheduleFilter(context.user, props.pathname),
    )
    this.selectShiftModel = new Model({
      compoundId: '',
      selectedBuilding: null,
      selectedShift: null,
      view: 'shortlist',
    })
    this.employeeDetailsModel = new Model({ showEmployeeDetailsBox: false })
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => this.model.values.month,
        () => (this.model.values.selectedUser = ''),
      ),
    )
    this.disposers.push(
      reaction(
        () => this.model.values.activeBuilding,
        (buildingId) => {
          if (!buildingId) {
            return
          }
          this.loadActiveBuildingShifts()
        },
      ),
    )
    this.disposers.push(
      reaction(
        () => this.model.values.viewId,
        () => {
          this.model.values.activeBuilding = ''
        },
      ),
    )
    this.disposers.push(
      reaction(
        () => toJS(this.model.values),
        () => storage.set(`workplan.${this.context.user.id}`, this.model.values),
      ),
    )
    this.disposers.push(
      reaction(
        () => this.props.pathname,
        () => {
          this.model.values.type =
            (this.props.pathname.split('/')[3] as 'plan' | 'real') || 'plan'
        },
      ),
    )
    this.loadSpecialShifts()
    this.loadActiveBuildingShifts()
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  @action private setSelectedDateAndUser = (userId: string | null, column: number) => {
    if (column < 1 || column > 31) {
      return
    }
    this.model.values.date = dayjs(`${this.model.values.month}-${column}`).format(
      'YYYY-MM-DD',
    )
    if (userId) {
      this.model.values.selectedUser = userId
    }
  }

  private async loadSpecialShifts() {
    try {
      const specialShifts = await hermes.indexOnceNew<IShift>(
        `/api/${this.context.instance.id}/workplan/specialShifts`,
      )
      runInAction(() => (this.specialShifts = specialShifts))
    } catch (_e) {
      toast.error('Beim Laden der Sonderschichten ist ein Fehler aufgetreten.')
    }
  }

  private async loadActiveBuildingShifts() {
    try {
      const activeBuildingShifts = await hermes.indexOnceNew<IShift>(
        `/api/${this.context.instance.id}/workplan/shifts?month=${this.model.values.month}&buildingId=${this.model.values.activeBuilding}`,
      )
      runInAction(() => (this.activeBuildingShifts = activeBuildingShifts))
    } catch (e) {
      toast.error('Beim Laden der Schichten ist ein Fehler aufgetreten.')
    }
  }

  render() {
    const isShiftView =
      (this.props.pathname.split('/')[2] as 'view' | 'shift') === 'shift'
    return (
      <div
        id={PARENT_ID}
        className='pt-14 min-h-full relative flex flex-col overflow-hidden'
      >
        {/* Top bar */}
        <div
          className={`flex divide-x divide-gray-300 ${
            this.model.values.viewId ? '' : 'border-b border-gray-300'
          } bg-white`}
        >
          <div className='flex flex-content w-[287px] p-4'>
            <InputEmployeePool
              model={this.model}
              name='employeePoolId'
              label='Mitarbeiterstamm'
            />
          </div>
          <WorkplanTopBar
            key={this.props.pathname.split('/')[2]}
            isShiftView={isShiftView}
            model={this.model}
            navigate={this.props.navigate}
            selectShiftModel={this.selectShiftModel}
          />
        </div>
        {/* Content */}
        {this.specialShifts && (
          <RenderWorkplanGrids
            key={`${this.props.pathname}${this.model.values.viewId}${this.model.values.employeePoolId}${this.model.values.month}${this.model.values.type}${this.model.values.activeBuilding}${this.model.values.shiftId}`}
            isShiftView={isShiftView}
            model={this.model}
            selectShiftModel={this.selectShiftModel}
            specialShifts={this.specialShifts}
            setSelectedDateAndUser={this.setSelectedDateAndUser}
          />
        )}
        {this.specialShifts && (
          <Legend
            key={this.model.values.activeBuilding}
            specialShifts={this.specialShifts}
            model={this.model}
            employeeDetailsModel={this.employeeDetailsModel}
            activeBuildingShifts={this.activeBuildingShifts}
          />
        )}
        {this.employeeDetailsModel.values.showEmployeeDetailsBox &&
          this.specialShifts && (
            <Movable
              dragBorder={24}
              windowWidth={500}
              windowHeight={250}
              menuBarHeight={56}
              model={this.employeeDetailsModel}
              name='showEmployeeDetailsBox'
              parentId={PARENT_ID}
            >
              <ViewEmployeeDetails
                key={`${this.model.values.selectedUser}${this.model.values.date}${this.model.values.type}`}
                viewId={this.model.values.viewId}
                userId={this.model.values.selectedUser}
                type={this.model.values.type}
                date={this.model.values.date}
                specialShifts={this.specialShifts}
                employeePoolId={this.model.values.employeePoolId}
                employeeDetailsModel={this.employeeDetailsModel}
              />
            </Movable>
          )}
      </div>
    )
  }
}

@observer
export class RenderWorkplanGrids extends React.Component<
  {
    isShiftView: boolean
    model: Model<IMonthlyEmployeeWorkScheduleFilter>
    selectShiftModel: Model<{
      compoundId: string
      selectedBuilding: IBuilding | null
      selectedShift: IShift | null
      view: 'shortlist' | 'select'
    }>
    specialShifts: IShift[]
    setSelectedDateAndUser: (userId: string | null, column: number) => void
  },
  {}
> {
  static contextType = AppContext
  @observable plannableShiftsMap: Map<string, IShift> = new Map()
  @observable private shift: IShift | null = null

  constructor(props: {
    isShiftView: boolean
    model: Model<IMonthlyEmployeeWorkScheduleFilter>
    selectShiftModel: Model<{
      compoundId: string
      selectedBuilding: IBuilding | null
      selectedShift: IShift | null
      view: 'shortlist' | 'select'
    }>
    specialShifts: IShift[]
    setSelectedDateAndUser: (userId: string | null, column: number) => void
  }) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    this.setPlannableShiftsMap()
    this.loadShift()
  }

  private loadShift = async () => {
    if (!this.props.model.values.shiftId) {
      return
    }
    try {
      const shift = await hermes.getOnceNew<IShift>(
        `/api/${this.context.instance.id}/workplan/shifts/${this.props.model.values.shiftId}`,
      )
      runInAction(() => (this.shift = shift))
    } catch (_e) {
      toast.error('Beim Laden der Schicht ist ein Fehler aufgetreten.')
    }
  }

  private setPlannableShiftsMap = async () => {
    this.plannableShiftsMap.clear()
    const abbreviations = new Set<string>()
    for (const shift of this.props.specialShifts) {
      const abbreviation = shift.comment.split(',')[0]
      abbreviations.add(abbreviation)
    }
    for (const shift of this.props.specialShifts) {
      const abbreviation = shift.comment.split(',')[0]
      if (
        !abbreviations.has(`${abbreviation}*`) ||
        this.context.permissions.workplan_vacation
      ) {
        this.plannableShiftsMap.set(shift.id, shift)
      }
    }
    if (this.props.isShiftView && this.props.model.values.shiftId) {
      const shift = await hermes.getOnceNew<IShift>(
        `/api/${this.context.instance.id}/workplan/shifts/${this.props.model.values.shiftId}`,
      )
      this.plannableShiftsMap.set(shift.id, shift)
    } else {
      if (!this.props.model.values.activeBuilding) {
        return
      }
      const shifts = await hermes.indexOnceNew<IShift>(
        `/api/${this.context.instance.id}/workplan/shifts?month=${this.props.model.values.month}&buildingId=${this.props.model.values.activeBuilding}`,
      )
      for (const shift of shifts) {
        this.plannableShiftsMap.set(shift.id, shift)
      }
    }
  }

  render() {
    if (
      this.props.isShiftView &&
      !this.props.model.values.shiftId &&
      !this.props.selectShiftModel.values.compoundId &&
      !this.props.selectShiftModel.values.selectedBuilding
    ) {
      return (
        <Callout
          className='border-t border-gray-300'
          icon='fas fa-clock'
          iconColor='#524ae8'
          title={'Bitte wählen Sie eine Schicht aus.'}
        />
      )
    }
    if (!this.props.isShiftView && !this.props.model.values.viewId) {
      return (
        <Callout
          className='border-t border-gray-300'
          icon='fas fa-desktop'
          iconColor='#524ae8'
          title={'Bitte wählen Sie eine Ansicht aus.'}
        />
      )
    }
    if (!this.props.model.values.employeePoolId) {
      return (
        <Callout
          className='border-t border-gray-300'
          icon='fas fa-users'
          iconColor='#524ae8'
          title={'Bitte wählen Sie einen Mitarbeiterstamm aus.'}
        />
      )
    }
    return (
      <WorkplanGrids
        isShiftView={this.props.isShiftView}
        shift={this.shift}
        model={this.props.model}
        specialShifts={this.props.specialShifts}
        plannableShiftsMap={this.plannableShiftsMap}
        setSelectedDateAndUser={this.props.setSelectedDateAndUser}
      />
    )
  }
}
