import { IResident } from 'contracts/residents/interfaces/IResident'
import * as React from 'react'
import { CreateWorkplanEmployee } from './components/CreateWorkplanEmployee'
import { WorkplanEmployee } from './components/WorkplanEmployee'
import { observer } from 'mobx-react'

interface Props {
  resident: IResident
}

@observer
export class WorkplanTab extends React.Component<Props, {}> {
  render() {
    return (
      <div className='flex bg-white rounded-md shadow-md p-6 mb-6 flex-grow'>
        <div
          className='hidden lg:block pr-12 pt-4 text-right'
          style={{ flex: '0 0 200px' }}
        >
          <span className='text-gray-900 text-lg'>Arbeitsplan</span>
          <br />
          <span className='text-sm text-gray-400'>
            Aktivieren oder Deaktivieren Sie die Arbeitsplanung für diesen Bewohner
          </span>
        </div>
        <div className='flex-auto pt-4 relative min-w-0 flex flex-col gap-4 lg:pr-4'>
          {!this.props.resident.employeeId && (
            <CreateWorkplanEmployee resident={this.props.resident} />
          )}
          {this.props.resident.employeeId && (
            <WorkplanEmployee resident={this.props.resident} />
          )}
        </div>
      </div>
    )
  }
}
