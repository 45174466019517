import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { IEmployeeSearchResultsFilter } from 'contracts/employees/interfaces/IEmployeeSearchResultsFilter'
import { IEmployeeSearchResultsMetadata } from 'contracts/employees/interfaces/IEmployeeSearchResultsMetadata'
import { UnsyncedCollection } from 'modules/Residents/modules/FindRecord/models/UnsyncedCollection'
import * as React from 'react'
import { EmployeeFilter } from './components/EmployeeFilter'
import { EmployeeList } from './components/EmployeeList'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { getSavedEmployeeSearchResultsFilter } from './helpers/getSavedEmployeeSearchResultsFilter'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { storage } from 'services/storage'
import { EmployeeDialog } from './components/EmployeeDialog'
import { Location, Route, Routes, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { ReportDialog } from './components/ReportDialog'
import { KanzleiDialog } from './components/KanzleiDialog'
import { Model } from 'components/Form/Model'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { hermes } from '@byll/hermes'
import { IEmployeeReportDefinition } from 'contracts/workload/interfaces/IEmployeeReportDefinition'

interface Props {
  location: Location
  navigate: (path: string) => void
}

@observer
export class Employees extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly disposers: Disposer[] = []
  private readonly employees: UnsyncedCollection<
    IEmployeeSearchResult,
    IEmployeeSearchResultsMetadata,
    IEmployeeSearchResultsFilter
  >
  private filterModel: Model<IEmployeeSearchResultsFilter>
  @observable private definitionOptions: InputSelectOption[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.filterModel = new Model(getSavedEmployeeSearchResultsFilter(context.user))
    this.employees = new UnsyncedCollection(
      `/api/${context.instance.id}/workload/employeeSearchResults`,
      this.filterModel.values,
    )
  }

  componentDidMount() {
    void this.loadDefinitionOptions()
    this.disposers.push(this.employees.init())
    this.disposers.push(
      reaction(
        () => toJS(this.employees.query),
        () =>
          storage.set(`employees.admin.${this.context.user.id}`, this.employees.query),
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private closeReportDialog = () => {
    this.props.navigate('/employees')
  }

  private loadDefinitionOptions = async () => {
    const definitions = await hermes.indexOnceNew<IEmployeeReportDefinition>(
      `/api/${this.context.instance.id}/workload/reportDefinitions`,
    )
    runInAction(() => {
      this.definitionOptions = [
        ...definitions.map(
          (definition: IEmployeeReportDefinition): InputSelectOption => ({
            value: definition.id,
            label: definition.label,
          }),
        ),
      ]
    })
  }

  render() {
    const match = this.props.location.pathname
      .replace(/\/$/, '')
      .toLowerCase()
      .match(/^\/employees\/(\d+)\/([a-z-]+)$/)
    const employeeId = match ? match[1] : null
    return (
      <div className='pt-14 bg-gray-100 min-h-full flex flex-col'>
        <EmployeeFilter
          filterModel={this.filterModel}
          employees={this.employees}
          navigate={this.props.navigate}
          definitionOptions={this.definitionOptions}
        />
        <div className='flex-auto relative overflow-hidden'>
          <div
            className={`z-1 transition-right bg-white shadow-md absolute top-0 bottom-0 left-0 ${
              employeeId ? 'right-[700px]' : 'right-0'
            }`}
          >
            <EmployeeList employees={this.employees} employeeId={employeeId} />
          </div>
          <div
            className={`z-1 md:z-0 overflow-auto transition-right bg-gray-100 absolute top-0 bottom-0 w-full md:w-[700px] ${
              employeeId ? 'right-0' : '-right-[1000px]'
            }`}
          >
            <EmployeeDialog
              filterModel={this.filterModel}
              employees={this.employees}
              employeeId={employeeId}
            />
          </div>
        </div>
        <Routes>
          <Route
            path='reports/:id'
            element={
              <RenderReportDialog
                loadDefinitionOptions={this.loadDefinitionOptions}
                onClose={this.closeReportDialog}
              />
            }
          />
          <Route
            path='kanzlei'
            element={
              <RenderKanzleiReport
                key={`${this.employees.query.month}-${this.employees.query.companyId}`}
                companyId={this.employees.query.companyId || null}
                month={this.employees.query.month}
                onClose={this.closeReportDialog}
              />
            }
          />
        </Routes>
      </div>
    )
  }
}

export const RenderReportDialog: React.FC<{
  onClose: () => void
  loadDefinitionOptions: () => void
}> = observer((props) => {
  const params = useParams()

  return (
    <ReportDialog
      loadDefinitionOptions={props.loadDefinitionOptions}
      definitionId={params.id || ''}
      onClose={props.onClose}
    />
  )
})

export const RenderKanzleiReport: React.FC<{
  companyId: string | null
  month: string
  onClose: () => void
}> = observer((props) => {
  return (
    <KanzleiDialog
      companyId={props.companyId}
      month={props.month}
      onClose={props.onClose}
    />
  )
})
