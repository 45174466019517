import { IEmployeeFileBlock } from '../interfaces/IEmployeeFileBlock'

export const employeeFileBlocks: IEmployeeFileBlock[] = [
  {
    id: 'name',
    label: 'Name',
    mandatoryFields: [
      {
        key: 'anrede',
        label: 'Anrede',
        type: 'select',
        colspan: 1,
        required: true,
        options: ['', 'Herr', 'Frau', 'Divers'],
        allowEmpty: true,
      },
      {
        key: 'titel',
        label: 'Titel',
        type: 'text',
        colspan: 1,
        required: true,
        options: [],
        allowEmpty: true,
      },
      {
        key: 'vorname',
        label: 'Vorname',
        type: 'text',
        colspan: 1,
        required: true,
        options: [],
        allowEmpty: false,
      },
      {
        key: 'nachname',
        label: 'Nachname',
        type: 'text',
        colspan: 1,
        required: true,
        options: [],
        allowEmpty: false,
      },
      {
        key: 'geburtsname',
        label: 'Geburtsname',
        type: 'text',
        colspan: 2,
        required: true,
        options: [],
        allowEmpty: true,
      },
    ],
  },
  {
    id: 'contact',
    label: 'Kontakt',
    mandatoryFields: [
      {
        key: 'Straße',
        label: 'Straße',
        type: 'text',
        colspan: 3,
        required: true,
        options: [],
        allowEmpty: true,
      },
      {
        key: 'Hausnummer',
        label: 'Hausnummer',
        type: 'text',
        colspan: 1,
        required: true,
        options: [],
        allowEmpty: true,
      },
      {
        key: 'PLZ',
        label: 'PLZ',
        type: 'text',
        colspan: 2,
        required: true,
        options: [],
        allowEmpty: true,
      },
      {
        key: 'Ort',
        label: 'Ort',
        type: 'text',
        colspan: 2,
        required: true,
        options: [],
        allowEmpty: true,
      },
    ],
  },
  {
    id: 'employment-contracts',
    label: 'Arbeitsverträge',
    mandatoryFields: [
      {
        key: 'type',
        label: 'Vertragsart',
        type: 'select',
        colspan: 2,
        required: true,
        options: [
          '',
          'Ausbildung',
          'Aushilfe',
          'Befristete Festanstellung',
          'Festanstellung',
          'Festanstellung Ergänzung zum Arbeitsvertrag befristet',
          'Festanstellung Vertragsergänzung',
          'Midijob',
          'Praktikum',
          'Teilzeit Festanstellung',
          'Teilzeit Vertragsergänzung',
          'Vertragsergänzung Festanstellung',
          'Werkstudent',
          'Subunternehmer',
          'Subunternehmer HSP',
          'Subunternehmer NOT',
          'Subunternehmer SMA',
          'Subunternehmer SMA + HSP',
        ],
        allowEmpty: false,
      },
      {
        key: 'joinDate',
        label: 'Vertragsbeginn',
        type: 'date',
        colspan: 1,
        required: true,
        options: [],
        allowEmpty: false,
        sort: 'desc',
      },
      {
        key: 'resignDate',
        label: 'Vertragsende',
        type: 'date',
        colspan: 1,
        required: true,
        options: [],
        allowEmpty: true,
      },
      {
        key: 'companyId',
        label: 'Firma',
        type: 'companySelect',
        colspan: 2,
        required: true,
        options: [],
        allowEmpty: false,
      },
      {
        key: 'personnelNumber',
        label: 'Personalnummer',
        type: 'text',
        colspan: 1,
        required: true,
        options: [],
        allowEmpty: true,
      },
      // {
      //   key: 'Gehaltsgruppe',
      //   label: 'Gehaltsgruppe',
      //   type: 'dropdownEntry',
      //   dropdownEntryType: 'personnelRecordContracts.Gehaltsgruppe',
      //   colspan: 2,
      //   required: true,
      //   options: [],
      //   allowEmpty: false,
      // },
      {
        key: 'workplanPlannable',
        label: 'Arbeitsplanung',
        type: 'select',
        colspan: 2,
        required: true,
        options: ['', 'Ja', 'Nein'],
        allowEmpty: false,
      },
    ],
    multiple: true,
  },
  {
    id: 'personnel-record',
    label: 'Persönliche Angaben',
    mandatoryFields: [],
  },
  {
    id: 'severe-disability',
    label: 'Schwerbehinderung',
    mandatoryFields: [],
  },
  {
    id: 'loan-agreements',
    label: 'Darlehensverträge',
    mandatoryFields: [
      {
        key: 'Datum',
        label: 'Abgeschlossen am',
        type: 'date',
        colspan: 1,
        required: true,
        options: [],
        sort: 'desc',
        allowEmpty: false,
      },
    ],
    multiple: true,
  },
  {
    id: 'accidents',
    label: 'Arbeitsunfälle',
    mandatoryFields: [
      {
        key: 'Datum',
        label: 'Unfalldatum',
        type: 'date',
        colspan: 1,
        required: true,
        options: [],
        sort: 'desc',
        allowEmpty: false,
      },
    ],
    multiple: true,
  },
  {
    id: 'medical-examinations',
    label: 'Untersuchungen',
    mandatoryFields: [
      {
        key: 'Datum',
        label: 'Datum',
        type: 'date',
        colspan: 1,
        required: true,
        options: [],
        sort: 'desc',
        allowEmpty: false,
      },
    ],
    multiple: true,
  },
  {
    id: 'warning-letters',
    label: 'Abmahnungen',
    mandatoryFields: [
      {
        key: 'Datum',
        label: 'Datum',
        type: 'date',
        colspan: 1,
        required: true,
        options: [],
        sort: 'desc',
        allowEmpty: false,
      },
    ],
    multiple: true,
  },
  {
    id: 'pregnancies',
    label: 'Schwangerschaften',
    mandatoryFields: [
      {
        key: 'Datum',
        label: 'Schwangerschaft gemeldet am',
        type: 'date',
        colspan: 2,
        required: true,
        options: [],
        sort: 'desc',
        allowEmpty: false,
      },
    ],
    multiple: true,
  },
  {
    id: 'bem-interviews',
    label: 'BEM Gespräche',
    mandatoryFields: [
      {
        key: 'Datum',
        label: 'Einladung',
        type: 'date',
        colspan: 1,
        required: true,
        options: [],
        sort: 'desc',
        allowEmpty: false,
      },
    ],
    multiple: true,
  },
  {
    id: 'ma-interviews',
    label: 'MA Gespräche',
    mandatoryFields: [
      {
        key: 'Datum',
        label: 'Datum',
        type: 'date',
        colspan: 1,
        required: true,
        options: [],
        sort: 'desc',
        allowEmpty: false,
      },
    ],
    multiple: true,
  },
  {
    id: 'work-equipment',
    label: 'Arbeitsmittel',
    mandatoryFields: [
      {
        key: 'Datum',
        label: 'Übergabedatum',
        type: 'date',
        colspan: 1,
        required: true,
        options: [],
        sort: 'desc',
        allowEmpty: false,
      },
    ],
    multiple: true,
  },
  {
    id: 'certificates',
    label: 'Nachweise',
    mandatoryFields: [
      {
        key: 'Gueltig_bis',
        label: 'Gültig bis',
        type: 'date',
        colspan: 1,
        required: true,
        options: [],
        sort: 'desc',
        allowEmpty: false,
      },
    ],
    multiple: true,
  },
]
