import { Menu, Transition } from '@headlessui/react'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { action, makeObservable, runInAction } from 'mobx'
import { box } from 'services/box'
import { EditViewDialog } from './EditViewDialog'
import { Collection, hermes } from '@byll/hermes'
import { IMonthlyEmployeeWorkScheduleFilter } from 'contracts/workplan/interfaces/IMonthlyEmployeeWorkScheduleFilter'
import { IView } from 'contracts/workplan/interfaces/IView'
import { RoundIcon } from 'components/RoundIcon'
import { toast } from 'react-toastify'
import { classNames } from 'helpers/classNames'

interface Props {
  options: InputSelectOption[]
  views: Collection<IView>
  model: Model<IMonthlyEmployeeWorkScheduleFilter>
  className?: string
}

@observer
export class ViewDropdown extends React.Component<Props, {}> {
  static contextType = AppContext

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private selectView = (id: string) => {
    this.props.model.values.viewId = id
  }

  private createView = async () => {
    const newView = {
      id: '',
      label: '',
      buildingIds: '',
      instanceId: this.context.instance.id,
    }
    const promise = box.custom(
      <EditViewDialog
        month={this.props.model.values.month}
        employeePoolId={this.props.model.values.employeePoolId}
        view={newView}
        onClose={(id?: string) => promise.close(id)}
      />,
      { context: this.context, closable: true },
    )
    const id = await promise
    if (id) {
      runInAction(() => (this.props.model.values.viewId = id))
    }
  }

  private deleteView = async () => {
    if (
      !(await box.alert(
        'Ansicht löschen?',
        'Möchten Sie das Ansicht wirklich unwiderruflich löschen?',
        { confirm: 'Ja', cancel: 'Abbrechen' },
      ))
    ) {
      return
    }
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/workplan/views/${this.props.model.values.month}/views/${this.props.model.values.viewId}`,
      )
      runInAction(() => (this.props.model.values.viewId = ''))
    } catch (_e) {
      toast.error('Beim Löschen der Ansicht ist ein Fehler aufgetreten.')
    }
  }

  render() {
    return (
      <Menu as='div' className={classNames('relative w-full', this.props.className)}>
        <Menu.Button
          className='text-white w-full flex justify-between items-center bg-gradient-to-r from-indigo-500 to-indigo-600 hover:bg-gradient-to-r hover:from-indigo-500 hover:to-indigo-500 focus:ring-indigo-500 py-[9px] px-[17px] text-center rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2'
          style={{
            borderRadius: this.props.model.values.viewId
              ? '0.375rem 0 0 0.375rem'
              : '0.375rem',
          }}
        >
          {this.props.options.find((o) => o.value === this.props.model.values.viewId)
            ?.label || 'Ansicht wählen...'}
          &nbsp;&nbsp;
          <i className='fa fa-caret-down' />
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            className={`top-10 z-[1000] max-h-[800px] w-full overflow-auto left-0 origin-top-left absolute mt-2 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          >
            {this.props.options.map((option) => (
              <Menu.Item key={option.value}>
                {({ active }) => (
                  <div
                    className={`${
                      active ? 'bg-blue-500 text-white' : 'text-gray-900'
                    } group flex justify-between items-center w-full px-3 py-2 gap-2 text-sm truncate cursor-pointer`}
                    onClick={() => this.selectView(option.value)}
                  >
                    <button>{option.label}</button>
                    <div className='flex-content'>
                      <RoundIcon
                        color='danger'
                        icon='fa fa-trash'
                        onClick={this.deleteView}
                      />
                    </div>
                  </div>
                )}
              </Menu.Item>
            ))}
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={this.createView}
                  className={`${
                    active ? 'bg-blue-500 text-white' : 'text-gray-900'
                  } group flex items-center w-full px-3 py-2 text-sm`}
                >
                  Neue Ansicht erstellen
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
