import { Resource } from '@byll/hermes'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { InputSection } from '../InputSection'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import {
  IInputBlock,
  IInputBlockField,
} from 'contracts/inputBlock/interfaces/IInputBlock'
import cloneDeep from 'lodash/cloneDeep'

interface Props {
  id: string
  name: string
  label: string
  model: Model<any>
  multiple?: boolean
  readonly?: boolean
  disabled?: boolean
  editable?: boolean | 'inEditingMode'
  emptyText?: string
  mandatoryFields?: IInputBlockField[]
  toggleEdit?: () => void
  onAdd?: (data?: any) => void
  onEdit?: (data: any) => void
  onDelete?: (data: any) => void
}

@observer
export class InputBlock extends React.Component<Props, {}> {
  static contextType = AppContext
  private inputBlock: Resource<IInputBlock>
  private disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.inputBlock = new Resource<IInputBlock>(
      `/api/${context.instance.id}/inputBlocks/${props.id}`,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.inputBlock.init({ readOnly: true }))
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  render() {
    if (!this.inputBlock.data) {
      return null
    }
    const { model, name, label, multiple, emptyText, mandatoryFields, ...attributes } =
      this.props
    return (
      <div key={this.inputBlock.data.version} className='bg-gray-100 px-6'>
        {this.inputBlock.data && (
          <InputSection
            inputBlock={cloneDeep(this.inputBlock)}
            name={name}
            model={model}
            label={label}
            multiple={multiple}
            emptyText={emptyText}
            mandatoryFields={mandatoryFields}
            {...attributes}
            editable={this.props.editable}
            toggleEdit={this.props.toggleEdit}
            onAdd={this.props.onAdd}
            onEdit={this.props.onEdit}
            onDelete={this.props.onDelete}
          />
        )}
      </div>
    )
  }
}
