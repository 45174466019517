import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import axios from 'axios'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { EmployeeReportDefinitionColumn } from 'contracts/report/helpers/EmployeeReportDefinition'
import {
  InputMultiSelect,
  InputMultiSelectOption,
} from 'components/Form/components/InputMultiSelect'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { AppContext } from 'services/connection/models/AppContext'
import { RoundIcon } from 'components/RoundIcon'
import { Model } from 'components/Form/Model'
import styles from './styles.module.scss'
import { employeeFileBlocks } from 'contracts/employees/helpers/EmployeeFileBlocks'
import { IInputBlock } from 'contracts/inputBlock/interfaces/IInputBlock'
import { hermes } from '@byll/hermes'
import { getCurrentEmployeeReportSections } from 'contracts/report/helpers/getCurrentEmployeeReportSections'
import { mergeFieldsAndMandatoryFields } from 'contracts/inputBlock/helpers/mergeFieldsAndMandatoryFields'

export interface ReportFieldProps {
  column: EmployeeReportDefinitionColumn
  onDelete: (column: EmployeeReportDefinitionColumn) => void
}

// const sections: InputSelectOption[] = personnelRecordSectionsReporting.map(s => ({ id: s.label, label: s.label }))
const sections: InputSelectOption[] = getCurrentEmployeeReportSections().map((s) => ({
  value: s.label,
  label: s.label,
}))
sections.unshift({ value: '', label: 'Wählen...' })

const aggregations: InputSelectOption[] = [
  { value: 'comma', label: 'Alle Einträge aufzählen' },
  { value: 'columns', label: 'Neue Spalte für jeden Wert' },
  { value: 'newest', label: 'Neuesten Eintrag verwenden' },
  { value: 'oldest', label: 'Ältesten Eintrag verwenden' },
]
const aggregationsWithRecent: InputSelectOption[] = [
  { value: 'comma', label: 'Alle Einträge aufzählen' },
  { value: 'columns', label: 'Neue Spalte für jeden Wert' },
  { value: 'newest', label: 'Neuesten Eintrag verwenden' },
  { value: 'oldest', label: 'Ältesten Eintrag verwenden' },
  { value: 'selected month', label: 'Eintrag aus gewähltem Monat verwenden' },
]
const aggregationsWithoutDate: InputSelectOption[] = [
  { value: 'comma', label: 'Alle Einträge aufzählen' },
  { value: 'columns', label: 'Neue Spalte für jeden Wert' },
]

const aggregationsWithSelect: InputSelectOption[] = [
  { value: 'comma', label: 'Alle Einträge aufzählen' },
  { value: 'columns', label: 'Neue Spalte für jeden Wert' },
  { value: 'selected', label: 'Ausgewählte Einträge' },
]

// If a field is not defined in aggregationsSelector, the default aggregations are used.
const aggregationSelector = {
  Nachweise: aggregationsWithSelect,
  Qualifikationen: aggregationsWithSelect,
  // 'Qualifikationskategorien' : aggregationsWithSelect,
  Einsatzort: aggregationsWithoutDate,
  Arbeitsverträge: aggregationsWithRecent,
  Stundenzettel: aggregationsWithRecent,
}

const FieldSortableHandle: React.ComponentClass<{}> = SortableHandle(() => (
  <i className='fa fa-bars' aria-hidden='false' style={{ cursor: 'move' }} />
))

@observer
export class ReportField extends React.Component<ReportFieldProps, {}> {
  static contextType = AppContext
  @observable.ref private fields: InputSelectOption[] = [
    { value: '', label: 'Wählen...' },
  ]
  @observable private qualifications: InputMultiSelectOption[] = []
  @observable private categories: InputMultiSelectOption[] = []
  @observable private certificates: InputMultiSelectOption[] = []
  @observable private inputBlocks: IInputBlock[] | null = null
  private readonly model: Model<EmployeeReportDefinitionColumn>
  private disposers: Disposer[] = []

  constructor(props: ReportFieldProps) {
    super(props)
    makeObservable(this)
    this.onSelectSection()
    this.model = new Model(props.column)
  }

  componentDidMount(): void {
    this.disposers.push(
      reaction(
        () => this.inputBlocks,
        () => this.onSelectSection(),
      ),
    )
    this.loadQualifications()
    this.loadCertificates()
    this.loadInputBlocks()
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private loadInputBlocks = async () => {
    const ids = employeeFileBlocks.map((s) => s.id).join(',')
    const inputBlocks = await hermes.indexOnceNew<IInputBlock>(
      `api/${this.context.instance.id}/inputBlocks?ids=${ids}`,
    )
    runInAction(() => (this.inputBlocks = inputBlocks))
  }

  private loadQualifications = async () => {
    const qualifications: any = await axios.get(
      `/api/${this.context.instance.id}/workload/qualifications`,
    )
    const qs = qualifications.data.resources.map((q) => ({
      id: q.id,
      label: q.label,
    }))
    const categories = await axios.get(
      `/api/${this.context.instance.id}/workload/qualificationCategories`,
    )
    const cs = categories.data.resources.map((c) => ({
      id: c.id,
      label: c.label,
    }))
    runInAction(() => {
      this.qualifications = qs
      this.categories = cs
    })
  }

  private loadCertificates = async () => {
    const response: any = await axios.get(
      `/api/${this.context.instance.id}/dropdownEntries?type=personnelRecordCertificates.certificateId`,
    )
    const certificates = response.data.resources.map((c) => ({
      id: c.id,
      label: c.label,
    }))
    runInAction(() => (this.certificates = certificates))
  }

  private getOptions = (section: string) => {
    switch (section) {
      case 'Nachweise':
        return this.certificates
      case 'Qualifikationen':
        return this.qualifications
      case 'Qualifikationskategorien':
        return this.categories
      default:
        return []
    }
  }

  private onDelete = (e) => {
    e.preventDefault()
    this.props.onDelete(this.props.column)
  }

  @action private onSelectSection = () => {
    if (!this.inputBlocks || !this.props.column.section) {
      return
    }
    const employeeFileSection = getCurrentEmployeeReportSections().filter(
      (s) => s.label === this.props.column.section,
    )
    if (sections.length === 0) {
      this.props.column.aggregate = null
      this.fields = [{ value: '', label: 'Wählen...' }]
      return
    }
    const inputBlock = this.inputBlocks.find((s) => s.id === employeeFileSection[0].id)
    if (!inputBlock?.config.fields && !employeeFileSection[0].mandatoryFields) {
      this.props.column.aggregate = null
      this.fields = [{ value: '', label: 'Wählen...' }]
    } else {
      this.props.column.aggregate = employeeFileSection[0].multiple
        ? this.props.column.aggregate || 'comma'
        : null
      const inputBlockFields = mergeFieldsAndMandatoryFields(
        inputBlock?.config.fields || [],
        employeeFileSection[0]?.mandatoryFields || [],
      )

      this.fields = inputBlockFields.map((field) => ({
        value: field.key,
        label: field.label ?? field.key,
      }))
      this.fields.push({ value: '', label: 'Wählen...' })
      if (
        this.fields.filter((f) => f.value === this.props.column.aggregateValue).length ===
          0 &&
        this.props.column.aggregateValue !== 'x' &&
        this.props.column.aggregateValue === ''
      ) {
        this.props.column.aggregateValue = null
      }
      if (this.fields.filter((f) => f.value === this.props.column.column).length === 0) {
        this.props.column.column = '' // Reset column if field is not supported for current section
      }
    }
  }

  render() {
    return (
      <>
        <div className={styles.row}>
          <div className={styles.handle}>
            <FieldSortableHandle />
          </div>
          <div style={{ flex: '0 0 150px' }}>
            <InputSelect
              model={this.model}
              name='section'
              label='Abschnitt'
              options={sections}
              onChange={this.onSelectSection}
            />
          </div>
          <div style={{ flex: '0 0 1rem' }} />
          <div className='flex-auto'>
            <InputSelect
              model={this.model}
              name='column'
              label='Feld'
              options={this.fields}
              disabled={this.fields.length === 1}
            />
          </div>
          {this.props.column.aggregate && <div style={{ flex: '0 0 1rem' }} />}
          {this.props.column.aggregate && (
            <div
              style={{
                flex: `0 0 ${
                  this.props.column.aggregate === 'columns' ||
                  this.props.column.aggregate === 'selected'
                    ? 150
                    : 316
                }px`,
              }}
            >
              <InputSelect
                model={this.model}
                name='aggregate'
                label='Bei mehreren Einträgen'
                options={aggregationSelector[this.props.column.section] || aggregations}
              />
            </div>
          )}
          {(this.props.column.aggregate === 'columns' ||
            this.props.column.aggregate === 'selected') && (
            <div style={{ flex: '0 0 1rem' }} />
          )}
          {(this.props.column.aggregate === 'columns' ||
            this.props.column.aggregate === 'selected') && (
            <div style={{ flex: '0 0 150px' }}>
              <InputSelect
                model={this.model}
                name='aggregateValue'
                label='Zellwert'
                options={[...this.fields, { value: 'x', label: 'x' }]}
                disabled={this.fields.length === 1}
              />
            </div>
          )}
          <div style={{ flex: '0 0 0', position: 'relative' }}>
            <div className={styles.onHover}>
              <RoundIcon
                onClick={this.onDelete}
                icon='fa fa-trash'
                style={{ position: 'absolute', right: -14, top: -14 }}
              />
            </div>
          </div>
        </div>
        {this.props.column.aggregate && this.props.column.aggregate === 'selected' && (
          <div className={styles.row}>
            {this.certificates.length > 0 && this.qualifications.length > 0 && (
              <div style={{ marginLeft: 27, width: '100%' }}>
                <InputMultiSelect
                  model={this.model}
                  name='aggregateValue'
                  label='Ausgewählte Einträge'
                  options={this.getOptions(this.props.column.section)}
                  disabled={this.fields.length === 1}
                />
              </div>
            )}
          </div>
        )}
      </>
    )
  }
}
