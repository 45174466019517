import { z } from 'zod'
import { isDecimalString } from '../../general/helpers/isDecimalString'
import { isIntegerString } from '../../general/helpers/isIntegerString'
import { isISODate, isoDateErrorMessage } from '../../general/helpers/isISODate'
import {
  isTrimmedString,
  trimmedStringErrorMessage,
} from '../../general/helpers/isTrimmedString'
import { isYmdDate, ymdDateErrorMessage } from '../../general/helpers/isYmdDate'

export const ResidentValidator = z.object({
  id: z.string(),
  instanceId: z.string().min(1).max(255),
  familyId: z.string().min(1).max(255),
  imageId: z.union([z.string().min(1).max(255), z.null()]),
  createdAt: z.string().refine(isISODate, isoDateErrorMessage),
  deletedAt: z.union([z.string().refine(isISODate, isoDateErrorMessage), z.null()]),
  firstName: z
    .string()
    .min(1)
    .max(255)
    .refine(isTrimmedString, trimmedStringErrorMessage),
  lastName: z.string().min(1).max(255).refine(isTrimmedString, trimmedStringErrorMessage),
  aliasName: z.string().max(255),
  oldNames: z.array(
    z.object({
      firstName: z.string().min(1).max(255),
      lastName: z.string().min(1).max(255),
      aliasName: z.string().max(255),
    }),
  ),
  dateOfBirth: z.union([z.string().refine(isYmdDate, ymdDateErrorMessage), z.null()]),
  oldDatesOfBirth: z.array(z.string().refine(isYmdDate, ymdDateErrorMessage)),
  mId: z.union([
    z.string().refine((val) => isDecimalString(val, 10, 0, '1'), {
      message: 'Not a valid decimal string with precision 10 and scale 0',
    }),
    z.null(),
  ]),
  nationalityId: z.union([z.string().min(1).max(255), z.null()]),
  countryOfBirthId: z.union([z.string().min(1).max(255), z.null()]),
  category: z.union([z.enum(['child', 'umf', 'adult']), z.null()]),
  nativeLanguage: z.string().max(255),
  languageIds: z.string(),
  cityOfBirth: z.string().max(255),
  religion: z.string().max(255),
  sex: z.union([z.enum(['male', 'female', 'divers']), z.null()]),
  title: z.string().max(255),
  familyRelation: z.string().max(255), // Haushaltsvorstand, Verhältnis zum HV
  roleInFamily: z.string().max(255), // Mutter, Vater, etc.
  kaId: z.string().max(10),
  wimNr: z
    .string()
    .refine((val) => isDecimalString(val, 10, 0, '1'))
    .nullable(),
  bvNr: z
    .string()
    .refine((val) => isDecimalString(val, 8, 0, '1'))
    .nullable(), // Not unique. Multiple persons can have same vb nr.
  tId: z
    .string()
    .refine((val) => isDecimalString(val, 8, 0, '1'))
    .nullable(),
  pickUpLocation: z.string().max(255),
  legalAufenthaltsstatus: z.string().max(255),
  residentStatus: z.string().max(255),
  legalAufenthaltsstatusSeit: z.union([
    z.string().refine(isYmdDate, ymdDateErrorMessage),
    z.null(),
  ]),
  legalAufenthaltsstatusComment: z.string(),
  maritalStatus: z.string().max(255),
  comment: z.string(),
  hasParentData: z.boolean(),
  parentData: z.string(),
  parentDataDate: z.union([z.string().refine(isYmdDate, ymdDateErrorMessage), z.null()]),
  guardianName: z.string().max(255),
  guardianAgency: z.string().max(255),
  guardianPhone: z.string().max(255),
  guardianFax: z.string().max(255),
  guardianEmail: z.string().max(255),
  rguDate: z.union([z.string().refine(isYmdDate, ymdDateErrorMessage), z.null()]),
  rguResult: z.string().max(255),
  gvhCheckDate: z.union([z.string().refine(isYmdDate, ymdDateErrorMessage), z.null()]),
  gvhCheckResult: z.string().max(255),
  alterseinschaetzungDate: z.union([
    z.string().refine(isYmdDate, ymdDateErrorMessage),
    z.null(),
  ]),
  alterseinschaetzungResult: z.string().max(255),
  alterseinschaetzungNewDateOfBirth: z.union([
    z.string().refine(isYmdDate, ymdDateErrorMessage),
    z.null(),
  ]),
  alterseinschaetzungIsLawsuit: z.boolean(),
  alterseinschaetzungLawsuitResult: z.union([
    z.enum(['ongoing', 'successful', 'unsuccessful']),
    z.null(),
  ]),
  inobhutnahmeState: z.union([
    z.enum([
      'bundesweite-verteilung',
      'verlegungshindernis-pruefung',
      'verlegungshindernis',
      'uebernahme-nach-fristablauf',
      'zuweisung-nach-koenigssteiner',
    ]),
    z.null(),
  ]),
  inobhutnahmeBeginDate: z.union([
    z.string().refine(isYmdDate, ymdDateErrorMessage),
    z.null(),
  ]),
  inobhutnahmeEndDate: z.union([
    z.string().refine(isYmdDate, ymdDateErrorMessage),
    z.null(),
  ]),
  p41State: z.union([z.enum(['yes', 'no']), z.null()]),
  p41Comment: z.string().max(255),
  p41Date: z.union([z.string().refine(isYmdDate, ymdDateErrorMessage), z.null()]),
  p41ProposalDate: z.union([z.string().refine(isYmdDate, ymdDateErrorMessage), z.null()]),
  p41ApprovalState: z.union([z.enum(['approved', 'rejected']), z.null()]),
  p41ApprovalDate: z.union([z.string().refine(isYmdDate, ymdDateErrorMessage), z.null()]),
  p41ApprovalUserName: z.string().max(255),
  hasAsylumRequest: z.boolean(),
  asylumRequestDate: z.union([
    z.string().refine(isYmdDate, ymdDateErrorMessage),
    z.null(),
  ]),
  asylumApplicationSubmissionDate: z.union([
    z.string().refine(isYmdDate, ymdDateErrorMessage),
    z.null(),
  ]),
  hasClothingAllowance: z.boolean(),
  clothingAllowanceDate: z.union([
    z.string().refine(isYmdDate, ymdDateErrorMessage),
    z.null(),
  ]),
  clothingAllowanceAmount: z.union([
    z.string().refine((val) => isDecimalString(val, 8, 2), {
      // Can be negative!
      message: 'Not a valid decimal string with precision 8 and scale 2',
    }),
    z.null(),
  ]),
  clearingReportDate: z.union([
    z.string().refine(isYmdDate, ymdDateErrorMessage),
    z.null(),
  ]),
  clearingReport: z.string(),
  federalTransferCharacteristics: z.string(),
  creationUserId: z.union([z.string().min(1).max(255), z.null()]),
  kindeswohlgefaehrdungCheckbox: z.boolean(),
  kindeswohlgefaehrdungText: z.string(),
  gesundheitszustandCheckbox: z.boolean(),
  verwandschaftszusammenfuehrungCheckbox: z.boolean(),
  verwandschaftszusammenfuehrungText: z.string(),
  gemeinsameInobhutnahmeCheckbox: z.boolean(),
  allgemeinerAnmerkungstext: z.string(),
  awumZuweisungsdatum: z.union([
    z.string().refine(isYmdDate, ymdDateErrorMessage),
    z.null(),
  ]),
  awumAsylbLGLeistungsanspruch: z.string().max(255),
  awumAsylbLGLeistungsanspruchSeit: z.union([
    z.string().refine(isYmdDate, ymdDateErrorMessage),
    z.null(),
  ]),
  awumOrdnungsmerkmal: z.string().max(255),
  ethnicity: z.string().max(255),
  leaBamfId: z.union([
    z.string().refine((val) => isDecimalString(val, 7, 0, '1'), {
      message: 'Not a valid decimal string with precision 7 and scale 0',
    }),
    z.null(),
  ]),
  leaGnaCheckbox: z.boolean(),
  leaGnaText: z.string().max(255),
  leaSchuleCheckbox: z.boolean(),
  leaSchuleText: z.string().max(255),
  leaSpecialNeedsCheckbox: z.boolean(),
  leaSpecialNeedsText: z.string().max(255),
  leaRamadan: z.boolean(),
  leaBarcodeScanAlarmText: z.string().max(255),
  migVisId: z.union([
    z.string().refine((val) => isDecimalString(val, 10, 0, '1'), {
      message: 'Not a valid decimal string with precision 10 and scale 0',
    }),
    z.null(),
  ]),
  leaInactive: z.boolean(),
  leaInactiveReason: z.string().max(255),
  kommFuBelegungshinweise: z.string(),

  lfgUnterbringungsgrund: z.union([
    z.enum(['Asylbewerber', 'Obdachlose', 'Ausländische Obdachlose', 'Spätaussiedler']),
    z.null(),
  ]),
  lfgAufenthaltsstatus: z.union([
    z.enum([
      'Aufenthaltsgestattung',
      'Aufenthaltserlaubnis',
      'Duldung',
      'Fiktionsbescheinigung',
      'Freizügigkeit EWR und Gleichgestellte',
      'Grenzübertrittsbescheinigung',
      'Keine Angaben',
      'Niederlassungserlaubnis',
      'Sonstiges',
      'Spätaussiedler',
      'Trifft nicht zu',
      'Visum',
    ]),
    z.null(),
  ]),
  lfgAufenthaltsstatusValidTill: z.string().refine(isYmdDate).nullable(),
  lfgSchutzbeduerftig: z.string(),
  lfgDetailSchutzbedarf: z.string(),
  lfgSozialarbeiter: z.union([z.string().min(1).max(255), z.null()]),
  lfgInitialerCheckIn: z.union([z.string().refine(isYmdDate), z.null()]),
  vulnerableGruppen: z.string(),
  vySoftId: z.string().max(255),
  email: z.union([z.string().max(0), z.string().email()]),
  phone: z.string().max(255),
  contactBevorzugterKanal: z.string().max(255),
  briefkasten: z.string().max(5).refine(isIntegerString).nullable(),
  abschiebeschutzberechtigt: z.boolean(),
  isChild: z.boolean(),
  isAdult: z.boolean(),
  kriegsfluchtUkr: z.boolean(),
  personalCharacteristicsIsSchwangerschaft: z.boolean(),
  personalCharacteristicsIsEinzelzimmer: z.boolean(),
  personalCharacteristicsIsRollstuhl: z.boolean(),
  personalCharacteristicsIsBarrierefreiheit: z.boolean(),
  jugendamtId: z.union([z.string(), z.null()]),
  azrNr: z
    .string()
    .refine((val) => isDecimalString(val, 12, 0, '1'))
    .nullable(),
  hasSchonkost: z.boolean(),
  foodMustBeBroughtToRoom: z.boolean(),
  lunchPackage: z.string().max(255),
  employeeId: z.union([z.string(), z.null()]),
})
