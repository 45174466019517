import { Model } from 'components/Form/Model'
import { InputDate } from 'components/Form/components/InputDate'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { RoundIcon } from 'components/RoundIcon'
import { IShiftParticipantOverride } from 'contracts/workplan/interfaces/IShiftParticipantOverride'
import { ShiftParticipantOverrideValidator } from 'contracts/workplan/validators/ShiftParticipantOverrideValidator'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  override: IShiftParticipantOverride
  onDelete: (id: string) => void
  readOnly?: boolean
}

@observer
export class OverrideRow extends React.Component<Props, {}> {
  private model: Model<IShiftParticipantOverride>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.override, ShiftParticipantOverrideValidator)
  }

  private onDelete = () => this.props.onDelete(this.props.override.id)

  render() {
    const { readOnly } = this.props
    return (
      <div className='flex items-center mb-3'>
        <div className='mr-3' style={{ flex: '0 0 250px' }}>
          <InputDate
            model={this.model}
            name='date'
            label='Datum'
            readOnly={readOnly}
            disabled={readOnly}
          />
        </div>
        <div className='flex-auto'>
          <InputDecimal
            maxLength={3}
            model={this.model}
            name='participants'
            label='Teilnehmerzahl'
            precision={3}
            scale={0}
            readOnly={readOnly}
            disabled={readOnly}
          />
        </div>
        {!readOnly && (
          <div className='flex-content ml-2'>
            <RoundIcon icon='fa fa-trash' color='danger' onClick={this.onDelete} />
          </div>
        )}
      </div>
    )
  }
}
