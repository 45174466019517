import { Button } from 'components/Form/components/Button'
import { InputMonth } from 'components/Form/components/InputMonth'
import * as React from 'react'
import { Model } from 'components/Form/Model'
import { IShiftFilter } from 'contracts/workplan/interfaces/IShiftFilter'
import { InputBuilding } from 'components/Form/components/InputBuilding'
import { ButtonDropdown } from 'components/ButtonDropdown'
import { makeObservable, observable, runInAction } from 'mobx'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { AppContext } from 'services/connection/models/AppContext'
import { dayjs } from 'helpers/dayjs'
import { observer } from 'mobx-react'
import { box } from 'services/box'
import { RechnungsanhangAwlDialog } from './RechnungsanhangAwlDialog'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { getEmptyShiftSearchResult } from 'contracts/workplan/helpers/getEmptyShiftSearchResult'
import { RenderShiftEditDialog } from './ShiftDialog/components/ShiftDialogOverviewTab'

const downloadOptions: InputSelectOption[] = [
  { value: 'journalFinance', label: 'Journal Finance' },
  { value: 'stundenzettelByBuilding', label: 'Zuschläge & Stunden' },
  { value: 'invoiceStundenzettelReport', label: 'Rechnungsanhang DSP' },
  { value: 'rechnungsanhangAwl', label: 'Rechnungsanhang AWL' },
  { value: 'invoiceStundenzettelKumuliertReport', label: 'Rechnungsanhang RPT' },
]

interface Props {
  filterModel: Model<IShiftFilter>
  navigate: (path: string) => void
}

@observer
export class ShiftFilter extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private downloading = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private navigateToEmployees = () => {
    this.props.navigate(`/employees`)
  }

  private onDownload = async (option: InputSelectOption) => {
    switch (option.value) {
      case 'journalFinance':
        await this.createJournalFinance()
        break
      case 'stundenzettelByBuilding':
        await this.createStundenzettelByBuilding()
        break
      case 'invoiceStundenzettelReport':
        await this.createInvoiceStundenzettelReport()
        break
      case 'rechnungsanhangAwl':
        this.openDialog()
        break
      case 'invoiceStundenzettelKumuliertReport':
        await this.createInvoiceStundenzettelKumuliertReport()
        break
    }
  }

  private openDialog = async () => {
    const promise = box.custom(
      <RechnungsanhangAwlDialog
        buildingId={this.props.filterModel.values.buildingId!}
        month={this.props.filterModel.values.month!}
        onClose={() => promise.close()}
      />,
      { context: this.context, closable: true, size: 'lg' },
    )
  }

  private createStundenzettelByBuilding = async () => {
    if (this.downloading) {
      return
    }
    if (!this.props.filterModel.values.buildingId) {
      alert(
        'Bitte wählen Sie ein Gebäude aus, für das der Stundenzettel erstellt werden soll.',
      )
      return
    }

    runInAction(() => (this.downloading = true))
    await createAndDownloadReport('stundenzettelByBuilding', this.context.instance.id, {
      month: this.props.filterModel.values.month,
      buildingId: this.props.filterModel.values.buildingId,
    })
    runInAction(() => (this.downloading = false))
  }

  private createJournalFinance = async () => {
    if (this.downloading) {
      return
    }
    if (this.props.filterModel.values.buildingId) {
      alert(
        'Hinweis: Da Sie ein Gebäude ausgewählt haben, enthält das Journal Finance nur dieses Gebäude. Falls Sie eine Auswertung über alle Gebäude erstellen möchten, können Sie den Punkt "Alle Gebäude" auswählen und den Vorgang wiederholen.',
      )
    }
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport(
      'journal-finance',
      this.context.instance.id,
      {
        month: this.props.filterModel.values.month,
        buildingId: this.props.filterModel.values.buildingId
          ? this.props.filterModel.values.buildingId
          : null,
      },
      'Journal Finance.xlsx',
    )
    runInAction(() => (this.downloading = false))
  }

  private createInvoiceStundenzettelReport = async () => {
    if (this.downloading) {
      return
    }
    if (!this.props.filterModel.values.buildingId) {
      alert(
        'Bitte wählen Sie ein Gebäude aus, für das der Anhang zur Rechnungsstellung erstellt werden soll.',
      )
      return
    }
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport('invoiceStundenzettel', this.context.instance.id, {
      month: this.props.filterModel.values.month,
      buildingId: this.props.filterModel.values.buildingId,
    })
    runInAction(() => (this.downloading = false))
  }

  private createInvoiceStundenzettelKumuliertReport = async () => {
    if (this.downloading) {
      return
    }
    if (!this.props.filterModel.values.buildingId) {
      alert(
        'Bitte wählen Sie ein Gebäude aus, für das der Anhang zur kumulierten Rechnungsstellung erstellt werden soll.',
      )
      return
    }
    const response: string =
      prompt(
        'Bis zu welchem Datum sollen die Arbeitsstunden berücksichtigt werden?',
        dayjs().format('DD.MM.YYYY'),
      ) || ''
    const till = dayjs(response, ['D.M.YYYY', 'D.M.YY'])
    if (!till.isValid()) {
      alert(
        'Das angegebene Datum ist nicht gültig. Bitte geben Sie das Datum im Format TT.MM.JJJJ an.',
      )
      return
    }
    const res: string =
      prompt('Bitte geben Sie die Belegungsstufe an (1, 2, 3 oder 4)', '2') || ''
    const allowedBelegungsstufen = ['1', '2', '3', '4']
    if (!allowedBelegungsstufen.includes(res)) {
      alert(
        'Die angegebene Belegungsstufe ist nicht gültig. Bitte geben Sie 1, 2, 3 oder 4 an.',
      )
      return
    }
    runInAction(() => (this.downloading = true))
    await createAndDownloadReport(
      'invoiceStundenzettelKumuliert',
      this.context.instance.id,
      {
        till: till.format('YYYY-MM-DD'),
        buildingId: this.props.filterModel.values.buildingId,
        belegungsstufe: res,
      },
    )
    runInAction(() => (this.downloading = false))
  }

  private createNewShift = () => {
    const shift: IShiftSearchResult = getEmptyShiftSearchResult()
    const promise = box.custom(
      <RenderShiftEditDialog
        shift={shift}
        filterModel={this.props.filterModel}
        onClose={() => promise.close()}
      />,
      { closable: true, context: this.context, size: 'md' },
    )
  }

  private onChange = () => this.props.navigate('/shifts')

  render() {
    return (
      <div className='relative flex-content grid grid-cols-1 sm:grid-cols-[1fr_206px] md:grid-cols-[206px_206px_1fr_1fr] xl:grid-cols-[206px_206px_206px_2fr_298px] gap-4 p-6 bg-white shadow border-b border-gray-300 z-2'>
        <div className='whitespace-nowrap flex'>
          <Button
            className='flex-auto'
            color='primary'
            style={{ borderRadius: '6px 0 0 6px' }}
          >
            Schichten
          </Button>
          <Button
            className='flex-auto'
            color='secondary'
            outline
            style={{ borderRadius: '0 6px 6px 0' }}
            onClick={this.navigateToEmployees}
          >
            Mitarbeiter
          </Button>
        </div>

        <InputMonth model={this.props.filterModel} name='month' />
        <InputBuilding
          model={this.props.filterModel}
          name='buildingId'
          className='col-span-1 sm:col-span-1 md:col-span-1'
          includeCompound
          onChange={this.onChange}
        />

        <div className='hidden xl:block' />

        <div className='flex w-full justify-end gap-4'>
          {this.context.permissions.workplan_shifts > 0 && (
            <Button className='md:flex-content flex-auto' onClick={this.createNewShift}>
              Neue Schicht
            </Button>
          )}
          <ButtonDropdown
            className='w-full flex-grow text-center float-right'
            options={downloadOptions}
            onSelect={this.onDownload}
            outline
            color='secondary'
          >
            {this.downloading ? (
              <span>
                <i className='fa fa-spinner fa-spin' />
              </span>
            ) : (
              <span>
                <i className='fa fa-download' />
              </span>
            )}
          </ButtonDropdown>
        </div>
      </div>
    )
  }
}
