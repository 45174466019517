import * as React from 'react'
import { ViewDropdown } from './ViewDropdown'
import { IMonthlyEmployeeWorkScheduleFilter } from 'contracts/workplan/interfaces/IMonthlyEmployeeWorkScheduleFilter'
import { Model } from 'components/Form/Model'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Collection, hermes, Resource } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { InputMonth } from 'components/Form/components/InputMonth'
import { IView } from 'contracts/workplan/interfaces/IView'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import { box } from 'services/box'
import { EditViewDialog } from './EditViewDialog'
import cloneDeep from 'lodash/cloneDeep'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import { SelectShiftShortlist } from './SelectShiftShortlist'

interface Props {
  isShiftView: boolean
  model: Model<IMonthlyEmployeeWorkScheduleFilter>
  selectShiftModel: Model<{
    selectedBuilding: IBuilding | null
    selectedShift: IShift | null
    compoundId: string
    view: 'select' | 'shortlist'
  }>
  navigate: (url: string) => void
}

@observer
export class WorkplanTopBar extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly views: Collection<IView>
  private disposers: Disposer[] = []
  @observable private compounds: ICompound[] | null = null
  @observable private buildings: IBuilding[] | null = null
  @observable private shifts: IShift[] | null = null

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.views = new Collection<IView>(
      `/api/${context.instance.id}/workplan/views/${this.props.model.values.month}/views`,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.views.init({ readOnly: true }))
    if (this.props.isShiftView) {
      this.initShiftView()
    }
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private initShiftView = async () => {
    const compounts = await hermes.indexOnceNew<ICompound>(
      `/api/${this.context.instance.id}/accommodations/compounds`,
    )
    const buildings = await hermes.indexOnceNew<IBuilding>(
      `/api/${this.context.instance.id}/accommodations/buildings`,
    )
    runInAction(() => {
      this.compounds = compounts
      this.buildings = buildings
    })
    this.loadShifts()
  }

  private loadShifts = async () => {
    const shifts = await hermes.indexOnceNew<IShift>(
      `/api/${this.context.instance.id}/workplan/shifts?month=${this.props.model.values.month}`,
    )
    runInAction(() => {
      this.shifts = shifts
    })
  }

  private getViewOptions = () => {
    if (!this.views.resources) {
      return []
    }
    const options: InputSelectOption[] = []
    for (const view of this.views.resources) {
      if (!view.data) {
        continue
      }
      options.push({
        label: view.data.label,
        value: view.data.id,
      })
    }
    return options
  }

  @action
  private editView = (id: string) => {
    const view = this.views.resources?.find((v: any) => v.id === id)
    if (!view) {
      return
    }
    const copy = cloneDeep(view.data)
    const promise = box.custom(
      <EditViewDialog
        month={this.props.model.values.month}
        view={copy}
        employeePoolId={this.props.model.values.employeePoolId}
        onClose={(id?: string) => promise.close(id)}
      />,
      { context: this.context, closable: true },
    )
    this.props.model.values.viewId = id
  }

  private downloadWorkplan = async () => {
    try {
      if (this.props.isShiftView) {
        createAndDownloadReport(
          'shift',
          this.context.instance.id,
          {
            shiftId: this.props.model.values.shiftId,
            month: this.props.model.values.month,
          },
          'Arbeitsplan.xlsx',
        )
        return
      } else {
        createAndDownloadReport(
          'viewShift',
          this.context.instance.id,
          {
            viewId: this.props.model.values.viewId,
            month: this.props.model.values.month,
          },
          'Komfortplan.xlsx',
        )
      }
    } catch (_e) {
      toast.error('Beim Download des Arbeitsplans ist ein Fehler aufgetreten.')
    }
  }

  @action
  private setTabPlan = () => {
    this.props.model.values.type = 'plan'
    this.props.navigate('shift/plan')
  }

  @action
  private setTabReal = () => {
    this.props.model.values.type = 'real'
    this.props.navigate('shift/real')
  }

  private getViewBuildingOptions = () => {
    const view = this.views.resources?.find(
      (v: Resource<IView>) => v.data?.id === this.props.model.values.viewId,
    )
    const options: InputSelectOption[] = [{ label: 'Bitte wählen...', value: '' }]
    if (!view || !view.data) {
      return options
    }
    for (const building of view.data.buildings) {
      options.push({
        label: building.label,
        value: building.id,
      })
    }
    return options
  }

  render() {
    const view = this.views.resources?.find(
      (v: Resource<IView>) => v.data?.id === this.props.model.values.viewId,
    )
    return (
      <div className='p-4 flex gap-4 w-full items-center'>
        {!this.props.isShiftView && (
          <div className='flex items-center'>
            <ViewDropdown
              className='min-w-[200px]'
              key={this.props.model.values.viewId}
              model={this.props.model}
              options={this.getViewOptions()}
              views={this.views}
            />
            {this.props.model.values.viewId && (
              <Button
                style={{ borderRadius: '0 0.375rem 0.375rem 0' }}
                onClick={() => this.editView(this.props.model.values.viewId)}
              >
                <span>
                  <i className='fa fa-pen' />
                </span>
              </Button>
            )}
            {view && view.data && view.data.buildingIds && (
              <InputSelect
                className='min-w-60 ml-4'
                model={this.props.model}
                name='activeBuilding'
                label='Beplantes Gebäude'
                options={this.getViewBuildingOptions()}
              />
            )}
          </div>
        )}
        {this.props.isShiftView && this.compounds && this.buildings && this.shifts && (
          <SelectShiftShortlist
            model={this.props.model}
            selectShiftModel={this.props.selectShiftModel}
            compounds={this.compounds}
            buildings={this.buildings}
            shifts={this.shifts}
            loadShifts={this.loadShifts}
          />
        )}
        <div className='ml-auto flex gap-4'>
          {this.props.isShiftView && (
            <div className='flex-content'>
              <Button
                className='border-r border-r-transparent flex-auto'
                style={{
                  borderRadius: '6px 0 0 6px',
                  marginRight: '-1px',
                  padding: '8px 12px',
                }}
                color='primary'
                outline={this.props.model.values.type === 'plan' ? false : true}
                onClick={this.setTabPlan}
              >
                Planung
              </Button>
              <Button
                className='flex-auto'
                color='primary'
                style={{
                  borderRadius: '0 6px 6px 0',
                  marginLeft: '-1px',
                  padding: '8px 12px',
                }}
                outline={this.props.model.values.type === 'real' ? false : true}
                onClick={this.setTabReal}
              >
                Realität
              </Button>
            </div>
          )}
          <InputMonth model={this.props.model} name='month' />
          {this.props.model.values.viewId && this.props.model.values.employeePoolId && (
            <Button color='secondary' outline onClick={this.downloadWorkplan}>
              <span>
                <i className='fa fa-download' />
              </span>
            </Button>
          )}
        </div>
      </div>
    )
  }
}
