import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { IShortcut } from '../components/ShortcutRow'
import { uniqueId } from 'helpers/uniqueId'
import { parseTimeInterval } from 'contracts/workplan/helpers/parseTimeInterval'

export function getShortcutsStructure(shift: IShiftSearchResult): IShortcut[] {
  const shortcuts: IShortcut[] = []
  for (const shortcut of Object.keys(shift.shortcuts)) {
    const times = parseTimeInterval(shift.shortcuts[shortcut].timeInterval)
    if (!times) {
      continue
    }
    shortcuts.push({
      key: uniqueId('s'),
      label: shortcut,
      begin: times.begin,
      end: times.end,
      hasBreak: shift.shortcuts[shortcut].hasBreak,
      breakIntervals: shift.shortcuts[shortcut].breakIntervals.split(',').join(', '),
    })
  }
  shortcuts.sort((a, b) => (a.label === 'x' ? -1 : 1))
  return shortcuts
}
