import { observer } from 'mobx-react'
import * as React from 'react'
import { colClasses } from '..'
import { arrayMoveImmutable } from 'array-move'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { Model } from 'components/Form/Model'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { EditField } from './EditField'
import { Button } from '../../Button'
import { uniqueId } from 'helpers/uniqueId'
import { IInputBlockField } from 'contracts/inputBlock/interfaces/IInputBlock'

interface Props {
  sectionModel: Model<any>
  mandatoryFields?: IInputBlockField[]
}

@observer
export class SortableInputGrid extends React.Component<Props, {}> {
  @observable private editingFieldKey: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action onSortEnd = ({ oldIndex, newIndex }) => {
    const newArray = arrayMoveImmutable(
      this.props.sectionModel.values.fields,
      oldIndex,
      newIndex,
    )
    runInAction(() => (this.props.sectionModel.values.fields = newArray))
  }

  @action private deleteField = (key: string) => {
    this.props.sectionModel.values.fields = this.props.sectionModel.values.fields.filter(
      (f) => f.key !== key,
    )
  }

  @action handleEditFieldClick = (field: IInputBlockField, e: MouseEvent) => {
    e.stopPropagation()
    this.editingFieldKey = field.key
  }

  @action handleOutsideClick = () => {
    this.editingFieldKey = null
  }

  @action private addInputField = () => {
    this.props.sectionModel.values.fields.push({
      key: uniqueId('unique_'),
      label: 'Neu',
      type: 'text',
      colspan: 1,
      options: [''],
      allowEmpty: true,
    })
  }

  render() {
    return (
      <SortableGrid
        key={this.props.sectionModel.values.fields.length}
        model={this.props.sectionModel}
        items={this.props.sectionModel.values.fields}
        mandatoryFields={this.props.mandatoryFields}
        onSortEnd={this.onSortEnd}
        axis='xy'
        distance={5}
        addInputField={this.addInputField}
        deleteField={this.deleteField}
        editingFieldKey={this.editingFieldKey}
        handleEditFieldClick={this.handleEditFieldClick}
        handleOutsideClick={this.handleOutsideClick}
      />
    )
  }
}

const SortableGrid = observer(
  SortableContainer(
    ({
      model,
      items,
      mandatoryFields,
      addInputField,
      deleteField,
      editingFieldKey,
      handleEditFieldClick,
      handleOutsideClick,
    }) => {
      return (
        <div className={`my-4 grid ${colClasses[model.values.cols]} gap-4`}>
          {items.map((item, index) => (
            <SortableItem
              key={item.key}
              index={index}
              item={item}
              model={model}
              mandatoryFields={mandatoryFields}
              deleteField={deleteField}
              editingFieldKey={editingFieldKey}
              handleEditFieldClick={handleEditFieldClick}
              handleOutsideClick={handleOutsideClick}
            />
          ))}
          <Button onClick={addInputField}>
            <span>
              <i className='fa fa-plus' />
            </span>
          </Button>
        </div>
      )
    },
  ),
)

const SortableItem = observer(
  SortableElement(
    ({
      item,
      model,
      mandatoryFields,
      deleteField,
      editingFieldKey,
      handleEditFieldClick,
      handleOutsideClick,
    }) => {
      return (
        <EditField
          field={item}
          cols={model.values.cols}
          mandatoryFields={mandatoryFields}
          onDelete={deleteField}
          isEditing={editingFieldKey === item.key}
          onEditClick={handleEditFieldClick}
          onOutsideClick={handleOutsideClick}
        />
      )
    },
  ),
)
