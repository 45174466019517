import { Collection, hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { InputShift } from 'components/Form/components/InputShift'
import { Model } from 'components/Form/Model'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { IMonthlyEmployeeWorkScheduleFilter } from 'contracts/workplan/interfaces/IMonthlyEmployeeWorkScheduleFilter'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import { IShortlistEntry } from 'contracts/workplan/interfaces/IShortlistEntry'
import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { getCompoundsWithShifts } from 'modules/Workplan/helpers/getCompoundsWithShifts'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'

interface Props {
  model: Model<IMonthlyEmployeeWorkScheduleFilter>
  selectedModel: Model<{
    selectedBuilding: IBuilding | null
    selectedShift: IShift | null
    compoundId: string
    view: 'select' | 'shortlist'
  }>
  shortlistEntries: Collection<IShortlistEntry>
  compounds: ICompound[]
  buildings: IBuilding[]
  shifts: IShift[]
  toggleDropdown: (e: any) => void
}

@observer
export class SelectShift extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable compoundOptions: InputSelectOption[] = []
  @observable buildingOptions: InputSelectOption[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    reaction(
      () => this.props.compounds,
      () => this.prepareCompoundSelectOptions(this.props, this.context),
      { fireImmediately: true },
    )
    reaction(
      () => this.props.selectedModel.values.compoundId,
      () => this.prepareBuildingSelectOptions(this.props, this.context),
      { fireImmediately: true },
    )
  }

  @action
  private prepareCompoundSelectOptions = (props: Props, context: AppContextProps) => {
    this.compoundOptions =
      context.permissions.workplan_shifts === 0
        ? getCompoundsWithShifts(props.compounds, props.buildings, props.shifts).map(
            (c) => ({ label: c.label, value: c.id }),
          )
        : props.compounds.map((c) => ({ label: c.label, value: c.id }))
    this.compoundOptions.unshift({ label: 'Gelände wählen', value: '' })
  }

  @action
  private prepareBuildingSelectOptions = (props: Props, context: AppContextProps) => {
    if (this.props.selectedModel.values.compoundId) {
      // Detect buildings with shifts
      const buildingHasShifts = new Set<string>() // building ids
      for (const shift of props.shifts) {
        if (!shift.buildingId) {
          continue
        }
        buildingHasShifts.add(shift.buildingId)
      }
      if (context.permissions.workplan_shifts === 0) {
        this.buildingOptions = props.buildings
          .filter(
            (b) =>
              b.compoundId === this.props.selectedModel.values.compoundId &&
              buildingHasShifts.has(b.id),
          )
          .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))
          .map((b) => ({ label: b.label, value: b.id }))
      } else {
        this.buildingOptions = props.buildings
          .filter((b) => b.compoundId === this.props.selectedModel.values.compoundId)
          .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))
          .map((b) => ({ label: b.label, value: b.id }))
      }
      this.buildingOptions.unshift({ label: 'Gebäude wählen', value: '' })
    }
  }

  @action
  private onSelectCompound = () => {
    this.props.selectedModel.values.selectedBuilding = null
    this.props.selectedModel.values.selectedShift = null
    this.props.model.values.shiftId = ''
    this.props.model.values.activeBuilding = ''
  }

  @action
  private onSelectBuilding = () => {
    this.props.selectedModel.values.selectedShift = null

    this.props.selectedModel.values.selectedBuilding =
      this.props.buildings.find((b) => b.id === this.props.model.values.activeBuilding) ||
      null
    this.props.model.values.shiftId = ''
  }

  private onSelectShift = async (e: any) => {
    runInAction(
      () =>
        (this.props.selectedModel.values.selectedShift =
          this.props.shifts.find((s) => s.id === this.props.model.values.shiftId) ||
          null),
    )
    if (this.props.model.values.shiftId) {
      await this.createShortlistEntry(false)
      this.props.toggleDropdown(e)
    }
  }

  private createShortlistEntry = async (isPinned: boolean) => {
    await hermes.create(`/api/${this.context.instance.id}/workplan/shortlistEntries`, {
      shiftId: this.props.model.values.shiftId,
      isPinned,
    })
  }

  @action
  private selectFromShortlist = (e) => {
    e.stopPropagation()
    this.props.selectedModel.values.view = 'shortlist'
  }

  render() {
    return (
      <div>
        <InputSelect
          model={this.props.selectedModel}
          name='compoundId'
          label='Gelände'
          options={this.compoundOptions}
          onChange={this.onSelectCompound}
        />
        {this.props.selectedModel.values.compoundId && this.buildingOptions && (
          <InputSelect
            className='mt-4'
            model={this.props.model}
            name='activeBuilding'
            label='Gebäude'
            options={this.buildingOptions}
            onChange={this.onSelectBuilding}
          />
        )}
        {this.props.selectedModel.values.compoundId &&
          this.props.model.values.activeBuilding && (
            <div className='flex gap-2 mt-4'>
              <InputShift
                key={this.props.model.values.activeBuilding}
                className='flex-auto'
                model={this.props.model}
                name='shiftId'
                label='Schicht'
                onChange={this.onSelectShift}
                filter={{
                  buildingId: this.props.model.values.activeBuilding,
                  month: this.props.model.values.month,
                }}
              />
              {this.props.model.values.shiftId &&
                !this.props.shortlistEntries.resources?.find(
                  (e) =>
                    e.data?.isPinned &&
                    e.data?.shiftId === this.props.model.values.shiftId,
                ) && (
                  <Button
                    className='flex-content'
                    onClick={() => this.createShortlistEntry(true)}
                  >
                    <span>
                      <i className='fa fa-thumbtack' />
                    </span>
                  </Button>
                )}
            </div>
          )}
        <Button className='w-full mt-2' small onClick={this.selectFromShortlist}>
          Kurzwahl
        </Button>
      </div>
    )
  }
}
