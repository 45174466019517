import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { classNames } from 'helpers/classNames'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { HexColorPicker } from 'react-colorful'

interface Props {
  className?: string
  model: Model<any>
  name: string
  onClose?: () => void
}

@observer
export class ColorPicker extends React.Component<Props, {}> {
  @observable color: string = ''

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private onColorChange = (color: string) => {
    this.color = color
  }

  @action
  private saveColor = () => {
    this.props.model.values[this.props.name] = this.color
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    return (
      <div
        className={classNames('bg-white rounded-lg shadow-md z-20', this.props.className)}
      >
        <HexColorPicker
          style={{ width: '100%', borderRadius: '0.375rem' }}
          color={this.color}
          onChange={this.onColorChange}
        />
        <div className='p-6 flex justify-between gap-4'>
          {this.props.onClose && (
            <Button color='secondary' outline onClick={() => this.props.onClose!()}>
              Abbrechen
            </Button>
          )}
          <Button onClick={this.saveColor}>Speichern</Button>
        </div>
      </div>
    )
  }
}
