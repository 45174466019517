import { hermes } from '@byll/hermes'
import { RoundIcon } from 'components/RoundIcon'
import { Tooltip } from 'components/Tooltip'
import { ITimeAccountRow } from 'contracts/workload/interfaces/ITimeAccountRow'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { toast } from 'react-toastify'
import { AppContext } from 'services/connection/models/AppContext'
import { TimeAccountChangeDialog } from './TimeAccountChangeDialog'
import { box } from 'services/box'

interface Props {
  row: ITimeAccountRow
  tab: 'overtime' | 'vacation' | 'sick' | 'parental leave'
  contract: any
  onReload: () => void
}

@observer
export class TimeAccountListRow extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable loading = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private onDelete = async () => {
    if (
      !(await box.alert(
        'Korrektur löschen?',
        'Möchten Sie das Korrektur wirklich unwiderruflich löschen?',
        { confirm: 'Ja', cancel: 'Abbrechen' },
      ))
    ) {
      return
    }
    try {
      runInAction(() => (this.loading = true))
      await hermes.delete(
        `/api/${this.context.instance.id}/employees/${this.props.row.userId}/timeAccounts/${this.props.row.id}`,
      )
      this.props.onReload()
    } catch (_e) {
      runInAction(() => (this.loading = false))
      toast.error('Beim Löschen ist leider ein Fehler aufgetreten.')
    }
  }

  private openTimeAccountChange = async (position: 'before' | 'after') => {
    const promise = box.custom(
      <TimeAccountChangeDialog
        row={this.props.row}
        contract={this.props.contract}
        tab={this.props.tab}
        onReload={this.props.onReload}
        position={position}
        onClose={(result?: boolean) => promise.close(result)}
      />,
      { closable: true, context: this.context },
    )
    const result = await promise
    if (result) {
      this.props.onReload()
    }
  }

  render() {
    const { row, tab } = this.props
    if (row.type === 'change' && tab === 'overtime' && row.hoursOvertime === 0) {
      return null
    }
    if (row.type === 'change' && tab === 'vacation' && row.daysApprovedVacation === 0) {
      return null
    }
    if (row.type === 'change' && tab !== 'vacation' && tab !== 'overtime') {
      return null
    }
    return (
      <tr className='group'>
        <td className='relative py-2 flex items-center'>
          {this.context.permissions.user_timeAccount > 1 &&
            (this.props.tab === 'overtime' || this.props.tab === 'vacation') && (
              <div className='group-hover:block hidden'>
                <span className='absolute -top-4'>
                  <RoundIcon
                    icon='fa fa-plus'
                    onClick={() => this.openTimeAccountChange('before')}
                  />
                </span>
                <span className='absolute -bottom-4'>
                  <RoundIcon
                    icon='fa fa-plus'
                    onClick={() => this.openTimeAccountChange('after')}
                  />
                </span>
                {row.type === 'change' && (
                  <RoundIcon
                    icon={`fa ${this.loading ? 'fa-refresh fa-spin' : 'fa-trash'}`}
                    color='danger'
                    style={{ position: 'absolute', top: 4, left: 582 }}
                    onClick={this.onDelete}
                  />
                )}
              </div>
            )}
          {row.type === 'change' ? 'Korrektur' : row.month}
          {this.props.contract.resignDate &&
            row.month > this.props.contract.resignDate && (
              <span className='h-4 w-4 ml-2 bg-red-500 text-white rounded-full text-center text-xs has-tooltip'>
                <i className='fa fa-info' />
                <Tooltip position='right'>
                  Korrektur liegt nach Vertragsende und ist deshalb ungültig.
                  <br />
                  Bitte löschen und korrekt neu anlegen.
                </Tooltip>
              </span>
            )}
          {row.type === 'change' &&
            !(
              this.props.contract.resignDate && row.month > this.props.contract.resignDate
            ) && (
              <span className='h-4 w-4 ml-2 pl-[1px] bg-indigo-500 text-white rounded-full text-center text-xs has-tooltip'>
                <i className='fa fa-info' />
                <Tooltip>{row.notes}</Tooltip>
              </span>
            )}
        </td>
        {tab === 'overtime' && (
          <td className='p-2'>{`${row.hoursOvertime.toFixed(2).replace('.', ',')} h`}</td>
        )}
        {tab === 'overtime' && (
          <td className='p-2'>{`${row.hoursHoliday.toFixed(2).replace('.', ',')} h`}</td>
        )}
        {tab === 'overtime' && (
          <td className='p-2'>{`${row.hoursOvertimeBalance
            .toFixed(2)
            .replace('.', ',')} h`}</td>
        )}
        {tab === 'vacation' && (
          <td className='p-2'>
            {`${row.daysApprovedVacation} d`}
            {row.daysUnapprovedVacation > 0 && (
              <span className='ml-1 text-red-500'>
                <i
                  className='fa fa-warning'
                  title='Für diesen Monat liegen nicht genehmigte Einträge vor'
                />
              </span>
            )}
          </td>
        )}
        {tab === 'vacation' && (
          <td className='p-2'>
            {`${row.daysApprovedUnpaidVacation} d`}
            {row.daysUnapprovedUnpaidVacation > 0 && (
              <span className='ml-1 text-red-500'>
                <i
                  className='fa fa-warning'
                  title='Für diesen Monat liegen nicht genehmigte Einträge vor'
                />
              </span>
            )}
          </td>
        )}
        {tab === 'vacation' && (
          <td className='p-2'>
            {`${row.daysApprovedSpecialVacation} d`}
            {row.daysUnapprovedSpecialVacation > 0 && (
              <span className='ml-1 text-red-500'>
                <i
                  className='fa fa-warning'
                  title='Für diesen Monat liegen nicht genehmigte Einträge vor'
                />
              </span>
            )}
          </td>
        )}
        {tab === 'vacation' && (
          <td className='p-2'>
            {`${row.daysApprovedAdditionalVacation} d`}
            {row.daysUnapprovedAdditionalVacation > 0 && (
              <span className='ml-1 text-red-500'>
                <i
                  className='fa fa-warning'
                  title='Für diesen Monat liegen nicht genehmigte Einträge vor'
                />
              </span>
            )}
          </td>
        )}
        {tab === 'vacation' && <td className='p-2'>{`${row.daysVacationLeft} d`}</td>}
        {tab === 'sick' && (
          <td className='p-2'>
            {`${row.daysApprovedSick} d`}
            {row.daysUnapprovedSick > 0 && (
              <span className='ml-1 text-red-500'>
                <i
                  className='fa fa-warning'
                  title='Für diesen Monat liegen nicht genehmigte Einträge vor'
                />
              </span>
            )}
          </td>
        )}
        {tab === 'sick' && (
          <td className='p-2'>
            {`${row.daysApprovedChildSick} d`}
            {row.daysUnapprovedChildSick > 0 && (
              <span className='ml-1 text-red-500'>
                <i
                  className='fa fa-warning'
                  title='Für diesen Monat liegen nicht genehmigte Einträge vor'
                />
              </span>
            )}
          </td>
        )}
        {tab === 'sick' && (
          <td className='p-2'>{`${row.daysSickBalance + row.daysChildSickBalance} d`}</td>
        )}
        {tab === 'parental leave' && (
          <td className='p-2'>
            {`${row.daysApprovedParentTime} d`}
            {row.daysUnapprovedParentTime > 0 && (
              <span className='ml-1 text-red-500'>
                <i
                  className='fa fa-warning'
                  title='Für diesen Monat liegen nicht genehmigte Einträge vor'
                />
              </span>
            )}
          </td>
        )}
        {tab === 'parental leave' && (
          <td className='p-2'>{`${row.daysParentTimeBalance} d`}</td>
        )}
      </tr>
    )
  }
}
