import * as React from 'react'
import { Resource, hermes } from '@byll/hermes'
import { IEmployeeFileBlock } from 'contracts/employees/interfaces/IEmployeeFileBlock'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { InputSelect } from 'components/Form/components/InputSelect'
import { Model } from 'components/Form/Model'
import { observer } from 'mobx-react'
import { InputDate } from 'components/Form/components/InputDate'
import { InputText } from 'components/Form/components/InputText'
import { InputCompany } from 'components/Form/components/InputCompany'
import { RoundIcon } from 'components/RoundIcon'
import { action, makeObservable, observable } from 'mobx'
import { InputBlock } from 'components/Form/components/InputBlock'
import styles from './styles.module.scss'
import cloneDeep from 'lodash/cloneDeep'
import { box } from 'services/box'
import { ContractEditDialog } from './components/ContractEditDialog'
import { AppContext } from 'services/connection/models/AppContext'
import { IEmployeeContractTerm } from 'contracts/employees/interfaces/IEmployeeContractTerm'
import * as uuid from 'uuid'
import { toast } from 'react-toastify'
interface Props {
  block: Resource<IEmployeeFileBlock>
  employee: IEmployeeSearchResult
  onDelete: (id: string) => void
}

const typeOptions = [
  '',
  'Ausbildung',
  'Aushilfe',
  'Befristete Festanstellung',
  'Festanstellung',
  'Festanstellung Ergänzung zum Arbeitsvertrag befristet',
  'Festanstellung Vertragsergänzung',
  'Midijob',
  'Praktikum',
  'Teilzeit Festanstellung',
  'Teilzeit Vertragsergänzung',
  'Vertragsergänzung Festanstellung',
  'Werkstudent',
  'Subunternehmer',
  'Subunternehmer HSP',
  'Subunternehmer NOT',
  'Subunternehmer SMA',
  'Subunternehmer SMA + HSP',
]

@observer
export class ContractPreview extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private isEditing: boolean = false
  private readonly model: Model<IEmployeeFileBlock>

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(props.block.data)
  }

  @action private toggleEdit = () => {
    this.isEditing = !this.isEditing
  }

  private openEditDialog = async (id: string) => {
    let contractData: { contract: any; contractTerms: IEmployeeContractTerm[] } | null =
      null
    if (!id.includes('-')) {
      contractData = await hermes.getOnceNew<{
        contract: any
        contractTerms: IEmployeeContractTerm[]
      }>(
        `/api/${this.context.instance.id}/employees/${this.props.employee.id}/contracts/${id}`,
      )
    }
    const copiedBlock = cloneDeep(this.props.block.data)
    const promise = box.custom(
      <ContractEditDialog
        id={id}
        block={copiedBlock}
        contractData={contractData}
        employee={this.props.employee}
        onClose={() => promise.close()}
      />,
      { closable: true, context: this.context, size: 'lg' },
    )
  }

  private addContract = () => {
    const id = uuid.v4()
    this.openEditDialog(id)
  }

  private deleteContract = async (id: string) => {
    if (
      !(await box.alert(
        'Vertrag Löschen',
        'Möchten Sie den Vertrag und die zugehörigen Vertragdetails wirklich unwiderruflich löschen?',
        { confirm: 'Ja', cancel: 'Abbrechen' },
      ))
    ) {
      return
    }
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/employees/${this.props.employee.id}/contracts/${id}`,
      )
    } catch (e: any) {
      toast.error('Beim Löschen des Vertrags ist ein Fehler aufgetreten.')
    }
  }

  render() {
    if (!this.props.block.data) {
      return null
    }
    if (this.isEditing) {
      return (
        <InputBlock
          id='employment-contracts'
          name='details'
          label={this.props.block.data.label}
          model={this.model}
          mandatoryFields={this.props.block.data.mandatoryFields}
          editable='inEditingMode'
          toggleEdit={this.toggleEdit}
        />
      )
    } else {
      return (
        <div className='bg-white shadow-md rounded-md mx-6 p-4'>
          <div className='flex'>
            <div className='flex-auto leading-[30px] text-base text-gray-600 truncate'>
              Arbeitsverträge
            </div>
            <div className='flex-content'>
              <RoundIcon icon='fa fa-plus' onClick={this.addContract} />
              <RoundIcon
                classNameContainer='ml-2'
                icon='fa fa-cog'
                onClick={this.toggleEdit}
              />
            </div>
          </div>
          <div>
            {this.props.block.data.details.map((d, i) => {
              const model = new Model(d)
              return (
                <div
                  className={i % 2 === 1 ? 'bg-gray-200 -mx-4' : 'undefined'}
                  key={d.id}
                >
                  {i !== 0 && i % 2 === 1 && (
                    <div className={`${styles.white} h-[20px] mt-2`} />
                  )}
                  <div
                    className={`grid grid-cols-4 gap-4 py-4 ${
                      i % 2 === 1 ? 'px-4' : ''
                    } group relative`}
                  >
                    <InputSelect
                      label='Vertragsart'
                      className='col-span-2'
                      model={model}
                      name='type'
                      options={typeOptions.map((o) => ({ label: o, value: o }))}
                      readOnly
                      disabled
                    />
                    <InputDate
                      label='Vertragsbeginn'
                      className='col-span-1'
                      model={model}
                      name='joinDate'
                      disabled
                    />
                    <InputDate
                      label='Vertragsende'
                      className='col-span-1'
                      model={model}
                      name='resignDate'
                      disabled
                      placeholder='Unbefristet'
                    />
                    <InputCompany
                      label='Firma'
                      className='col-span-3'
                      model={model}
                      name='companyId'
                      disabled
                    />
                    <InputText
                      label='Personalnummer'
                      className='col-span-1'
                      model={model}
                      name='personnelNumber'
                      disabled
                    />
                    <div
                      className={`absolute top-2 ${
                        i % 2 === 1 ? 'right-2' : '-right-2'
                      } hidden group-hover:block z-1`}
                    >
                      <RoundIcon
                        icon='fa fa-edit'
                        onClick={() => this.openEditDialog(d.id)}
                      />
                      <RoundIcon
                        classNameContainer='ml-2'
                        icon='fa fa-trash'
                        color='danger'
                        onClick={() => this.deleteContract(d.id)}
                      />
                    </div>
                  </div>
                  {i % 2 === 1 && <div className={`${styles.gray} h-[20px] mb-4`} />}
                </div>
              )
            })}
          </div>
        </div>
      )
    }
  }
}
