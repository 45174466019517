import { hermes } from '@byll/hermes'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { InputMonth } from 'components/Form/components/InputMonth'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { InputUser } from 'components/Form/components/InputUser'
import { ICompany } from 'contracts/companies/interfaces/ICompany'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { IEmployeeSearchResultsFilter } from 'contracts/employees/interfaces/IEmployeeSearchResultsFilter'
import { IEmployeeSearchResultsMetadata } from 'contracts/employees/interfaces/IEmployeeSearchResultsMetadata'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { UnsyncedCollection } from 'modules/Residents/modules/FindRecord/models/UnsyncedCollection'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { DownloadDropdownButton } from '../ReportDialog/components/DownloadDropdownButton'
import { InputCompany } from 'components/Form/components/InputCompany'
import { box } from 'services/box'
import { SendDialog } from './components/SendDialog'

const scopes: InputSelectOption[] = [
  { value: 'all', label: 'Alle' },
  { value: 'active employment contract', label: 'Aktueller Vertrag vorhanden' },
  { value: 'planning problems', label: 'Probleme in der Arbeitsplanung' },
  { value: 'salary statement not available', label: 'Gehaltszettel nicht vorhanden' },
  { value: 'salary statement available', label: 'Gehaltszettel vorhanden' },
  { value: 'salary statement seen', label: 'Gehaltszettel zugestellt' },
]

interface Props {
  employees: UnsyncedCollection<
    IEmployeeSearchResult,
    IEmployeeSearchResultsMetadata,
    IEmployeeSearchResultsFilter
  >
  filterModel: Model<IEmployeeSearchResultsFilter>
  definitionOptions: InputSelectOption[]
  navigate: (path: string) => void
}

@observer
export class EmployeeFilter extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private companies: InputSelectOption[] = [
    { value: null, label: 'Kein Vertrag' },
  ]

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    void this.load()
  }

  private load = async () => {
    const companies = await hermes.indexOnceNew<ICompany>(
      `/api/${this.context.instance.id}/companies`,
    )
    runInAction(() => {
      this.companies = [
        { value: null, label: 'Kein Vertrag' },
        ...companies.map(
          (company: ICompany): InputSelectOption => ({
            value: company.id,
            label: company.name,
          }),
        ),
      ]
    })
  }

  private navigateToShifts = () => {
    this.props.navigate(`/shifts`)
  }

  @action
  private navigateToUser = (id: string | null) => {
    if (!id) {
      return
    }
    this.props.navigate(`/employees/${id}/overview`)
    this.props.filterModel.values.employeeId = ''
  }

  private openSendDialog = () => {
    if (!this.props.employees.resources) {
      return
    }
    const employees = this.props.employees.resources.filter((e) => {
      const value = this.props.employees.rerender.get(e.id)
      return value && value > 0
    })
    const promise = box.custom(
      <SendDialog
        employees={employees}
        month={this.props.filterModel.values.month}
        onClose={() => promise.close()}
      />,
      { closable: true, context: this.context, size: 'md' },
    )
  }

  @action
  private checkAllEmployees = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!this.props.employees.resources) {
      return
    }
    const rerender = this.props.employees.rerender
    for (const employee of this.props.employees.resources) {
      if (e.target.checked) {
        rerender.set(employee.id, 1) // check
      } else {
        rerender.set(employee.id, -1) // uncheck
      }
    }
  }

  render() {
    const allChecked = Array.from(this.props.employees.rerender.values()).every(
      (v) => v === 1,
    )
    return (
      <div className='relative flex-content grid grid-cols-1 sm:grid-cols-[1fr_206px] md:grid-cols-[206px_206px_1fr] xl:grid-cols-[14px_206px_206px_206px_206px_1fr_298px] gap-4 p-6 bg-white shadow border-b border-gray-300 z-2'>
        <div className='pt-[7px] hidden xl:block'>
          <input
            className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
            type='checkbox'
            checked={allChecked}
            onChange={this.checkAllEmployees}
          />
        </div>
        <div className='whitespace-nowrap flex'>
          <Button
            className='flex-auto'
            color='secondary'
            outline
            style={{ borderRadius: '6px 0 0 6px' }}
            onClick={this.navigateToShifts}
          >
            Schichten
          </Button>
          <Button
            className='flex-auto'
            color='primary'
            style={{ borderRadius: '0 6px 6px 0' }}
          >
            Mitarbeiter
          </Button>
        </div>

        <InputMonth model={this.props.filterModel} name='month' />
        <InputCompany
          model={this.props.filterModel}
          name='companyId'
          className='col-span-1 sm:col-span-2 md:col-span-1'
        />
        <InputSelect
          model={this.props.filterModel}
          name='scope'
          options={scopes}
          className='col-span-1 sm:col-span-2 xl:col-span-1'
        />

        <div className='hidden xl:block' />

        <div className='flex col-span-1 sm:col-span-2 md:col-span-1'>
          <InputUser
            model={this.props.filterModel}
            name='employeeId'
            className='flex-auto mr-4'
            onChoose={this.navigateToUser}
          />
          {this.context.permissions.workplan_sendPlans && (
            <Button
              color='secondary'
              className='w-[38px] text-center bg-white'
              outline
              style={{
                borderRadius: '6px 0 0 6px',
                borderRight: 'none',
                padding: '8px 0',
              }}
              onClick={this.openSendDialog}
            >
              <i className='fas fa-paper-plane' />
            </Button>
          )}
          <DownloadDropdownButton
            employees={this.props.employees}
            model={this.props.filterModel}
            options={this.props.definitionOptions}
            navigate={this.props.navigate}
          />
        </div>
      </div>
    )
  }
}
