import { isPlainObject } from 'helpers/isPlainObject'
import { storage } from 'services/storage'

export function getCellWindowStatus(
  id: string,
  userId: string,
): { x: number; y: number } {
  let pos = storage.get('cell.detail.window.' + userId)
  if (!isPlainObject(pos) || typeof pos.x !== 'number' || typeof pos.y !== 'number') {
    const rect = document.getElementById(id)!.getBoundingClientRect()
    pos = { x: rect.width - 40, y: rect.height - 40 }
  }
  return { x: pos.x, y: pos.y }
}
