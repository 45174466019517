import * as React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { FormColor } from 'components/Form/interfaces/FormColor'
import { getButtonStyle } from 'components/Form/components/Button/helpers/getButtonStyle'

interface Props {
  options: InputSelectOption[]
  onSelect: (option: InputSelectOption) => void
  color?: FormColor
  outline?: boolean
  block?: boolean
  className?: string
  width?: number
  align?: 'left' | 'right'
  disabled?: boolean
  style?: React.CSSProperties
}

export const ButtonDropdown: React.FC<Props> = (props) => (
  <Menu
    as='div'
    className={`relative inline-block text-left ${props.block ? 'w-full' : ''}`}
  >
    <div>
      <Menu.Button
        disabled={props.disabled}
        className={getButtonStyle(props.color, props)}
        style={props.style}
      >
        {props.children}
      </Menu.Button>
    </div>
    <Transition
      as={React.Fragment}
      enter='transition ease-out duration-100'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100'
      leaveTo='transform opacity-0 scale-95'
    >
      <Menu.Items
        className={`${
          props.align === 'left' ? 'left-0 origin-top-left' : 'right-0 origin-top-right'
        } absolute mt-2 py-2 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
        style={{ width: props.width || 224 }}
      >
        {props.options.map((option) => (
          <Menu.Item key={option.value}>
            {({ active }) => (
              <button
                onClick={() => props.onSelect(option)}
                className={`${
                  active ? 'bg-blue-500 text-white' : 'text-gray-900'
                } group flex items-center w-full px-3 py-2 text-sm`}
              >
                {option.label}
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Transition>
  </Menu>
)
