import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { getUserImageSrc } from 'modules/Users/Administration/helpers/getUserImageSrc'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import styles from '../../styles.module.scss'
import { Collection } from '@byll/hermes'
import { IEmployeeFileBlock } from 'contracts/employees/interfaces/IEmployeeFileBlock'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { observer } from 'mobx-react'
import { EmployeeFileBlock } from './components/EmployeeFileBlock'
import { Spinner } from 'components/Spinner'
import { EmployeeBlockDropdownButton } from './components/EmployeeBlockDropdownButton'
import { action, makeObservable, observable, reaction } from 'mobx'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { checkPermissionForEmployeeFile } from 'contracts/employees/helpers/checkPermissionForEmployeeFile'
import { Forbidden } from 'modules/ErrorPages/Forbidden'

interface Props {
  employee: IEmployeeSearchResult
}

@observer
export class EmployeeDialogRecordTab extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly employeeFileBlocks: Collection<IEmployeeFileBlock>
  @observable private options: InputSelectOption[] = []
  private disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.employeeFileBlocks = new Collection(
      `/api/${context.instance.id}/employees/${props.employee.id}/employeeFileBlocks`,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.employeeFileBlocks.init({ readOnly: true }))
    this.disposers.push(
      reaction(() => this.employeeFileBlocks.resources, this.getOptions, {
        fireImmediately: true,
      }),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  @action private getOptions = () => {
    for (const block of this.employeeFileBlocks.resources ?? []) {
      if (!block.data) {
        continue
      }
      if (
        block.data.multiple &&
        Array.isArray(block.data.details) &&
        block.data.details.length === 0
      ) {
        if (!this.options.find((o) => o.value === block.data?.id)) {
          this.options.push({
            label: block.data.label,
            value: block.data.id,
          })
        }
      } else if (
        block.data.multiple &&
        Array.isArray(block.data.details) &&
        block.data.details.length > 0
      ) {
        if (this.options.find((o) => o.value === block.data?.id)) {
          this.options.splice(
            this.options.findIndex((o) => o.value === block.data?.id),
            1,
          )
        }
      }
    }
  }

  render() {
    const employee = this.props.employee
    if (
      !checkPermissionForEmployeeFile(
        this.context.permissions.user_mitarbeiterakte,
        employee.tagIds,
      )
    ) {
      return <Forbidden />
    }
    return (
      <div>
        {/* User image */}
        <img
          src={getUserImageSrc(
            this.context.instance.id,
            employee.imageId, // user.imageId,
            employee.sex, // user.sex,
            'portrait',
          )}
          className={styles.portrait}
          alt={`${employee.lastName}, ${employee.firstName}`}
        />
        <div className='flex flex-col gap-6 py-6'>
          {!this.employeeFileBlocks.resources && <Spinner />}
          {this.employeeFileBlocks.resources?.map((block) => {
            if (!block.data) {
              return null
            }
            if (
              block.data.multiple &&
              (!Array.isArray(block.data.details) || block.data.details.length === 0)
            ) {
              return null
            }
            return (
              <div
                key={block.data.id}
                className={block.data.id === 'name' ? 'pr-[238px]' : ''}
              >
                <EmployeeFileBlock
                  block={block}
                  employee={this.props.employee}
                  getOptions={this.getOptions}
                />
              </div>
            )
          })}
          {this.options.length > 0 && (
            <div className='pl-6'>
              <EmployeeBlockDropdownButton
                options={this.options}
                employeeFileBlocks={this.employeeFileBlocks}
                employee={this.props.employee}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
