import { Model } from 'components/Form/Model'
import * as React from 'react'
import { colSpanClasses, selectOptions } from '..'
import { InputSelect, InputSelectOption } from '../../InputSelect'
import { InputText } from '../../InputText'
import { observer } from 'mobx-react'
import { RoundIcon } from 'components/RoundIcon'
import { IfOutsideClick } from 'components/IfOutsideClick'
import { action, makeObservable, observable, reaction } from 'mobx'
import { IInputBlockField } from 'contracts/inputBlock/interfaces/IInputBlock'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'

interface Props {
  field: IInputBlockField
  cols: number
  mandatoryFields?: IInputBlockField[]
  onDelete: (key: string) => void
  isEditing: boolean
  onEditClick: (field: IInputBlockField, e) => void
  onOutsideClick: () => void
}

const orientationClasses = {
  left: 'left-0',
  right: 'right-0',
}

@observer
export class EditField extends React.Component<Props, {}> {
  private readonly model: Model<any>
  @observable private optionModel: string | null
  @observable private orientation: 'left' | 'right' = 'left'
  private disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(props.field)
    this.optionModel =
      props.field.type === 'select' ? props.field.options.map((o) => o).join(',') : ''
  }

  componentDidMount(): void {
    this.disposers.push(
      reaction(
        () => this.props.field.type,
        () => this.setOptionModel(),
      ),
      reaction(
        () => this.props.cols,
        () => this.checkColSpan(),
        { fireImmediately: true },
      ),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  @action
  private checkColSpan = () => {
    if (this.props.field.colspan > this.props.cols) {
      this.model.values.colspan = this.props.cols
    }
  }

  @action private setOptionModel = () => {
    if (this.props.field.type === 'select') {
      this.optionModel = this.props.field.options.map((o) => o).join(',')
    }
  }

  @action private handleOptionChange = (e) => {
    this.optionModel = e.target.value
    this.model.values.options = e.target.value.split(',').map((o) => o.trim())
  }

  private getColspanOptions = () => {
    const options: InputSelectOption[] = []
    for (let i = 1; i <= this.props.cols; i++) {
      options.push({ value: i, label: i.toString() })
    }
    return options
  }

  private checkAllowEmpty = action((event: React.FormEvent<HTMLInputElement>) => {
    this.model.values.allowEmpty = !this.model.values.allowEmpty
  })

  @action private editField = (field: IInputBlockField, e) => {
    e.stopPropagation()
    const rect = e.currentTarget.getBoundingClientRect()
    const docWidth = document.documentElement.clientWidth
    if (rect.left + 420 > docWidth) {
      this.orientation = 'right'
    } else {
      this.orientation = 'left'
    }
    this.props.onEditClick(field, e)
  }

  render() {
    const isRequired =
      this.props.field.required &&
      !!this.props.mandatoryFields?.find((f) => f.key === this.props.field.key)
    const colSpan =
      this.props.field.colspan > this.props.cols
        ? this.props.cols
        : this.props.field.colspan
    return (
      <div className={`relative ${colSpanClasses[colSpan]}`}>
        <div
          className={`border border-gray-300 rounded-md block w-full h-[38px] relative ${
            this.props.field.required ? 'bg-gray-100 cursor-move' : 'cursor-pointer'
          }`}
          onClick={(e) => this.editField(this.props.field, e)}
        >
          <span
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 8, top: -8 }}
          >
            {this.props.field.label}
          </span>
        </div>
        {this.props.isEditing && (
          <IfOutsideClick
            className={`absolute top-12 ${
              orientationClasses[this.orientation]
            } z-10 w-[420px] p-4 border border-indigo-300 bg-white rounded-md`}
            onOutsideClick={this.props.onOutsideClick}
          >
            <div className='flex items-center gap-4 '>
              <InputText
                className='flex-auto'
                model={this.model}
                name='label'
                label='Feldbezeichnung'
                disabled={isRequired}
              />
              <InputSelect
                model={this.model}
                className='flex-content'
                name='colspan'
                label='Spalten'
                options={this.getColspanOptions()}
              />

              {!isRequired && (
                <RoundIcon
                  classNameContainer='flex-content'
                  icon='fa fa-trash'
                  color='danger'
                  onClick={() => this.props.onDelete(this.props.field.key)}
                  tooltip={{ position: 'left', text: 'Feld löschen' }}
                />
              )}
            </div>
            <div className='flex items-center gap-4 mt-4'>
              <InputSelect
                model={this.model}
                name='type'
                label='Typ'
                options={selectOptions}
                disabled={isRequired}
              />
              <div className={`relative flex items-center`}>
                <input
                  className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                  maxLength={255}
                  type='checkbox'
                  name='allowEmpty'
                  onChange={this.checkAllowEmpty}
                  checked={!this.model.values.allowEmpty}
                  id={'checkbox-allow-empty'}
                />
                <label
                  htmlFor={'checkbox-allow-empty'}
                  className='ml-2 block text-sm text-gray-900'
                >
                  Pflichtfeld
                </label>
              </div>
            </div>
            {this.props.field.type === 'select' && (
              <div className='mt-2'>
                <div className='text-sm text-indigo-400 mb-2'>
                  Geben Sie die Auswahloptionen mit Komma getrennt (A,B,C,...) ein.
                </div>
                <input
                  className='w-full border border-gray-300 rounded-md p-2'
                  value={this.optionModel || ''}
                  onChange={this.handleOptionChange}
                  disabled={isRequired}
                />
              </div>
            )}
            {this.props.field.type === 'dropdownEntry' && (
              <div className='mt-2'>
                <InputText
                  model={this.model}
                  name='dropdownEntryType'
                  label='Dropdown Type'
                  placeholder='Automatisch'
                  disabled={!this.props.field.key.includes('unique_')}
                />
              </div>
            )}
          </IfOutsideClick>
        )}
      </div>
    )
  }
}
