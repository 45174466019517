import { uniqueId } from 'helpers/uniqueId'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Model } from '../../Model'
import { tooltip } from '../../helpers/tooltip'
import { dayjs } from 'helpers/dayjs'
import { Dayjs } from 'dayjs'
import { classNames } from 'helpers/classNames'
import 'react-day-picker/lib/style.css'
import './styles.css'
import DayPickerInput from 'react-day-picker/DayPickerInput'

const WEEKDAYS_SHORT = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']

const MONTHS = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
]

const WEEKDAYS_LONG = [
  'Sonntag',
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
]

const rx =
  /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.([0-9]{2}|19[0-9]{2}|20[0-9]{2})$/

interface Props extends React.HTMLProps<HTMLInputElement> {
  name: string
  model: Model<any>
  tooltip?: string | ((error: boolean) => string | null)
  className?: string
  children?: Element
  hasError?: boolean
  setRef?: (HTMLInputElement) => void
}

/**
 * TODO: Calendar overlay should hide on tab back. (Also on tab forward).
 * Maybe: Show Calendar overview only with button at the end of the field (enable tab through form)
 * (Arrow down should also trigger calendar overview)
 */

export const InputDate: React.ComponentClass<Props> = tooltip<Props>(
  observer((props) => {
    let innerClassName =
      'outline-none block w-full shadow-sm text-sm focus:border-2 focus:border-indigo-500 border-gray-300 rounded-md px-3 py-2 focus:py-[7px] border'
    const { name, model, label, className, disabled, readOnly, children, hasError } =
      props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)
    const [id] = React.useState(() => props.id || uniqueId('input-'))
    const [hasFocus, setHasFocus] = React.useState(false)

    const onChange = action(
      (value: Date | [Date | null, Date | null] | null, modifiers, input) => {
        if (value instanceof Date) {
          model.values[name] = dayjs(value).format('YYYY-MM-DD')
        } else {
          model.values[name] = null
        }
        props.onChange?.(value)
      },
    )

    function parseDate(value: string) {
      //if (hasFocus) { return }
      const match = value.match(rx)
      if (!match) {
        return
      }
      if (match[3].length === 2) {
        if (match[3] === '20' || match[3] === '19') {
          return
        }
        if (+match[3] < new Date().getFullYear() - 2000 + 5) {
          match[3] = '20' + match[3]
        } else {
          match[3] = '19' + match[3]
        }
      }
      const date = dayjs(
        `${match[1].length === 1 ? '0' + match[1] : match[1]}.${
          match[2].length === 1 ? '0' + match[2] : match[2]
        }.${match[3]}`,
        'DD.MM.YYYY',
        true,
      )
      if (date.isValid()) {
        return date.toDate()
      }
    }

    function formatDate(value: Date) {
      return dayjs(value).format('DD.MM.YYYY')
    }

    const onBlur = action(() => {
      setHasFocus(false)
      props.model.touched.set(props.name, true)
    })

    if ((touched && error && !disabled) || hasError) {
      innerClassName =
        'outline-none block w-full shadow-sm text-sm focus:border-2 border-red-500 rounded-md px-3 py-2 focus:py-[7px] border'
    }

    if (disabled) {
      innerClassName += ' bg-gray-100'
    }

    const selected: Dayjs | null = model.values[name] ? dayjs(model.values[name]) : null

    return (
      <div
        className={classNames('relative', className)}
        style={{ lineHeight: 'initial' }}
      >
        {label && (
          <label
            htmlFor={id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400 whitespace-pre'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <DayPickerInput
          value={
            selected?.isValid()
              ? selected.toDate()
              : hasFocus || !!props.placeholder
              ? ''
              : ' '
          }
          formatDate={formatDate}
          parseDate={parseDate}
          onDayChange={onChange}
          inputProps={{
            name,
            disabled: disabled || readOnly,
            className: innerClassName,
            placeholder: props.placeholder || 'TT.MM.JJJJ',
            onBlur,
            onFocus: () => setHasFocus(true),
          }}
          clickUnselectsDay
          dayPickerProps={{
            locale: 'de',
            months: MONTHS,
            weekdaysLong: WEEKDAYS_LONG,
            weekdaysShort: WEEKDAYS_SHORT,
            firstDayOfWeek: 1,
          }}
        />
        {children}
      </div>
    )
  }),
)
