import { toPlainObject, warn } from '@byll/hermes/lib/errors/HermesErrors'
import { requestPendingError } from '@byll/hermes/lib/helpers/requestPendingError'
import * as qs from 'qs'
import { makeObservable, observable, reaction, runInAction } from 'mobx'
import { Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { isPlainObject } from 'helpers/isPlainObject'
import { hermes } from '@byll/hermes'

export class UnsyncedCollection<D extends { id: string }, M = {}, Q = {}> {
  // Rerender works this way: If you render a list of items (resources),
  // you can use .rerender.get(elem.id) as a react html element key. This way you can just
  // increment the rerender entry if you want to rerender a specific item.
  // Using .rerender is purly optional.
  @observable rerender = new Map<string, number>()
  @observable.ref error: { [key: string]: any } | null = requestPendingError
  @observable.ref resources: D[] | null = null
  @observable.ref metadata: M | null = null // null on error
  // Requested target query object. User can change props of this object to trigger
  // reload with different query params. Changes are batched, so that only last
  // query object is used for querying as soon as running request returns with
  // initial data. Null values are skipped and become undefined on the server.
  @observable readonly query: Q

  private initialized = false
  private loading = false
  private updateRequested = false
  private disposeObserver: Disposer | null = null

  constructor(
    public readonly pathname: string,
    query: Q = {} as any,
  ) {
    makeObservable(this)
    if (pathname.indexOf('?') !== -1) {
      throw new Error(
        warn(
          `Pathname must not contain query params. Use second constructor param instead to add a query object. ${pathname}`,
        ),
      )
    }
    if (this.pathname.substr(-1, 1) === '/') {
      throw new Error(warn(`Created collection with invalid path ${this.pathname}`))
    }
    if (!isPlainObject(query)) {
      throw new Error(
        warn(`Query param must be a plain object to be fully observable. ${pathname}`),
      )
    }
    this.query = query
  }

  init(): Disposer {
    if (this.initialized) {
      throw new Error(
        warn(`Collection is already initialized. ${this.pathname}${this.query}`),
      )
    }
    this.initialized = true
    this.disposeObserver = reaction(
      () =>
        this.pathname +
        qs.stringify(this.query, {
          allowDots: true,
          addQueryPrefix: true,
          skipNulls: true,
        }),
      (path: string) => {
        if (!this.initialized) {
          throw new Error(
            warn(
              `Reaction should not fire as collection is not initialized. ${this.pathname}`,
            ),
          )
        }
        if (this.loading) {
          this.updateRequested = true
          return
        }
        void this.load(path)
      },
      { fireImmediately: true },
    )
    return this.dispose
  }

  dispose = () => {
    // Use const with arrow function to enable passing around this function
    // Collection could be disposed multiple times, e. g. via
    // auto dispose on disconnect and component unmount dispose.
    // Therefore ignore subsequent disposes after first dispose.
    if (!this.initialized) {
      return
    }
    this.initialized = false
    this.disposeObserver?.()
    this.disposeObserver = null
  }

  private async load(path: string) {
    this.loading = true
    this.updateRequested = false

    try {
      const data = await hermes.indexMeta<{ metadata: any; resources: any[] }>(path)
      runInAction(() => {
        this.error = null
        this.metadata = data.metadata
        this.rerender = new Map(data.resources.map((r) => [r.id, 0]))
        this.resources = data.resources
      })
    } catch (e) {
      runInAction(() => {
        this.error = toPlainObject(e)
        this.metadata = null
        this.rerender = new Map()
        this.resources = null
      })
    }

    if (this.updateRequested) {
      void this.load(
        this.pathname +
          qs.stringify(this.query, {
            allowDots: true,
            addQueryPrefix: true,
            skipNulls: true,
          }),
      )
    } else {
      this.loading = false
    }
  }
}
