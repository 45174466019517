import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { IShift } from 'contracts/workplan/interfaces/IShift'

export function getCompoundsWithShifts(
  compounds: ICompound[],
  buildings: IBuilding[],
  shifts: IShift[],
): ICompound[] {
  // Detect buildings with shifts
  const buildingHasShifts = new Set<string>()
  for (const shift of shifts) {
    if (!shift.buildingId) {
      continue
    }
    buildingHasShifts.add(shift.buildingId)
  }

  // Only return compounds with buildings and shifts
  return compounds.filter((c) =>
    buildings.some((b) => b.compoundId === c.id && buildingHasShifts.has(b.id)),
  )
}
