import { Resource } from '@byll/hermes'
import { IEmployeeFileBlock } from 'contracts/employees/interfaces/IEmployeeFileBlock'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import * as React from 'react'
import { box } from 'services/box'
import { EmployeeFileEditDialog } from './EmployeeFileEditDialog'
import { InputBlock } from 'components/Form/components/InputBlock'
import { Model } from 'components/Form/Model'
import { AppContext } from 'services/connection/models/AppContext'
import cloneDeep from 'lodash/cloneDeep'
import { observer } from 'mobx-react'
import * as uuid from 'uuid'
import { DeleteEmployeeFileEntryPrompt } from './DeleteEmployeeFileEntryPrompt'
import { reaction } from 'mobx'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { ContractPreview } from './ContractPreview'

interface Props {
  block: Resource<IEmployeeFileBlock>
  employee: IEmployeeSearchResult
  getOptions?: () => void
}

@observer
export class EmployeeFileBlock extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IEmployeeFileBlock>
  private disposer: Disposer[] = []

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.block.data)
  }

  componentDidMount(): void {
    if (this.props.getOptions) {
      this.disposer.push(
        reaction(
          () => this.props.block.data?.details.length,
          () => this.props.getOptions!(),
          { fireImmediately: true },
        ),
      )
    }
  }

  componentWillUnmount(): void {
    dispose(this.disposer)
  }

  private addEntry = () => {
    const id = uuid.v4()
    this.openEditDialog(id)
  }

  // id is only set for multiple blocks if one entry is edited or added
  private openEditDialog = (id?: string) => {
    if (!this.props.block.data) {
      return
    }
    const copiedBlock = cloneDeep(this.props.block.data)
    const promise = box.custom(
      <EmployeeFileEditDialog
        id={id}
        block={copiedBlock}
        employee={this.props.employee}
        onClose={() => promise.close()}
      />,
      { closable: true, context: this.context, size: 'lg' },
    )
  }

  private deleteEntry = (id: string) => {
    if (!this.props.block.data) {
      return
    }
    const details = cloneDeep(this.props.block.data.details.filter((d) => d.id !== id))
    const promise = box.custom(
      <DeleteEmployeeFileEntryPrompt
        details={details}
        blockId={this.props.block.data.id}
        employeeId={this.props.employee.id}
        close={() => promise.close()}
      />,
      { context: this.context },
    )
  }

  render() {
    const { block } = this.props
    if (!block.data) {
      return null
    }
    if (block.data.id === 'employment-contracts') {
      return (
        <ContractPreview
          key={block.data.id}
          block={block}
          employee={this.props.employee}
          onDelete={this.deleteEntry}
        />
      )
    }
    return (
      <InputBlock
        key={block.data.id}
        id={block.data.id}
        name='details'
        label={block.data.label}
        model={this.model}
        mandatoryFields={block.data.mandatoryFields}
        multiple={block.data.multiple}
        readonly
        disabled
        editable={!(block.data.id === 'name')}
        onAdd={this.addEntry}
        onEdit={this.openEditDialog}
        onDelete={this.deleteEntry}
      />
    )
  }
}
