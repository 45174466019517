import { observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { IWorkplanFestschreibung } from 'contracts/workplan/interfaces/IWorkplanFestschreibung'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { AppContext } from 'services/connection/models/AppContext'
import { dayjs } from 'helpers/dayjs'
import { RoundIcon } from 'components/RoundIcon'

interface Props {
  festschreibung: IWorkplanFestschreibung
  onDelete: (festschreibung: IWorkplanFestschreibung) => void
}

@observer
export class FestschreibungRow extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private loading = false
  private onDelete = () => this.props.onDelete(this.props.festschreibung)

  private downloadExcel = async () => {
    if (this.loading) {
      return
    }
    runInAction(() => (this.loading = true))
    await createAndDownloadReport(
      'excelLohnFestschreibung',
      this.context.instance.id,
      { festschreibungId: String(this.props.festschreibung.id) },
      `${this.props.festschreibung.month} Festschreibung.xlsx`,
    )
    runInAction(() => (this.loading = false))
  }

  private downloadKanzleiExport = async () => {
    if (this.loading) {
      return
    }
    runInAction(() => (this.loading = true))
    await createAndDownloadReport(
      'datevLohnFestschreibung',
      this.context.instance.id,
      { festschreibungId: String(this.props.festschreibung.id) },
      `${this.props.festschreibung.month} ${this.props.festschreibung.companyName} Lohnbüro.txt`,
    )
    runInAction(() => (this.loading = false))
  }

  render() {
    const f = this.props.festschreibung
    const date = dayjs(f.createdAt)
    return (
      <div className='flex items-center border border-gray-300 rounded-md p-2 mb-3 relative'>
        <div style={{ flex: '0 0 160px' }}>
          <span>{date.format('DD.MM.YYYY')}</span>
          <span className='text-gray-500 text-sm ml-2'>{date.format('HH:mm')}</span>
        </div>
        <div className='truncate' style={{ flex: '0 1 370px', maxWidth: 370 }}>
          Festschreibung{' '}
          <span className='bg-indigo-400 text-white px-2 py-1 text-xs rounded-xl ml-2'>
            Stundenlöhne
          </span>
        </div>
        {!this.loading && (
          <div className='flex-auto'>
            <RoundIcon
              onClick={this.downloadExcel}
              tooltip='Excel Download'
              icon='fa fa-table'
              style={{ position: 'absolute', top: 4, right: 71 }}
            />
            <RoundIcon
              onClick={this.downloadKanzleiExport}
              tooltip='Lohnbüro Download'
              icon='fa fa-download'
              style={{ position: 'absolute', top: 4, right: 38 }}
            />
            <RoundIcon
              onClick={this.onDelete}
              tooltip={{ text: 'Festschreibung löschen', position: 'left' }}
              icon='fa fa-trash'
              style={{ position: 'absolute', top: 4, right: 5 }}
            />
          </div>
        )}
        {this.loading && (
          <div className='flex-auto tr'>
            <i className='fa fa-refresh fa-spin' />
          </div>
        )}
      </div>
    )
  }
}
