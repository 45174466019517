import * as React from 'react'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import styles from './styles.module.scss'

const minVisible = 20

interface Props {
  prefix: string
  model
  onDelete?: () => void
  disabled?: boolean
}

@observer
export class MovableImage extends React.Component<Props, {}> {
  elem: HTMLElement | null = null
  offset: { x: number; y: number } | null = null
  edge: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left' | 'area' | null = null
  ratio: number = 1

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.move)
    window.addEventListener('mouseup', this.up)
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.move)
    window.removeEventListener('mouseup', this.up)
  }

  get style() {
    return {
      left: `${this.props.model[this.props.prefix + 'X']}px`,
      top: `${this.props.model[this.props.prefix + 'Y']}px`,
      width: `${this.props.model[this.props.prefix + 'Width']}px`,
      height: `${this.props.model[this.props.prefix + 'Height']}px`,
    }
  }

  down = (e) => {
    if (!this.elem || this.props.disabled) {
      return
    }
    const elemRect = this.elem?.getBoundingClientRect()
    this.edge = e.target.getAttribute('data-template-movable')
    this.offset = {
      x: e.nativeEvent.pageX - elemRect.left,
      y: e.nativeEvent.pageY - elemRect.top,
    }
    this.ratio =
      this.props.model[this.props.prefix + 'OriginalWidth'] /
      this.props.model[this.props.prefix + 'OriginalHeight']
    /*/ e.preventDefault()
    // remove focus from active element (which is prevented due to preventDefault. Yet preventDefault is required,
    // so that the user doesn't start selecting the entire page while dragging the element around.)
    if (document.activeElement !== document.body && (document.activeElement as HTMLElement).blur) {
      (document.activeElement as HTMLElement).blur()
    }*/
  }

  @action
  up = () => {
    if (!this.offset) {
      return
    }
    if (!this.elem) {
      return
    }

    const parentRect = this.elem.parentElement!.getBoundingClientRect()
    const elemRect = this.elem.getBoundingClientRect()

    // set position on mouse up (rect changes trigger server side settings update)
    this.props.model[this.props.prefix + 'X'] = Math.round(
      elemRect.left - parentRect.left,
    ) // ensure integer positions
    this.props.model[this.props.prefix + 'Y'] = Math.round(elemRect.top - parentRect.top) // ensure integer positions
    this.props.model[this.props.prefix + 'Width'] = Math.round(elemRect.width) // ensure integer width
    this.props.model[this.props.prefix + 'Height'] = Math.round(elemRect.height) // ensure integer height

    this.offset = null
  }

  move = (e) => {
    if (!this.offset) {
      return
    }
    if (!this.elem) {
      return
    }
    const parentRect = this.elem.parentElement!.getBoundingClientRect()
    const elemRect = this.elem.getBoundingClientRect()

    let left = elemRect.left - parentRect.left
    let top = elemRect.top - parentRect.top
    let width = elemRect.width
    let height = elemRect.height
    const enforceRatio = () => {
      if (height < 20) {
        height = 20
      }
      if (width < 20) {
        width = 20
      }

      if (height > width / this.ratio) {
        height = width / this.ratio
        if (height < 20) {
          height = 20
          width = height * this.ratio
        }
      } else {
        width = height * this.ratio
        if (width < 20) {
          width = 20
          height = width / this.ratio
        }
      }
    }

    switch (this.edge) {
      case 'bottom-right': {
        width = e.pageX - elemRect.left
        height = e.pageY - elemRect.top
        enforceRatio()
        break
      }
      case 'bottom-left': {
        let xFix = elemRect.left + elemRect.width
        width = xFix - e.pageX
        height = e.pageY - elemRect.top
        enforceRatio()
        left = xFix - parentRect.left - width
        break
      }
      case 'top-left': {
        let xFix = elemRect.left + elemRect.width
        let yFix = elemRect.top + elemRect.height
        width = xFix - e.pageX
        height = yFix - e.pageY
        enforceRatio()
        left = xFix - parentRect.left - width
        top = yFix - parentRect.top - height
        break
      }
      case 'top-right': {
        let yFix = elemRect.top + elemRect.height
        width = e.pageX - elemRect.left
        height = yFix - e.pageY
        enforceRatio()
        top = yFix - parentRect.top - height
        break
      }
      default: {
        left = e.pageX - this.offset.x - parentRect.left
        top = e.pageY - this.offset.y - parentRect.top
      }
    }
    // fix position to ensure that image is at least partially visible
    if (left < minVisible - elemRect.width) {
      left = minVisible - elemRect.width
    }
    if (top < minVisible - elemRect.height) {
      top = minVisible - elemRect.height
    }
    if (left > parentRect.width - minVisible) {
      left = parentRect.width - minVisible
    }
    if (top > parentRect.height - minVisible) {
      top = parentRect.height - minVisible
    }
    this.elem.style.left = `${left}px`
    this.elem.style.top = `${top}px`
    this.elem.style.width = `${width}px`
    this.elem.style.height = `${height}px`
  }

  private keyDown = (event) => {
    if (event.key !== 'Delete' || !this.elem?.contains(event.target)) {
      return
    }
    this.props.onDelete?.()
  }

  setRef = (elem) => {
    this.elem = elem
  }

  render() {
    return (
      <div
        data-template-movable='area'
        className={styles.movable}
        ref={this.setRef}
        style={this.style}
        onMouseDown={this.down}
        onKeyDown={this.keyDown}
        tabIndex={-1}
      >
        {this.props.children}
        <div
          className={`${styles.edge} ${styles.topLeft}`}
          data-template-movable='top-left'
        />
        <div
          className={`${styles.edge} ${styles.topRight}`}
          data-template-movable='top-right'
        />
        <div
          className={`${styles.edge} ${styles.bottomRight}`}
          data-template-movable='bottom-right'
        />
        <div
          className={`${styles.edge} ${styles.bottomLeft}`}
          data-template-movable='bottom-left'
        />
      </div>
    )
  }
}
