import { Collection, hermes, Resource } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { IMonthlyEmployeeWorkScheduleFilter } from 'contracts/workplan/interfaces/IMonthlyEmployeeWorkScheduleFilter'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import { IShortlistEntry } from 'contracts/workplan/interfaces/IShortlistEntry'
import { action, makeObservable } from 'mobx'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  model: Model<IMonthlyEmployeeWorkScheduleFilter>
  selectedModel: Model<{
    selectedBuilding: IBuilding | null
    selectedShift: IShift | null
    compoundId: string
    view: 'shortlist' | 'select'
  }>
  shortlistEntries: Collection<IShortlistEntry>
  toggleDropdown: (e: any) => void
}

export class Shortlist extends React.Component<Props, {}> {
  static contextType = AppContext

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    if (!props.shortlistEntries.resources) {
      props.selectedModel.values.view = 'select'
    }
  }

  private mapPinnedEntry = (entry: Resource<IShortlistEntry>) => {
    if (!entry.data || !entry.data.isPinned) {
      return null
    }
    return (
      <span
        key={entry.data.id}
        className='inline-flex items-center gap-x-0.5 rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10 cursor-pointer hover:bg-indigo-100'
        onClick={() => this.selectShift(entry.data!.shiftId)}
      >
        {`${entry.data.compoundLabel} / ${entry.data.buildingLabel} / ${entry.data.shiftLabel}`}
        <button
          type='button'
          className='group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-indigo-600/20 ml-auto'
          onClick={(e) => {
            e.stopPropagation()
            this.deleteEntry(entry.data!.id)
          }}
        >
          <span className='sr-only'>Löschen</span>
          <svg
            viewBox='0 0 14 14'
            className='h-3.5 w-3.5 stroke-indigo-700/50 group-hover:stroke-indigo-700/75'
          >
            <path d='M4 4l6 6m0-6l-6 6' />
          </svg>
          <span className='absolute -inset-1' />
        </button>
      </span>
    )
  }

  private mapRecentEntry = (entry: Resource<IShortlistEntry>) => {
    if (!entry.data || entry.data.isPinned) {
      return null
    }
    return (
      <span
        key={entry.data.id}
        className='inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-700/10 cursor-pointer hover:bg-gray-100'
        onClick={() => this.selectShift(entry.data!.shiftId)}
      >
        {`${entry.data.compoundLabel} / ${entry.data.buildingLabel} / ${entry.data.shiftLabel}`}
        <button
          type='button'
          className='group relative mx-0.5 h-3.5 w-3.5 rounded-sm hover:bg-gray-600/20 ml-auto'
          onClick={(e) => {
            e.stopPropagation()
            this.pinEntry(entry.data!)
          }}
        >
          <span className='sr-only'>Pin</span>
          <i className='fa fa-thumbtack' />
          <span className='absolute -inset-1' />
        </button>
        <button
          type='button'
          className='group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-600/20 ml-auto'
          onClick={(e) => {
            e.stopPropagation()
            this.deleteEntry(entry.data!.id)
          }}
        >
          <span className='sr-only'>Löschen</span>
          <svg
            viewBox='0 0 14 14'
            className='h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75'
          >
            <path d='M4 4l6 6m0-6l-6 6' />
          </svg>
          <span className='absolute -inset-1' />
        </button>
      </span>
    )
  }

  @action
  private selectShift = (shiftId: string) => {
    this.props.model.values.shiftId = shiftId
  }

  @action
  private selectOthers = (e) => {
    e.stopPropagation()
    this.props.selectedModel.values.view = 'select'
  }

  private pinEntry = async (entry: IShortlistEntry) => {
    await hermes.create(`/api/${this.context.instance.id}/workplan/shortlistEntries`, {
      isPinned: true,
      shiftId: entry.shiftId,
    })
  }

  private deleteEntry = async (entryId: string) => {
    await hermes.delete(
      `/api/${this.context.instance.id}/workplan/shortlistEntries/${entryId}`,
    )
  }

  render() {
    if (!this.props.shortlistEntries.resources) {
      return null
    }
    if (this.props.shortlistEntries.resources.length === 0) {
      return (
        <div className='flex flex-col gap-2 items-center justify-center p-2 text-sm text-gray-500'>
          <div>Keine Einträge vorhanden.</div>
          <Button small onClick={this.selectOthers}>
            Schichtauswahl
          </Button>
        </div>
      )
    }
    return (
      <div className='flex flex-col gap-2'>
        {this.props.shortlistEntries.resources.filter((e) => e.data?.isPinned).length >
          0 && (
          <div className='flex flex-col gap-1'>
            <div className='text-xs text-gray-500'>Vorgemerkt</div>
            {this.props.shortlistEntries.resources.map(this.mapPinnedEntry)}
          </div>
        )}
        {this.props.shortlistEntries.resources.filter((e) => !e.data?.isPinned).length >
          0 && (
          <div className='flex flex-col gap-1'>
            <div className='text-xs text-gray-500'>Zuletzt</div>
            {this.props.shortlistEntries.resources.map(this.mapRecentEntry)}
          </div>
        )}
        <Button small onClick={this.selectOthers}>
          Andere
        </Button>
      </div>
    )
  }
}
