import { Model } from 'components/Form/Model'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { Tooltip } from 'components/Tooltip'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'

const tooltipDict = {
  exclude: 'Nicht an Feiertagen',
  include: 'An Feiertagen',
  null: 'Keine Feiertagsregelung',
}

const classDict = {
  exclude: 'fa-ban',
  include: 'fa-toggle-on',
  null: 'fa-toggle-off',
}

interface Props {
  model: Model<IShiftSearchResult>
  readOnly?: boolean
}

@observer
export class ShiftRepeatDays extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action private toggleHolidays = () => {
    if (this.props.readOnly) {
      return
    }
    if (this.props.model.values.id) {
      alert('Die Feiertagsregelung kann im Nachhinein nicht mehr geändert werden.')
      return
    }
    if (this.props.model.values.repeatHoliday === null) {
      this.props.model.values.repeatHoliday = 'include'
    } else if (this.props.model.values.repeatHoliday === 'include') {
      this.props.model.values.repeatHoliday = 'exclude'
    } else {
      this.props.model.values.repeatHoliday = null
    }
  }

  render() {
    return (
      <div
        className='relative col-span-4 border border-gray-300 rounded-md'
        style={{ padding: '0.5rem 1rem' }}
      >
        <div className='flex justify-between items-center'>
          <div className='flex-content mr-3'>
            <InputCheckbox
              model={this.props.model}
              name='repeatMon'
              label='Mo'
              disabled={!!this.props.model.values.id || this.props.readOnly}
            />
          </div>
          <div className='flex-content mr-3'>
            <InputCheckbox
              model={this.props.model}
              name='repeatTue'
              label='Di'
              disabled={!!this.props.model.values.id || this.props.readOnly}
            />
          </div>
          <div className='flex-content mr-3'>
            <InputCheckbox
              model={this.props.model}
              name='repeatWed'
              label='Mi'
              disabled={!!this.props.model.values.id || this.props.readOnly}
            />
          </div>
          <div className='flex-content mr-3'>
            <InputCheckbox
              model={this.props.model}
              name='repeatThu'
              label='Do'
              disabled={!!this.props.model.values.id || this.props.readOnly}
            />
          </div>
          <div className='flex-content mr-3'>
            <InputCheckbox
              model={this.props.model}
              name='repeatFri'
              label='Fr'
              disabled={!!this.props.model.values.id || this.props.readOnly}
            />
          </div>
          <div className='flex-content mr-3'>
            <InputCheckbox
              model={this.props.model}
              name='repeatSat'
              label='Sa'
              disabled={!!this.props.model.values.id || this.props.readOnly}
            />
          </div>
          <div className='flex-content mr-3'>
            <InputCheckbox
              model={this.props.model}
              name='repeatSun'
              label='So'
              disabled={!!this.props.model.values.id || this.props.readOnly}
            />
          </div>
          <div className='flex-content has-tooltip'>
            <span
              key={this.props.model.values.repeatHoliday}
              className='cursor-pointer text-gray-800'
              onClick={this.toggleHolidays}
            >
              <i
                className={`fas ${
                  classDict[String(this.props.model.values.repeatHoliday)]
                }`}
              />
            </span>
            <span className='ml-1'>Feiertage</span>
            <Tooltip>
              {tooltipDict[String(this.props.model.values.repeatHoliday)]}
            </Tooltip>
          </div>
        </div>
        <label
          className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400 z-1'
          style={{ left: 9, top: -7 }}
        >
          Wiederholung
        </label>
      </div>
    )
  }
}
