import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { Message } from 'components/Message'
import { Spinner } from 'components/Spinner'
import { IEmployeeWorkScheduleCellLog } from 'contracts/workplan/interfaces/IEmployeeWorkScheduleCellLog'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { getLogLabel } from 'modules/Workplan/helpers/getLogLabel'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  userId: string
  date: string
  type: 'plan' | 'real'
  specialShifts: IShift[] | null
}

@observer
export class EmployeeDetailsHistory extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private logs: IEmployeeWorkScheduleCellLog[] | null = null
  @observable private view: 'all users' | 'selected user' = 'selected user'
  @observable private loading: boolean = false
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    this.loadLogs()
  }

  private loadLogs = async () => {
    try {
      runInAction(() => (this.loading = true))
      const logs = await hermes.indexOnceNew<IEmployeeWorkScheduleCellLog>(
        `/api/${this.context.instance.id}/workplan/employeeWorkScheduleCellLogs?date=${
          this.props.date
        }&type=${this.props.type}${
          this.view === 'selected user' ? `&userId=${this.props.userId}` : ''
        }`,
      )
      runInAction(() => {
        this.logs = logs
        this.loading = false
      })
    } catch (_e) {
      runInAction(() => {
        this.loading = false
        this.error =
          'Beim Laden der Historie ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
      })
    }
  }

  @action
  private loadAllEmployees = () => {
    this.view = 'all users'
    this.loadLogs()
  }

  render() {
    if (!this.logs) {
      return null
    }
    return (
      <div className='absolute top-10 bottom-0 right-0 left-0 overflow-y-scroll bg-white'>
        {this.error && (
          <Message className='p-4' color='danger'>
            {this.error}
          </Message>
        )}
        {this.loading && <Spinner />}
        {this.logs.length === 0 && (
          <div className='text-gray-800 p-4'>Es sind keine Logs vorhanden.</div>
        )}
        {this.logs.length > 0 && (
          <table className='min-w-full divide-y divide-gray-300 p-4'>
            <thead>
              <tr>
                <th
                  scope='col'
                  className='py-3 pl-4 text-left text-sm font-semibold text-gray-900'
                >
                  Geplant am
                </th>
                <th
                  scope='col'
                  className='py-2 text-left text-sm font-semibold text-gray-900'
                >
                  Geplant von
                </th>
                <th
                  scope='col'
                  className='py-3 text-left text-sm font-semibold text-gray-900'
                >
                  Mitarbeiter
                </th>
                <th
                  scope='col'
                  className='py-3 pr-4 text-left text-sm font-semibold text-gray-900'
                >
                  Zeiten
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
              {this.logs.map((log) => (
                <tr key={log.id}>
                  <td className='py-3 pl-4 pr-2 text-sm font-medium text-gray-900 whitespace-pre-wrap'>
                    {dayjs(log.createdAt).format('DD.MM.YYYY, HH:mm')}
                  </td>
                  <td className='py-3 pr-2 text-sm text-gray-500 whitespace-pre-wrap'>
                    {log.createdByUserName}
                  </td>
                  <td className='py-3 pr-2 text-sm text-gray-500 whitespace-pre-wrap'>
                    {log.employeeName}
                  </td>
                  <td className='py-3 pr-2 text-sm text-gray-500 whitespace-pre-wrap'>
                    {getLogLabel(
                      log.begin,
                      log.end,
                      log.shortcut,
                      this.props.specialShifts?.map((s) => s.comment.split(',')[0]) || [],
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {this.view === 'selected user' && (
          <Button onClick={this.loadAllEmployees} className='ml-4 mb-4' small>
            Alle Mitarbeiter anzeigen
          </Button>
        )}
      </div>
    )
  }
}
