import { dayjs } from 'helpers/dayjs'

export function getLogLabel(
  begin: string | null,
  end: string | null,
  shortcut: string,
  specialShiftShortcuts: string[],
) {
  if (specialShiftShortcuts.includes(shortcut)) {
    return shortcut
  }
  if (!begin && !end) {
    return 'Gelöscht'
  }
  return `${dayjs(begin).format('HH:mm')} - ${dayjs(end).format('HH:mm')}`
}
