export function checkPermissionForEmployeeFile(
  permission: string,
  employeeTagIds: string[],
): boolean {
  if (!permission) {
    return false
  }
  if (permission.includes('only')) {
    const tagIds = permission.split(',').filter((tag: string) => tag !== 'only')

    if (employeeTagIds.length === 0) {
      return false
    }
    for (const id of employeeTagIds) {
      if (tagIds.includes(id)) {
        return true
      }
    }
    return false
  }
  if (permission.includes('all but')) {
    for (const id of employeeTagIds) {
      if (permission.includes(id)) {
        return false
      }
    }
    return true
  }
  return true
}
