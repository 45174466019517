const time = /^ *([0-9]|0[0-9]|1[0-9]|2[0-4]):([0-5][0-9]) *$/

export function hoursMinutesToMinutes(hhMm: string): number | null {
  const match = hhMm.match(time)
  if (!match) {
    return null
  }
  const h = parseInt(match[1], 10)
  const m = parseInt(match[2], 10)
  if (h === 24 && m > 0) {
    return null
  }
  return h * 60 + m
}
