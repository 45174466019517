import { dayjs } from '../../general/helpers/dayjs'
import { IShiftSearchResult } from '../interfaces/IShiftSearchResult'

export function getEmptyShiftSearchResult(): IShiftSearchResult {
  return {
    id: '',
    type: 'shift',
    label: '',
    comment: '',
    maxNumParticipants: 0,
    minNumFemaleParticipants: null,
    minNumMaleParticipants: null,
    participantValidation: 'exact',
    hasBreak: false,
    begin: '',
    end: '',
    shortcuts: {
      x: {
        timeInterval: '09:00-17:00',
        breakIntervals: '',
        hasBreak: true,
      },
    },
    recovery: 720,
    buildingId: null,
    abbreviation: '',
    color: null,

    repeatMon: true,
    repeatTue: true,
    repeatWed: true,
    repeatThu: true,
    repeatFri: true,
    repeatSat: true,
    repeatSun: true,
    repeatHoliday: null,
    repeatStartDate: dayjs().startOf('month').format('YYYY-MM-DD'),
    repeatStopDate: null,

    holidayRegion: '',
    shiftCreatedByUserId: null,
    shiftEditedByUserId: null,
    shiftLastEditTimestamp: 0,
    participantsOverrideDays: '{}',

    buildingLabel: '',
    compoundLabel: '',
    compoundId: '',
    actualPlannedParticipants: 0,
    targetPlannedParticipants: 0,
  }
}
