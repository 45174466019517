import { Dayjs } from 'dayjs'
import { dayjs } from '../../general/helpers/dayjs'

const holidayMap = new Map<string, any>() // 'year.location' => holidays

export function getHoliday(date: Dayjs, location = 'Deutschland'): string | null {
  const key = `${date.year()}.${location}`
  if (!holidayMap.has(key)) {
    holidayMap.set(key, getHolidays(date.year(), location))
  }
  return holidayMap.get(key)![date.format('YYYY-MM-DD')] || null
}

function getHolidays(year: number, location?: string) {
  //  format: yyyy-mm-dd
  const holidays: any = {}
  holidays[`${year}-01-01`] = 'Neujahr'
  holidays[`${year}-01-06`] = 'Heilige Drei Könige'
  holidays[`${year}-05-01`] = 'Tag der Arbeit'
  holidays[`${year}-08-15`] = 'Mariä Himmelfahrt' // In München ein Feiertag
  holidays[`${year}-10-03`] = 'Tag der Deutschen Einheit'
  holidays[`${year}-11-01`] = 'Allerheiligen'
  holidays[`${year}-12-25`] = '1. Weihnachtsfeiertag'
  holidays[`${year}-12-26`] = '2. Weihnachtsfeiertag'

  // calc dynamic holidays
  const easter = ostersonntag(year)
  holidays[easter.subtract(2, 'day').format('YYYY-MM-DD')] = 'Karfreitag'
  holidays[easter.add(1, 'day').format('YYYY-MM-DD')] = 'Ostermontag'
  holidays[easter.add(39, 'day').format('YYYY-MM-DD')] = 'Christi Himmelfahrt'
  holidays[easter.add(50, 'day').format('YYYY-MM-DD')] = 'Pfingstmontag'
  holidays[easter.add(60, 'day').format('YYYY-MM-DD')] = 'Fronleichnam'

  if (location === 'Augsburg') {
    holidays[`${year}-08-08`] = 'Hohes Friedensfest'
  }

  if (location === 'Baden-Württemberg') {
    delete holidays[`${year}-08-15`] // 'Mariä Himmelfahrt'
  }

  if (location === 'Bayern ohne Mariä Himmelfahrt') {
    delete holidays[`${year}-08-15`] // 'Mariä Himmelfahrt'
  }

  return holidays
}

function ostersonntag(Y): Dayjs {
  let C = Math.floor(Y / 100)
  let N = Y - 19 * Math.floor(Y / 19)
  let K = Math.floor((C - 17) / 25)
  let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15
  I = I - 30 * Math.floor(I / 30)
  I =
    I -
    Math.floor(I / 28) *
      (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11))
  let J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4)
  J = J - 7 * Math.floor(J / 7)
  let L = I - J
  let M = 3 + Math.floor((L + 40) / 44)
  let D = L + 28 - 31 * Math.floor(M / 4)

  return dayjs(`${Y}-${M}-${D}`, 'YYYY-M-D')
}
