import * as React from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { tooltip } from '../../helpers/tooltip'
import { Model } from '../../Model'
import { uniqueId } from 'helpers/uniqueId'
import { classNames } from 'helpers/classNames'

export type InputSelectOption = { value: any; label: string }

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: string
  model: Model<any>
  options: InputSelectOption[] // options that can be chosen by this input
  tooltip?: string | ((error: boolean) => string | null)
  className?: string
  children?: Element
  hasError?: boolean
  setRef?: (HTMLInputElement) => void
}

export const InputSelect: React.ComponentClass<Props> = tooltip<Props>(
  observer((props) => {
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
    const {
      name,
      model,
      options,
      label,
      tooltip,
      className,
      setRef,
      children,
      hasError,
      ...attributes
    } = props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)
    const [id] = React.useState(() => props.id || uniqueId('input-'))

    const onChange = action((event: React.FormEvent<HTMLSelectElement>) => {
      if (!(event.target instanceof HTMLSelectElement)) {
        return
      }
      const target: HTMLSelectElement = event.target
      model.values[name] = JSON.parse(target.value)
      props.onChange?.(event)
    })

    if ((touched && error) || hasError) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md'
    }

    if (attributes.disabled) {
      innerClassName += ' bg-gray-100'
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <select
          {...attributes}
          className={innerClassName}
          name={name}
          ref={setRef}
          onChange={onChange}
          value={JSON.stringify(model.values[name])}
          id={id}
        >
          {options.map((option) => (
            <option key={option.value} value={JSON.stringify(option.value)}>
              {option.label}
            </option>
          ))}
        </select>
        {children}
      </div>
    )
  }),
)
