import * as React from 'react'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from '@headlessui/react'
import { Message } from 'components/Message'
import { Model } from 'components/Form/Model'
import { InputCheckbox } from 'components/Form/components/InputCheckbox'
import { AppContext } from 'services/connection/models/AppContext'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'

interface Props {
  onClose: () => void
  month: string
  employees: IEmployeeSearchResult[]
}

@observer
export class SendDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable step: 'initial' | 'sending' | 'error' | 'success' = 'initial'
  @observable counterJustSent = 0
  @observable progress: number | null = null
  counterPreviouslySentInitial = 0
  counterInvalidEmail = 0
  private model: Model<{ resend: boolean }> = new Model({ resend: false })

  @computed get counterNotYetSent() {
    return (
      this.props.employees.length -
      this.counterPreviouslySent -
      this.counterInvalidEmail -
      this.counterJustSent
    )
  }

  @computed get counterPreviouslySent() {
    return this.model.values.resend ? 0 : this.counterPreviouslySentInitial
  }

  constructor(props) {
    super(props)
    makeObservable(this)
    for (const employee of this.props.employees) {
      if (!employee.email) {
        this.counterInvalidEmail++
      } else if (employee.utilization.planSendDate) {
        this.counterPreviouslySentInitial++
      }
    }
  }

  dismiss = () => {
    runInAction(() => (this.step = 'initial'))
    this.props.onClose()
  }

  send = async () => {
    const date = dayjs().format('YYYY-MM-DD')
    runInAction(() => {
      this.progress = 1
      this.step = 'sending'
    })

    const shouldSendEmail = (employee) => {
      if (!employee.email) return false
      if (!this.model.values.resend && employee.planSendDate) return false
      return true
    }

    const totalEmployeesToSend = this.props.employees.filter(shouldSendEmail).length

    for (const employee of this.props.employees) {
      if (!employee.email) continue
      if (!this.model.values.resend && employee.utilization.planSendDate) continue

      await this.sendTo(employee.id)
      runInAction(() => {
        this.progress = Math.round((this.counterJustSent / totalEmployeesToSend) * 100)
        employee.utilization.planSendDate = date
      })
      if (this.step !== 'sending') return
    }

    runInAction(() => {
      this.step = 'success'
      this.progress = null
    })
  }

  sendTo = async (userId: string) => {
    try {
      await hermes.create(`/api/${this.context.instance.id}/workplan/send`, {
        receiverId: String(userId),
        month: this.props.month,
      })
      runInAction(() => this.counterJustSent++)
    } catch (_e) {
      runInAction(() => (this.step = 'error'))
    }
  }

  @action toggleResend = () => (this.model.values.resend = !this.model.values.resend)

  render() {
    return (
      <div>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6 pb-4 -mx-6 -mt-6 border-b border-gray-200'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                Arbeitspläne versenden
              </Dialog.Title>
            </div>
          </div>
        </div>

        <div className='bg-gray-100 p-6 -mx-6'>
          {this.step === 'error' && (
            <Message color='danger' className='border border-red-500 sticky top-0 z-10'>
              Beim Versenden der Arbeitspläne ist leider ein Fehler aufgetreten.
            </Message>
          )}

          {(this.step === 'sending' ||
            this.step === 'initial' ||
            this.step === 'success') && (
            <div className='rounded shadow bg-white p-6'>
              {this.step === 'success' && (
                <div className='mb-2'>Die Arbeitspläne wurden erfolgreich versendet.</div>
              )}

              {this.props.employees.length === 0 && this.step === 'initial' && (
                <div>
                  Derzeit sind keine Mitarbeiter für den Versand der Arbeitspläne
                  ausgewählt. Sie können die Mitarbeiter mit Hilfe der Checkboxen
                  auswählen, bevor Sie diesen Dialog öffnen.
                </div>
              )}

              {this.props.employees.length > 0 && (
                <div>
                  {this.progress && (
                    <div className='border border-gray-300 rounded-md h-10 overflow-hidden'>
                      <div
                        className={`bg-green-500 text-white text-sm overflow-hidden whitespace-nowrap transition-width duration-500 ease-in-out ${
                          this.progress > 0 ? 'p-2' : ''
                        }`}
                        style={{ width: `${this.progress}%`, height: '100%' }}
                      >
                        {Math.round(this.progress)} %
                      </div>
                    </div>
                  )}

                  <div>
                    {this.counterInvalidEmail > 0 && (
                      <div className='flex items-center'>
                        <div
                          className='bg-yellow-500 w-4 h-4  mr-2'
                          style={{ borderRadius: '4px' }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <span>
                          {`${this.counterInvalidEmail} ${
                            this.counterInvalidEmail === 1
                              ? 'Mitarbeiter hat'
                              : 'Mitarbeiter haben'
                          } keine gültige E-Mail Adresse`}
                        </span>
                      </div>
                    )}
                    {this.counterPreviouslySent > 0 && (
                      <div className='flex items-center'>
                        <div
                          className='bg-green-500  w-4 h-4  mr-2'
                          style={{ borderRadius: '4px' }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <span>
                          {`${this.counterPreviouslySent} ${
                            this.counterPreviouslySent === 1
                              ? 'Plan wurde'
                              : 'Pläne wurden'
                          } bereits ein anderes Mal versendet`}
                        </span>
                      </div>
                    )}
                    {this.counterJustSent > 0 && (
                      <div className='flex items-center'>
                        <div
                          className='bg-indigo-500 w-4 h-4  mr-2'
                          style={{ borderRadius: '4px' }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <span>
                          {`${this.counterJustSent} ${
                            this.counterJustSent === 1 ? 'Plan wurde' : 'Pläne wurden'
                          } gerade eben versendet`}
                        </span>
                      </div>
                    )}
                    {this.counterNotYetSent > 0 && (
                      <div className='flex items-center'>
                        <div
                          className='bg-gray-300 w-4 h-4 mr-2'
                          style={{ borderRadius: '4px' }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <span>
                          {`${this.counterNotYetSent} ${
                            this.counterNotYetSent === 1 ? 'Plan wurde' : 'Pläne wurden'
                          } noch nicht versendet`}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div
          className='flex gap-2 justify-end py-4 px-6 -mb-6 -mx-6 sticky z-1 text-right bottom-0 bg-white border-t border-gray-200'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          {this.counterPreviouslySentInitial > 0 && (
            <InputCheckbox
              model={this.model}
              name='resend'
              label='Erneut senden'
              disabled={this.step !== 'initial'}
            />
          )}
          <Button color='secondary' onClick={this.dismiss}>
            {this.step === 'sending' ? 'Abbrechen' : 'Schließen'}
          </Button>
          {this.step === 'initial' && this.props.employees.length > 0 && (
            <Button
              color='primary'
              onClick={this.send}
              disabled={this.counterNotYetSent === 0}
            >
              Versenden
            </Button>
          )}
        </div>
      </div>
    )
  }
}
