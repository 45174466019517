import { z } from 'zod'
import { isIntegerString } from '../../general/helpers/isIntegerString'
import { isTime } from '../../general/helpers/isTime'
import { isYmdDate } from '../../general/helpers/isYmdDate'

export const ShiftValidator = z.object({
  id: z.string().refine(isIntegerString),
  type: z.enum(['shift', 'training', 'on call']), // on call: Bereitschaft
  label: z.string().min(1).max(30),
  comment: z.string(),
  maxNumParticipants: z.number(),
  minNumFemaleParticipants: z.number().nullable(),
  minNumMaleParticipants: z.number().nullable(),
  participantValidation: z.enum(['exact', 'max']), // Show day in red if participants exceed 'max' or are not equal to 'exact'
  hasBreak: z.boolean(),
  begin: z.string().refine(isTime), // 09:00
  end: z.string().refine(isTime), // 17:00
  shortcuts: z.any(), // { [shortcut: string]: string } // { "x": { "hasBreak": true, timeInterval: "15:00 - 18:00", breakIntervals: "16:00-16:30,17:45-18:00" }, "xs": { hasBreak: false, timeInterval: "15:30 - 18:00", breakIntervals: "" }, ... } Alternative shift times and respective shortcuts for assignment
  recovery: z.number(), // wait time after shift that cannot be planned for another shift ("Ruhezeit")
  buildingId: z.string().refine(isIntegerString).nullable(),
  abbreviation: z.string(),
  color: z.string().nullable(),

  repeatMon: z.boolean(),
  repeatTue: z.boolean(),
  repeatWed: z.boolean(),
  repeatThu: z.boolean(),
  repeatFri: z.boolean(),
  repeatSat: z.boolean(),
  repeatSun: z.boolean(),
  repeatHoliday: z.enum(['include', 'exclude']).nullable(),
  repeatStartDate: z.string().refine(isYmdDate), // 2019-01-01
  repeatStopDate: z.string().refine(isYmdDate).nullable(), // 2019-01-01

  holidayRegion: z.string(),
  shiftCreatedByUserId: z.string().refine(isIntegerString).nullable(),
  shiftEditedByUserId: z.string().refine(isIntegerString).nullable(), // if no edit yet, it contains create user id
  shiftLastEditTimestamp: z.number(), // if no edit yet, it contains create timestamp
  participantsOverrideDays: z.any(), // saves day overrides in json: { "0": null | number of participants } from 0 (sunday) to 6 (saturday)
})
