import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { FestschreibungRow } from './FestschreibungRow'
import { Button } from 'components/Form/components/Button'
import { Spinner } from 'components/Spinner'
import { hermes } from '@byll/hermes'
import { IWorkplanFestschreibung } from 'contracts/workplan/interfaces/IWorkplanFestschreibung'
import { AppContext } from 'services/connection/models/AppContext'
import { Message } from 'components/Message'
import { ConflictError } from 'contracts/errors/HermesErrors'

interface Props {
  companyId: string
  month: string
}

@observer
export class Festschreibungsliste extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private error: string | null = null
  @observable private loading = true
  @observable.ref private festschreibungen: IWorkplanFestschreibung[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.load()
  }

  private load = async () => {
    runInAction(() => (this.loading = true))
    const data = await hermes.indexOnceNew<IWorkplanFestschreibung>(
      `/api/${this.context.instance.id}/workplan/workplanFestschreibungen?companyId=${this.props.companyId}&month=${this.props.month}`,
    )
    runInAction(() => {
      this.festschreibungen = data
      this.loading = false
    })
  }

  private createFestschreibung = async () => {
    runInAction(() => {
      this.loading = true
      this.error = null
    })
    try {
      await hermes.create(
        `/api/${this.context.instance.id}/workplan/workplanFestschreibungen`,
        { companyId: this.props.companyId, month: this.props.month },
      )
      runInAction(() => (this.loading = false))
      this.load()
    } catch (e: any) {
      runInAction(() => (this.loading = false))
      if (e.id === ConflictError.id) {
        runInAction(() => (this.error = e.message))
      } else {
        alert('Die Festschreibung ist leider fehlgeschlagen.')
      }
    }
  }

  private deleteFestschreibung = async (festschreibung: IWorkplanFestschreibung) => {
    if (
      !window.confirm('Möchten Sie diese Festschreibung wirklich unwiderruflich löschen?')
    ) {
      return
    }
    runInAction(() => (this.loading = true))
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/workplan/workplanFestschreibungen/${festschreibung.id}?companyId=${this.props.companyId}&month=${this.props.month}`,
      )
      runInAction(() => {
        this.loading = false
      })
      this.load()
    } catch (_e) {
      runInAction(() => (this.loading = false))
      alert('Die Festschreibung konnte leider nicht gelöscht werden.')
    }
  }

  private mapFestschreibung = (festschreibung: IWorkplanFestschreibung) => (
    <FestschreibungRow
      key={festschreibung.id}
      festschreibung={festschreibung}
      onDelete={this.deleteFestschreibung}
    />
  )

  render() {
    if (this.loading) {
      return <Spinner />
    }
    return (
      <div className='my-3'>
        {this.festschreibungen.map(this.mapFestschreibung)}

        {this.error && (
          <Message className='mb-3' color='danger'>
            {this.error}
          </Message>
        )}

        <Button color='secondary' onClick={this.createFestschreibung}>
          <i className='fa fa-plus' /> Neue Festschreibung
        </Button>
      </div>
    )
  }
}
