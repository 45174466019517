import { hermes } from '@byll/hermes'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { Message } from 'components/Message'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { IMonthlyEmployeeWorkScheduleFilter } from 'contracts/workplan/interfaces/IMonthlyEmployeeWorkScheduleFilter'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import { IShiftBonusType } from 'contracts/workplan/interfaces/IShiftBonusType'
import { IShiftBuildingBonusType } from 'contracts/workplan/interfaces/IShiftBuildingBonusType'
import { IShiftCompany } from 'contracts/workplan/interfaces/IShiftCompany'
import { IShiftParticipantOverride } from 'contracts/workplan/interfaces/IShiftParticipantOverride'
import { IShiftQualification } from 'contracts/workplan/interfaces/IShiftQualification'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { ShiftValidator } from 'contracts/workplan/validators/ShiftValidator'
import { dayjs } from 'helpers/dayjs'
import { uniqueId } from 'helpers/uniqueId'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { ShiftBonusTypesForm } from 'modules/Shifts/components/ShiftDialog/components/ShiftDialogBonusesTab/components/ShiftBonusTypesForm'
import { OverrideList } from 'modules/Shifts/components/ShiftDialog/components/ShiftDialogOverridesTab/components/DateOverrides/components/OverrideList'
import { DayOverridesForm } from 'modules/Shifts/components/ShiftDialog/components/ShiftDialogOverridesTab/components/DayOverrides/components/DayOverridesForm'
import { ShiftOverviewForm } from 'modules/Shifts/components/ShiftDialog/components/ShiftDialogOverviewTab/components/ShiftOverviewForm'
import {
  IShortcut,
  shortcutLabels,
} from 'modules/Shifts/components/ShiftDialog/components/ShiftDialogOverviewTab/components/ShortcutRow'
import { calculateRecovery } from 'modules/Shifts/components/ShiftDialog/components/ShiftDialogOverviewTab/helpers/calculateRecovery'
import { getShiftShortcuts } from 'modules/Shifts/components/ShiftDialog/components/ShiftDialogOverviewTab/helpers/getShiftShortcuts'
import { getShortcutsStructure } from 'modules/Shifts/components/ShiftDialog/components/ShiftDialogOverviewTab/helpers/getShortcutsStructure'
import { hoursMinutesToMinutes } from 'modules/Shifts/components/ShiftDialog/components/ShiftDialogOverviewTab/helpers/hoursMinutesToMinutes'
import { ShiftQualificationsForm } from 'modules/Shifts/components/ShiftDialog/components/ShiftDialogQualificationsTab/components/ShiftQualificationsForm'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { z } from 'zod'

const tabs = [
  { label: 'Übersicht', value: 'overview' },
  { label: 'Teilnehmerabweichungen', value: 'overrides' },
  { label: 'Anforderungsprofil', value: 'qualifications' },
  { label: 'Zulagen', value: 'bonuses' },
]

interface Props {
  shift: IShift
  filterModel: Model<IMonthlyEmployeeWorkScheduleFilter>
  buildings: IBuilding[]
  onClose: (result?: boolean) => void
}

@observer
export class EditShiftDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private shiftSearchResult: IShiftSearchResult
  @observable private saving = false
  @observable private error: string | null = null
  @observable private shortcuts: IShortcut[] = []
  @observable private tab: 'overview' | 'overrides' | 'qualifications' | 'bonuses' =
    'overview'
  @observable private shiftCompanies: IShiftCompany[] | null = null
  @observable private shiftQualifications: IShiftQualification[] | null = null
  @observable private dateOverrides: IShiftParticipantOverride[] | null = null
  @observable private shiftBonusTypes: IShiftBonusType[] | null = null
  @observable private shiftBuildingBonusTypes: IShiftBuildingBonusType[] | null = null
  private model: Model<IShiftSearchResult>

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.shiftSearchResult = {
      ...props.shift,
      buildingLabel: '',
      compoundLabel: '',
      compoundId:
        props.buildings.find((b) => b.id === props.shift.buildingId)?.compoundId || '',
      targetPlannedParticipants: 0,
      actualPlannedParticipants: 0,
    }
    this.model = new Model(
      {
        ...this.shiftSearchResult,
        recovery: calculateRecovery(props.shift.recovery),
        maxNumParticipants: String(props.shift.maxNumParticipants),
      },
      ShiftValidator.omit({ id: true, begin: true, end: true }).extend({
        recovery: z.string(),
        maxNumParticipants: z.string(),
        buildingId: z.string().min(1),
      }),
    )
    this.shortcuts = getShortcutsStructure(this.shiftSearchResult)
  }

  componentDidMount(): void {
    void this.loadShiftCompaniesAndQualifications()
    void this.loadDateOverrides()
    void this.loadBonusTypes()
  }

  private loadShiftCompaniesAndQualifications = async () => {
    const companies = await hermes.indexOnceNew<IShiftCompany>(
      `/api/${this.context.instance.id}/workplan/shiftCompanies?shiftId=${this.props.shift.id}`,
    )
    const quals = await hermes.indexOnceNew<IShiftQualification>(
      `/api/${this.context.instance.id}/workplan/shiftQualifications?shiftId=${this.props.shift.id}`,
    )
    runInAction(() => {
      this.shiftCompanies = companies
      this.shiftQualifications = quals
    })
  }

  private loadDateOverrides = async () => {
    try {
      const res = await hermes.indexOnceNew<IShiftParticipantOverride>(
        `/api/${this.context.instance.id}/workplan/shiftParticipantOverrides?shiftId=${this.props.shift.id}&month=${this.props.filterModel.values.month}`,
      )
      runInAction(() => (this.dateOverrides = res))
    } catch (e: any) {
      runInAction(
        () =>
          (this.error =
            'Beim Laden der Abweichungen ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.'),
      )
    }
  }

  private loadBonusTypes = async () => {
    const bonusTypes = await hermes.indexOnceNew<IShiftBonusType>(
      `/api/${this.context.instance.id}/workplan/shiftBonusTypes?shiftId=${this.props.shift.id}`,
    )
    const buildingBonusTypes = await hermes.indexOnceNew<IShiftBuildingBonusType>(
      `/api/${this.context.instance.id}/workplan/shiftBuildingBonusTypes?shiftId=${this.props.shift.id}`,
    )
    runInAction(() => {
      this.shiftBonusTypes = bonusTypes
      this.shiftBuildingBonusTypes = buildingBonusTypes
    })
  }

  private saveShift = async () => {
    try {
      if (!this.model.isValid()) {
        this.model.setFocusToLeftTopmostInvalidField()
        return
      }
      const recovery = hoursMinutesToMinutes(this.model.values.recovery as any)
      if (!recovery) {
        runInAction(
          () =>
            (this.error =
              'Bitte geben Sie eine gültige Ruhezeit zwischen 0 und 24 Stunden ein.'),
        )
        return
      }
      runInAction(() => {
        this.error = null
      })
      if (!this.shortcuts[0].begin || !this.shortcuts[0].end) {
        runInAction(
          () =>
            (this.error = 'Bitte geben Sie die Anfangs- und Endzeiten der Schicht an.'),
        )
        return
      }
      const shortcuts = getShiftShortcuts(this.shortcuts)
      // Save the shift
      const shift: Omit<IShift, 'holidayRegion'> = {
        id: this.model.values.id,
        type: this.model.values.type,
        label: this.model.values.label,
        abbreviation: this.model.values.abbreviation,
        color: this.model.values.color,
        comment: this.model.values.comment,
        maxNumParticipants: Number(this.model.values.maxNumParticipants),
        minNumFemaleParticipants: this.model.values.minNumFemaleParticipants,
        minNumMaleParticipants: this.model.values.minNumMaleParticipants,
        participantValidation: this.model.values.participantValidation,
        hasBreak: this.shortcuts[0].hasBreak,
        begin: this.shortcuts[0].begin,
        end: this.shortcuts[0].end,
        shortcuts,
        recovery,
        buildingId: this.model.values.buildingId,
        repeatMon: this.model.values.repeatMon,
        repeatTue: this.model.values.repeatTue,
        repeatWed: this.model.values.repeatWed,
        repeatThu: this.model.values.repeatThu,
        repeatFri: this.model.values.repeatFri,
        repeatSat: this.model.values.repeatSat,
        repeatSun: this.model.values.repeatSun,
        repeatHoliday: this.model.values.repeatHoliday,
        repeatStartDate: this.model.values.repeatStartDate,
        repeatStopDate: this.model.values.repeatStopDate,
        participantsOverrideDays: this.model.values.participantsOverrideDays,
        shiftCreatedByUserId: this.model.values.shiftCreatedByUserId,
        shiftEditedByUserId: this.model.values.shiftEditedByUserId,
        shiftLastEditTimestamp: this.model.values.shiftLastEditTimestamp,
      }
      if (!this.model.values.id) {
        await hermes.create(`/api/${this.context.instance.id}/workplan/shifts`, {
          shift,
          month: this.props.filterModel.values.month,
        })
      } else {
        await hermes.patch(
          `/api/${this.context.instance.id}/workplan/shifts/${this.model.values.id}`,
          {
            shift,
            month: this.props.filterModel.values.month,
            scheduleId: `${this.props.filterModel.values.employeePoolId}-${this.props.filterModel.values.month}-${this.props.filterModel.values.type}`,
          },
        )
      }
      await this.saveQualifications()
      await this.saveDayOverrides()
      await this.saveBonusTypes()

      this.props.onClose(true)
    } catch (e: any) {
      if (e.id && e.id === ConflictError.id) {
        runInAction(() => (this.error = e.message))
      } else {
        runInAction(
          () =>
            (this.error =
              'Beim Speichern der Schicht ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.'),
        )
      }
    }
  }

  private saveQualifications = async () => {
    const companyIds = new Set(
      this.shiftCompanies!.filter((c) => !!c.companyId).map((c) => c.companyId),
    )
    await hermes.create(`/api/${this.context.instance.id}/workplan/shiftCompanies`, {
      shiftId: this.props.shift.id,
      companyIds: Array.from(companyIds),
    })
    const qualificationIds = new Set(
      this.shiftQualifications!.filter((q) => !!q.qualificationId).map(
        (q) => q.qualificationId,
      ),
    )
    await hermes.create(`/api/${this.context.instance.id}/workplan/shiftQualifications`, {
      shiftId: this.props.shift.id,
      qualificationIds: Array.from(qualificationIds),
    })
  }

  private saveDayOverrides = async () => {
    if (!this.dateOverrides) {
      return
    }
    try {
      runInAction(() => (this.error = null))
      if (
        this.dateOverrides.some(
          (override) =>
            dayjs(override.date).isBefore(this.props.filterModel.values.month, 'month') ||
            dayjs(override.date).isAfter(this.props.filterModel.values.month, 'month'),
        )
      ) {
        runInAction(
          () =>
            (this.error = 'Das Datum der Abweichung liegt nicht im ausgewählten Monat.'),
        )
        return
      }
      for (const override of this.dateOverrides) {
        if (override.participants) {
          runInAction(() => (override.participants = +override.participants))
        }
      }
      await hermes.create(
        `/api/${this.context.instance.id}/workplan/shiftParticipantOverrides`,
        {
          shiftId: this.props.shift.id,
          month: this.props.filterModel.values.month,
          overrides: this.dateOverrides,
        },
      )
    } catch (e: any) {
      runInAction(
        () =>
          (this.error =
            'Beim Speichern der Abweichungen ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.'),
      )
    }
  }

  private saveBonusTypes = async () => {
    if (!this.shiftBonusTypes || !this.shiftBuildingBonusTypes) {
      return
    }
    try {
      await hermes.create(`/api/${this.context.instance.id}/workplan/shiftBonusTypes`, {
        shiftId: this.props.shift.id,
        bonusTypes: this.shiftBonusTypes.map((b) => ({
          schichtzulageId: b.schichtzulageId,
          notes: b.notes,
        })),
      })
      await hermes.create(
        `/api/${this.context.instance.id}/workplan/shiftBuildingBonusTypes`,
        {
          shiftId: this.props.shift.id,
          bonusTypes: this.shiftBuildingBonusTypes.map((b) => ({
            schichtzulageId: b.schichtzulageId,
            notes: b.notes,
          })),
        },
      )
    } catch (e: any) {
      runInAction(
        () => (this.error = 'Beim Speichern der Zulagen ist ein Fehler aufgetreten.'),
      )
    }
  }

  @action private addShortcut = () => {
    const letters = new Set<string>()
    for (const shortcut of this.shortcuts) {
      letters.add(shortcut.label)
    }
    for (const label of shortcutLabels) {
      if (letters.has(label)) {
        continue
      }
      this.shortcuts.push({
        key: uniqueId('s'),
        label,
        begin: '',
        end: '',
        hasBreak: true,
        breakIntervals: '',
      })
      return
    }
  }

  render() {
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start mb-4'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Schicht bearbeiten
            </Dialog.Title>
          </div>
        </div>

        {this.error && (
          <Message color='danger' className='my-4'>
            {this.error}
          </Message>
        )}

        <div className='border-y border-gray-300 -mx-6 bg-gray-100'>
          <div
            className='flex flex-row mx-6 mt-6 w-fit shadow-md'
            style={{ borderRadius: '0.375rem 0.375rem 0 0' }}
          >
            {tabs.map((tab) => (
              <button
                key={tab.value}
                onClick={action(() => (this.tab = tab.value as any))}
                className={`${
                  this.tab === tab.value
                    ? 'text-indigo-500 bg-white'
                    : 'text-gray-500 bg-gray-200'
                } px-4 py-2 text-sm font-medium`}
              >
                {tab.label}
              </button>
            ))}
          </div>
          <div
            id={this.model.id}
            className='p-6 mx-6 mb-6 bg-white shadow-md'
            style={{ borderRadius: '0 0.375rem 0.375rem 0.375rem' }}
          >
            {this.tab === 'overview' && (
              <ShiftOverviewForm
                model={this.model}
                filterModel={this.props.filterModel}
                shortcuts={this.shortcuts}
                onAdd={this.addShortcut}
              />
            )}
            {this.tab === 'overrides' && (
              <div>
                <div>
                  <span className='text-gray-600'>Abweichung - Datum</span>
                  {this.dateOverrides && (
                    <OverrideList
                      shift={this.model.values}
                      month={this.props.filterModel.values.month}
                      overrides={this.dateOverrides}
                    />
                  )}
                </div>
                <div className='bg-gray-300 h-[1px] my-4 -mx-6' />
                <div>
                  <span className='mb-4 text-gray-600'>Abweichung - Wochentag</span>
                  <DayOverridesForm
                    model={
                      new Model(
                        this.props.shift.participantsOverrideDays.hasOwnProperty('0')
                          ? this.props.shift.participantsOverrideDays
                          : {
                              0: null,
                              1: null,
                              2: null,
                              3: null,
                              4: null,
                              5: null,
                              6: null,
                              7: null,
                            },
                      )
                    }
                  />
                </div>
              </div>
            )}
            {this.tab === 'qualifications' &&
              this.shiftCompanies &&
              this.shiftQualifications && (
                <ShiftQualificationsForm
                  shift={this.shiftSearchResult}
                  shiftCompanies={this.shiftCompanies}
                  shiftQualifications={this.shiftQualifications}
                />
              )}
            {this.tab === 'bonuses' &&
              this.shiftBonusTypes &&
              this.shiftBuildingBonusTypes && (
                <ShiftBonusTypesForm
                  shift={this.model.values}
                  shiftBonusTypes={this.shiftBonusTypes}
                  shiftBuildingBonusTypes={this.shiftBuildingBonusTypes}
                />
              )}
          </div>
        </div>

        <div className='flex flex-row-reverse justify-between mt-4'>
          <div>
            <Button color='secondary' outline onClick={() => this.props.onClose()}>
              Abbrechen
            </Button>
            <Button
              color='primary'
              disabled={this.saving}
              className='ml-2'
              onClick={this.saveShift}
            >
              Speichern
            </Button>
          </div>
        </div>

        {this.saving && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
