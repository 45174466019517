import React from 'react'
import { Model } from 'components/Form/Model'
import { InputText } from 'components/Form/components/InputText'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { z } from 'zod'
import { observer } from 'mobx-react'
import { Button } from 'components/Form/components/Button'
import { RoundIcon } from '../../components/RoundIcon'
import { InputDropdown } from 'components/Form/components/InputDropdown'
import { InputDocument } from 'components/Form/components/InputDocument'
import { CircularProgressbar } from 'components/CircularProgressbar'
import { Tooltip } from 'components/Tooltip'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import dayjs from 'dayjs'
import { InputDate } from 'components/Form/components/InputDate'
import {
  InputMultiSelect,
  InputMultiSelectOption,
} from 'components/Form/components/InputMultiSelect'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { InputBlock } from 'components/Form/components/InputBlock'
import { InputDocumentMultiple } from 'components/Form/components/InputDocumentMultiple'
import { HermesResourceChangeHandlerExample } from './components/HermesResourceChangeHandlerExample'
import { HermesCollectionChangeHandlerExample } from './components/HermesCollectionChangeHandlerExample'
import { ColorPicker } from 'components/ColorPicker'
import { InputColor } from 'components/Form/components/InputColor'

const User = z.object({
  firstName: z.string().min(1),
  email: z.string().email().or(z.string().max(0)),
  dateOfBirth: z.string(),
  multi: z.string(),
})
// type IUser = z.infer<typeof User>

const yesNo = [
  { value: 'null', label: '-' },
  { value: 'false', label: 'Nein' },
  { value: 'true', label: 'Ja' },
]

const options: InputMultiSelectOption[] = [
  { id: '1', label: 'Chocolate' },
  { id: '2', label: 'Strawberry' },
  { id: '3', label: 'Vanilla' },
]

const mandatoryFields = [
  {
    key: 'vorname',
    label: 'Vorname',
    type: 'text',
    colspan: 1,
    required: true,
    options: [],
  },
  {
    key: 'nachname',
    label: 'Nachname',
    type: 'text',
    colspan: 1,
    required: true,
    options: [],
  },
  {
    key: 'email',
    label: 'E-Mail',
    type: 'text',
    colspan: 1,
    required: true,
    options: [],
  },
  {
    key: 'geburtsdatum',
    label: 'Geburtsdatum',
    type: 'date',
    colspan: 1,
    required: true,
    options: [],
  },
]

interface Props {}

@observer
export class Showroom extends React.Component<Props, {}> {
  static contextType = AppContext
  model: Model<any>
  value

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.model = new Model(
      {
        firstName: 'Test',
        lastName: 'Mustermann',
        email: '',
        multi: ['2', '1'],
        dec: null,
        select: null,
        file: null,
        documents: 'b8cea07e-614c-4b8a-be10-f4a4c39c3f8e',
        dateOfBirth: null,
        color: '',
        customFields: [
          {
            neu_0: 'Test',
            test: '2024-01-01',
            test_1: '2024-01-01',
            select: 'A',
          },
          {
            neu_0: 'Test2',
            test: '2024-01-02',
            test_1: '2024-01-02',
            select: 'B',
          },
        ],
      },
      User,
    )
  }

  private download = () => {
    createAndDownloadReport('bewohnerstatistik', '0', {
      compoundId: '1',
      date: dayjs(new Date()).toISOString(),
    })
  }

  private downloadCurrentVisitors = () => {
    createAndDownloadReport('current-visitors', '0', {
      compoundId: '1',
      timestamp: dayjs(new Date()),
    })
  }

  private downloadVisitProtocol = () => {
    createAndDownloadReport('scan-protocol', '0', { compoundId: '1' })
  }

  render() {
    return (
      <div className='max-w-[1100px] mx-auto pt-20'>
        <HermesCollectionChangeHandlerExample />
        <HermesResourceChangeHandlerExample />

        <div className='mr-auto ml-auto'>
          <InputMultiSelect model={this.model} name='multi' options={options} />

          <InputText
            className='mt-6'
            name='email'
            type='email'
            placeholder='E-Mail'
            model={this.model}
            label='E-Mail'
            tooltip={(e) =>
              e ? 'Bitte geben Sie eine gültige E-Mail Adresse an' : 'E-Mail'
            }
          />

          <InputDecimal
            className='mt-6'
            name='dec'
            model={this.model}
            label='Decimal'
            precision={4}
            scale={2}
          />

          <InputDropdown
            className='mt-6'
            model={this.model}
            label='Select'
            name='select'
            options={yesNo}
          />

          <InputDropdown
            className='mt-6'
            model={this.model}
            label='Select'
            name='select'
            options={yesNo}
          />

          <InputDate
            className='mt-6'
            model={this.model}
            label='Datum'
            name='dateOfBirth'
          />

          <InputDate
            className='mt-6'
            model={this.model}
            label='Datum'
            name='dateOfBirth'
            disabled
          />

          <InputDocument
            scope='employee'
            className='mt-6'
            model={this.model}
            label='Upload'
            name='file'
          />

          <InputColor className='mt-6' model={this.model} name='color' />

          <ColorPicker
            className='mt-6'
            model={this.model}
            name='color'
            onClose={() => {}}
          />

          <InputDocumentMultiple
            scope='section'
            className='my-6'
            model={this.model}
            label='Upload multiple'
            name='documents'
            preview
            disabled
          />

          <div className='py-6 bg-gray-100'>
            <InputBlock
              id='residents-healthdata'
              name='customFields'
              model={this.model}
              label='Persönliche Angaben'
              mandatoryFields={mandatoryFields}
              multiple
              editable
            />
          </div>

          <div className='mt-4'>
            <Button small color='primary'>
              primary
            </Button>{' '}
            <Button small color='secondary'>
              secondary
            </Button>{' '}
            <Button small color='success'>
              success
            </Button>{' '}
            <Button small color='info'>
              info
            </Button>{' '}
            <Button small color='warning'>
              warning
            </Button>{' '}
            <Button small color='danger'>
              danger
            </Button>{' '}
          </div>

          <div className='mt-4'>
            <Button color='primary'>primary</Button>{' '}
            <Button color='secondary'>secondary</Button>{' '}
            <Button color='success'>success</Button> <Button color='info'>info</Button>{' '}
            <Button color='warning'>warning</Button>{' '}
            <Button color='danger'>danger</Button>{' '}
          </div>

          <div className='mt-4'>
            <Button onClick={this.download} outline color='primary'>
              Belegungsplan
            </Button>{' '}
            <Button onClick={this.downloadCurrentVisitors} outline color='secondary'>
              Aktuelle Besucher
            </Button>{' '}
            <Button onClick={this.downloadVisitProtocol} outline color='success'>
              success
            </Button>{' '}
            <Button outline color='info'>
              info
            </Button>{' '}
            <Button outline color='warning'>
              warning
            </Button>{' '}
            <Button outline color='danger'>
              danger
            </Button>{' '}
          </div>

          <div className='mt-4'>
            <RoundIcon color='white' icon='fas fa-camera' />{' '}
            <RoundIcon color='primary' icon='fas fa-camera' />{' '}
            <RoundIcon color='danger' icon='fas fa-camera' />{' '}
          </div>

          <div style={{ width: 200 }}>
            <CircularProgressbar percentage={0.3} strokeWidth={4} />
          </div>

          <span className='has-tooltip'>
            Hover me <Tooltip>Hallo Welt</Tooltip>
          </span>
          <div style={{ height: 1100 }} />
          {/*<InputText name='firstName' model={this.model} label='Vorname' />
          <MyPopover />*/}
        </div>
      </div>
    )
  }
}
