import { z } from 'zod'
import { isYmdDate } from '../../general/helpers/isYmdDate'
import { isIntegerString } from '../../general/helpers/isIntegerString'

const hourFormatRegEx =
  /^(|([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]-([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9])(,([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]-([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9])?$/

export const ShiftCellPatchValidator = z.object({
  date: z.string().refine(isYmdDate),
  shiftId: z.string().refine(isIntegerString),
  userId: z.string().refine(isIntegerString),
  employeePoolId: z.string().refine(isIntegerString),
  viewId: z.string().refine(isIntegerString),
  type: z.enum(['plan', 'real']),
  break: z.string().regex(hourFormatRegEx).optional(),
  notes: z.string().optional(),
})
