import { ConflictError } from 'contracts/errors/HermesErrors'
import { isTime } from 'contracts/general/helpers/isTime'
import { getBreakIntervals } from 'contracts/workplan/helpers/getBreakIntervals'
import { getShiftBeginTime } from 'contracts/workplan/helpers/getShiftBeginTime'
import { getShiftEndTime } from 'contracts/workplan/helpers/getShiftEndTime'
import { parseTimeInterval } from 'contracts/workplan/helpers/parseTimeInterval'
import { runInAction } from 'mobx'

export function getShiftShortcuts(s): {
  [shortcut: string]: { timeInterval: string; breakIntervals: string; hasBreak: boolean }
} {
  const shortcuts: {
    [shortcut: string]: {
      timeInterval: string
      breakIntervals: string
      hasBreak: boolean
    }
  } = {} // shortcut => times, breakIntervals, hasBreak
  for (const shortcut of s) {
    if (shortcuts[shortcut.label]) {
      throw new ConflictError('Bitte vergeben Sie jedes Kürzel nur einmal.', {})
    }
    if (!isTime(shortcut.begin) || !isTime(shortcut.end)) {
      throw new ConflictError('Bitte geben Sie für alle Kürzel gültige Zeiten an.', {})
    }
    const interval = `${
      shortcut.begin.length === 4 ? `0${shortcut.begin}` : shortcut.begin
    } - ${shortcut.end.length === 4 ? `0${shortcut.end}` : shortcut.end}`
    if (shortcuts[interval]) {
      throw new ConflictError(
        'Bitte geben Sie für jedes Kürzel unterschiedliche Zeiten an.',
        {},
      )
    }
    if (shortcut.hasBreak && !shortcut.breakIntervals) {
      const timeInterval = parseTimeInterval(interval)
      runInAction(() => {
        shortcut.breakIntervals = getBreakIntervals(
          getShiftBeginTime('2024-01-01', timeInterval!.begin),
          getShiftEndTime('2024-01-01', timeInterval!.begin, timeInterval!.end),
          shortcut.hasBreak,
        )
      })
    }
    shortcuts[shortcut.label] = {
      timeInterval: interval,
      breakIntervals: shortcut.breakIntervals
        .split(',')
        .map((i) => i.trim())
        .join(','),
      hasBreak: shortcut.hasBreak,
    }
  }
  return shortcuts
}
