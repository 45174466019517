import * as React from 'react'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from '../../Model'
import { uniqueId } from 'helpers/uniqueId'
import { classNames } from 'helpers/classNames'
import { Collection, Resource } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IShift } from 'contracts/workplan/interfaces/IShift'

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: string
  model: Model<any>
  className?: string
  children?: Element
  filter?: {
    month?: string
    buildingId?: string
    includeBuildingPathInLabels?: 'yes' | 'no'
  }
  onChange?: (event: React.FormEvent<HTMLSelectElement>) => void
}

@observer
export class InputShift extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly id: string
  private readonly shifts: Collection<IShift>
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.id = props.id || uniqueId('shift-select-')
    this.shifts = new Collection(`/api/${context.instance.id}/workplan/shifts`, {
      month: props.filter?.month,
      buildingId: props.filter?.buildingId,
      includeBuildingPathInLabels: props.filter?.includeBuildingPathInLabels,
    })
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.shifts.init({ readOnly: true, observeQuery: true }))
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action
  private onChange = (event: React.FormEvent<HTMLSelectElement>) => {
    if (!(event.target instanceof HTMLSelectElement)) {
      return
    }
    const target: HTMLSelectElement = event.target
    this.props.model.values[this.props.name] = target.value || null
    this.props.onChange?.(event)
  }

  private optionMapper = (res: Resource<IShift>) => {
    if (!res.data) {
      return null
    }
    return (
      <option key={res.id} value={res.id}>
        {res.data.label}
      </option>
    )
  }

  render() {
    let innerClassName =
      'block w-full shadow-sm text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md'
    const { name, model, label, className, children, ...attributes } = this.props
    const touched = !!model.touched.get(name)
    const validator = model.validators.get(name)
    const error = !(validator?.safeParse(model.values[name]).success ?? true)

    if (touched && error) {
      innerClassName =
        'block w-full shadow-sm text-sm focus:ring-red-500 focus:border-red-500 border-red-500 rounded-md'
    }

    if (attributes.disabled) {
      innerClassName += ' bg-gray-100'
    }

    return (
      <div className={classNames('relative', className)}>
        {label && (
          <label
            htmlFor={this.id}
            className='absolute -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-400'
            style={{ left: 9, top: -7, zIndex: 1 }}
          >
            {label}
          </label>
        )}
        <select
          {...attributes}
          className={innerClassName}
          name={name}
          onChange={this.onChange}
          value={model.values[name] || ''}
          id={this.id}
        >
          <option key='null' value=''>
            Schicht wählen
          </option>
          {this.shifts.resources && this.shifts.resources.map(this.optionMapper)}
        </select>
        {children}
      </div>
    )
  }
}
