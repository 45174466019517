import { ColorPicker } from 'components/ColorPicker'
import { Model } from 'components/Form/Model'
import { classNames } from 'helpers/classNames'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  model: Model<any>
  name: string
  className?: string
  readOnly?: boolean
  position?: 'top' | 'bottom'
}

const positions = {
  top: 'bottom-5',
  bottom: 'top-5 right-0',
}

@observer
export class InputColor extends React.Component<Props, {}> {
  @observable showPicker = false

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private togglePicker = () => {
    if (this.props.readOnly) {
      return
    }
    this.showPicker = !this.showPicker
  }

  render() {
    const { model, name, className, position } = this.props
    const color = this.props.model.values[this.props.name]
    const rainbowBackground = `
      linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),
      linear-gradient(127deg, rgba(0,255,0,.8), rgba(0,255,0,0) 70.71%),
      linear-gradient(336deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.71%)
    `
    return (
      <div className={classNames('relative', className)}>
        <div
          onClick={this.togglePicker}
          className={`h-5 w-5 ${
            this.props.readOnly ? 'cursor-auto' : 'cursor-pointer'
          } rounded-full shadow-md`}
          style={{ background: color ? color : rainbowBackground }}
        />
        {this.showPicker && (
          <ColorPicker
            className={`absolute ${positions[position || 'top']} z-10`}
            model={model}
            name={name}
            onClose={this.togglePicker}
          />
        )}
      </div>
    )
  }
}
