import { Model } from 'components/Form/Model'
import { InputDate } from 'components/Form/components/InputDate'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { InputDocument } from 'components/Form/components/InputDocument'
import { InputDropdownEntry } from 'components/Form/components/InputDropdownEntry'
import { InputSelect } from 'components/Form/components/InputSelect'
import { RoundIcon } from 'components/RoundIcon'
import { IEmployeeContractTerm } from 'contracts/employees/interfaces/IEmployeeContractTerm'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  index: number
  contractTerms: IEmployeeContractTerm
  onDelete: (id: string) => void
}

@observer
export class ContractTermsRow extends React.Component<Props, {}> {
  private readonly model: Model<IEmployeeContractTerm>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.contractTerms)
  }

  private onDelete = (e) => {
    e.preventDefault()
    this.props.onDelete(this.props.contractTerms.id)
  }

  render() {
    return (
      <div
        className={`py-4 grid grid-cols-4 ${
          this.props.index % 2 === 1 ? 'px-4' : ''
        } gap-4 relative group`}
      >
        <InputDate model={this.model} name='validFrom' label='Gültig ab' />
        <InputDecimal
          model={this.model}
          name='workingHours'
          label='Arbeitszeit [Std.]'
          precision={10}
          scale={2}
        />
        <InputSelect
          model={this.model}
          name='workingHoursInterval'
          label='Pro Woche/Monat'
          options={[
            { value: '', label: '' },
            { value: 'week', label: 'pro Woche' },
            { value: 'month', label: 'pro Monat' },
          ]}
        />
        <InputDecimal
          model={this.model}
          name='workdaysPerWeek'
          label='Tage p. Woche'
          precision={3}
          scale={2}
        />
        <InputDecimal
          model={this.model}
          name='wage'
          label='Lohn'
          precision={8}
          scale={2}
          postfix='€'
        />
        <InputSelect
          model={this.model}
          name='wageInterval'
          options={[
            { value: '', label: '' },
            { value: 'hour', label: 'pro Stunde' },
            { value: 'month', label: 'pro Woche' },
          ]}
        />
        <InputDecimal
          model={this.model}
          name='vacationDaysPerYear'
          label='Urlaubstage'
          precision={2}
          scale={0}
        />
        <InputDropdownEntry
          model={this.model}
          name='lohngruppenId'
          label='Lohnart'
          type='personnelRecordContracts.Lohnart'
        />
        <InputDropdownEntry
          model={this.model}
          name='salaryGroup'
          label='Gehaltsgruppe'
          type='personnelRecordContracts.Gehaltsgruppe'
        />
        <InputDocument
          className='col-span-3'
          model={this.model}
          name='documentId'
          label='Dokument'
          scope='employee'
        />
        <div
          className={`absolute top-2 ${
            this.props.index % 2 === 1 ? 'right-4' : 'right-0'
          } hidden group-hover:block`}
        >
          <RoundIcon color='danger' icon='fa fa-trash' onClick={this.onDelete} />
        </div>
      </div>
    )
  }
}
