import { IEmployeeSearchResultsFilter } from 'contracts/employees/interfaces/IEmployeeSearchResultsFilter'
import { isIntegerString } from 'contracts/general/helpers/isIntegerString'
import { isMonth } from 'contracts/general/helpers/isMonth'
import { IUser } from 'contracts/users/interfaces/IUser'
import { dayjs } from 'helpers/dayjs'
import { isPlainObject } from 'helpers/isPlainObject'
import { storage } from 'services/storage'

export function getSavedEmployeeSearchResultsFilter(
  user: IUser,
): IEmployeeSearchResultsFilter {
  let settings: any = storage.get(`employees.admin.${user.id}`)
  if (!isPlainObject(settings)) {
    settings = {}
  }
  const filter: IEmployeeSearchResultsFilter = {
    month: dayjs().format('YYYY-MM'),
    companyId: '',
    scope: 'all',
    employeeId: '',
    page: '0,50',
  }
  if (isMonth(settings.month)) {
    filter.month = settings.month
  }
  if (isIntegerString(settings.companyId)) {
    filter.companyId = settings.companyId
  }
  if (isIntegerString(settings.employeeId)) {
    filter.employeeId = settings.employeeId
  }
  if (
    settings.scope === 'all' ||
    settings.scope === 'active' ||
    settings.scope === 'problems'
  ) {
    filter.scope = settings.scope
  }
  return filter
}
