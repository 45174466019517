import { observer } from 'mobx-react'
import * as React from 'react'
import {
  SortableContainer,
  SortableElement,
  SortableContainerProps,
  SortableElementProps,
} from 'react-sortable-hoc'
import { ReportField, ReportFieldProps } from './components/ReportField'
import { EmployeeReportDefinitionColumn } from 'contracts/report/helpers/EmployeeReportDefinition'

interface Props {
  columns: EmployeeReportDefinitionColumn[]
  onDelete: (column: EmployeeReportDefinitionColumn) => void
}

const SortableItem: React.ComponentClass<ReportFieldProps & SortableElementProps> =
  SortableElement((props: ReportFieldProps) => (
    <ReportField key={props.column.id} column={props.column} onDelete={props.onDelete} />
  ))

@observer
class List extends React.Component<Props, {}> {
  mapToComponent = (column: EmployeeReportDefinitionColumn, i: number) => (
    <SortableItem
      key={column.id}
      column={column}
      onDelete={this.props.onDelete}
      index={i}
    />
  )

  render() {
    return <div className='mt3'>{this.props.columns.map(this.mapToComponent)}</div>
  }
}

export const ReportFields: React.ComponentClass<Props & SortableContainerProps> =
  SortableContainer((props: Props) => {
    return <List columns={props.columns} onDelete={props.onDelete} />
  })
