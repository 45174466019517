import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Button } from 'components/Form/components/Button'
import { InputForm } from 'components/Form/components/InputForm'
import { Message } from 'components/Message'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { makeObservable, observable, runInAction } from 'mobx'
import * as React from 'react'
import { ShiftQualificationsForm } from './ShiftQualificationsForm'
import { IShiftCompany } from 'contracts/workplan/interfaces/IShiftCompany'
import { IShiftQualification } from 'contracts/workplan/interfaces/IShiftQualification'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  shift: IShiftSearchResult
  shiftCompanies: IShiftCompany[]
  shiftQualifications: IShiftQualification[]
  onClose: (result?: boolean) => void
}

@observer
export class ShiftQualificationsEditDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private error: string | null = null
  private model: Model<{
    companies: IShiftCompany[]
    qualifications: IShiftQualification[]
  }>

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model({
      companies: props.shiftCompanies,
      qualifications: props.shiftQualifications,
    })
  }

  private saveQualifications = async () => {
    try {
      const companyIds = new Set(
        this.model.values.companies.filter((c) => !!c.companyId).map((c) => c.companyId),
      )
      await hermes.create(`/api/${this.context.instance.id}/workplan/shiftCompanies`, {
        shiftId: this.props.shift.id,
        companyIds: Array.from(companyIds),
      })
      const qualificationIds = new Set(
        this.model.values.qualifications
          .filter((q) => !!q.qualificationId)
          .map((q) => q.qualificationId),
      )
      await hermes.create(
        `/api/${this.context.instance.id}/workplan/shiftQualifications`,
        { shiftId: this.props.shift.id, qualificationIds: Array.from(qualificationIds) },
      )
      this.props.onClose(true)
    } catch (e: any) {
      runInAction(
        () =>
          (this.error = 'Beim Speichern der Anforderungen ist ein Fehler aufgetreten.'),
      )
    }
  }

  render() {
    const { shift } = this.props
    return (
      <div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4 z-20'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div
          className={`sticky ${
            this.error ? '-top-7' : '-top-11'
          } bg-white -mx-6 px-6 border-b border-gray-300 z-10`}
        >
          <div className='pb-4 pt-1 -mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Anforderungsprofil
            </Dialog.Title>
          </div>
          {this.error && (
            <Message className='mb-6' color='danger'>
              {this.error}
            </Message>
          )}
        </div>
        <InputForm onSubmit={this.saveQualifications}>
          <div className='sm:-mx-6 -mx-4 bg-gray-100 py-6'>
            <div className='mx-6 bg-white rounded-md shadow-md p-4'>
              <ShiftQualificationsForm
                shift={shift}
                shiftCompanies={this.model.values.companies}
                shiftQualifications={this.model.values.qualifications}
              />
            </div>
          </div>
        </InputForm>
        <div className='flex gap-2 justify-end sticky z-1 text-right bottom-0 bg-white -mx-6 border-t border-gray-300 py-4 px-6 -mb-4'>
          <Button color='secondary' outline onClick={() => this.props.onClose()}>
            Abbrechen
          </Button>
          <Button onClick={this.saveQualifications}>Speichern</Button>
        </div>
      </div>
    )
  }
}
