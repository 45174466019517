import { employeeFileBlocks } from '../../employees/helpers/EmployeeFileBlocks'

export function getCurrentEmployeeReportSections() {
  const sections: any[] = []
  sections.push(
    ...employeeFileBlocks.map((block) => ({
      id: block.id,
      label: block.label,
      multiple: block.multiple,
      mandatoryFields: block.mandatoryFields,
    })),
  )
  sections.push(
    {
      id: 'qualifications',
      label: 'Qualifikationen',
      mandatoryFields: [
        { key: 'label', label: 'Bezeichnung' },
        { key: 'startDate', label: 'Von' },
        { key: 'endDate', label: 'Bis' },
        { key: 'notes', label: 'Notizen' },
        { key: 'file', label: 'Anhang' },
      ],
      multiple: true,
    },
    {
      id: 'stundenzettel',
      label: 'Stundenzettel',
      mandatoryFields: [
        { key: 'month', label: 'Monat' },
        { key: 'uploadingUserId', label: 'Hochgeladen von' },
        { key: 'uploadedAt', label: 'Hochgeladen am' },
      ],
    },
  )
  return sections
}
