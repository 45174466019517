import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { getUserImageSrc } from 'modules/Users/Administration/helpers/getUserImageSrc'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import styles from '../../styles.module.scss'
import { hermes } from '@byll/hermes'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { Message } from 'components/Message'
import { dayjs } from 'helpers/dayjs'
import { TimeAccountList } from './components/TimeAccountList'
import { getSavedContractId } from './helpers/getSavedContractId'
import { Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { storage } from 'services/storage'

interface Props {
  employee: IEmployeeSearchResult
}

@observer
export class EmployeeDialogHoursTab extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable error: string | null = null
  private readonly model: Model<{ contractId: string }>
  @observable private contracts: any[] | null = null
  @observable private contractsSelectOptions: InputSelectOption[] = [
    { value: '', label: 'Vertrag wählen' },
  ]
  private readonly disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(getSavedContractId(props.employee.id))
  }

  componentDidMount() {
    this.loadContracts()
    this.disposers.push(
      reaction(
        () => toJS(this.model.values),
        () =>
          storage.set(
            `employees.contractId.${this.props.employee.id}`,
            this.model.values.contractId,
          ),
      ),
    )
  }

  loadContracts = async () => {
    try {
      const contracts = await hermes.indexOnceNew(
        `/api/${this.context.instance.id}/employees/${this.props.employee.id}/contracts`,
      )
      const selectOptions = contracts.map((contract: any) => ({
        value: contract.id,
        label: `${contract.type} ab ${dayjs(contract.joinDate).format('DD.MM.YYYY')}`,
      }))
      runInAction(() => {
        this.contracts = contracts
        this.contractsSelectOptions.push(...selectOptions)
      })
    } catch (e) {
      runInAction(
        () => (this.error = 'Beim Laden der Verträge ist ein Fehler aufgetreten.'),
      )
    }
  }

  render() {
    const { employee } = this.props
    const contract = this.contracts?.find((c) => c.id === this.model.values.contractId)
    return (
      <div>
        {/* User image */}
        <img
          src={getUserImageSrc(
            this.context.instance.id,
            employee.imageId, // user.imageId,
            employee.sex, // user.sex,
            'portrait',
          )}
          className={styles.portrait}
          alt={`${employee.lastName}, ${employee.firstName}`}
        />
        <div className='flex flex-col gap-6 p-6'>
          <div className='mr-[238px] bg-white rounded-md shadow-md p-4'>
            {this.error && (
              <Message className='mb-2' color='danger'>
                {this.error}
              </Message>
            )}
            <div className='text-lg text-gray-600'>Vertrag</div>
            <Message className='mt-2 mb-4' color='primary'>
              Wählen Sie einen Vertrag aus, um das Stundenkonto zu sehen.
            </Message>
            <InputSelect
              name='contractId'
              model={this.model}
              options={this.contractsSelectOptions}
            />
          </div>
          {this.model.values.contractId && contract && (
            <TimeAccountList
              key={this.model.values.contractId}
              employeeId={employee.id}
              contract={contract}
            />
          )}
        </div>
      </div>
    )
  }
}
