import { isIntegerString } from 'contracts/general/helpers/isIntegerString'
import { storage } from 'services/storage'

export function getSavedContractId(employeeId: string) {
  let contractId: string = storage.get(`employees.contractId.${employeeId}`)
  const filter = {
    contractId: '',
  }
  if (isIntegerString(contractId)) {
    filter.contractId = contractId
  }
  return filter
}
