import { hermes } from '@byll/hermes'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { InputBlock } from 'components/Form/components/InputBlock'
import { InputForm } from 'components/Form/components/InputForm'
import { Message } from 'components/Message'
import { IEmployeeFileBlock } from 'contracts/employees/interfaces/IEmployeeFileBlock'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import dayjs from 'dayjs'
import { RoundIcon } from 'components/RoundIcon'
import { IEmployeeContractTerm } from 'contracts/employees/interfaces/IEmployeeContractTerm'
import * as uuid from 'uuid'
import styles from '../styles.module.scss'
import { ContractTermsRow } from './ContractTermsRow'

interface Props {
  block: IEmployeeFileBlock
  id: string
  contractData: { contract: any; contractTerms: IEmployeeContractTerm[] } | null
  employee: IEmployeeSearchResult
  onClose: () => void
}

@observer
export class ContractEditDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private error: string | null = null
  @observable private contractTerms: IEmployeeContractTerm[] = []
  private model: Model<IEmployeeFileBlock>

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    if (!props.id.includes('-')) {
      let details = props.contractData!.contract.details
      this.contractTerms = props.contractData!.contractTerms
      this.model = new Model({ ...props.block, details })
    } else {
      this.model = new Model({
        ...props.block,
        details: {
          id: props.id,
        },
      })
      this.contractTerms.push({
        id: uuid.v4(),
        plannable: true,
        contractId: this.props.id,
        userId: this.props.employee.id,
        workingHours: null,
        workingHoursInterval: 'week',
        wage: null,
        wageInterval: 'month',
        workdaysPerWeek: null,
        vacationDaysPerYear: null,
        lohngruppenId: null,
        salaryGroup: null,
        documentId: null,
        validFrom: dayjs().format('YYYY-MM-DD'),
      })
    }
  }

  @action private addContractTerm = (e?: any) => {
    e?.preventDefault()
    if (!this.props.id) {
      return
    }
    this.contractTerms.unshift({
      id: uuid.v4(),
      contractId: this.props.id,
      userId: this.props.employee.id,
      plannable: this.model.values.details.workplanPlannable === 'Ja' ? true : false,
      workingHours: null,
      workingHoursInterval: 'week',
      wage: null,
      wageInterval: 'month',
      workdaysPerWeek: null,
      vacationDaysPerYear: null,
      lohngruppenId: null,
      salaryGroup: null,
      documentId: null,
      validFrom: dayjs().format('YYYY-MM-DD'),
    })
  }

  @action private deleteContractTerm = (id: string) => {
    this.contractTerms = this.contractTerms.filter((t) => t.id !== id)
  }

  private saveContract = async () => {
    try {
      for (const term of this.contractTerms) {
        term.plannable =
          this.model.values.details.workplanPlannable === 'Ja' ? true : false
      }
      await hermes.update(
        `/api/${this.context.instance.id}/employees/${this.props.employee.id}/contracts/${this.model.values.details.id}`,
        { contract: this.model.values.details, contractTerms: this.contractTerms },
      )
      this.props.onClose()
    } catch (e: any) {
      if (e?.id === ConflictError.id) {
        runInAction(() => (this.error = e.message))
      } else {
        runInAction(
          () =>
            (this.error =
              'Beim Speichern der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.'),
        )
      }
    }
  }

  render() {
    return (
      <div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4 z-20'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div
          className={`sticky ${
            this.error ? '-top-7' : '-top-11'
          } bg-white -mx-6 px-6 border-b border-gray-300 z-10`}
        >
          <div className='pb-4 pt-1 -mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Arbeitsvertrag{' '}
              {this.model.values.id.includes('-') ? 'hinzufügen' : 'bearbeiten'}
            </Dialog.Title>
          </div>
          {this.error && (
            <Message className='mb-6' color='danger'>
              {this.error}
            </Message>
          )}
        </div>
        <InputForm onSubmit={this.saveContract}>
          <div className='sm:-mx-6 -mx-4 bg-gray-100 py-6'>
            <InputBlock
              id='employment-contracts'
              name='details'
              label='Arbeitsverträge'
              model={this.model}
              mandatoryFields={this.props.block.mandatoryFields}
            />
            <div className='bg-white mt-4 mx-6 rounded-md shadow-md p-4'>
              <div className='flex'>
                <div className='flex-auto leading-[30px] text-base text-gray-600 truncate'>
                  Vertragsdetails
                </div>
                <RoundIcon
                  classNameContainer='flex-content'
                  icon='fa fa-plus'
                  onClick={(e) => this.addContractTerm(e)}
                />
              </div>
              <div>
                {this.contractTerms.filter((t) => t.contractId === this.props.id)
                  .length === 0 && (
                  <div className='text-gray-400'>Keine Vertragsdetails vorhanden.</div>
                )}
                {this.contractTerms
                  .filter((t) => t.contractId === this.props.id)
                  .map((t, i) => (
                    <div
                      className={i % 2 === 1 ? 'bg-gray-200 -mx-4' : 'undefined'}
                      key={t.id}
                    >
                      {i !== 0 && i % 2 === 1 && (
                        <div className={`${styles.white} h-[20px] mt-2`} />
                      )}
                      <ContractTermsRow
                        index={i}
                        contractTerms={t}
                        onDelete={this.deleteContractTerm}
                      />
                      {i % 2 === 1 && <div className={`${styles.gray} h-[20px] mb-4`} />}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </InputForm>
        <div className='flex gap-2 justify-end sticky z-1 text-right bottom-0 bg-white -mx-6 border-t border-gray-300 p-4 -mb-4'>
          <Button color='secondary' outline onClick={this.props.onClose}>
            Abbrechen
          </Button>
          <Button onClick={this.saveContract}>Speichern</Button>
        </div>
      </div>
    )
  }
}
