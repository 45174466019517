import { Model } from 'components/Form/Model'
import { InputDecimal } from 'components/Form/components/InputDecimal'
import { formatDecimal } from 'components/Form/components/InputDecimal/helpers/formatDecimal'
import { Tooltip } from 'components/Tooltip'
import { ISalaryIncrease } from 'contracts/companies/interfaces/ISalaryIncrease'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  salary: ISalaryIncrease
}

@observer
export class SalaryListEntry extends React.Component<Props, {}> {
  private readonly model: Model<{ amount: string }>

  constructor(props: Props) {
    super(props)
    this.model = new Model(props.salary)
  }

  render() {
    const keys = this.props.salary.id.split('#')
    const workingHours = keys[0] // workingHours is oldSalary for keys.length === 1
    const scope = keys[1] === 'monthly' ? 'pro Monat' : 'pro Woche'
    const oldSalary = keys[2]
    const employees = this.props.salary.employees.slice(0, 15)
    if (keys.length === 1) {
      return (
        <tr>
          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
            <span className='text-gray-500 cursor-pointer mr-2 has-tooltip'>
              <i className='fa fa-question-circle' />
              <Tooltip position='bottom'>
                Betroffene Mitarbeiter:
                {employees.map((employee, i) => (
                  <div key={i}>{employee.name}</div>
                ))}
                <div>
                  {this.props.salary.employees.length > 15 && (
                    <div>... {this.props.salary.employees.length - 15} weitere</div>
                  )}
                </div>
              </Tooltip>
            </span>
            {formatDecimal(workingHours)} €
          </td>
          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
            <InputDecimal
              name='wage'
              model={this.model}
              scale={2}
              precision={8}
              postfix='€'
            />
          </td>
        </tr>
      )
    }
    return (
      <tr>
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
          <span className='text-gray-500 cursor-pointer mr-2 has-tooltip'>
            <i className='fa fa-question-circle' />
            <Tooltip position='bottom'>
              Betroffene Mitarbeiter:
              {employees.map((employee, i) => (
                <div key={i}>{employee.name}</div>
              ))}
              <div>
                {this.props.salary.employees.length > 15 && (
                  <div>... {this.props.salary.employees.length - 15} weitere</div>
                )}
              </div>
            </Tooltip>
          </span>
          {formatDecimal(workingHours)}
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{scope}</td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          {formatDecimal(oldSalary)} €
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <InputDecimal
            name='wage'
            model={this.model}
            scale={2}
            precision={8}
            postfix='€'
          />
        </td>
      </tr>
    )
  }
}
