import { IInputBlockField } from '../interfaces/IInputBlock'

export function mergeFieldsAndMandatoryFields(
  fields: IInputBlockField[],
  mandatoryFields: IInputBlockField[],
): IInputBlockField[] {
  const missingFields: IInputBlockField[] = []
  if (typeof fields === 'string') {
    fields = JSON.parse(fields)
  }
  for (const mf of mandatoryFields) {
    const field = fields.find((f) => f.key === mf.key)
    if (field) {
      field.label = mf.label
      field.type = mf.type
      field.options = mf.options
      field.allowEmpty = mf.allowEmpty
    } else {
      missingFields.push(mf)
    }
  }

  return [...missingFields, ...fields]
}
