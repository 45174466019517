import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { OverrideRow } from './OverrideRow'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { IShiftParticipantOverride } from 'contracts/workplan/interfaces/IShiftParticipantOverride'
import { AppContext } from 'services/connection/models/AppContext'
import { Button } from 'components/Form/components/Button'
import { dayjs } from 'helpers/dayjs'

let counter = 0

interface Props {
  shift: IShiftSearchResult
  overrides: IShiftParticipantOverride[]
  month?: string
  readOnly?: boolean
}

@observer
export class OverrideList extends React.Component<Props, {}> {
  static contextType = AppContext

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private mapOverride = (override: IShiftParticipantOverride) => (
    <OverrideRow
      key={override.id}
      override={override}
      onDelete={this.deleteOverride}
      readOnly={this.props.readOnly}
    />
  )

  @action private addOverride = (e) => {
    const date = this.props.month
      ? dayjs(this.props.month).startOf('month').format('YYYY-MM-DD')
      : null
    e.preventDefault()
    this.props.overrides.push({
      id: String(counter--),
      shiftId: this.props.shift.id,
      date,
      participants: null as any,
    })
  }

  @action private deleteOverride = (id: string) => {
    for (let i = 0; i < this.props.overrides.length; i++) {
      if (this.props.overrides[i].id !== id) {
        continue
      }
      this.props.overrides.splice(i, 1)
      return
    }
  }

  render() {
    return (
      <div className='mt-4'>
        {this.props.overrides.map(this.mapOverride)}
        {!this.props.readOnly && (
          <Button color='secondary' onClick={this.addOverride}>
            <i className='fa fa-plus' /> Abweichende Teilnehmerzahl
          </Button>
        )}
      </div>
    )
  }
}
