import { hermes } from '@byll/hermes'
import { DAYS } from 'contracts/general/helpers/days'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { EmployeeDaySchedule } from './components/EmployeeDaySchedule'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import { Message } from 'components/Message'
import { IEmployeeDaySchedule } from 'contracts/workplan/interfaces/IEmployeeDaySchedule'
import { EmployeeDetailsHistory } from './components/EmployeeDetailsHistory'
import { XIcon } from '@heroicons/react/outline'
import { Model } from 'components/Form/Model'
import { Spinner } from 'components/Spinner'

interface Props {
  viewId: string
  userId: string
  type: 'plan' | 'real'
  date: string
  specialShifts: IShift[]
  employeePoolId: string
  employeeDetailsModel: Model<{ showEmployeeDetailsBox: boolean }>
}

@observer
export class ViewEmployeeDetails extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private monthlyEmployeeWorkSchedule: any | null = null
  @observable private shifts: IShift[] | null = null
  @observable days: string[] = []
  @observable error: string | null = null
  @observable tab: 'calendar' | 'history' = 'calendar'

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    for (let i = -2; i < 3; i++) {
      const date = dayjs(props.date).add(i, 'day').format('YYYY-MM-DD')
      this.days.push(date)
    }
  }

  componentDidMount(): void {
    this.loadSchedule()
    this.loadViewShifts()
  }

  private loadSchedule = async () => {
    try {
      const monthlyEmployeeWorkSchedule = await hermes.getOnceNew<any>(
        `/api/${this.context.instance.id}/workplan/employeeWorkScheduleDetails/${this.props.userId}?date=${this.props.date}&type=${this.props.type}`,
      )
      runInAction(() => {
        this.monthlyEmployeeWorkSchedule = monthlyEmployeeWorkSchedule
      })
    } catch (_e) {
      runInAction(
        () =>
          (this.error =
            'Beim Laden des Schichtplans ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'),
      )
    }
  }

  private loadViewShifts = async () => {
    try {
      const shifts = await hermes.indexOnceNew<IShift & { color: string }>(
        `/api/${this.context.instance.id}/workplan/shifts?month=${this.props.date.slice(
          0,
          7,
        )}`,
      )
      this.props.specialShifts.map((s) =>
        shifts.push({ ...s, color: s.comment.split(',')[1] }),
      )
      runInAction(() => (this.shifts = shifts))
    } catch (_e) {
      runInAction(
        () => (this.error = 'Beim Laden der Schichten ist ein Fehler aufgetreten.'),
      )
    }
  }

  @action
  private switchTab = (tab: 'calendar' | 'history') => {
    this.tab = tab
  }

  @action
  private closeBox = () => {
    this.props.employeeDetailsModel.values.showEmployeeDetailsBox = false
  }

  render() {
    if (!this.props.userId) {
      return (
        <div className='border border-gray-300 h-full p-4 text-gray-800 bg-white cursor-move'>
          Bitte wählen Sie einen Mitarbeiter aus.
          <div className='absolute top-0 right-0 pt-2 pr-2'>
            <button
              type='button'
              className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              onClick={this.closeBox}
            >
              <span className='sr-only'>Close</span>
              <XIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
        </div>
      )
    }
    if (!this.monthlyEmployeeWorkSchedule || +this.props.userId <= 0) {
      return (
        <div className='h-full bg-white'>
          <Spinner />
        </div>
      )
    }
    return (
      <div className='h-full overflow-hidden cursor-move'>
        <div className='flex justify-between bg-gray-800 p-2 text-white'>
          <div className='truncate text-white'>
            {this.monthlyEmployeeWorkSchedule.lastName},{' '}
            {this.monthlyEmployeeWorkSchedule.firstName}
          </div>
          <div className='flex-content flex'>
            <span
              onClick={() => this.switchTab('calendar')}
              className={`${
                this.tab === 'calendar' ? 'bg-gray-400' : ''
              } px-2 cursor-pointer`}
              style={{ borderRadius: '4px 0 0 4px' }}
            >
              <i className='fa fa-calendar-alt' />
            </span>
            <span
              onClick={() => this.switchTab('history')}
              className={`${
                this.tab === 'history' ? 'bg-gray-400' : ''
              } px-2 cursor-pointer`}
              style={{ borderRadius: '0 4px 4px 0' }}
            >
              <i className='fa fa-history' />
            </span>
            <span>
              <button
                type='button'
                className='rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={this.closeBox}
              >
                <span className='sr-only'>Close</span>
                <XIcon className='h-4 w-4' aria-hidden='true' />
              </button>
            </span>
          </div>
        </div>
        {this.tab === 'calendar' && (
          <div>
            {/* Day captions */}
            <div className='flex absolute left-0 top-10 right-0 bottom-0 z-10 bg-white'>
              {this.days.map((d, i) => {
                const day = d.slice(8, 10).startsWith('0')
                  ? d.slice(9, 10)
                  : d.slice(8, 10)
                return (
                  <div
                    key={i}
                    className={`relative flex-[0_0_20%] p-2 text-center ${
                      i === 0 || i === 3 ? 'border-r' : ''
                    } ${d === this.props.date ? 'border-2 border-blue-500' : ''}`}
                  >
                    <span className='bg-gray-600 text-white px-1 py-0.5 text-sm rounded-md mr-2'>
                      {DAYS[dayjs(d).day()]}
                    </span>
                    <span>{day}</span>
                  </div>
                )
              })}
            </div>
            {/* Cell details */}
            <div className='mt-[42px] -mb-[10px]'>
              {this.error && (
                <Message className='z-20 absolute bottom-0 left-0 right-0' color='danger'>
                  {this.error}
                </Message>
              )}
              {this.shifts &&
                this.monthlyEmployeeWorkSchedule.days &&
                this.monthlyEmployeeWorkSchedule.days.map(
                  (d: IEmployeeDaySchedule, i: number) => {
                    return (
                      <EmployeeDaySchedule
                        key={i}
                        date={this.props.date}
                        schedule={d}
                        shifts={this.shifts!}
                        employeePoolId={this.props.employeePoolId}
                        viewId={this.props.viewId}
                        loadSchedule={this.loadSchedule}
                      />
                    )
                  },
                )}
            </div>
          </div>
        )}
        {this.tab === 'history' && (
          <EmployeeDetailsHistory
            userId={this.props.userId}
            date={this.props.date}
            type={this.props.type}
            specialShifts={this.props.specialShifts}
          />
        )}
      </div>
    )
  }
}
