import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Button } from 'components/Form/components/Button'
import { InputForm } from 'components/Form/components/InputForm'
import { Message } from 'components/Message'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { makeObservable, observable, runInAction } from 'mobx'
import * as React from 'react'
import { IShiftParticipantOverride } from 'contracts/workplan/interfaces/IShiftParticipantOverride'
import { observer } from 'mobx-react'
import { AppContext } from 'services/connection/models/AppContext'
import { OverrideList } from './OverrideList'
import { hermes } from '@byll/hermes'
import { dayjs } from 'helpers/dayjs'

interface Props {
  overrides: IShiftParticipantOverride[]
  shift: IShiftSearchResult
  month: string
  onClose: (result?: boolean) => void
}

@observer
export class DateOverridesEditDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private error: string | null = null
  @observable private dateOverrides: IShiftParticipantOverride[]

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.dateOverrides = [
      ...props.overrides.map((override) => ({
        ...override,
        participants: override.participants?.toString() as any,
      })),
    ]
  }

  private saveDayOverrides = async () => {
    try {
      runInAction(() => (this.error = null))
      if (
        this.dateOverrides.some(
          (override) =>
            dayjs(override.date).isBefore(this.props.month, 'month') ||
            dayjs(override.date).isAfter(this.props.month, 'month'),
        )
      ) {
        runInAction(
          () =>
            (this.error = 'Das Datum der Abweichung liegt nicht im ausgewählten Monat.'),
        )
        return
      }
      for (const override of this.dateOverrides) {
        if (override.participants) {
          runInAction(() => (override.participants = +override.participants))
        }
      }
      await hermes.create(
        `/api/${this.context.instance.id}/workplan/shiftParticipantOverrides`,
        {
          shiftId: this.props.shift.id,
          month: this.props.month,
          overrides: this.dateOverrides,
        },
      )
      this.props.onClose(true)
    } catch (e: any) {
      runInAction(
        () =>
          (this.error =
            'Beim Speichern der Abweichungen ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.'),
      )
    }
  }

  render() {
    return (
      <div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4 z-20'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div
          className={`sticky ${
            this.error ? '-top-7' : '-top-11'
          } bg-white -mx-6 px-6 border-b border-gray-300 z-10`}
        >
          <div className='pb-4 pt-1 -mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              Abweichungen
            </Dialog.Title>
          </div>
          {this.error && (
            <Message className='mb-6' color='danger'>
              {this.error}
            </Message>
          )}
          <Message color='primary' className='mb-6'>
            Legen Sie hier die Abweichungen für ein spezifisches Datum des ausgewählten
            Monats fest.
          </Message>
        </div>
        <InputForm onSubmit={this.saveDayOverrides}>
          <div className='sm:-mx-6 -mx-4 bg-gray-100 py-6 flex flex-col gap-6'>
            <div className='mx-6 bg-white rounded-md shadow-md p-4'>
              <div className='text-lg text-gray-600 mb-4'>Datum</div>
              <OverrideList
                overrides={this.dateOverrides}
                shift={this.props.shift}
                month={this.props.month}
              />
            </div>
          </div>
        </InputForm>
        <div className='flex gap-2 justify-end sticky z-1 text-right bottom-0 bg-white -mx-6 border-t border-gray-300 py-4 px-6 -mb-4'>
          <Button color='secondary' outline onClick={() => this.props.onClose()}>
            Abbrechen
          </Button>
          <Button onClick={this.saveDayOverrides}>Speichern</Button>
        </div>
      </div>
    )
  }
}
