import React, { useContext, useState } from 'react'
import { Navbar } from 'components/Navbar'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router'
import { Dashboard } from 'modules/Dashboard'
import { AppStatus } from 'services/connection/helpers/getAppStatus'
import { CaseRecord } from 'modules/Residents/modules/CaseRecord'
import { CreateRecord } from 'modules/Residents/modules/CreateRecord'
import { FindRecord } from 'modules/Residents/modules/FindRecord'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { action, makeObservable, observable, reaction } from 'mobx'
import { Resource } from '@byll/hermes'
import { IUser } from 'contracts/users/interfaces/IUser'
import { IPermissions } from 'contracts/users/interfaces/IPermissions'
import { NotFound } from 'modules/ErrorPages/NotFound'
import { Showroom } from 'modules/Showroom'
import { Visits } from 'modules/Visits'
import { CostCoverages } from 'modules/CostCoverages'
import { Meals } from 'modules/Meals'
import { useParams } from 'react-router'
import { fromJbpId } from 'contracts/residents/helpers/fromJbpId'
import { Forbidden } from 'modules/ErrorPages/Forbidden'
import { Spinner } from 'components/Spinner'
import { ResidentsOccupancy } from 'modules/ResidentsOccupancy'
import { MaintenanceOverlay } from 'components/MaintenanceOverlay'
import { IUserDefaults } from 'contracts/users/interfaces/IUserDefaults'
import { Reporting } from 'modules/Reporting'
import { Account } from 'modules/Account'
import { ResidentLetters } from 'modules/ResidentLetters'
import { Cards } from 'modules/Cards'
import { Documents } from 'modules/Documents'
import { Visitors } from 'modules/Visits/components/Visitors'
import { isIntegerString } from 'contracts/general/helpers/isIntegerString'
import { Inventory } from 'modules/Inventory'
import { InventoryScan } from 'modules/InventoryScan'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { DisplayPromptIfMoreThan0PreventRouteChangeRequests } from 'components/PreventRouteChange/DisplayPromptIfMoreThan0PreventRouteChangeRequests'
import { RastNotifications } from 'modules/RastNotifications'
import { TransferPlanning } from 'modules/TransferPlanning'
import { ResidentMigration } from 'modules/Residents/modules/Migration'
import { FederalTransfers } from 'modules/FederalTransfers'
import { LeaInactiveList } from 'modules/InactiveList'
import { Companies } from 'modules/Companies'
import { TodoOverlay } from 'modules/Todo/components/TodoOverlay'
import { WorkplanSignatures } from 'modules/WorkplanSignatures'
import { Groups } from './modules/Groups'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Employees } from 'modules/Employees'
import { Migrations } from 'modules/Migrations'
import { Workplan } from 'modules/Workplan'
import { Shifts } from 'modules/Shifts'
import { EmployeeCommunication } from 'modules/EmployeeCommunication'

const Administration = React.lazy(() => import('./modules/Users/Administration'))
const Compounds = React.lazy(() => import('./modules/Compounds'))

interface Props {
  appStatus: AppStatus
}

@observer
export class App extends React.Component<Props, {}> {
  @observable private isOpenTodoWindow = false
  @observable private appContextProps: AppContextProps | null = null
  private readonly disposers: Disposer[] = []
  private readonly user: Resource<IUser>
  private readonly userDefaults: Resource<IUserDefaults>
  private readonly permissions: Resource<IPermissions & { id: string }>

  constructor(props: Props) {
    super(props)
    this.user = new Resource(
      `/api/${props.appStatus.instanceId}/users/${props.appStatus.userId}`,
    )
    this.permissions = new Resource(
      `/api/${props.appStatus.instanceId}/users/permissions/${props.appStatus.userId}`,
    )
    this.userDefaults = new Resource(
      `/api/${props.appStatus.instanceId}/userDefaults/${props.appStatus.userId}`,
    )
    makeObservable(this)
  }

  componentDidMount() {
    void this.init()
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action private toggleTodoWindow = () =>
    (this.isOpenTodoWindow = !this.isOpenTodoWindow)

  private init = async () => {
    this.disposers.push(this.userDefaults.init())
    this.disposers.push(this.user.init({ readOnly: true }))
    this.disposers.push(this.permissions.init({ readOnly: true }))
    this.disposers.push(
      reaction(
        () => `${this.user.data?.id}.${this.permissions.data?.id}`,
        () => {
          if (
            !this.user.data ||
            !this.userDefaults.data ||
            !this.permissions.data ||
            !this.props.appStatus.sessionId
          ) {
            this.appContextProps = null
          } else {
            this.appContextProps = {
              session: { id: this.props.appStatus.sessionId },
              instance: { id: this.props.appStatus.instanceId },
              user: this.user.data,
              permissions: this.permissions.data,
              defaults: this.userDefaults.data,
            }
          }
        },
        { fireImmediately: true },
      ),
    )
  }

  render() {
    if (!this.appContextProps) {
      return null
    }
    return (
      <AppContext.Provider value={this.appContextProps}>
        <Navbar onBellClick={this.toggleTodoWindow} />
        <React.Suspense fallback={<Spinner delay />}>
          <DndProvider backend={HTML5Backend}>
            <Routes>
              <Route path='/showroom' element={<Showroom />} />
              <Route path='/accommodations/*' element={<Compounds />} />
              <Route path='/inventory/*' element={<RenderInventory />} />
              <Route path='inventory/scan' element={<InventoryScan />} />
              <Route path='/costs/*' element={<CostCoverages />} />
              <Route path='/residents/registration/*' element={<RenderCreateRecord />} />
              <Route path='/residents/find' element={<RenderFindRecord />} />
              <Route path='/residents/occupancy' element={<ResidentsOccupancy />} />
              <Route path='/residents/letters' element={<RenderResidentLetters />} />
              <Route
                path='/employee/communication/*'
                element={<RenderEmployeeCommunication />}
              />
              <Route
                path='/residents/transfer/planning'
                element={<RenderTransferPlanning />}
              />
              <Route
                path='/residents/transfer/federal'
                element={<RenderFederalTransfers />}
              />
              <Route
                path='/residents/transfer/planning'
                element={<RenderTransferPlanning />}
              />
              <Route
                path='/residents/transfer/federal'
                element={<RenderFederalTransfers />}
              />
              <Route path='/residents/:hash/*' element={<RenderCaseRecord />} />
              <Route path='/residents/migration' element={<ResidentMigration />} />
              <Route path='/residents/cards' element={<Cards />} />
              <Route path='/residents/rast/*' element={<RenderRastNotifications />} />
              <Route path='/migrations/*' element={<Migrations />} />
              <Route path='/reports/*' element={<Reporting />} />
              <Route path='/administration/*' element={<RenderAdministration />} />
              <Route path='/visits' element={<Visits />} />
              <Route path='/visitors/:compoundId' element={<RenderVisitors />} />
              <Route path='/meals' element={<Meals />} />
              <Route path='/account/*' element={<Account />} />
              <Route path='/documents/:id' element={<RenderDocument />} />
              <Route path='/inactive' element={<RenderInactiveList />} />
              <Route path='/companies/*' element={<RenderCompanies />} />
              <Route path='/workplan-signatures' element={<RenderWorkplanSignatures />} />
              <Route path='/workplan/*' element={<RenderWorkplan />} />
              <Route path='/employees/*' element={<RenderEmployees />} />
              <Route path='shifts/*' element={<RenderShifts />} />
              <Route path='/groups' element={<Groups />} />
              <Route path='/cache/*' element={<Navigate to='/' replace />} />
              <Route path='/*' element={<Dashboard />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </DndProvider>
        </React.Suspense>
        {this.isOpenTodoWindow && <TodoOverlay onClose={this.toggleTodoWindow} />}
        <ToastContainer
          position='top-right'
          autoClose={5000}
          pauseOnHover
          pauseOnFocusLoss
        />
        {/* Render maintenance overlay */}
        <MaintenanceOverlay appStatus={this.props.appStatus} />
        {/* Render route blocker */}
        <DisplayPromptIfMoreThan0PreventRouteChangeRequests />
      </AppContext.Provider>
    )
  }
}

const RenderInventory: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)
  const location = useLocation()
  const navigate = useNavigate()

  if (context.permissions.menu_inventoryManagement < 1) {
    return <Forbidden />
  }

  return <Inventory navigate={navigate} pathname={location.pathname} />
})

const RenderTransferPlanning: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)

  if (context.permissions.menu_residentTransferPlanningList_show < 1) {
    return <Forbidden />
  }

  return <TransferPlanning />
})

const RenderCompanies: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)

  if (!context.permissions.menu_companies) {
    return <Forbidden />
  }

  return <Companies />
})

const RenderWorkplan: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)
  const navigate = useNavigate()
  const location = useLocation()

  if (context.permissions.menu_workplan === 0) {
    return <Forbidden />
  }

  return <Workplan pathname={location.pathname} navigate={navigate} />
})

const RenderInactiveList: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)

  if (!context.permissions.resident_leaInactive) {
    return <Forbidden />
  }

  return <LeaInactiveList />
})

const RenderCaseRecord: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)
  const location = useLocation()
  const params = useParams<{ hash: string }>()
  const [fromSearch] = useState(!!(location.state as any)?.fromSearch)
  const hash = params.hash || ''
  const base = `/residents/${hash.toLowerCase()}`
  if (!hash || !/^[a-zA-Z0-9]{4,10}$/.test(hash) || !fromJbpId(hash)) {
    return <NotFound />
  }

  if (
    hash.toLowerCase() !== hash ||
    (!location.pathname.startsWith(`${base}/overview`) &&
      !location.pathname.startsWith(`${base}/profile`) &&
      !location.pathname.startsWith(`${base}/accommodation`) &&
      !location.pathname.startsWith(`${base}/documentation`) &&
      !location.pathname.startsWith(`${base}/health`) &&
      !location.pathname.startsWith(`${base}/inventory`) &&
      !location.pathname.startsWith(`${base}/log`) &&
      !location.pathname.startsWith(`${base}/workplan`))
  ) {
    return <Navigate to={`${base}/overview`} />
  }

  if (!context.permissions.menu_residents) {
    return <Forbidden />
  }

  return <CaseRecord hash={hash} fromSearch={fromSearch} />
})

const RenderFindRecord: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)

  if (!context.permissions.menu_resident_search) {
    return <Forbidden />
  }

  return <FindRecord key={context.permissions.menu_resident_search} />
})

const RenderEmployees: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)
  const location = useLocation()
  const navigate = useNavigate()

  if (!context.permissions.menu_workload) {
    return <Forbidden />
  }

  return <Employees location={location} navigate={navigate} />
})

const RenderShifts: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)
  const location = useLocation()
  const navigate = useNavigate()

  if (!context.permissions.menu_workload) {
    return <Forbidden />
  }

  return <Shifts location={location} navigate={navigate} />
})

const RenderEmployeeCommunication: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)

  if (!context.permissions.menu_employeeCommunication) {
    return <Forbidden />
  }

  return <EmployeeCommunication />
})

const RenderVisitors: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)
  const params = useParams<{ compoundId: string }>()
  const compoundId = params.compoundId || ''
  if (
    !context.permissions.resident_barcodeVisitTrackings ||
    !isIntegerString(compoundId)
  ) {
    return <Forbidden />
  }

  return <Visitors compoundId={compoundId} />
})

const RenderWorkplanSignatures: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)
  if (!context.permissions.menu_workplanSignature) {
    return <Forbidden />
  }

  return <WorkplanSignatures />
})

const RenderCreateRecord: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)
  const location = useLocation()
  const navigate = useNavigate()

  if (!context.permissions.menu_resident_register) {
    return <Forbidden />
  }

  return <CreateRecord pathname={location.pathname} navigate={navigate} />
})

const RenderAdministration: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)

  if (!context.permissions.admin_isAdmin) {
    return <Forbidden />
  }

  return <Administration />
})

const RenderResidentLetters: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)

  if (!context.permissions.menu_resident_letters) {
    return <Forbidden />
  }

  return <ResidentLetters />
})

const RenderFederalTransfers: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)

  if (!context.permissions.menu_residentFederalTransfers) {
    return <Forbidden />
  }

  return <FederalTransfers />
})

const RenderRastNotifications: React.FC<{}> = observer(() => {
  const context = useContext(AppContext)

  if (!context.permissions.menu_rastNotification) {
    return <Forbidden />
  }

  return <RastNotifications />
})

const RenderDocument: React.FC<{}> = () => {
  const params = useParams<{ id: string }>()
  return <Documents key={params.id || ''} id={params.id || ''} />
}
