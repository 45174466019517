import { Model } from 'components/Form/Model'
import { IShiftFilter } from 'contracts/workplan/interfaces/IShiftFilter'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import * as React from 'react'
import { ShiftOverviewForm } from './components/ShiftOverviewForm'
import { RoundIcon } from 'components/RoundIcon'
import { box } from 'services/box'
import { ShiftEditDialog } from './components/ShiftEditDialog'
import { AppContext } from 'services/connection/models/AppContext'
import { makeObservable, observable, reaction, toJS } from 'mobx'
import { IShortcut } from './components/ShortcutRow'
import { getShortcutsStructure } from './helpers/getShortcutsStructure'
import { calculateRecovery } from './helpers/calculateRecovery'
import { observer } from 'mobx-react'
import { Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { useNavigate } from 'react-router'

interface Props {
  filterModel: Model<IShiftFilter>
  shift: IShiftSearchResult
}

@observer
export class ShiftDialogOverviewTab extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private shortcuts: IShortcut[] = []
  private model: Model<IShiftSearchResult>
  private disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model({
      ...props.shift,
      recovery: calculateRecovery(props.shift.recovery),
    })
    this.shortcuts = getShortcutsStructure(props.shift)
  }

  componentDidMount(): void {
    this.disposers.push(
      reaction(
        () => toJS(this.props.shift),
        () => {
          this.model = new Model({
            ...this.props.shift,
            recovery: calculateRecovery(this.props.shift.recovery),
          })
          this.shortcuts = getShortcutsStructure(this.props.shift)
        },
      ),
    )
  }

  private editShift = async () => {
    const promise = box.custom(
      <RenderShiftEditDialog
        shift={this.props.shift}
        filterModel={this.props.filterModel}
        onClose={() => promise.close()}
      />,
      { closable: true, context: this.context, size: 'md' },
    )
  }

  render() {
    return (
      <div className='p-6'>
        <div className='bg-white rounded-md shadow-md p-4 relative'>
          <div className='text-lg text-gray-600'>{this.props.shift.label}</div>
          {this.context.permissions.workplan_shifts > 1 && (
            <div className='absolute top-4 right-4'>
              <RoundIcon icon='fa fa-edit' onClick={this.editShift} />
            </div>
          )}
          <div className='pt-4'>
            <ShiftOverviewForm
              model={this.model}
              filterModel={this.props.filterModel}
              shortcuts={this.shortcuts}
              readOnly
            />
          </div>
        </div>
      </div>
    )
  }
}

export const RenderShiftEditDialog: React.FC<{
  shift: IShiftSearchResult
  filterModel: Model<IShiftFilter>
  onClose: () => void
}> = ({ shift, filterModel, onClose }) => {
  const navigate = useNavigate()

  return (
    <ShiftEditDialog
      shift={shift}
      filterModel={filterModel}
      navigate={navigate}
      onClose={onClose}
    />
  )
}
