import { Dayjs } from 'dayjs'
import { dayjs } from '../../general/helpers/dayjs'

export function getShiftBeginTime(date: string, time: string): Dayjs {
  if (isDstStartDate(date)) {
    const hour = +time.split(':')[0]
    if (hour === 2) {
      // Keep shift time at 2:00 between 2:00 and 3:00 CET (invalid times)
      // -> No jumping back in time like dayjs would naturally do.
      return dayjs(date + ' 02:00', 'YYYY-M-D H:m')
    }
  }

  // Dst end date -> skip one our at 3:00 CET (2:59 CET -> 0:59 UTC, 3:00 CET -> 2:00 UTC)
  // No special handling needed for this use case because that's how dayjs works naturally.

  // All other cases
  return dayjs(date + ' ' + time, 'YYYY-M-D H:m')
}

function isDstStartDate(ymd: string): boolean {
  const t1 = dayjs(ymd + ' 00:00', 'YYYY-M-D HH:mm')
  const t2 = dayjs(ymd + ' 06:00', 'YYYY-M-D HH:mm')
  return t1.utcOffset() === 60 && t2.utcOffset() === 120
}
