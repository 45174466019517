import { hermes } from '@byll/hermes'
import { Model } from 'components/Form/Model'
import { InputDropdownEntry } from 'components/Form/components/InputDropdownEntry'
import { InputText } from 'components/Form/components/InputText'
import { RoundIcon } from 'components/RoundIcon'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { IShiftBonusType } from 'contracts/workplan/interfaces/IShiftBonusType'
import { IShiftBuildingBonusType } from 'contracts/workplan/interfaces/IShiftBuildingBonusType'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

let idGenerator = 0

interface Props {
  shiftBonusTypes: IShiftBonusType[]
  shiftBuildingBonusTypes: IShiftBuildingBonusType[]
  shift: IShiftSearchResult
  readOnly?: boolean
}

@observer
export class ShiftBonusTypesForm extends React.Component<Props, {}> {
  static contextType = AppContext

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private addShiftBonusType = (e: MouseEvent) => {
    e.preventDefault()
    if (!this.props.shiftBonusTypes) {
      return
    }
    this.props.shiftBonusTypes.push({
      id: String(idGenerator--),
      schichtzulageId: '',
      notes: '',
      shiftId: this.props.shift.id,
    })
  }

  @action
  private addShiftBuildingBonusType = (e: MouseEvent) => {
    e.preventDefault()
    if (!this.props.shiftBuildingBonusTypes || !this.props.shift.buildingId) {
      return
    }
    this.props.shiftBuildingBonusTypes.push({
      id: String(idGenerator--),
      schichtzulageId: '',
      notes: '',
      buildingId: this.props.shift.buildingId,
    })
  }

  @action
  private deleteShiftBonusType = (id: string) => {
    if (!this.props.shiftBonusTypes) {
      return
    }
    const index = this.props.shiftBonusTypes.findIndex((company) => company.id === id)
    if (index !== -1) {
      this.props.shiftBonusTypes.splice(index, 1)
    }
  }

  @action
  private deleteShiftBuildingBonusType = (id: string) => {
    if (!this.props.shiftBuildingBonusTypes) {
      return
    }
    const index = this.props.shiftBuildingBonusTypes.findIndex(
      (qualification) => qualification.id === id,
    )
    if (index !== -1) {
      this.props.shiftBuildingBonusTypes.splice(index, 1)
    }
  }

  private createNewEntry = async () => {
    const label = prompt('Bitte geben Sie den Namen der neuen Zulage ein:')
    if (!label) {
      return
    }
    try {
      const response = await hermes.create(
        `/api/${this.context.instance.id}/dropdownEntries`,
        {
          type: 'schichtzulage',
          label,
          abbreviation: '',
        },
      )
      return response.id
    } catch (e: any) {
      const error =
        e?.id === ConflictError.id
          ? e.message
          : 'Beim Erstellen ist ein Fehler aufgetreten.'
      alert(error)
    }
  }

  render() {
    const { shiftBonusTypes, shiftBuildingBonusTypes, readOnly } = this.props
    return (
      <div>
        <div className='flex text-lg text-gray-600'>
          Schichtzulage für diese Schicht
          {!readOnly && (
            <RoundIcon
              classNameContainer='ml-auto'
              icon='fa fa-plus'
              onClick={this.addShiftBonusType}
            />
          )}
        </div>
        <div className='my-4 flex flex-col gap-4'>
          {shiftBonusTypes.length === 0 && (
            <div>Derzeit sind keine Zulagen hinterlegt.</div>
          )}
          {shiftBonusTypes.map((bonusType) => {
            const model = new Model(bonusType)
            return (
              <div key={bonusType.id} className='flex items-center gap-4'>
                <InputDropdownEntry
                  className='flex-auto'
                  label='Typ'
                  type='schichtzulage'
                  model={model}
                  name='schichtzulageId'
                  readOnly={readOnly}
                  disabled={readOnly}
                  allowCreate={!readOnly}
                  onCreate={this.createNewEntry}
                />
                <InputText
                  className='flex-auto'
                  model={model}
                  name='notes'
                  label='Notiz'
                  readOnly={readOnly}
                  disabled={readOnly}
                />
                {!readOnly && (
                  <RoundIcon
                    classNameContainer='flex-content'
                    color='danger'
                    icon='fa fa-trash'
                    onClick={() => this.deleteShiftBonusType(bonusType.id)}
                  />
                )}
              </div>
            )
          })}
        </div>
        <div className='flex text-lg text-gray-600'>
          Schichtzulage für das komplette Gebäude
          {!readOnly && (
            <RoundIcon
              classNameContainer='ml-auto'
              icon='fa fa-plus'
              onClick={this.addShiftBuildingBonusType}
            />
          )}
        </div>
        <div className='mt-4 flex flex-col gap-4'>
          {shiftBuildingBonusTypes.length === 0 && (
            <div>Derzeit sind keine Zulagen hinterlegt.</div>
          )}
          {shiftBuildingBonusTypes.map((buildingBonusType) => {
            const model = new Model(buildingBonusType)
            return (
              <div key={buildingBonusType.id} className='flex items-center gap-4'>
                <InputDropdownEntry
                  className='flex-auto'
                  label='Typ'
                  type='schichtzulage'
                  model={model}
                  name='schichtzulageId'
                  readOnly={readOnly}
                  disabled={readOnly}
                  allowCreate={!readOnly}
                  onCreate={this.createNewEntry}
                />
                <InputText
                  className='flex-auto'
                  model={model}
                  name='notes'
                  label='Notiz'
                  readOnly={readOnly}
                  disabled={readOnly}
                />
                {!readOnly && (
                  <RoundIcon
                    classNameContainer='flex-content'
                    color='danger'
                    icon='fa fa-trash'
                    onClick={() =>
                      this.deleteShiftBuildingBonusType(buildingBonusType.id)
                    }
                  />
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
