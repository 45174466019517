import { hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { Message } from 'components/Message'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  resident: IResident
}

@observer
export class CreateWorkplanEmployee extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private createUserForWorkplan = async () => {
    runInAction(() => (this.error = null))
    try {
      await hermes.create(`/api/${this.context.instance.id}/workplan/workplanResidents`, {
        residentId: this.props.resident.id,
      })
    } catch (_e) {
      runInAction(
        () =>
          (this.error =
            'Beim Freischalten der Arbeitsplanung ist ein Fehler aufgetreten.'),
      )
    }
  }

  render() {
    return (
      <div>
        {this.error && (
          <Message className='mb-4' color='danger'>
            {this.error}
          </Message>
        )}
        <Message color='primary'>
          Dieser Bewohner ist noch nicht für die Arbeitsplanung freigeschalten.
        </Message>
        <div className='flex mt-4'>
          <Button
            onClick={this.createUserForWorkplan}
            color='success'
            className='mx-auto'
          >
            Arbeitsplanung freischalten
          </Button>
        </div>
      </div>
    )
  }
}
