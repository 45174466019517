import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { Location } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Model } from 'components/Form/Model'
import { ShiftFilter } from './components/ShiftFilter'
import { IShiftFilter } from 'contracts/workplan/interfaces/IShiftFilter'
import { ShiftList } from './components/ShiftList'
import { ShiftDialog } from './components/ShiftDialog'
import { getSavedShiftSearchResultsFilter } from './helpers/getSavedShiftSearchResultsFilter'
import { reaction, toJS } from 'mobx'
import { storage } from 'services/storage'

interface Props {
  location: Location
  navigate: (path: string) => void
}

@observer
export class Shifts extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly disposers: Disposer[] = []
  private filterModel: Model<IShiftFilter>

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.filterModel = new Model(getSavedShiftSearchResultsFilter(context.user))
  }

  componentDidMount() {
    this.disposers.push(
      reaction(
        () => toJS(this.filterModel.values),
        () => storage.set(`shiftlist.${this.context.user.id}`, this.filterModel.values),
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  render() {
    const match = this.props.location.pathname
      .replace(/\/$/, '')
      .toLowerCase()
      .match(/^\/shifts\/(\d+)\/([a-z-]+)$/)
    const shiftId = match ? match[1] : null
    return (
      <div className='pt-14 bg-gray-100 min-h-full flex flex-col'>
        <ShiftFilter filterModel={this.filterModel} navigate={this.props.navigate} />
        <div className='flex-auto relative overflow-hidden'>
          <div
            className={`z-1 transition-right bg-white shadow-md absolute top-0 bottom-0 left-0 ${
              shiftId ? 'right-[700px]' : 'right-0'
            }`}
          >
            <ShiftList
              key={`${this.filterModel.values.month}-${this.filterModel.values.buildingId}`}
              filterModel={this.filterModel}
              shiftId={shiftId}
            />
          </div>
          <div
            className={`z-1 md:z-0 overflow-auto transition-right bg-gray-100 absolute top-0 bottom-0 w-full md:w-[700px] ${
              shiftId ? 'right-0' : '-right-[1000px]'
            }`}
          >
            <ShiftDialog
              key={`${this.filterModel.values.month}-${this.filterModel.values.buildingId}`}
              filterModel={this.filterModel}
              shiftId={shiftId}
            />
          </div>
        </div>
      </div>
    )
  }
}
