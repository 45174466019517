import { TitleBar } from 'components/TitleBar'
import { TitleBarTab } from 'components/TitleBar/TitleBarTab'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  companyId?: string
}

export const CompanyTitleBar: React.FC<Props> = (props) => {
  const context = React.useContext(AppContext)
  return (
    <div className='flex-content sticky top-14 z-10'>
      <TitleBar>
        {props.companyId && (
          <>
            <TitleBarTab label='Firma' to={`/companies/${props.companyId}/overview`} />
            <TitleBarTab
              label='Ausweisvorlage'
              to={`/companies/${props.companyId}/card`}
            />
            {context.permissions.companies_editExportLohnartenConfig && (
              <TitleBarTab
                label='Kanzleiexport'
                to={`/companies/${props.companyId}/export`}
              />
            )}
            <TitleBarTab
              label='Lohnerhöhung'
              to={`/companies/${props.companyId}/salaryGroups`}
            />
          </>
        )}
        {!props.companyId && (
          <TitleBarTab label='Neue Firma' to={`/companies/new/overview`} />
        )}
      </TitleBar>
    </div>
  )
}
