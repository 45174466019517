import { Model } from 'components/Form/Model'
import { InputCompany } from 'components/Form/components/InputCompany'
import { InputQualification } from 'components/Form/components/InputQualification'
import { RoundIcon } from 'components/RoundIcon'
import { IShiftCompany } from 'contracts/workplan/interfaces/IShiftCompany'
import { IShiftQualification } from 'contracts/workplan/interfaces/IShiftQualification'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { action, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'

let idGenerator = 0

interface Props {
  shiftCompanies: IShiftCompany[]
  shiftQualifications: IShiftQualification[]
  shift: IShiftSearchResult
  readOnly?: boolean
}

@observer
export class ShiftQualificationsForm extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  @action
  private addCompany = (e: MouseEvent) => {
    e.preventDefault()
    if (!this.props.shiftCompanies) {
      return
    }
    this.props.shiftCompanies.push({
      id: String(idGenerator--),
      companyId: '',
      shiftId: this.props.shift.id,
    })
  }

  @action
  private addQualification = (e: MouseEvent) => {
    e.preventDefault()
    if (!this.props.shiftQualifications) {
      return
    }
    this.props.shiftQualifications.push({
      id: String(idGenerator--),
      qualificationId: '',
      shiftId: this.props.shift.id,
    })
  }

  @action
  private deleteCompany = (id: string) => {
    if (!this.props.shiftCompanies) {
      return
    }
    const index = this.props.shiftCompanies.findIndex((company) => company.id === id)
    if (index !== -1) {
      this.props.shiftCompanies.splice(index, 1)
    }
  }

  @action
  private deleteQualification = (id: string) => {
    if (!this.props.shiftQualifications) {
      return
    }
    const index = this.props.shiftQualifications.findIndex(
      (qualification) => qualification.id === id,
    )
    if (index !== -1) {
      this.props.shiftQualifications.splice(index, 1)
    }
  }

  render() {
    const { shiftCompanies, shiftQualifications, readOnly } = this.props
    return (
      <div>
        <div className='flex text-lg text-gray-600'>
          Erlaubte Firmen
          {!readOnly && (
            <RoundIcon
              classNameContainer='ml-auto'
              icon='fa fa-plus'
              onClick={this.addCompany}
            />
          )}
        </div>
        <div className='my-4 flex flex-col gap-4'>
          {shiftCompanies.length === 0 && (
            <div>Derzeit sind keine Firmen hinterlegt.</div>
          )}
          {shiftCompanies.map((company) => {
            const model = new Model(company)
            return (
              <div key={company.id} className='flex items-center gap-4'>
                <InputCompany
                  className='flex-auto'
                  model={model}
                  name='companyId'
                  readOnly={readOnly}
                  disabled={readOnly}
                />
                {!readOnly && (
                  <RoundIcon
                    classNameContainer='flex-content'
                    color='danger'
                    icon='fa fa-trash'
                    onClick={() => this.deleteCompany(company.id)}
                  />
                )}
              </div>
            )
          })}
        </div>
        <div className='flex text-lg text-gray-600'>
          Anforderungen
          {!readOnly && (
            <RoundIcon
              classNameContainer='ml-auto'
              icon='fa fa-plus'
              onClick={this.addQualification}
            />
          )}
        </div>
        <div className='mt-4 flex flex-col gap-4'>
          {shiftQualifications.length === 0 && (
            <div>Derzeit sind keine Anforderungen hinterlegt.</div>
          )}
          {shiftQualifications.map((qualification) => {
            const model = new Model(qualification)
            return (
              <div key={qualification.id} className='flex items-center gap-4'>
                <InputQualification
                  model={model}
                  name='qualificationId'
                  readOnly={readOnly}
                  disabled={readOnly}
                />
                {!readOnly && (
                  <RoundIcon
                    classNameContainer='flex-content'
                    color='danger'
                    icon='fa fa-trash'
                    onClick={() => this.deleteQualification(qualification.id)}
                  />
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
