import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { Callout } from 'components/Callout'
import { Spinner } from 'components/Spinner'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { IEmployeeSearchResultsFilter } from 'contracts/employees/interfaces/IEmployeeSearchResultsFilter'
import { IEmployeeSearchResultsMetadata } from 'contracts/employees/interfaces/IEmployeeSearchResultsMetadata'
import { action, makeObservable, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { UnsyncedCollection } from 'modules/Residents/modules/FindRecord/models/UnsyncedCollection'
import * as React from 'react'
import { FixedSizeList as List } from 'react-window'
import debounce from 'lodash/debounce'
import { EmployeeListRow } from './components/EmployeeListRow'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { LoadMoreButton } from 'components/LoadMoreButton'

interface Props {
  employeeId: string | null
  employees: UnsyncedCollection<
    IEmployeeSearchResult,
    IEmployeeSearchResultsMetadata,
    IEmployeeSearchResultsFilter
  >
}

@observer
export class EmployeeList extends React.Component<Props, {}> {
  private parentRef: HTMLDivElement | null = null
  private readonly disposers: Disposer[] = []
  @observable private readonly size = { width: 0, height: 0, small: false }

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    this.onResize()
    window.addEventListener('resize', this.debouncedResize)
    this.disposers.push(() => window.removeEventListener('resize', this.debouncedResize))
    this.disposers.push(
      reaction(
        () => this.props.employeeId,
        () => this.debouncedResize(),
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private onResize = action(() => {
    if (!this.parentRef) {
      return
    }
    const size = this.parentRef.getBoundingClientRect()
    this.size.width = size.width
    this.size.height = size.height
    this.size.small = size.width < 800
  })

  private debouncedResize: any = debounce(this.onResize, 200)

  private setParentRef = (ref: HTMLDivElement) => {
    if (!ref) {
      this.parentRef = null
    } else {
      this.parentRef = ref
      this.onResize()
    }
  }

  private renderRow = ({ index, style }) => {
    const employee = this.props.employees.resources?.[index]
    if (!employee) {
      return <div style={style} />
    }

    if (index === +this.props.employees.metadata?.page.split(',')[1]! - 1) {
      return (
        <div className='flex justify-center items-center' style={style}>
          <LoadMoreButton
            collection={this.props.employees}
            incrementBy={50}
            pluralText={`Sie sehen alle ${
              (this.props.employees.resources?.length || 0) < 5
                ? ''
                : this.props.employees.resources?.length
            } Mitarbeiter`}
          />
        </div>
      )
    }

    return (
      <EmployeeListRow
        style={style}
        employee={employee}
        rerender={this.props.employees.rerender}
        size={this.size}
      />
    )
  }

  render() {
    const employees = this.props.employees
    if (employees.error && employees.error.id !== RequestPendingError.id) {
      return (
        <div className='absolute top-0 left-0 right-0 bottom-0 flex' key={1}>
          <Callout
            className='my-auto'
            icon='fas fa-exclamation-triangle'
            iconColor='#f56565'
            title='Fehler beim Laden der Mitarbeiter'
          />
        </div>
      )
    }
    if (!employees.resources) {
      return <Spinner />
    }
    if (employees.resources.length === 0) {
      return (
        <div className='absolute top-0 left-0 right-0 bottom-0 flex' key={2}>
          <Callout
            className='my-auto'
            icon='fas fa-filter'
            iconColor='#22c55e'
            title={'Keine Mitarbeiter mit den aktuellen\nFilterkriterien gefunden'}
          />
        </div>
      )
    }
    return (
      <div
        ref={this.setParentRef}
        className='absolute top-0 left-0 right-0 bottom-0 overflow-hidden'
      >
        {this.size.height > 0 && (
          <List
            key={employees.metadata?.key}
            height={this.size.height}
            itemCount={employees.resources.length}
            itemSize={66}
            width={''}
          >
            {this.renderRow}
          </List>
        )}
      </div>
    )
  }
}
