import { Dayjs } from 'dayjs'
import { dayjs } from '../../general/helpers/dayjs'

/**
 * Get the break intervals for a given shift.
 * @param begin - The begin time of the shift.
 * @param end - The end time of the shift.
 * @returns The break intervals.
 */
export function getBreakIntervals(begin: Dayjs, end: Dayjs, hasBreak: boolean): string {
  if (!hasBreak) {
    return ''
  }
  const diff = dayjs(end).diff(begin, 'minutes')
  let breakIntervals = ''
  if (diff > 6 * 60) {
    const b = dayjs(begin).add(6, 'hours')
    const e = dayjs(begin).add(6.5, 'hours')
    breakIntervals = `${b.format('HH:mm')}-${e.format('HH:mm')}`
    if (diff > 9.5 * 60) {
      const b = dayjs(begin).add(9.5, 'hours')
      const e = dayjs(begin).add(9.75, 'hours')
      breakIntervals += `,${b.format('HH:mm')}-${e.format('HH:mm')}`
    }
  }
  return breakIntervals
}
