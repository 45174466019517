import { Tooltip } from 'components/Tooltip'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'

interface Props {
  shift: IShiftSearchResult
  style: any
}

export const ShiftListRow: React.FC<Props> = observer(({ shift, style }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const selected = location.pathname.indexOf(`/${shift.id}/`) !== -1
  const utilization = `${
    shift.actualPlannedParticipants % 1 === 0
      ? shift.actualPlannedParticipants
      : shift.actualPlannedParticipants.toFixed(1).replace('.', ',')
  } / ${
    shift.targetPlannedParticipants % 1 === 0
      ? shift.targetPlannedParticipants
      : shift.targetPlannedParticipants.toFixed(1).replace('.', ',')
  }`

  function onOpen() {
    if (selected) {
      navigate(`/shifts`)
      return
    }

    const path = window.location.pathname.replace(/\/$/, '').toLowerCase()
    if (path.endsWith('/shifts')) {
      navigate(`/shifts/${shift.id}/overview`)
    } else {
      // Navigate to different user but same open tab as before
      navigate(`/shifts/${shift.id}/${path.split('/').pop()}`)
    }
  }

  return (
    <div
      style={style}
      className={`border-b border-gray-200 flex py-2 ${selected ? 'bg-indigo-500' : ''}`}
    >
      <div
        className='mx-6 min-w-0 flex-auto truncate flex-col group cursor-pointer'
        onClick={onOpen}
      >
        <div className='mt-1 flex items-center gap-x-1.5'>
          <span
            className={`has-tooltip flex-content cursor-pointer inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium text-white ${
              shift.actualPlannedParticipants < shift.targetPlannedParticipants
                ? 'bg-red-500'
                : 'bg-green-500'
            }`}
          >
            {shift.actualPlannedParticipants < shift.targetPlannedParticipants ? (
              <span className='mr-1'>
                <i className='fas fa-exclamation-triangle' />
              </span>
            ) : (
              <span className='mr-1'>
                <i className='fas fa-check' />
              </span>
            )}
            {utilization}
            <Tooltip position='right'>Belegung</Tooltip>
          </span>
          <div className='flex-auto'>
            <div
              className={`flex-[0_0_auto] lg:flex-[0_1_auto] group-hover:underline truncate ${
                selected ? 'text-white' : 'text-black group-hover:text-blue-500'
              }`}
            >
              {shift.label}
            </div>
          </div>
        </div>
        <div
          className={`text-sm text-gray-600 ${selected ? 'text-white' : 'text-black'}`}
        >
          {shift.compoundLabel === shift.buildingLabel
            ? shift.buildingLabel
            : `${shift.compoundLabel} / ${shift.buildingLabel}`}
        </div>
      </div>
    </div>
  )
})
