import { XIcon } from '@heroicons/react/outline'
import { IEmployeeDayScheduleCell } from 'contracts/workplan/interfaces/IEmployeeDayScheduleCell'
import * as React from 'react'
import { Dialog } from '@headlessui/react'
import { observer } from 'mobx-react'
import { makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { Button } from 'components/Form/components/Button'
import { PreventRouteChange } from 'components/PreventRouteChange'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { InputTextarea } from 'components/Form/components/InputTextarea'
import { Model } from 'components/Form/Model'
import { InputText } from 'components/Form/components/InputText'
import { ShiftCellPatchValidator } from 'contracts/workplan/validators/ShiftCellPatchValidator'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { Message } from 'components/Message'
import { InputForm } from 'components/Form/components/InputForm'

interface Props {
  shiftCell: IEmployeeDayScheduleCell
  employeePoolId: string
  viewId: string
  onClose: (success?: boolean) => void
}

@observer
export class ShiftCellDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IEmployeeDayScheduleCell>
  @observable private hasUnsavedChanges: boolean = false
  @observable private loading: boolean = false
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model(
      { ...props.shiftCell },
      ShiftCellPatchValidator.omit({
        date: true,
        shiftId: true,
        userId: true,
        type: true,
        employeePoolId: true,
      }),
    )
  }

  componentDidMount(): void {
    reaction(
      () => toJS(this.model.values),
      () => (this.hasUnsavedChanges = true),
    )
  }

  private onSubmit = async () => {
    runInAction(() => (this.error = null))
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    try {
      runInAction(() => (this.loading = true))
      await hermes.patch(
        `/api/${this.context.instance.id}/workplan/employeeWorkScheduleDetails/${this.props.shiftCell.userId}`,
        {
          break: this.model.values.break,
          notes: this.model.values.notes,
          date: this.props.shiftCell.date,
          shiftId: this.props.shiftCell.shiftId,
          userId: this.props.shiftCell.userId,
          type: this.props.shiftCell.type,
          employeePoolId: this.props.employeePoolId,
          viewId: this.props.viewId,
        },
      )
      runInAction(() => {
        this.loading = false
        this.hasUnsavedChanges = false
      })
      this.props.onClose(true)
    } catch (_e) {
      runInAction(
        () =>
          (this.error =
            'Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.'),
      )
    }
  }

  render() {
    const { shiftCell } = this.props
    return (
      <div>
        <div className='absolute top-0 right-0 pt-4 pr-6'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='px-6 pt-6 pb-4 -mx-6 -mt-6 border-b border-gray-200'>
          <div className='flex items-start'>
            <div className='-mt-2 text-left'>
              <Dialog.Title
                as='h3'
                className='text-lg leading-6 font-medium text-gray-900'
              >
                {shiftCell.label}
              </Dialog.Title>
            </div>
          </div>
        </div>

        <div className='bg-gray-100 p-6 -mx-6'>
          {this.error && (
            <Message
              color='danger'
              className='mb-6 border border-red-500 sticky top-0 z-10'
            >
              {this.error}
            </Message>
          )}
          <div className='rounded shadow bg-white p-6 flex'>
            <div className='flex-[0_0_140px] leading-7'>
              <div>Details</div>
              <div className='text-gray-400 text-xs'>
                Überschreiben Sie die
                <br />
                Pause oder hinterlassen
                <br />
                Sie ein Kommentar.
              </div>
            </div>

            <InputForm className='flex-auto' onSubmit={this.onSubmit}>
              <div id={this.model.id} className='flex flex-col gap-4'>
                {shiftCell.type === 'real' && (
                  <InputText
                    className='w-full'
                    model={this.model}
                    name='break'
                    label='Pause'
                    placeholder='HH:mm-HH:mm'
                  />
                )}
                <InputTextarea model={this.model} name='notes' label='Kommentar' />
              </div>
            </InputForm>
          </div>
        </div>

        <div
          className='flex gap-2 justify-end py-4 px-6 -mb-6 -mx-6 sticky z-1 text-right bottom-0 bg-white border-t border-gray-200'
          style={{ borderRadius: '0 0 8px 8px' }}
        >
          <Button color='secondary' outline onClick={() => this.props.onClose()}>
            Schließen
          </Button>
          <Button
            color='primary'
            disabled={this.loading}
            onClick={this.onSubmit}
            loading={this.loading}
          >
            Speichern
          </Button>
        </div>
        {this.hasUnsavedChanges && <PreventRouteChange />}
        {this.loading && <DialogOverlaySpinner opaque />}
      </div>
    )
  }
}
