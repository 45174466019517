import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { InputCompound } from 'components/Form/components/InputCompound'
import { InputForm } from 'components/Form/components/InputForm'
import { InputText } from 'components/Form/components/InputText'
import { Message } from 'components/Message'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { z } from 'zod'
import { hermes } from '@byll/hermes'
import { AppContext } from 'services/connection/models/AppContext'
import { InputAsyncMultiSelect } from 'components/Form/components/InputAsyncMultiSelect'
import { ConflictError } from 'contracts/errors/HermesErrors'

interface Props {
  view: any
  employeePoolId: string
  month: string
  onClose: (id?: string) => void
}

@observer
export class EditViewDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<any>
  private readonly compoundModel: Model<{ id: string }>
  @observable private saving = false
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    this.model = new Model({ ...props.view }, z.object({ label: z.string().min(1) }))
    this.compoundModel = new Model({ id: '' })
  }

  private onSubmit = async () => {
    if (!this.model.values.buildingIds) {
      runInAction(() => (this.error = 'Bitte wählen Sie mindestens ein Gebäude aus.'))
      return
    }
    if (!this.model.isValid()) {
      this.model.setFocusToLeftTopmostInvalidField()
      return
    }
    let res: any
    try {
      if (!this.props.view.id) {
        res = await hermes.create(
          `/api/${this.context.instance.id}/workplan/views/${this.props.month}/views`,
          this.model.values,
        )
      } else {
        res = await hermes.patch(
          `/api/${this.context.instance.id}/workplan/views/${this.props.month}/views/${this.props.view.id}`,
          {
            ...this.model.values,
            month: this.props.month,
            type: 'plan',
            employeePoolId: this.props.employeePoolId,
          },
        )
      }
      this.props.onClose(res.id)
    } catch (e: any) {
      if (e.id && e.id === ConflictError.id) {
        runInAction(() => (this.error = e.message))
      } else {
        runInAction(
          () => (this.error = 'Beim Speichern der Ansicht ist ein Fehler aufgetreten.'),
        )
      }
    }
  }

  render() {
    const isNew = !this.props.view.id
    return (
      <>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>

        <div className='flex items-start mb-4'>
          <div className='-mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {isNew ? 'Ansicht erstellen' : 'Ansicht bearbeiten'}
            </Dialog.Title>
          </div>
        </div>

        {this.error && (
          <Message color='danger' className='my-4'>
            {this.error}
          </Message>
        )}

        <div className='border-y border-gray-300 -mx-6 bg-gray-100'>
          <div className='p-6 m-6 bg-white rounded-md shadow-md'>
            <InputForm id={this.model.id} onSubmit={this.onSubmit}>
              <div className='relative grid grid-cols-2 gap-4' id={this.model.id}>
                <InputText
                  className={isNew ? 'col-span-1' : 'col-span-2'}
                  model={this.model}
                  name='label'
                  label='Bezeichnung'
                />
                {isNew && (
                  <InputCompound
                    className='col-span-1'
                    model={this.compoundModel}
                    name='id'
                    label='Geländefilter'
                  />
                )}
                <InputAsyncMultiSelect
                  className='col-span-2'
                  model={this.model}
                  name='buildingIds'
                  label='Gebäude'
                  options={`/api/${this.context.instance.id}/accommodations/buildings`}
                  optionParams={`compoundId=${this.compoundModel.values.id}&includeCompoundLabel=yes`}
                  filter={{ includeCompoundLabel: 'yes' }}
                  initParams={{ readOnly: true }}
                />
              </div>
            </InputForm>
          </div>
        </div>

        <div className='flex flex-row-reverse justify-between mt-4'>
          <div>
            <Button color='secondary' outline onClick={() => this.props.onClose()}>
              Abbrechen
            </Button>
            <Button
              color='primary'
              disabled={this.saving}
              className='ml-2'
              onClick={this.onSubmit}
            >
              {isNew ? 'Ansicht erstellen' : 'Speichern'}
            </Button>
          </div>
        </div>

        {this.saving && <DialogOverlaySpinner opaque />}
      </>
    )
  }
}
