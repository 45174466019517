import { Model } from 'components/Form/Model'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import { observer } from 'mobx-react'
import * as React from 'react'

interface Props {
  shiftId: string | null
  selectShiftModel: Model<{
    selectedBuilding: IBuilding | null
    selectedShift: IShift | null
    compoundId: string
    view: 'select' | 'shortlist'
  }>
  toggleDropdown: (e: any) => void
}

@observer
export class ShiftLabel extends React.Component<Props, {}> {
  private setLabel = () => {
    if (
      this.props.shiftId &&
      this.props.selectShiftModel.values.selectedBuilding &&
      this.props.selectShiftModel.values.selectedShift
    ) {
      return (
        <div className='flex'>
          <span className='truncate mr-2' style={{ maxWidth: 120 }}>
            {this.props.selectShiftModel.values.selectedBuilding.label}
          </span>
          <span className='truncate'>
            <i className='fa fa-angle-right' />
          </span>
          <span className='truncate ml-2' style={{ maxWidth: 120 }}>
            {this.props.selectShiftModel.values.selectedShift.label}
          </span>
        </div>
      )
    }
    return null
  }

  render() {
    return (
      <div
        className='border border-gray-300 text-gray-500 hover:border-gray-500 focus:border-gray-500 hover:bg-gray-500 hover:text-white focus:ring-gray-500 focus:bg-gray-500 focus:text-white py-2 px-4 cursor-pointer text-center rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2'
        onClick={this.props.toggleDropdown}
        style={{
          borderRadius: this.props.shiftId ? '0.375rem 0 0 0.375rem' : '0.375rem',
        }}
      >
        {this.setLabel() || 'Schicht wählen'}
      </div>
    )
  }
}
