import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { Model } from 'components/Form/Model'
import { Message } from 'components/Message'
import { RoundIcon } from 'components/RoundIcon'
import { IShiftSearchResult } from 'contracts/workplan/interfaces/IShiftSearchResult'
import { reaction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import { DayOverridesEditDialog } from './components/DayOverridesEditDialog'
import { DayOverridesForm } from './components/DayOverridesForm'

interface Props {
  shift: IShiftSearchResult
}

@observer
export class DayOverrides extends React.Component<Props, {}> {
  static contextType = AppContext
  private dayOverridesModel: Model<{ [key: number]: string | null }>
  private disposers: Disposer[] = []

  constructor(props: Props) {
    super(props)
    let data: { [key: number]: string | null } = {}
    if (props.shift.participantsOverrideDays.hasOwnProperty('0') === false) {
      data = { 0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null, 7: null }
    } else if (typeof props.shift.participantsOverrideDays === 'string') {
      data = JSON.parse(props.shift.participantsOverrideDays)
    } else {
      data = props.shift.participantsOverrideDays
    }
    this.dayOverridesModel = new Model(data)
  }

  componentDidMount(): void {
    this.disposers.push(
      reaction(
        () => this.props.shift.participantsOverrideDays,
        () => {
          this.dayOverridesModel = new Model({
            ...this.props.shift.participantsOverrideDays,
          })
        },
      ),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private editDayOverrides = async () => {
    const promise = box.custom(
      <DayOverridesEditDialog
        shift={this.props.shift}
        onClose={(result?: boolean) => promise.close(result)}
      />,
      { closable: true, context: this.context, size: 'md' },
    )
  }

  render() {
    return (
      <>
        {this.context.permissions.workplan_shifts > 1 && (
          <div className='absolute top-4 right-4'>
            <RoundIcon icon='fa fa-edit' onClick={this.editDayOverrides} />
          </div>
        )}
        <div className='text-lg text-gray-600'>Abweichung - Wochentag</div>
        <Message color='primary' className='mt-4 mb-6'>
          Legen Sie hier die Abweichungen für die einzelnen Wochentage fest.
        </Message>
        <DayOverridesForm
          key={this.props.shift.participantsOverrideDays.toString()}
          model={this.dayOverridesModel}
          readOnly
        />
      </>
    )
  }
}
