import { Collection, hermes } from '@byll/hermes'
import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { Message } from 'components/Message'
import { Spinner } from 'components/Spinner'
import { ConflictError, NotAuthorizedError } from 'contracts/errors/HermesErrors'
import { IBooking } from 'contracts/residents/interfaces/IBooking'
import { IResident } from 'contracts/residents/interfaces/IResident'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { dayjs } from 'helpers/dayjs'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'
import { ResponsibilityEndModel } from '../index'
import { IOccupancy } from 'contracts/residents/interfaces/IOccupancy'
import { IRoom } from 'contracts/accommodations/interfaces/IRoom'
import { isJbpInstance } from 'contracts/general/helpers/instanceIds'
import { DialogOverlaySpinner } from 'components/Dialog/components/DialogOverlaySpinner'

interface Props {
  resident: IResident
  model: Model<ResponsibilityEndModel>
  members: Collection<IResidentSearchResult> // Family members
  onClose: (val?: any) => void
  setStep: (step: 'form' | 'creating') => void
}

const types = {
  success: {
    parent: 'bg-green-50 text-green-600 border border-green-500 mt-6 rounded-md p-3',
    message: 'text-green-500 text-sm ml-2',
  },
  warning: {
    parent: 'bg-yellow-50 text-yellow-500 border border-yellow-500 mt-6 rounded-md p-3',
    message: 'text-yellow-400 text-sm ml-2',
  },
  error: {
    parent: 'bg-red-50 text-red-500 border border-red-500 mt-6 rounded-md p-3',
    message: 'text-red-400 text-sm ml-2',
  },
}

interface IMessage {
  resident: { id: string; firstName: string; lastName: string }
  message: string
  type: 'success' | 'warning' | 'error'
}

@observer
export class ResponsibilityEndFormStep2Creating extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable.ref private messages: IMessage[] | null = null
  @observable.ref private rooms: IRoom[] | null = null
  @observable.ref private emptyRooms: string[] | null = null
  @observable private loading = false
  @observable private error: string | null = null

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    void this.create()
    void this.loadRooms()
  }

  private loadRooms = async () => {
    try {
      const rooms = await hermes.indexOnceNew<IRoom>(
        `/api/${this.context.instance.id}/accommodations/rooms?compoundId=${this.props.model.values.compoundId}`,
      )
      runInAction(() => (this.rooms = rooms))
    } catch (_e) {
      runInAction(() => (this.error = 'Beim Laden der Räume ist ein Fehler aufgetreten.'))
    }
  }

  private create = async () => {
    const begin = dayjs(
      `${this.props.model.values.beginDate} ${this.props.model.values.beginTime}`,
    )
    const data: Partial<IBooking> = {
      instanceId: this.context.instance.id,
      type: 'responsibility-end',
      compoundId: this.props.model.values.compoundId,
      // residentId
      roomId: null,
      bed: null,
      reason: this.props.model.values.reason,
      comment: this.props.model.values.comment,
      beginAt: begin.toISOString(),
      endAt: begin.add(1, 'minute').toISOString(),
      extra: this.props.model.values.extra,
    }
    const messages: IMessage[] = []

    for (const member of this.props.members.resources || []) {
      if (!member.data || member.data.deletedAt) {
        continue
      }
      if (member.id !== this.props.resident.id && !this.props.model.values.family) {
        continue
      }
      try {
        await hermes.create(`/api/${this.context.instance.id}/bookings`, {
          ...data,
          residentId: member.id,
        })
        messages.push({
          resident: member.data,
          message: 'Zuständigkeit erfolgreich beendet.',
          type: 'success',
        })
      } catch (e: any) {
        if (e.id === ConflictError.id) {
          messages.push({
            resident: member.data,
            message: e.message,
            type: e.details?.type || 'error',
          })
        } else if (e.id === NotAuthorizedError.id) {
          messages.push({
            resident: member.data,
            message: 'Sie haben nicht die nötige Berechtigung für diesen Vorgang.',
            type: 'error',
          })
        } else {
          messages.push({
            resident: member.data,
            message: 'Die Zuständigkeit konnte nicht beendet werden.',
            type: 'error',
          })
        }
      }
    }

    let emptyRooms: string[] = []
    if (isJbpInstance(this.context.instance.id)) {
      let roomIds: string[] = []
      for (const member of this.props.members.resources || []) {
        if (!member.data || member.data.deletedAt) {
          continue
        }
        if (member.id !== this.props.resident.id && !this.props.model.values.family) {
          continue
        }
        const lastBooking = member.data?.data.bookings
          ?.filter(
            (b) => b.type === 'internal-residence' && dayjs().diff(b.endAt, 'hour') < 12,
          )
          .sort((a, b) => dayjs(b.beginAt).diff(dayjs(a.beginAt)))[0]
        if (!lastBooking) {
          continue
        }
        if (lastBooking.roomId && !roomIds.includes(lastBooking.roomId)) {
          roomIds.push(lastBooking.roomId)
        }
      }
      for (const roomId of roomIds) {
        const occupancies = await hermes.indexOnceNew<IOccupancy>(
          `/api/${
            this.context.instance.id
          }/accommodations/occupancies?queryAt=${dayjs().toISOString()}&roomId=${roomId}`,
        )
        if (occupancies.length === 0) {
          continue
        }
        if (occupancies[0].occupied === '0') {
          emptyRooms.push(roomId)
        }
      }
    }

    runInAction(() => {
      this.messages = messages
      this.emptyRooms = emptyRooms
    })
  }

  private lockRooms = async () => {
    runInAction(() => (this.loading = true))
    for (const roomId of this.emptyRooms || []) {
      try {
        await hermes.update(
          `/api/${this.context.instance.id}/accommodations/rooms/${roomId}`,
          {
            lock: {
              beginAt: new Date().toISOString(),
              beginBy: `${this.context.user.lastName}, ${this.context.user.firstName}`,
              endAt: null,
              endBy: '',
              reason: 'Reinigung',
              notes: '',
            },
          },
        )
      } catch (_e) {
        runInAction(
          () => (this.error = 'Beim Sperren der Räume ist ein Fehler aufgetreten.'),
        )
      }
    }
    runInAction(() => (this.loading = false))
  }

  render() {
    return (
      <div className='min-h-[242px] flex flex-col'>
        {!this.messages && <Spinner />}
        {this.messages && (
          <>
            {this.messages.length === 0 && (
              <Message color='danger' className='mt-6'>
                Beim Beenden der Zuständigkeit ist ein Fehler aufgetreten.
              </Message>
            )}
            {this.error && (
              <Message color='danger' className='mt-6'>
                {this.error}
              </Message>
            )}
            {this.messages.map((m) => (
              <div key={m.resident.id} className={types[m.type]?.parent}>
                <span className='text-md'>{`${m.resident.lastName.toUpperCase()}, ${
                  m.resident.firstName
                }`}</span>
                <span className={types[m.type]?.message}>{m.message}</span>
              </div>
            ))}
            {isJbpInstance(this.context.instance.id) &&
              this.emptyRooms &&
              this.emptyRooms.length > 0 && (
                <div className='my-4'>
                  <div className='mb-2'>Es werden folgende Räume frei:</div>
                  <ul>
                    {this.emptyRooms.map((roomId) => {
                      const room = this.rooms?.find((r) => r.id === roomId)
                      if (!room) {
                        return null
                      }
                      return (
                        <li key={roomId} className='border-t border-gray-200 py-2'>
                          {room.label}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            <div className='mt-auto border-t border-gray-300 flex-content text-right py-6 -mb-6 z-10 bottom-0 sticky bg-white'>
              <Button color='secondary' outline onClick={this.props.onClose}>
                Schließen
              </Button>
              {this.emptyRooms && this.emptyRooms.length > 0 && (
                <Button color='primary' className='ml-2' onClick={this.lockRooms}>
                  Für Reinigung sperren
                </Button>
              )}
            </div>
          </>
        )}
        {this.loading && <DialogOverlaySpinner opaque />}
      </div>
    )
  }
}
