import { isIntegerString } from 'contracts/general/helpers/isIntegerString'
import { isMonth } from 'contracts/general/helpers/isMonth'
import { isYmdDate } from 'contracts/general/helpers/isYmdDate'
import { IUser } from 'contracts/users/interfaces/IUser'
import { IMonthlyEmployeeWorkScheduleFilter } from 'contracts/workplan/interfaces/IMonthlyEmployeeWorkScheduleFilter'
import { dayjs } from 'helpers/dayjs'
import { isPlainObject } from 'helpers/isPlainObject'
import { storage } from 'services/storage'

export function getSavedMonthlyEmployeeWorkScheduleFilter(
  user: IUser,
  pathname: string,
): IMonthlyEmployeeWorkScheduleFilter {
  let settings: any = storage.get(`workplan.${user.id}`)
  if (!isPlainObject(settings)) {
    settings = {}
  }
  const filter: IMonthlyEmployeeWorkScheduleFilter = {
    month: dayjs().format('YYYY-MM'),
    date: dayjs().format('YYYY-MM-DD'),
    viewId: '',
    employeePoolId: '',
    selectedUser: '',
    activeBuilding: '',
    shiftId: '',
    type: (pathname.split('/')[3] as 'plan' | 'real') || 'plan',
  }
  if (isMonth(settings.month)) {
    filter.month = settings.month
  }
  if (isYmdDate(settings.date)) {
    filter.date = settings.date
  }
  if (isIntegerString(settings.viewId)) {
    filter.viewId = settings.viewId
  }
  if (isIntegerString(settings.employeePoolId)) {
    filter.employeePoolId = settings.employeePoolId
  }
  if (isIntegerString(settings.activeBuilding)) {
    filter.activeBuilding = settings.activeBuilding
  }
  if (isIntegerString(settings.shiftId)) {
    filter.shiftId = settings.shiftId
  }
  return filter
}
