import { Button } from 'components/Form/components/Button'
import { Model } from 'components/Form/Model'
import { IfOutsideClick } from 'components/IfOutsideClick'
import { IBuilding } from 'contracts/accommodations/interfaces/IBuilding'
import { ICompound } from 'contracts/accommodations/interfaces/ICompound'
import { IMonthlyEmployeeWorkScheduleFilter } from 'contracts/workplan/interfaces/IMonthlyEmployeeWorkScheduleFilter'
import { IShift } from 'contracts/workplan/interfaces/IShift'
import { observable, makeObservable, action, reaction } from 'mobx'
import * as React from 'react'
import { box } from 'services/box'
import { EditShiftDialog } from '../EditShiftDialog'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { observer } from 'mobx-react'
import { SelectShift } from './components/SelectShift'
import { Collection } from '@byll/hermes'
import { IShortlistEntry } from 'contracts/workplan/interfaces/IShortlistEntry'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Shortlist } from './components/Shortlist'
import { ShiftLabel } from './components/ShiftLabel'

interface Props {
  model: Model<IMonthlyEmployeeWorkScheduleFilter>
  selectShiftModel: Model<{
    selectedBuilding: IBuilding | null
    selectedShift: IShift | null
    compoundId: string
    view: 'select' | 'shortlist'
  }>
  compounds: ICompound[]
  buildings: IBuilding[]
  shifts: IShift[]
  loadShifts: () => void
}

@observer
export class SelectShiftShortlist extends React.Component<Props, {}> {
  static contextType = AppContext
  private shortlistEntries: Collection<IShortlistEntry>
  @observable private showDropdown: boolean = false
  private disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    this.shortlistEntries = new Collection<IShortlistEntry>(
      `/api/${context.instance.id}/workplan/shortlistEntries`,
    )
  }

  componentDidMount(): void {
    this.disposers.push(this.shortlistEntries.init({ readOnly: true }))
    this.disposers.push(
      reaction(
        () => this.props.model.values.shiftId,
        () =>
          this.getModelConfig(
            this.props.model.values.shiftId,
            this.props.compounds,
            this.props.buildings,
            this.props.shifts,
          ),
        { fireImmediately: true },
      ),
    )
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  @action
  private getModelConfig = (
    shiftId: string,
    compounds: ICompound[],
    buildings: IBuilding[],
    shifts: IShift[],
  ) => {
    const shift = shifts.find((s) => s.id === shiftId)
    if (!shift) {
      return
    }
    for (const compound of compounds) {
      if (!compound.id) {
        continue
      }
      for (const building of buildings) {
        if (building.compoundId !== compound.id) {
          continue
        }
        if (building.id === shift.buildingId) {
          this.props.selectShiftModel.values.compoundId = compound.id

          this.props.selectShiftModel.values.selectedBuilding = building
          this.props.model.values.activeBuilding = building.id

          this.props.selectShiftModel.values.selectedShift = shift
          this.props.model.values.shiftId = shift.id
          return
        }
      }
    }
  }

  @action
  private toggleDropdown = (e) => {
    e.stopPropagation()
    this.showDropdown = !this.showDropdown
  }

  private editShift = async () => {
    const shift = this.props.shifts.find((s) => s.id === this.props.model.values.shiftId)
    if (!shift) {
      return
    }
    const promise = box.custom(
      <EditShiftDialog
        shift={shift}
        filterModel={this.props.model}
        buildings={this.props.buildings}
        onClose={(result?: boolean) => promise.close(result)}
      />,
      { closable: true, context: this.context, size: 'lg' },
    )
    const result = await promise
    if (result) {
      this.props.loadShifts()
    }
  }

  render() {
    return (
      <div className='relative'>
        <div className='flex'>
          <ShiftLabel
            key={this.props.model.values.shiftId}
            shiftId={this.props.model.values.shiftId}
            selectShiftModel={this.props.selectShiftModel}
            toggleDropdown={this.toggleDropdown}
          />
          {this.props.model.values.shiftId && (
            <Button
              onClick={this.editShift}
              color='secondary'
              outline
              style={{ borderRadius: '0 0.375rem 0.375rem 0', marginLeft: '-1px' }}
            >
              <span>
                <i className='fa fa-edit' />
              </span>
            </Button>
          )}
        </div>
        {this.showDropdown && (
          <IfOutsideClick onOutsideClick={this.toggleDropdown}>
            <div className='w-[250px] absolute top-14 z-20 bg-white p-4 border border-gray-300 rounded-md shadow-md'>
              {this.shortlistEntries.resources &&
                this.props.selectShiftModel.values.view === 'shortlist' && (
                  <Shortlist
                    key={this.shortlistEntries.resources.length}
                    model={this.props.model}
                    selectedModel={this.props.selectShiftModel}
                    shortlistEntries={this.shortlistEntries}
                    toggleDropdown={this.toggleDropdown}
                  />
                )}
              {(!this.shortlistEntries.resources ||
                this.props.selectShiftModel.values.view === 'select') && (
                <SelectShift
                  model={this.props.model}
                  selectedModel={this.props.selectShiftModel}
                  buildings={this.props.buildings}
                  compounds={this.props.compounds}
                  shifts={this.props.shifts}
                  shortlistEntries={this.shortlistEntries}
                  toggleDropdown={this.toggleDropdown}
                />
              )}
            </div>
          </IfOutsideClick>
        )}
      </div>
    )
  }
}
