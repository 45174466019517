import { hermes } from '@byll/hermes'
import { RequestPendingError } from '@byll/hermes/lib/errors/HermesErrors'
import { XIcon } from '@heroicons/react/outline'
import { Dialog } from 'components/Dialog'
import { IEmployeeReportDefinition } from 'contracts/workload/interfaces/IEmployeeReportDefinition'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Dialog as UIDialog } from '@headlessui/react'
import { NotAuthorizedError, NotFoundError } from 'contracts/errors/HermesErrors'
import { LoadingError } from 'components/Callout/components/LoadingError'
import { Button } from 'components/Form/components/Button'
import { NotFound } from 'components/Callout/components/NotFound'
import { observer } from 'mobx-react'
import { Disposer, dispose } from '@byll/hermes/lib/helpers/Disposer'
import { ReportForm } from './components/ReportForm'
import { makeObservable, observable, runInAction } from 'mobx'
import * as uuid from 'uuid'

interface Props {
  definitionId: string
  onClose: () => void
  loadDefinitionOptions: () => void
}

@observer
export class ReportDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private definition: IEmployeeReportDefinition | null = null
  @observable private error: any = null
  private readonly disposers: Disposer[] = []

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    if (context.permissions.workplan_customReports < 2) {
      this.error = new NotAuthorizedError()
    }
  }

  componentDidMount(): void {
    if (this.props.definitionId !== 'new') {
      this.loadDefinition()
    } else {
      runInAction(() => {
        this.definition = {
          id: '',
          instanceId: this.context.instance.id,
          label: '',
          columns: [
            {
              id: uuid.v4(),
              section: 'Name',
              column: 'nachname',
              aggregate: null,
              aggregateValue: '',
              selected: null,
            },
            {
              id: uuid.v4(),
              section: 'Name',
              column: 'vorname',
              aggregate: null,
              aggregateValue: '',
              selected: null,
            },
          ],
        }
      })
    }
  }

  componentWillUnmount(): void {
    dispose(this.disposers)
  }

  private loadDefinition = async () => {
    try {
      const def = await hermes.getOnceNew<IEmployeeReportDefinition>(
        `/api/${this.context.instance.id}/workload/reportDefinitions/${this.props.definitionId}`,
      )
      runInAction(() => (this.definition = def))
    } catch (e) {
      runInAction(() => (this.error = e))
    }
  }

  render() {
    if (this.error && this.error?.id !== RequestPendingError.id) {
      return (
        <Dialog size='lg' open setOpen={this.props.onClose}>
          <div>
            <div className='absolute top-0 right-0 pt-4 pr-6'>
              <button
                type='button'
                className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                onClick={this.props.onClose}
              >
                <span className='sr-only'>Close</span>
                <XIcon className='h-6 w-6' aria-hidden='true' />
              </button>
            </div>

            <div className='px-6 pt-6 pb-4 border-b border-gray-200'>
              <div className='flex items-start'>
                <div className='-mt-2 text-left'>
                  <UIDialog.Title
                    as='h3'
                    className='text-lg leading-6 font-medium text-gray-900'
                  >
                    Report
                  </UIDialog.Title>
                </div>
              </div>
            </div>

            <div className='py-10 bg-gray-100'>
              {this.error.id !== NotFoundError.id && (
                <LoadingError
                  title={
                    this.error.id === NotAuthorizedError.id
                      ? 'Keine Sichtberechtigung für diesen Report'
                      : 'Dieser Reporttyp konnte nicht geladen werden'
                  }
                  subtitle='Bitte wenden Sie sich an einen Administrator'
                />
              )}
              {this.error.id === NotFoundError.id && (
                <NotFound
                  title='Dieser Reporttyp konnte nicht gefunden werden'
                  subtitle='Möglicherweise wurde er in der Zwischenzeit gelöscht'
                />
              )}
            </div>

            <div
              className='py-4 px-6 sticky z-1 text-right bottom-0 bg-white border-t border-gray-200'
              style={{ borderRadius: '0 0 8px 8px' }}
            >
              <Button color='secondary' outline onClick={this.props.onClose}>
                Schließen
              </Button>
            </div>
          </div>
        </Dialog>
      )
    }

    if (!this.definition) {
      return null
    }
    return (
      <Dialog size='lg' open setOpen={this.props.onClose}>
        <ReportForm
          definition={this.definition}
          loadDefinitionOptions={this.props.loadDefinitionOptions}
          onClose={this.props.onClose}
        />
      </Dialog>
    )
  }
}
