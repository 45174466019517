import { Collection } from '@byll/hermes'
import { Menu, Transition } from '@headlessui/react'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { IEmployeeFileBlock } from 'contracts/employees/interfaces/IEmployeeFileBlock'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import * as React from 'react'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'
import * as uuid from 'uuid'
import { EmployeeFileEditDialog } from './EmployeeFileEditDialog'
import cloneDeep from 'lodash/cloneDeep'
import { observer } from 'mobx-react'
import { ContractEditDialog } from './ContractPreview/components/ContractEditDialog'
import { IEmployeeContractTerm } from 'contracts/employees/interfaces/IEmployeeContractTerm'

interface Props {
  employeeFileBlocks: Collection<IEmployeeFileBlock>
  employee: IEmployeeSearchResult
  options: InputSelectOption[]
}

@observer
export class EmployeeBlockDropdownButton extends React.Component<Props, {}> {
  static contextType = AppContext

  private openEditDialog = (blockId: string) => {
    const block = this.props.employeeFileBlocks.resources?.find(
      (b) => b.data?.id === blockId,
    )
    if (!block) {
      return
    }
    const copiedBlock = cloneDeep(block.data)
    const id = uuid.v4()
    if (blockId === 'employment-contracts') {
      let contractData: {
        contract: any
        contractTerms: IEmployeeContractTerm[]
      } | null = null
      const promise = box.custom(
        <ContractEditDialog
          id={id}
          block={copiedBlock}
          contractData={contractData}
          employee={this.props.employee}
          onClose={() => promise.close()}
        />,
        { closable: true, context: this.context, size: 'lg' },
      )
    } else {
      const promise = box.custom(
        <EmployeeFileEditDialog
          id={id}
          block={copiedBlock}
          employee={this.props.employee}
          onClose={() => promise.close()}
        />,
        { closable: true, context: this.context, size: 'lg' },
      )
    }
  }

  render() {
    if (this.props.options.length === 0) {
      return null
    }
    return (
      <Menu as='div' className='relative'>
        <Menu.Button className='text-white bg-gradient-to-r from-indigo-500 to-indigo-600 hover:bg-gradient-to-r hover:from-indigo-500 hover:to-indigo-500 focus:ring-indigo-500 text-center rounded-full shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 py-3 px-4'>
          <span>
            <i className='fa fa-plus' />
          </span>
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            className={`bottom-12 origin-bottom-left absolute mt-2 py-2 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
            style={{ width: 224 }}
          >
            {this.props.options.map((option) => (
              <Menu.Item key={option.value}>
                {({ active }) => (
                  <button
                    onClick={() => this.openEditDialog(option.value)}
                    className={`${
                      active ? 'bg-blue-500 text-white' : 'text-gray-900'
                    } group flex items-center w-full px-3 py-2 text-sm`}
                  >
                    {option.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    )
  }
}
