import { hermes } from '@byll/hermes'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Model } from 'components/Form/Model'
import { Button } from 'components/Form/components/Button'
import { InputBlock } from 'components/Form/components/InputBlock'
import { InputForm } from 'components/Form/components/InputForm'
import { Message } from 'components/Message'
import { IEmployeeFileBlock } from 'contracts/employees/interfaces/IEmployeeFileBlock'
import { IEmployeeSearchResult } from 'contracts/employees/interfaces/IEmployeeSearchResult'
import { ConflictError } from 'contracts/errors/HermesErrors'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  block: IEmployeeFileBlock
  id?: string
  employee: IEmployeeSearchResult
  onClose: () => void
}

@observer
export class EmployeeFileEditDialog extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private error: string | null = null
  private model: Model<IEmployeeFileBlock>

  constructor(props: Props) {
    super(props)
    makeObservable(this)
    if (props.id) {
      let details = props.block.details.find((d) => d.id === props.id)
      if (!details) {
        details = { id: props.id }
      }
      this.model = new Model({ ...props.block, details })
    } else {
      this.model = new Model({ ...props.block })
    }
  }

  private saveBlock = async () => {
    try {
      let details = this.model.values.details
      if (Array.isArray(this.props.block.details)) {
        if (
          this.props.id &&
          this.props.block.details.find((d) => d.id === this.props.id)
        ) {
          this.props.block.details.splice(
            this.props.block.details.findIndex((d) => d.id === this.props.id),
            1,
            this.model.values.details,
          )
          details = this.props.block.details
        } else {
          details = [this.model.values.details, ...this.props.block.details]
        }
      }
      await hermes.patch(
        `/api/${this.context.instance.id}/employees/${this.props.employee.id}/employeeFileBlocks/${this.props.block.id}`,
        { details },
      )
      this.props.onClose()
    } catch (e: any) {
      if (e?.id === ConflictError.id) {
        runInAction(() => (this.error = e.message))
      } else {
        runInAction(
          () =>
            (this.error =
              'Beim Speichern der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie den Systemadministrator.'),
        )
      }
    }
  }

  render() {
    const { block } = this.props
    return (
      <div>
        <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4 z-20'>
          <button
            type='button'
            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={() => this.props.onClose()}
          >
            <span className='sr-only'>Close</span>
            <XIcon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <div
          className={`sticky ${
            this.error ? '-top-7' : '-top-11'
          } bg-white -mx-6 px-6 border-b border-gray-300 z-10`}
        >
          <div className='pb-4 pt-1 -mt-2 text-left'>
            <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
              {block.label}{' '}
              {this.props.block.multiple && this.model.values.id.includes('-')
                ? 'hinzufügen'
                : 'bearbeiten'}
            </Dialog.Title>
          </div>
          {this.error && (
            <Message className='mb-6' color='danger'>
              {this.error}
            </Message>
          )}
        </div>
        <InputForm onSubmit={this.saveBlock}>
          <div className='sm:-mx-6 -mx-4 bg-gray-100 py-6'>
            <InputBlock
              id={block.id}
              name='details'
              label={block.label}
              model={this.model}
              mandatoryFields={block.mandatoryFields}
            />
          </div>
        </InputForm>
        <div className='flex gap-2 justify-end sticky z-1 text-right bottom-0 bg-white -mx-6 border-t border-gray-300 p-4 -mb-4'>
          <Button color='secondary' outline onClick={this.props.onClose}>
            Abbrechen
          </Button>
          <Button onClick={this.saveBlock}>Speichern</Button>
        </div>
      </div>
    )
  }
}
